import { ArrayPaging } from './types'
/**
 * @param totalPage
 * @param page
 */
export function makeArrayPaging(totalPage: number, page: number): ArrayPaging {
  const array: ArrayPaging = []
  if (totalPage <= 9) {
    if (totalPage >= 0 && totalPage <= 9) {
      for (let i = 1; i <= totalPage; i++) {
        array.push(i)
      }
    }
    return array
  }
  if (page <= 6) {
    for (let i = 1; i <= 7; i++) {
      array.push(i)
    }
    array.push('space')
    array.push(totalPage)
    return array
  }
  if (page >= totalPage - 3) {
    array.push(1)
    array.push('space')
    for (let i = 1; i <= 5; i++) {
      array.push(totalPage - (5 - i))
    }
    return array
  }
  array.push(1)
  array.push('space')
  for (let i = page - 2; i <= page + 2; i++) {
    array.push(i)
  }
  array.push('space')
  array.push(totalPage)
  return array
}
