import React, { FC, useCallback, useState } from 'react'
import { Flex } from '@chakra-ui/layout'
import styled from '@emotion/styled'
import { IconButton, Tooltip } from '@chakra-ui/react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { SelectForm } from '../../../../components/modules/SelectForm'
import { Option } from '../../../../../types'
import { Input } from '../../../../components/elements/Input/Input'
import { ErrorText } from '../../../../components/elements/ErrorText'

const StyledWrapper = styled(Flex)`
  align-items: center;
  gap: 12px;
`

const StyledSelectForm = styled(SelectForm)`
  max-width: 300px;
`

const StyledInput = styled(Input)`
  max-width: 300px;
`

type FlexibleOptionProps = {
  name: string
  error?: string
  label: string
  options?: Option[]
  isRequired?: boolean
  value?: string
  placeholder?: string
  otherValue?: string
  isLoading?: boolean
  onChangeSelect: (name: string, option: Option | unknown) => void
  onChangeInput: (name: string, value?: string) => void
}

/**
 * FlexibleOption component page.
 * @return {JSX.Element} FlexibleOption component.
 */
export const FlexibleOption: FC<FlexibleOptionProps> = ({
  name,
  error,
  options,
  placeholder,
  onChangeSelect,
  onChangeInput,
  value,
  label,
  isRequired,
  otherValue,
  isLoading,
}): JSX.Element => {
  const [isShowInput, setIsShowInput] = useState<boolean>(false)

  const handleShowInput = useCallback(() => {
    setIsShowInput(!isShowInput)
    if (!isShowInput) {
      onChangeSelect(name, { value: '', label: '' })
    } else {
      onChangeInput(`other_${name}`, undefined)
    }
  }, [isShowInput, name, onChangeInput, onChangeSelect])
  return (
    <StyledWrapper>
      <StyledSelectForm
        direction={'horizontal'}
        error={error}
        isDisabled={isShowInput}
        isLoading={isLoading}
        isRequired={isRequired}
        label={label}
        labelWidth={60}
        options={options}
        placeholder={placeholder}
        value={isShowInput ? null : options?.find(item => item.value === value)}
        onChange={option => onChangeSelect(name, option)}
      />
      <Tooltip bg={'#000000'} label={`Add other ${name}`} placement="top-start">
        <IconButton
          aria-label="button flexible"
          icon={isShowInput ? <MinusIcon /> : <AddIcon />}
          mb={error ? 6 : 0}
          onClick={handleShowInput}
        />
      </Tooltip>
      {isShowInput && (
        <Flex flexDirection={'column'} gap={2} w={'full'}>
          <StyledInput
            isInvalid={!!error}
            name={`other_${name}`}
            value={otherValue}
            onChange={e => onChangeInput(`other_${name}`, e.target.value)}
          />
          {error && <ErrorText />}
        </Flex>
      )}
    </StyledWrapper>
  )
}
