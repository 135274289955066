import React from 'react'

import { ProductListScreen } from './components/modules'

/**
 * @returns Component ProductList Page
 */
export function ProductListPage() {
  return <ProductListScreen />
}
