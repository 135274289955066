import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import moment from 'moment/moment'
import { SearchIcon } from '@chakra-ui/icons'
import { IoMdStar } from 'react-icons/io'
import { InputForm } from '../../../../../components/modules/InputForm'
import { TableCustom } from '../../../../../components/elements/TableCustom'
import { Pagination } from '../../../../../components/modules/Pagination'
import { InputDateTime } from '../../../../../components/elements/Input/InputDateTime'
import { useEditUser } from './hook'

/**
 * @returns Component UserEditScreen Page
 */
export const UserEditScreen = () => {
  const {
    handleSubmit,
    errors,
    handleChangeInput,
    userDetail,
    currentPage,
    totalPage,
    handleNextPage,
    dataUserSurvey,
    handleChangeStartDate,
    handleChangeEndDate,
    valueStartAt,
    valueEndAt,
    textValidate,
    handleReset,
    handleSearch,
    isLoading,
  } = useEditUser()

  const columns = [
    {
      title: 'Star',
      render: record => (
        <Flex alignItems={'center'} justifyContent={'flex-start'}>
          {record?.star} <IoMdStar color={'yellow'} size={'20px'} />
        </Flex>
      ),
    },
    {
      title: 'App Favorites',
      render: record => <Box>{record?.service}</Box>,
    },
    {
      title: 'App Wish',
      render: record => <Box>{record?.addedOrImproved}</Box>,
    },
    {
      title: 'created At',
      render: record => (
        <Box>{moment(record?.createdAt).format('YYYY/MM/DD')}</Box>
      ),
    },
  ]
  return (
    <>
      <TitleText>Edit Profile User</TitleText>
      <StyledContainer>
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.email?.message}
          isInvalid={!!errors?.email?.message}
          label={'Email'}
          labelWidth={60}
          name="email"
          placeholder={''}
          value={userDetail?.email}
          onChange={handleChangeInput}
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.phoneNumber?.message}
          isInvalid={!!errors?.phoneNumber?.message}
          label={'Phone Number'}
          labelWidth={60}
          name="phoneNumber"
          placeholder={''}
          value={userDetail?.phoneNumber}
          onChange={handleChangeInput}
        />
      </StyledContainer>
      <StyledWrapperButton>
        <StyledButtonSubmit onClick={() => handleSubmit(Number(userDetail.id))}>
          Submit
        </StyledButtonSubmit>
      </StyledWrapperButton>
      <BoxDecan />
      <TitleText>View User Survey</TitleText>
      <Flex
        alignItems={'center'}
        gap={10}
        justifyContent={'center'}
        m={'40px 0'}
        w={'full'}
      >
        <Button
          _hover={{ bg: '#222', cursor: 'pointer' }}
          bg={'#222'}
          color={'#fff'}
          h={'32px'}
          w={'91px'}
          onClick={handleReset}
        >
          Reset
        </Button>
        <InputDateTime
          direction="horizontal"
          isShowTime={false}
          label={'Start Date'}
          value={valueStartAt ? new Date(valueStartAt) : undefined}
          onChange={date => {
            handleChangeStartDate(date)
          }}
        />
        <InputDateTime
          direction="horizontal"
          isShowTime={false}
          label={'End Date'}
          value={valueEndAt ? new Date(valueEndAt) : undefined}
          onChange={date => {
            handleChangeEndDate(date)
          }}
        />
        <Button
          _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
          bg={'#3F6FBA'}
          color={'#fff'}
          leftIcon={<SearchIcon />}
          p={'0px 34px'}
          w={160}
          onClick={handleSearch}
        >
          Search
        </Button>
      </Flex>
      <Box>{textValidate && <TextError>{textValidate}</TextError>}</Box>
      {dataUserSurvey && !!dataUserSurvey.length && (
        <>
          <Box overflowX={'auto'} w={'100%'}>
            <TableCustom columns={columns} data={dataUserSurvey || []} />
          </Box>
          <Box py={5}>
            <Pagination
              page={currentPage}
              totalPage={totalPage}
              onChange={handleNextPage}
            />
          </Box>
        </>
      )}
      {dataUserSurvey?.length === 0 && !isLoading && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          No Data
        </Flex>
      )}
    </>
  )
}

const StyledContainer = styled(Flex)`
  gap: 20px;
  flex-direction: column;
  margin: 40px;
`
const StyledInputForm = styled(InputForm)`
  max-width: 300px;
`
const StyledWrapperButton = styled(Flex)`
  justify-content: center;
`
const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  height: 32px;
  border-radius: 25px;
  background: #385187;
  color: #ffffff;
  &:hover {
    background: #385187;
    cursor: pointer;
  }
`

const TextError = styled(Text)`
  color: #ba1a1a;
  text-align: center;
`
const BoxDecan = styled(Box)`
  border-bottom: 1px solid black;
  height: 40px;
`

const TitleText = styled(Text)`
  padding-top: 20px;
  font-weight: 700;
  font-size: 20px;
  color: #000;
`
