import React from 'react'
import { PromotedBrandScreen } from './components/templates'

/**
 * PromotionPage component page.
 * @return {JSX.Element} PromotionPage component.
 */
export function PromotionBrandPage() {
  return <PromotedBrandScreen />
}
