/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  AxiosError,
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosRequestConfig,
} from 'axios'
import {
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeAccessToken,
  removeRefreshToken,
} from '../service/storageServices'
import { refresh } from '../service/authService'
import { setAuthorizationToken } from './axios'

interface ICustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean
  _queued?: boolean
}

type CustomAxiosError = AxiosError & {
  config: ICustomAxiosRequestConfig
}

interface ITokenData {
  accessToken: string
  refreshToken: string
}

type FailedQueue = Array<{
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resolve: (value: string | null | PromiseLike<string | null>) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unused-vars
  reject: (reason?: any) => void
}>

/**
 * @returns function handle check response status
 */
const shouldIntercept = (error: CustomAxiosError) =>
  error.response?.status === 401

/**
 * @returns function handle set token
 */
const setTokenData = (axiosClient: AxiosInstance, tokenData?: ITokenData) => {
  if (!tokenData) return
  axiosClient.defaults.headers.common.Authorization = `Bearer ${tokenData.accessToken}`
}

/**
 * @returns function handle refresh token
 */
async function handleTokenRefresh() {
  const refreshToken = getRefreshToken()

  if (!refreshToken) {
    window.location.replace('/login')
    throw new Error('refresh token not found')
  }

  try {
    const credentials = await refresh(refreshToken)
    setAccessToken(credentials.accessToken)
    setRefreshToken(credentials.refreshToken)
    return credentials
  } catch (error) {
    console.error('Error refreshing token:', error)
    // Handle any error that occurred during the token refresh
    throw error
  }
}

/**
 * @returns function handle set token
 */
const attachTokenToRequest = (request: AxiosRequestConfig, token?: string) => {
  if (request.headers) {
    request.headers.Authorization = `Bearer ${token}`
    setAuthorizationToken(token)
  }
}

/**
 * @example
 * const axiosInstance = axios.create({...});
 * applyAppTokenRefreshInterceptor(axiosInstance);
 */
export function applyAppTokenRefreshInterceptor(axiosClient: AxiosInstance) {
  let isRefreshing = false
  let failedQueue: FailedQueue = []

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    setTokenData,
    shouldIntercept,
  }

  /**
   * @returns function handle set process queue
   */
  const processQueue = (error: any, token: null | string = null) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const prom of failedQueue) {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    }
    failedQueue = []
  }

  /**
   * @returns function handle reject interceptor
   */
  const onRejectInterceptor: Parameters<
    AxiosInterceptorManager<unknown>['use']
  >[1] = async (error: CustomAxiosError) => {
    if (!error.response) {
      // TODO need to do later
    }
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error)
    }
    if (error.config._retry || error.config._queued) {
      return Promise.reject(error)
    }

    const originalRequest = error.config
    if (isRefreshing) {
      try {
        const token = await new Promise<string | null>((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
        originalRequest._queued = true
        if (token === null) {
          throw new Error('token is null')
        }
        options.attachTokenToRequest(originalRequest, token)
        return axiosClient.request(originalRequest)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        return Promise.reject(error)
      }
    }

    originalRequest._retry = true
    isRefreshing = true
    try {
      const tokenData = await options.handleTokenRefresh()
      options.setTokenData(axiosClient, tokenData)
      options.attachTokenToRequest(originalRequest, tokenData?.accessToken)
      processQueue(null, tokenData?.accessToken)
      isRefreshing = false
      return axiosClient.request(originalRequest)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err?.response?.status === 401 || err?.response?.status === 403) {
        window.location.replace('/login')
        removeAccessToken()
        removeRefreshToken()
      }
      processQueue(err, null)
      throw err
    }
  }

  axiosClient.interceptors.response.use(undefined, onRejectInterceptor)
}
