export type Option = {
  value: string
  label: string
}
export const OptionSelect: Option[] = [
  {
    label: 'Attributes',
    value: 'user-attributes',
  },
  {
    label: 'Routine Activity',
    value: 'routine-log',
  },
  {
    label: 'Routine Summaries',
    value: 'routine-analysis-history-log',
  },
]

export type OptionAttributes = {
  value: number
  label: string
}
export const OptionSelectAttributes: OptionAttributes[] = [
  {
    label: 'Acne',
    value: 1,
  },
  {
    label: 'Anti-Aging',
    value: 2,
  },
  {
    label: 'Allergy: Acrylates',
    value: 3,
  },
  {
    label: 'Allergy: Balsam of Peru',
    value: 4,
  },
  {
    label: 'Allergy: Benzisothiazolinone',
    value: 5,
  },
  {
    label: 'Allergy: Cobalt',
    value: 6,
  },
  {
    label: 'Allergy: Cocamide DEA',
    value: 7,
  },
  {
    label: 'Allergy: Cocamidopropyl betaine',
    value: 8,
  },
  {
    label: 'Allergy: Colophony',
    value: 9,
  },
  {
    label: 'Allergy: Decyl Glucoside',
    value: 10,
  },
  {
    label: 'Allergy: Diazolidinyl Urea',
    value: 11,
  },
  {
    label: 'Allergy: Dimethylaminopropylamine',
    value: 12,
  },
  {
    label: 'Allergy: DMDM Hydantoin',
    value: 13,
  },
  {
    label: 'Allergy: Formaldehyde',
    value: 14,
  },
  {
    label: 'Allergy: Fragrance',
    value: 15,
  },
  {
    label: 'Allergy: Imidazolidinyl Urea',
    value: 16,
  },
  {
    label: 'Allergy: Iodopropynyl Butylcarbamate',
    value: 17,
  },
  {
    label: 'Allergy: Lanolin',
    value: 18,
  },
  {
    label: 'Allergy: Limonene',
    value: 19,
  },
  {
    label: 'Allergy: Linalool',
    value: 20,
  },
  {
    label: 'Allergy: Methylchloroisothiazolinone',
    value: 21,
  },
  {
    label: 'Allergy: Methyldibromoglutaronitrile',
    value: 22,
  },
  {
    label: 'Allergy: Methylisothiazolinone',
    value: 23,
  },
  {
    label: 'Allergy: Nickel',
    value: 24,
  },
  {
    label: 'Allergy: Oleamidopropyl Dimethylamine',
    value: 25,
  },
  {
    label: 'Allergy: Paraphenylenediamine',
    value: 26,
  },
  {
    label: 'Allergy: Propolis',
    value: 27,
  },
  {
    label: 'Allergy: Propylene Glycol',
    value: 28,
  },
  {
    label: 'Allergy: Quaternium-15',
    value: 29,
  },
  {
    label: 'Allergy: Sodium Metabisulfite',
    value: 30,
  },
  {
    label: 'Allergy: Tea Tree Oil',
    value: 31,
  },
  {
    label: 'Allergy: Tocopherol (Vitamin E)',
    value: 32,
  },
  {
    label: 'Black skin',
    value: 33,
  },
  {
    label: 'Black/Afro-Textured/Kinky Hair',
    value: 34,
  },
  {
    label: 'Cracker heels',
    value: 35,
  },
  {
    label: 'Cracker hair',
    value: 36,
  },
  {
    label: 'Cold & dry climate',
    value: 37,
  },
  {
    label: 'Combination face skin',
    value: 38,
  },
  {
    label: 'Dullness',
    value: 39,
  },
  {
    label: 'Dry face skin',
    value: 40,
  },
  {
    label: 'Dry body skin',
    value: 41,
  },
  {
    label: 'Dry hands',
    value: 42,
  },
  {
    label: 'Dry hair',
    value: 43,
  },
  {
    label: 'Dandruff',
    value: 44,
  },
  {
    label: 'Damaged hair',
    value: 45,
  },
  {
    label: 'Eczema',
    value: 46,
  },
  {
    label: 'Keratosis pilaris',
    value: 47,
  },
  {
    label: 'Rosacea',
    value: 48,
  },
  {
    label: 'Redness',
    value: 49,
  },
  {
    label: 'Psoriasis',
    value: 50,
  },
  {
    label: 'Pregnant of breastfeeding',
    value: 51,
  },
  {
    label: 'Hyperpigmentation',
    value: 52,
  },
  {
    label: 'Hair loss',
    value: 53,
  },
  {
    label: 'Hypoallergenic',
    value: 54,
  },
  {
    label: 'Oily face skin',
    value: 55,
  },
  {
    label: 'Oily hair',
    value: 56,
  },
]
