import React from 'react'
import { Box, Text, TextProps } from '@chakra-ui/react'

type ErrorFormProps = TextProps

/**
 * @returns Component ErrorForm
 */
export const ErrorText = (props: ErrorFormProps) => {
  const { children, ...rest } = props
  return (
    <Box minH={'14px'} mt={'4px'}>
      <Text
        color={'input_error'}
        fontFamily={'Hiragino Sans'}
        fontSize={'12px'}
        {...rest}
      >
        {children}
      </Text>
    </Box>
  )
}
