import { useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { twoFactorAuth } from '../../../../../service/authService'
import {
  setAccessToken,
  setRefreshToken,
} from '../../../../../service/storageServices'

/***
 *
 * Returns: useLogin
 */
export const useTwoFactorAuth = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [value, setValue] = useState('')
  const [error, setError] = useState('')
  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation()
  const handleSubmit = useCallback(async () => {
    setIsLoading(true)
    try {
      if (value) {
        const { accessToken, refreshToken } = await twoFactorAuth({
          otp: value,
          email: location.state as string,
        })
        setAccessToken(accessToken)
        setRefreshToken(refreshToken)
        navigate('/', { replace: true })
      } else setError('Two Factor Auth required')
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [location.state, navigate, toast, value])

  const handleChange = useCallback(event => {
    setValue(event.target.value)
    setError('')
  }, [])

  return {
    isLoading,
    handleSubmit,
    handleChange,
    value,
    error,
  }
}

export type Props = ReturnType<typeof useTwoFactorAuth>
