import qs from 'qs'
import {
  AuthServiceConverted,
  AuthServiceResponse,
  TLoginData,
} from '../types/Login'
import { axiosWithoutToken, axiosWithToken } from '../configureAxios'

/**
 * @returns get profile
 * */
export const getProfile = async () => {
  const { data } = await axiosWithToken.get('/users/profile')
  return data.info
}

/**
 * @returns function handle convert snake to camel credential response
 */
const convertAuthResponse = (
  response: AuthServiceResponse,
): AuthServiceConverted => {
  return {
    accessToken: response.access_token,
    refreshToken: response.refresh_token,
  }
}

/**
 * @returns login
 * */
export const login = async (data: TLoginData) => {
  const res = await axiosWithoutToken.post('/login', data)
  return res.data
}

/**
 * @returns auth
 * */
export const twoFactorAuth = async (data: {
  otp: string
  email: string
}): Promise<AuthServiceConverted> =>
  convertAuthResponse(
    (await axiosWithoutToken.post('/login/verify', data)).data,
  )

/**
 * @returns function handle refresh token
 */
export const refresh = async (
  refreshToken: string,
): Promise<AuthServiceConverted> =>
  convertAuthResponse(
    (
      await axiosWithoutToken.post(
        '/refresh-token',
        {},
        {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        },
      )
    ).data,
  )

/**
 * @returns get Dashboard
 * */
export const getDashboard = async () => {
  const { data } = await axiosWithToken.post('/admin/statistical')
  return data
}

type UserRoutineType = {
  isRemove?: boolean
  page: number
  limit: number
  startAt?: string
  endAt?: string
  keywordType?: number
  word?: string
}

/**
 * @returns getUserRoutine
 * */
export const getUserRoutine = async (params: UserRoutineType, type: string) => {
  const { data } = await axiosWithToken.get(`/admin/statistical/${type}`, {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns getDetailProductRoutine
 * */
export const getDetailProductRoutine = async (params: UserRoutineType) => {
  const { data } = await axiosWithToken.get(
    '/admin/statistical/user-routine-detail',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}

/**
 * @returns getDetailProductRoutine
 * */
export const getDetailRetailerViews = async (params: UserRoutineType) => {
  const { data } = await axiosWithToken.get(
    '/admin/statistical/retailer-link-user-detail',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}

/**
 * @returns getDetailProductRoutine
 * */
export const getDetailProductViews = async (params: UserRoutineType) => {
  const { data } = await axiosWithToken.get(
    '/admin/statistical/product-page-log-detail',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}

/**
 * @returns getDetailOfProductViews
 * */
export const getDetailOfProductViews = async (params: UserRoutineType) => {
  const { data } = await axiosWithToken.get(
    '/admin/statistical/product-page-log-detail-of-detail',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}

/**
 * @returns getAllProductViews
 * */
export const getAllProductViews = async (params: UserRoutineType) => {
  const { data } = await axiosWithToken.get(
    '/admin/statistical/product-page-log-history',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}

/**
 * @returns get Product Routine
 * */
export const getTopProduct = async (params: UserRoutineType, type: string) => {
  const { data } = await axiosWithToken.get(`/admin/statistical/${type}`, {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns get User Routine
 * */
export const getTotalUserRoutine = async () => {
  const { data } = await axiosWithToken.post(`/admin/statistical`)
  return data
}

/**
 * @returns getIndividualUser
 * */
export const getIndividualUser = async (
  params: UserRoutineType,
  type: string,
) => {
  const { data } = await axiosWithToken.get(`/admin/statistical/${type}`, {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns getMonthlyUserUseApp
 * */
export const getMonthlyUserUseApp = async (params: UserRoutineType) => {
  const { data } = await axiosWithToken.get(
    '/user-survey/statistical/monthly-user-using-app',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}

/**
 * @returns getProductView
 * */
export const getProductView = async (params: UserRoutineType, type: string) => {
  const { data } = await axiosWithToken.get(`/admin/statistical/${type}`, {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

type ExportFileType = {
  page?: number
  limit?: number
  isExport?: boolean
  isExportAll?: boolean
}

/**
 * @returns exportFileRoutineLog
 * */
export const exportFileRoutineLog = async (
  params: ExportFileType,
  type: string,
) => {
  const { data } = await axiosWithToken.get(`/admin/statistical/${type}`, {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns exportProductView
 * */
export const exportProductView = async (
  params: UserRoutineType,
  type: string,
) => {
  const { data } = await axiosWithToken.get(`/admin/statistical/${type}`, {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns exportFileUserRoutine
 * */
export const exportFileUserRoutine = async (params: ExportFileType) => {
  const { data } = await axiosWithToken.get('/admin/statistical/user-routine', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns exportFileUserAttributes
 * */
export const exportFileUserAttributes = async (params: ExportFileType) => {
  const { data } = await axiosWithToken.get(
    '/admin/statistical/user-most-attributes',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}

/**
 * @returns exportFileProductRoutine
 * */
export const exportFileProductRoutine = async (
  params: ExportFileType,
  type: string,
) => {
  const { data } = await axiosWithToken.get(`/admin/statistical/${type}`, {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns exportFileMonthlyUse
 * */
export const exportFileMonthlyUse = async (params: ExportFileType) => {
  const { data } = await axiosWithToken.get(
    '/user-survey/statistical/monthly-user-using-app',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}

/**
 * @returns getDetailAttributesViews
 * */
export const getDetailAttributesViews = async (params: UserRoutineType) => {
  const { data } = await axiosWithToken.get(
    '/admin/statistical/user-attributes-detail',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}
