import React from 'react'

import { ViewRulesScreen } from './components/modules'

/**
 * @returns Component ViewRules Page
 */
export function ViewRulesPage() {
  return <ViewRulesScreen />
}
