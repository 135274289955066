import qs from 'qs'
import { axiosWithToken } from '../configureAxios'

/**
 * @returns get Quiz Question
 * */
export const getQuizQuestion = async () => {
  const { data } = await axiosWithToken.get('/quizs/questions')
  return data
}

/**
 * @returns get Quiz Answer
 * */
export const getQuizAnswer = async (questionId: number) => {
  const { data } = await axiosWithToken.get(`/quizs/answers/${questionId}`)
  return data
}

/**
 * @returns get Quiz Question Detail
 * */
export const getQuizQuestionDetail = async (questionId: number) => {
  const { data } = await axiosWithToken.get(`/quizs/questions/${questionId}`)
  return data
}

/**
 * @returns get Quiz Answer Detail
 * */
export const getQuizAnswerDetail = async (answerId: number) => {
  const { data } = await axiosWithToken.get(`/quizs/answers/detail/${answerId}`)
  return data
}

/**
 * @returns update Quiz Question
 * */
export const updateQuizQuestion = async (questionId: number, payload) => {
  const { data } = await axiosWithToken.put(
    `/quizs/questions/${questionId}`,
    payload,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  )
  return data
}

/**
 * @returns update Quiz Answer
 * */
export const updateQuizAnswer = async (answerId: number, payload) => {
  const { data } = await axiosWithToken.put(
    `/quizs/answers/${answerId}`,
    payload,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  )
  return data
}

type AnswerAboutType = {
  page: number
  limit: number
}

/**
 * @returns get Answer About
 * */
export const getAnswerAbout = async (params: AnswerAboutType) => {
  const { data } = await axiosWithToken.get('/answer-abouts', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns get Answer About Detail
 * */
export const getAnswerAboutDetail = async (answerAboutId: number) => {
  const { data } = await axiosWithToken.get(`/answer-abouts/${answerAboutId}`)
  return data
}

/**
 * @returns update Answer About Detail
 * */
export const updateAnswerAboutDetail = async (
  answerAboutId: number,
  payload,
) => {
  const { data } = await axiosWithToken.put(
    `/answer-abouts/${answerAboutId}`,
    payload,
    {
      headers: {
        'Content-type': 'application/json',
      },
    },
  )
  return data
}

/***
 *
 *  uploadVideo
 */
export const uploadVideo = async payload => {
  const formData = new FormData()
  formData.append('file', payload)
  const { data } = await axiosWithToken.post(`/answer-abouts/video`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

/**
 * @returns upload Image
 * */
export const uploadImage = async payload => {
  const formData = new FormData()
  formData.append('file', payload)
  const { data } = await axiosWithToken.post(`/answer-abouts/image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}
