import React, { ComponentProps } from 'react'
import { Box, BoxProps, CheckboxProps } from '@chakra-ui/react'
import { TextBase } from 'app/components/elements/Text'
import { Checkbox } from 'app/components/elements/Checkbox'

type Props = {
  errorMessage?: string
  errorProps?: ComponentProps<typeof TextBase>
  errorContainerStyle?: BoxProps
} & CheckboxProps
/**
 * @returns Component InputBase
 */
export const CheckBoxForm = (props: Props) => {
  const {
    errorMessage,
    errorProps,
    children,
    errorContainerStyle,
    ...checkBoxProps
  } = props

  return (
    <Box>
      <Checkbox {...checkBoxProps}>{children}</Checkbox>
      {errorMessage && (
        <Box minH={'14px'} {...errorContainerStyle}>
          <TextBase
            color={'input_error'}
            fontFamily={'Hiragino Sans'}
            fontSize={'12px'}
            text={errorMessage}
            {...errorProps}
          />
        </Box>
      )}
    </Box>
  )
}
