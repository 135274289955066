import { Flex, Spinner, Text } from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/layout'
import moment from 'moment'
import { TableCustom } from '../../../components/elements/TableCustom'
import { Pagination } from '../../../components/modules/Pagination'
import { Props, useProductRequestCompleteScreen } from './hooks'

const StyledContainer = styled(Flex)`
  flex-direction: column;
  gap: 16px;
`

const StyledWrapperTable = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`

/**
 * ProductRequestLayout component page.
 * @return {JSX.Element} ProductRequestLayout component.
 */
export const ProductRequestCompleteLayout = (props: Props) => {
  const {
    dataComplete,
    isLoading,
    currentPage,
    totalPage,
    handleNextPage,
    handleNavigateDetailProduct,
  } = props
  const columnsComplete = [
    {
      title: 'Brand',
      render: record => <Box>{record?.brand}</Box>,
    },
    {
      title: 'Upc',
      render: record => <Box>{record?.upc}</Box>,
    },
    {
      title: 'Weblink',
      render: record => <Box>{record?.weblink}</Box>,
    },
    {
      title: 'Name',
      render: record => <Box>{record?.name}</Box>,
    },
    {
      title: 'Retailer',
      render: record => <Box>{record?.retailer}</Box>,
    },
    {
      title: 'Condition',
      render: record =>
        record?.type === 1 ? (
          <Flex alignItems={'center'} gap={4} justifyContent={'flex-start'}>
            <Text fontSize={16} fontWeight={700}>
              Added
            </Text>
            <Box>
              <Text color={'black'} fontFamily={'Hiragino Sans'} fontSize={14}>
                {moment(record?.addedAt).format('YYYY/MM/DD, HH:mm:ss A')}
              </Text>
              <Text
                color={'blue'}
                textDecoration={'underline'}
                onClick={() => handleNavigateDetailProduct(record.productId)}
              >
                ID: {record?.productId}
              </Text>
            </Box>
          </Flex>
        ) : (
          <Flex alignItems={'center'} gap={4} justifyContent={'flex-start'}>
            <Text color={'#C00000'} fontSize={16} fontWeight={700}>
              Ignore
            </Text>
            <Text color={'black'} fontSize={14}>
              Not relevant
            </Text>
          </Flex>
        ),
    },
  ]

  if (isLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <StyledContainer>
      <StyledWrapperTable>
        <Box overflowX={'auto'} w={'100%'}>
          <TableCustom columns={columnsComplete} data={dataComplete || []} />
        </Box>
        <Pagination
          page={currentPage}
          totalPage={totalPage}
          onChange={handleNextPage}
        />
      </StyledWrapperTable>
    </StyledContainer>
  )
}

/**
 * PromotionTable component page.
 * @return {JSX.Element} PromotionTable component.
 */
export const ProductRequestCompleteScreen = () => {
  return <ProductRequestCompleteLayout {...useProductRequestCompleteScreen()} />
}
