export type TAllSkinProfile = {
  content: string
}

const mockData: TAllSkinProfile = {
  content: `<p>
  Hello World! <sub>subscript</sub>, <sup>superscript</sup>
</p>`,
}

/**
 * @returns get detail product
 * */
export const getAllSkinProfile = async (): Promise<TAllSkinProfile> =>
  await new Promise(resolve => {
    setTimeout(() => {
      resolve(mockData)
    }, 2000)
  })
