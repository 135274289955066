import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import { isAxiosError } from 'axios'
import {
  addProductAnalysis,
  getIngredientTags,
  getProductAnalysisFirst,
  updateIngredient,
} from '../../../../../service/ingredients'
import { calculateMinDateTime } from '../../utils'
import { TIngredientTags, TMetaType, TProductAnalysisFirst } from './type'

/**
 * @returns Component useIngredientTags hook
 */
export const useIngredientTags = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [fileUpload, setFileUpload] = useState<File>()
  const [dataIngredientTags, setDataIngredientTags] =
    useState<TIngredientTags[]>()
  const [dataProductFirst, setDataProductFirst] =
    useState<TProductAnalysisFirst>()
  const [meta, setMeta] = useState<TMetaType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const [isActiveProductAnalysis, setIsActiveProductAnalysis] =
    useState<boolean>(true)
  const [date, setDate] = useState(calculateMinDateTime())
  const {
    isOpen: isOpenSubmit,
    onOpen: onOpenSubmit,
    onClose: onCloseSubmit,
  } = useDisclosure()

  const toast = useToast()
  let [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')

  const fetchProductAnalysisFirst = useCallback(async () => {
    try {
      const response = await getProductAnalysisFirst()
      setDataProductFirst(response)
      setIsActiveProductAnalysis(response.isActive === 'ACTIVE')
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    }
  }, [toast])

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getIngredientTags(params)
      setDataIngredientTags(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, toast])

  useEffect(() => {
    fetchProductAnalysisFirst().then()
  }, [])

  useEffect(() => {
    fetchData().then()
  }, [page])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )
  /**
   * @returns function handle upload file xlsx
   */
  const handleUploadFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) {
        setFileUpload(file)
      }
    },
    [],
  )

  const handleSubmitFile = useCallback(async () => {
    onCloseSubmit()
    try {
      await updateIngredient(fileUpload)
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Submit Success',
        duration: 1000,
      })
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 1000,
        })
      }
    }
  }, [fileUpload, onCloseSubmit, toast])

  const handleSubmitProductAnalysisTime = useCallback(async () => {
    setIsLoadingSubmit(true)
    try {
      const response = await addProductAnalysis({
        status: isActiveProductAnalysis ? 1 : 0,
        time: date,
      })
      setDataProductFirst(response)
      setIsActiveProductAnalysis(response.isActive === 'ACTIVE')
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Submit Success',
        duration: 1000,
      })
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 1000,
        })
      }
    } finally {
      setIsLoadingSubmit(false)
    }
  }, [date, isActiveProductAnalysis, toast])

  const onChangeActiveProductAnalysis = useCallback(() => {
    setIsActiveProductAnalysis(!isActiveProductAnalysis)
  }, [isActiveProductAnalysis])

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef?.current.click()
    }
  }

  const handleOpenModalSubmit = useCallback(async () => {
    onOpenSubmit()
  }, [onOpenSubmit])

  return {
    isLoading,
    isLoadingSubmit,
    isActiveProductAnalysis,
    dataIngredientTags,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNextPage,
    fileInputRef,
    handleFileSelect,
    handleUploadFile,
    handleSubmitFile,
    fileUpload,
    isOpenSubmit,
    onCloseSubmit,
    handleOpenModalSubmit,
    dataProductFirst,
    date,
    onChangeDate: setDate,
    onChangeActiveProductAnalysis,
    handleSubmitProductAnalysisTime,
  }
}
