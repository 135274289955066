import React from 'react'
import { Button, ButtonProps, Icon, TextProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { MdAdd } from 'react-icons/md'
import { TextBase } from './Text'

type ButtonBaseProps = {
  title: string
  hasAdd?: boolean
  disable?: boolean
  textProps?: TextProps
} & ButtonProps

const ButtonBasePrimaryMain = styled(Button)`
  border-radius: 100px;
  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.1);
  padding: 17px 30px;
`

/**
 * @returns ButtonBase component
 */
export const ButtonBase = (props: ButtonBaseProps) => {
  const { title, textProps, hasAdd, ...buttonProps } = props

  if (hasAdd) {
    return (
      <ButtonBasePrimaryMain {...buttonProps} background={'#385187'}>
        <Icon as={MdAdd} boxSize={6} color={'#fff'} />
        <TextBase
          color={'white'}
          fontSize={'14px'}
          fontWeight={600}
          pl={'10px'}
          text={title}
        />
      </ButtonBasePrimaryMain>
    )
  }

  return (
    <ButtonBasePrimaryMain
      background={'linear-gradient(180deg, #aaadf6 0%, #6b70f0 100%)'}
      {...buttonProps}
    >
      <TextBase
        color={'white'}
        fontSize={'14px'}
        fontWeight={600}
        text={title}
        {...textProps}
      />
    </ButtonBasePrimaryMain>
  )
}
