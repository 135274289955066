import React from 'react'
import { Button, Flex, Spinner, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Editor } from '../modules/Editor'
import { Props, useEditProfile } from './hooks'

const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  height: 32px;
  border-radius: 25px;
  background: #000000;
  color: #ffffff;
`

const StyledWrapperEditor = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Text)`
  font-size: 18px;
  font-weight: bold;
  color: #000000;
`

/**
 * EditProfileLayout component page.
 * @return {JSX.Element} EditProfileLayout component.
 */
export const EditProfileLayout = (props: Props) => {
  const { value, isLoading, handleOnChange } = props

  if (isLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <Flex flexDirection={'column'} gap={10}>
      <StyledWrapperEditor>
        <StyledLabel>Privacy Policy</StyledLabel>
        <Editor
          name={'edit privacy policy'}
          value={value?.content ?? ''}
          onChange={handleOnChange}
        />
      </StyledWrapperEditor>

      <StyledWrapperEditor>
        <StyledLabel>Terms Of Service</StyledLabel>
        <Editor
          name={'edit terms of service'}
          value={value?.content ?? ''}
          onChange={handleOnChange}
        />
      </StyledWrapperEditor>

      <Flex justifyContent={'flex-end'}>
        <StyledButtonSubmit>Save</StyledButtonSubmit>
      </Flex>
    </Flex>
  )
}

/**
 * EditProfile component page.
 * @return {JSX.Element} EditProfile component.
 */
export const EditProfile = () => <EditProfileLayout {...useEditProfile()} />
