import { useCallback, useEffect, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isAxiosError } from 'axios'
import { TMetaType } from '../../../../ViewProductData/components/modules/types'
import {
  // deleteAdministrator,
  getUsers,
  updateStatusUser,
  UserListType,
} from '../../../../../../service/administratorService'
import { TUser } from './type'

/**
 * @returns Component useAdministrators hook
 */
export const useAdministrators = () => {
  const [dataAdministrator, setDataAdministrator] = useState<TUser[]>()
  const toast = useToast()
  const navigate = useNavigate()
  const [meta, setMeta] = useState<TMetaType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const keywordType = searchParams.get('keywordType')
  const word = searchParams.get('word')
  const [dataAdministratorId, setdataAdministratorId] = useState<number>()

  const [valueSelectKeyword, setValueSelectKeyword] = useState<{
    label: string
    value?: number
  }>({
    label: 'Select Keyword ...',
    value: undefined,
  })
  const [valueSelectOrderBy, setValueSelectOrderBy] = useState<{
    label: string
    value?: number
  }>({
    label: 'Select Order By ...',
    value: undefined,
  })
  const [valueSelectOrderType, setValueSelectOrderType] = useState<{
    label: string
    value?: number
  }>({
    label: 'Select Order Type ...',
    value: undefined,
  })
  const [valueInput, setValueInput] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params: UserListType = {
        status: 1,
        keywordType: keywordType ? Number(keywordType) : undefined,
        word: word ? word : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
        userType: 1,
      }
      const response = await getUsers(params)
      setDataAdministrator(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [keywordType, page, toast, word])

  useEffect(() => {
    fetchData().then()
  }, [fetchData])

  const handleCreateAdd = useCallback(() => {
    navigate('/administrators/add')
  }, [navigate])

  const handleEdit = useCallback(
    (id: number) => {
      navigate(`/administrators/edit/${id}`)
    },
    [navigate],
  )
  const handleUpdateStatus = useCallback(
    async (id: number) => {
      const statusUser = dataAdministrator?.find(it => it.id === id)
      const payload = {
        removeUser: statusUser?.status === 'ACTIVE',
      }
      try {
        await updateStatusUser(id, payload)
        fetchData()
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    },
    [dataAdministrator, fetchData, toast],
  )

  const handleDelete = useCallback(async () => {
    try {
      if (!dataAdministratorId) {
        return
      }
      const payload = {
        removeUser: true,
      }
      await updateStatusUser(dataAdministratorId, payload)
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Delete Success',
        duration: 3000,
      })
      onClose()
      fetchData()
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    }
  }, [dataAdministratorId, fetchData, onClose, toast])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )
  const handleChangeSelectKeyword = useCallback(value => {
    setValueSelectKeyword(value)
  }, [])

  const handleChangeSelectOrderBy = useCallback(value => {
    setValueSelectOrderBy(value)
  }, [])

  const handleChangeSelectOrderType = useCallback(value => {
    setValueSelectOrderType(value)
  }, [])

  const handleChangeInput = useCallback(e => {
    setValueInput(e.target.value)
  }, [])

  const handleSearchAdministrator = useCallback(() => {
    setSearchParams({
      // orderBy: String(valueSelectOrderBy.value ?? ''),
      // orderType: String(valueSelectOrderType.value ?? ''),
      // keywordType: String(valueSelectKeyword.value ?? ''),
      word: String(valueInput),
      page: String(1),
    })
  }, [setSearchParams, valueInput])

  /**
   * @returns handleReset
   */
  const handleReset = useCallback(() => {
    setValueInput('')
    setValueSelectOrderBy({ label: 'Select Order By ...', value: undefined })
    setValueSelectOrderType({
      label: 'Select Order Type ...',
      value: undefined,
    })
    setValueSelectKeyword({ label: 'Select Keyword ...', value: undefined })
    setSearchParams({
      orderBy: '',
      orderType: '',
      keywordType: '',
      word: '',
      page: String(1),
    })
  }, [setSearchParams])

  const handleOpenModal = useCallback(async () => {
    onOpen()
  }, [onOpen])

  const onOpenDelete = useCallback(
    (id: number) => {
      setdataAdministratorId(id)
      onOpen()
    },
    [onOpen],
  )

  const handleShowListInActive = useCallback(() => {
    navigate('/administrators-inactive')
  }, [navigate])

  return {
    dataAdministrator,
    isLoading,
    handleCreateAdd,
    handleEdit,
    handleUpdateStatus,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNextPage,
    setSearchParams,
    valueSelectKeyword,
    handleChangeSelectKeyword,
    valueInput,
    handleChangeInput,
    handleSearchAdministrator,
    handleChangeSelectOrderBy,
    valueSelectOrderBy,
    handleChangeSelectOrderType,
    valueSelectOrderType,
    handleReset,
    handleDelete,
    isOpen,
    onClose,
    handleOpenModal,
    dataAdministratorId,
    onOpenDelete,
    handleShowListInActive,
  }
}
