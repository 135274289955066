import qs from 'qs'
import {
  TBrandOption,
  TCategory1,
  TCategory2,
  TCategory3,
  TProductList,
  TProductRequestList,
  TRetailerOption,
} from '../app/pages/ViewProductData/components/modules/types'
import { axiosWithToken } from '../configureAxios'

type ProductListType = {
  page: number
  limit: number
  keywordType?: number
  word?: string
}
/**
 * @returns get productList
 * */
export const getProductList = async (
  params: ProductListType,
): Promise<TProductList> => {
  const { data } = await axiosWithToken.get('/products', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns delete product
 * */
export const deleteProduct = async (params: { productIds: number[] }) => {
  const { data } = await axiosWithToken.delete('/products', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns get retailer options
 * */
export const getRetailerOption = async (): Promise<TRetailerOption[]> => {
  const { data } = await axiosWithToken.get(`/retailers/options`)
  return data
}

/**
 * @returns get brands options
 * */
export const getBrandOption = async (): Promise<TBrandOption[]> => {
  const { data } = await axiosWithToken.get(`/brands/options`)
  return data
}

/**
 * @returns get detail product
 * */
export const getProductDetail = async (suggestId: string) => {
  const { data } = await axiosWithToken.get(`/products/detail/${suggestId}`)
  return data
}

/**
 * @returns get detail product request
 * */
export const getProductRequestDetail = async (suggestId: string) => {
  const { data } = await axiosWithToken.get(`/products/suggest/${suggestId}`)
  return data
}

type ProductRequestType = {
  page: number
  limit: number
  type: number
}

/**
 * @returns get list product request
 * */
export const getProductRequest = async (
  params: ProductRequestType,
): Promise<TProductRequestList> => {
  const { data } = await axiosWithToken.get('/products/suggest', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns get Category1 options
 * */
export const getCategory1 = async (): Promise<TCategory1[]> => {
  const { data } = await axiosWithToken.get(`/products/categories`)
  return data
}

/**
 * @returns get Category2 options
 * */
export const getCategory2 = async (
  categoryId: number,
): Promise<TCategory2[]> => {
  const { data } = await axiosWithToken.get(
    `/products/second-categories/${categoryId}`,
  )
  return data
}

/**
 * @returns get Category3 options
 * */
export const getCategory3 = async (
  secondCategoryId: number,
): Promise<TCategory3[]> => {
  const { data } = await axiosWithToken.get(
    `/products/third-categories/${secondCategoryId}`,
  )
  return data
}

/***
 *
 * add update products
 */
export const updateProducts = async (id: number, payload) => {
  const { data } = await axiosWithToken.put(`/products/${id}`, payload)
  return data
}

/***
 *
 * add put products
 */
export const putProducts = async (id: number, payload) => {
  const { data } = await axiosWithToken.put(`/products/suggest/${id}`, payload)
  return data
}
/***
 *
 * add creat products
 */
export const createProductRequest = async (suggestId: number, payload) => {
  const { data } = await axiosWithToken.post(
    `/products?suggestId=${suggestId}`,
    payload,
  )
  return data
}

/***
 *
 * add creat products
 */
export const createProduct = async payload => {
  const { data } = await axiosWithToken.post(`/products`, payload)
  return data
}
/***
 *
 *  uploadSubmitFile
 */
export const uploadSubmitFile = async payload => {
  const formData = new FormData()
  formData.append('file', payload)
  const { data } = await axiosWithToken.post(`/products/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

/***
 *
 *  Example File
 */
export const exampleFile = async () => {
  const { data } = await axiosWithToken.get(`/products/sample-file`)
  return data
}

type ExportFileType = {
  page: number
  limit: number
}

/***
 *
 *  Export File
 */
export const exportFile = async (params: ExportFileType) => {
  const { data } = await axiosWithToken.get('/products/export-file', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/***
 *
 * add send ContactMails
 */
export const sendContactMails = async payload => {
  const { data } = await axiosWithToken.post(`/mails/contact`, payload)
  return data
}

/***
 *
 * add send sendContactMailsUser
 */
export const sendContactMailsUser = async payload => {
  const { data } = await axiosWithToken.post(`/mails/send-all-user`, payload)
  return data
}
