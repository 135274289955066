export const TemplateEmailSignature = `
  <div dir="ltr">
    <div dir="ltr" style="font-size:1px;direction:ltr">
      <table cellpadding="0" cellspacing="0" border="0" style="background-color: rgb(255, 255, 255); width: 100%; border-collapse: collapse; font-size: 1px; --darkreader-inline-bgcolor: #181a1b;" data-darkreader-inline-bgcolor="">
        <tbody>
        <tr style="font-size:0">
          <td align="left" style="vertical-align:top">
            <table cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse;font-size:0">
              <tbody>
              <tr style="font-size:0">
                <td colspan="3" align="left" style="padding:0;vertical-align:top">
                  <table cellpadding="0" cellspacing="0" border="0" style="width: 100%; border-collapse: collapse; font-size: 0px; color: rgb(0, 0, 1); font-style: normal; font-weight: 400; white-space: nowrap; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color="">
                    <tbody>
                    <tr style="font-size:14.67px">
                      <td align="left" style="vertical-align:top;font-family:Calibri,Arial,sans-serif">&nbsp;&nbsp;&nbsp;&nbsp;<span style="font-family: remialcxesans; font-size: 1px; color: rgb(255, 255, 255); line-height: 1px; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color=""><span style="font-family:'template-BJSV_rEaEe6-oWBFvWIg1w'"></span><span style="font-family:'zone-1'"></span><span style="font-family:'zones-AQ'"></span></span><br></td>
                    </tr>
                    <tr style="font-size:0;line-height:normal">
                      <td align="center" style="vertical-align:top">
                        <img src="${process.env.REACT_APP_API_URL}/public/logo.png" width="175" height="138" border="0" alt="" style="width:175px;min-width:175px;max-width:175px;height:138px;min-height:138px;max-height:138px;font-size:0" data-image-whitelisted="" class="CToWUd" data-bit="iit">
                      </td>
                    </tr>c
                    <tr style="font-size:14.67px">
                      <td align="left" style="vertical-align:top;font-family:Calibri,Arial,sans-serif">&nbsp;&nbsp;&nbsp;&nbsp;<br></td>
                    </tr>
                    <tr style="font-size:0">
                      <td align="left" style="vertical-align:top">
                        <table cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse;font-size:0;line-height:normal">
                          <tbody>
                          <tr style="font-size:0">
                            <td align="left" style="vertical-align:top"><a href="https://apps.apple.com/ca/app/all-skin-skincare-analysis/id1589490582" id="m_5606633361483600200LPlnk689713" style="text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://apps.apple.com/ca/app/all-skin-skincare-analysis/id1589490582&amp;source=gmail&amp;ust=1722408912527000&amp;usg=AOvVaw0KU7R3GqReGvQs4L2RgFL_">
                              <img src="${process.env.REACT_APP_API_URL}/public/download-appstore.png" width="79.2" height="27" border="0" title="Download All Skin on the Apple App Store" alt="Download All Skin on the Apple App Store" style="width:79.2px;min-width:79.2px;max-width:79.2px;height:27px;min-height:27px;max-height:27px;font-size:12px" data-image-whitelisted="" class="CToWUd" data-bit="iit"></a>
                            </td>
                            <td align="left" style="vertical-align: top; font-size: 14.67px; color: rgb(0, 0, 1); font-family: Calibri, Arial, sans-serif; font-style: normal; font-weight: 400; white-space: nowrap; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color="">&nbsp;<br></td>
                            <td align="left" style="vertical-align:top"><a href="https://play.google.com/store/apps/details?id=com.allskin.allskinapp&amp;pli=1" id="m_5606633361483600200LPlnk689713" style="text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://play.google.com/store/apps/details?id%3Dcom.allskin.allskinapp%26pli%3D1&amp;source=gmail&amp;ust=1722408912527000&amp;usg=AOvVaw2utQdAqJNHKeSQYKNBnk1g">
                              <img src="${process.env.REACT_APP_API_URL}/public/download-googleplay.png" width="90.24" height="27" border="0" title="Get All Skin on Google Play" alt="Get All Skin on Google Play" style="width:90.24px;min-width:90.24px;max-width:90.24px;height:27px;min-height:27px;max-height:27px;font-size:12px" data-image-whitelisted="" class="CToWUd" data-bit="iit"></a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
                <td style="padding:0 12px 0 0">&nbsp;</td>
                <td align="left" style="padding: 0px 0px 0px 10px; border-top: none; border-right: none; border-bottom: none; border-left: 2px solid rgb(0, 0, 1); vertical-align: top; --darkreader-inline-border-top: initial; --darkreader-inline-border-right: initial; --darkreader-inline-border-bottom: initial; --darkreader-inline-border-left: #8c8273;" data-darkreader-inline-border-top="" data-darkreader-inline-border-right="" data-darkreader-inline-border-bottom="" data-darkreader-inline-border-left="">
                  <table cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse;font-size:0">
                    <tbody>
                    <tr style="font-size:0">
                      <td style="padding:0 6px 0 0">&nbsp;</td>
                      <td colspan="2" align="left" style="padding:0;vertical-align:top">
                        <table cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse;font-size:0">
                          <tbody>
                          <tr style="font-size:0">
                            <td align="left" style="padding:0 5px 10px 0;vertical-align:top">
                              <table cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse;font-size:0">
                                <tbody>
                                <tr style="font-size:0">
                                  <td align="left" style="vertical-align:top">
                                    <table cellpadding="0" cellspacing="0" border="0" style="width:100%;border-collapse:collapse;font-size:0">
                                      <tbody>
                                      <tr style="font-size:0">
                                        <td align="left" style="vertical-align:top">
                                          <table cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse;font-size:0">
                                            <tbody>
                                            <tr style="font-size:0">
                                              <td align="left" style="padding:10px 0 0;vertical-align:top">
                                                <table cellpadding="0" cellspacing="0" border="0" style="border-collapse: collapse; font-size: 0px; color: rgb(0, 0, 1); font-style: normal; font-weight: 400; white-space: nowrap; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color="">
                                                  <tbody>
                                                  <tr style="font-size:14px">
                                                    <td align="left" style="vertical-align:top;font-size:16.5px;font-family:Arial;font-weight:700">ALL&nbsp;SKIN</td>
                                                    <td align="left" style="vertical-align:top;font-family:Arial">&nbsp;<br></td>
                                                    <td align="left" style="vertical-align:top;font-family:Arial">&nbsp;&nbsp;<br></td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr style="font-size:0">
                                  <td align="left" style="vertical-align:top">
                                    <table cellpadding="0" cellspacing="0" border="0" style="width:100%;border-collapse:collapse;font-size:0">
                                      <tbody>
                                      <tr style="font-size:0">
                                        <td align="left" style="vertical-align:top">
                                          <table cellpadding="0" cellspacing="0" border="0" style="border-collapse: collapse; font-size: 0px; color: rgb(123, 150, 173); font-style: normal; font-weight: 700; white-space: nowrap; --darkreader-inline-color: #a49d91;" data-darkreader-inline-color="">
                                            <tbody>
                                            <tr style="font-size:14px">
                                              <td align="left" style="padding:7px 0 0;vertical-align:top;font-family:Arial">Developed&nbsp;by&nbsp;dermatologists,&nbsp;<br>Powered&nbsp;by&nbsp;science</td>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr style="font-size:0">
                      <td style="padding:0">&nbsp;</td>
                      <td align="left" style="padding: 0px 5px 10px 0px; vertical-align: top; font-size: 13px; color: rgb(0, 0, 1); font-family: Arial; font-style: normal; font-weight: 400; white-space: nowrap; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color="">All&nbsp;Skin&nbsp;Inc.<br>32&nbsp;Royal&nbsp;Vista&nbsp;Drive&nbsp;NW<br>Calgary,&nbsp;Alberta&nbsp;T3R&nbsp;0H9</td>
                      <td style="padding:0 0 10px">&nbsp;</td>
                    </tr>
                    <tr style="font-size:0">
                      <td style="padding:0">&nbsp;</td>
                      <td align="left" style="padding: 0px 5px 0px 0px; vertical-align: top; font-size: 13px; color: rgb(0, 0, 1); font-family: Arial; font-style: normal; font-weight: 400; white-space: nowrap; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color=""><a href="mailto:info@allskinhealth.com" id="m_5606633361483600200LPlnk689713" style="text-decoration: none; color: rgb(0, 0, 1); --darkreader-inline-color: #e8e6e3;" target="_blank" data-darkreader-inline-color="">info@allskinhealth.com</a></td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr style="font-size:0">
                      <td style="padding:0">&nbsp;</td>
                      <td align="left" style="padding: 10px 5px 0px 0px; vertical-align: middle; font-size: 13px; color: rgb(0, 0, 1); font-family: Arial; font-style: normal; font-weight: 400; white-space: nowrap; --darkreader-inline-color: #e8e6e3;" data-darkreader-inline-color=""><a href="http://www.allskinhealth.com/" id="m_5606633361483600200LPlnk689713" style="text-decoration: none; color: rgb(0, 0, 1); --darkreader-inline-color: #e8e6e3;" target="_blank" data-saferedirecturl="https://www.google.com/url?q=http://www.allskinhealth.com/&amp;source=gmail&amp;ust=1722408912527000&amp;usg=AOvVaw2xZO_tr-7BxzA4T1DteZlD" data-darkreader-inline-color="">www.allskinhealth.com</a></td>
                      <td style="padding:10px 0 0">&nbsp;</td>
                    </tr>
                    <tr style="font-size:0">
                      <td style="padding:0">&nbsp;</td>
                      <td colspan="2" align="left" style="padding:10px 0 0;vertical-align:top">
                        <table cellpadding="0" cellspacing="0" border="0" style="height:26px;border-collapse:collapse;font-size:0">
                          <tbody>
                          <tr style="font-size:0">
                            <td align="left" style="vertical-align:top">
                              <table cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse;font-size:0;line-height:normal">
                                <tbody>
                                <tr style="font-size:0">
                                  <td align="left" style="padding:0 3px 3px 0;vertical-align:top"><a href="https://www.instagram.com/allskinhealth" id="m_5606633361483600200LPlnk689713" style="text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.instagram.com/allskinhealth&amp;source=gmail&amp;ust=1722408912527000&amp;usg=AOvVaw3Oic8V5kFqCiegVUKsXt5X">
                                    <img src="${process.env.REACT_APP_API_URL}/public/social-insta.png" width="25" height="25" border="0" title="Instagram" alt="Instagram" style="width:25px;min-width:25px;max-width:25px;height:25px;min-height:25px;max-height:25px;font-size:12px" data-image-whitelisted="" class="CToWUd" data-bit="iit"></a>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <td align="left" style="vertical-align:top">
                              <table cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse;font-size:0;line-height:normal">
                                <tbody>
                                <tr style="font-size:0">
                                  <td align="left" style="padding:0 3px 3px 0;vertical-align:top"><a href="https://www.tiktok.com/@allskinhealth" id="m_5606633361483600200LPlnk689713" style="text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.tiktok.com/@allskinhealth&amp;source=gmail&amp;ust=1722408912527000&amp;usg=AOvVaw0vy08LDz_5gIf3egQDAnTe">
                                    <img src="${process.env.REACT_APP_API_URL}/public/social-tiktok.png" width="25" height="25" border="0" title="TikTok" alt="TikTok" style="width:25px;min-width:25px;max-width:25px;height:25px;min-height:25px;max-height:25px;font-size:12px" data-image-whitelisted="" class="CToWUd" data-bit="iit"></a>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <td align="left" style="vertical-align:top">
                              <table cellpadding="0" cellspacing="0" border="0" style="border-collapse:collapse;font-size:0;line-height:normal">
                                <tbody>
                                <tr style="font-size:0">
                                  <td align="left" style="padding:0 3px 3px 0;vertical-align:top"><a href="https://www.linkedin.com/company/All-Skin-Inc" id="m_5606633361483600200LPlnk689713" style="text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.linkedin.com/company/All-Skin-Inc&amp;source=gmail&amp;ust=1722408912527000&amp;usg=AOvVaw3eTgc23ISz1d3chstBYHXL">
                                    <img src="${process.env.REACT_APP_API_URL}/public/social-linkedin.png" width="25" height="25" border="0" title="LinkedIn" alt="LinkedIn" style="width:18px;min-width:18px;max-width:18px;height:18px;min-height:18px;max-height:18px;font-size:12px;margin-top:3px;margin-left:5px;" data-image-whitelisted="" class="CToWUd" data-bit="iit">
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr style="font-size:0">
          <td align="left" style="vertical-align:top">
            <table cellpadding="0" cellspacing="0" border="0" style="width:100%;border-collapse:collapse;font-size:0">
              <tbody>
              <tr style="font-size:0">
                <td align="left" style="padding:10px 0 0;vertical-align:top">
                  <table cellpadding="0" cellspacing="0" border="0" style="white-space: normal; color: rgb(174, 170, 170); font-size: 14.67px; font-family: Calibri, Arial, sans-serif; font-weight: 400; font-style: normal; text-align: justify; width: 100%; border-collapse: collapse; --darkreader-inline-color: #b4ada3;" data-darkreader-inline-color="">
                    <tbody>
                    <tr style="font-size:10.67px">
                      <td style="font-family:Arial,sans-serif">The content of this message is the proprietary and confidential property of All Skin Inc. and should be treated as such. If you are not the intended recipient and have received this message in error, please delete this message from your computer system and notify me immediately by reply e-mail. Any unauthorized use or distribution of the content of this message is prohibited.<br></td>
                    </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </tbody>
            </table>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <br>
    <br>
  </div>
`
