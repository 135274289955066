import React from 'react'
import { PromotedRetailerScreen } from './components/templates'

/**
 * PromotionPage component page.
 * @return {JSX.Element} PromotionPage component.
 */
export function PromotionRetailersPage() {
  return <PromotedRetailerScreen />
}
