import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import {
  Spinner,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
} from '@chakra-ui/react'
import { Input } from 'app/components/elements/Input/Input'
import { SearchIcon } from '@chakra-ui/icons'
import { Select } from 'app/components/elements/Select'
import styled from '@emotion/styled'
import { RiDeleteBin6Line } from 'react-icons/ri'
import moment from 'moment/moment'
import { Pagination } from '../../../../components/modules/Pagination'
import { Table } from '../../../../components/modules/TableCustom/TableCustom'
import { ModalExportData } from '../../../../components/modules/ModalExportData'
import { useProductListScreen, Props } from './hooks'
import { OptionSelect } from './constants'

const StyledWrapperDescription = styled(Box)`
  max-width: 800px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

/**
 * @returns Component
 */
const Component = (props: Props) => {
  const {
    isLoading,
    data,
    handleExportToXLSX,
    handleReset,
    navigate,
    retailerOption,
    brandOption,
    totalPage,
    currentPage,
    handleNextPage,
    valueSelect,
    handleChangeSelect,
    valueInput,
    handleChangeInput,
    handleSearchProduct,
    handleCheckbox,
    selectProduct,
    isOpenDelete,
    onCloseDelete,
    handleOpenModalDelete,
    handleSaveDelete,
    isOpenModalExportData,
    setIsOpenModalExportData,
    totalItem,
    handleClickDownload,
    isButtonChosen,
    downloadedItem,
  } = props
  if (isLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    )
  }
  const columns = [
    {
      title: 'Edit',
      render: record => (
        <Box
          onClick={() => {
            navigate(`/product-detail/${record?.id}`)
          }}
        >
          <Text color={'#3F6FBA'} textDecoration={'underline'}>
            Edit
          </Text>
        </Box>
      ),
    },
    {
      title: 'Entry Date',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {moment(record?.createdAt).format('YYYY/MM/DD, HH:mm:ss A')}
        </Box>
      ),
    },
    {
      title: 'Last Updated Date',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {moment(record?.updatedAt).format('YYYY/MM/DD, HH:mm:ss A')}
        </Box>
      ),
    },
    {
      title: 'UPC',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.upc}</Box>
      ),
    },
    {
      title: 'Brand',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.brand}</Box>
      ),
    },
    {
      title: 'Product',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.product}</Box>
      ),
    },
    {
      title: 'Price',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.price}</Box>
      ),
    },
    {
      title: 'SPF',
      render: record => {
        return <Box _hover={{ textDecoration: 'underline' }}>{record?.spf}</Box>
      },
    },
    {
      title: 'category1',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.cat1}</Box>
      ),
    },
    {
      title: 'Category2',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.cat2}</Box>
      ),
    },
    {
      title: 'Category3',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.cat3}</Box>
      ),
    },
    {
      title: 'Retailer',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.retailer}</Box>
      ),
    },
    {
      title: 'Upload notes',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.joblink}</Box>
      ),
    },
    {
      title: 'Stars',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.stars}</Box>
      ),
    },

    {
      title: 'Reviews',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {record?.reviewCount}
        </Box>
      ),
    },
    {
      title: 'Description',
      render: record => (
        <StyledWrapperDescription>
          {record?.description}
        </StyledWrapperDescription>
      ),
    },
    {
      title: 'Ingredients',
      render: record => (
        <Tooltip
          hasArrow
          label={record?.ingredients}
          placement={'bottom-start'}
        >
          <Box
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            w={'400px'}
            whiteSpace={'nowrap'}
          >
            {record?.ingredients}
          </Box>
        </Tooltip>
      ),
    },
    {
      title: 'URL',
      render: record => (
        <Tooltip hasArrow label={record?.originurl} placement={'bottom-start'}>
          <Box
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            w={'400px'}
            whiteSpace={'nowrap'}
          >
            {record?.originurl}
          </Box>
        </Tooltip>
      ),
    },
    {
      title: 'Image Url',
      render: record => (
        <Tooltip hasArrow label={record?.imageurl} placement={'bottom-start'}>
          <Box
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            w={'400px'}
            whiteSpace={'nowrap'}
          >
            {record?.imageurl}
          </Box>
        </Tooltip>
      ),
    },
    {
      title: 'Retailer Promoted',
      render: record => {
        const retailerHasPromote = retailerOption?.some(
          it => it?.name === record?.retailer,
        )
        return (
          <Box>{retailerHasPromote ? <Text>Yes</Text> : <Text>No</Text>}</Box>
        )
      },
    },
    {
      title: 'Brand Promoted',
      render: record => {
        const brandHasPromote = brandOption?.some(
          it => it.name === record.brand,
        )
        return <Box>{brandHasPromote ? <Text>Yes</Text> : <Text>No</Text>}</Box>
      },
    },
  ]

  return (
    <Flex flexDirection={'column'} gap={4}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Button
          _hover={{ bg: '#222', cursor: 'pointer' }}
          bg={'#222'}
          color={'#fff'}
          h={'32px'}
          w={'91px'}
          onClick={handleReset}
        >
          Reset
        </Button>

        <Flex alignItems={'center'} gap={2}>
          <Select
            options={OptionSelect}
            value={valueSelect}
            width={200}
            onChange={newValue => handleChangeSelect(newValue)}
          />
          <Input
            placeholder={'keyword search'}
            value={valueInput}
            onChange={handleChangeInput}
          />
          <Button
            _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            w={160}
            onClick={handleSearchProduct}
          >
            Search
          </Button>
        </Flex>
      </Flex>
      <Box>
        <Button
          colorScheme="red"
          isDisabled={selectProduct.length === 0}
          rightIcon={<RiDeleteBin6Line />}
          size={'sm'}
          variant="outline"
          onClick={handleOpenModalDelete}
        >
          Delete
        </Button>
      </Box>
      {data && !!data.length && (
        <>
          <Box maxW={'calc(100vw - 351px)'} overflowX={'auto'} w={'100%'}>
            <Table
              isSelect
              isSelectAll
              columns={columns}
              data={data || []}
              onChange={handleCheckbox}
              onSelect={val => navigate(`/product-detail/${val?.id}`)}
            />
          </Box>
          <Pagination
            page={currentPage}
            totalPage={totalPage}
            onChange={handleNextPage}
          />
        </>
      )}
      {data?.length === 0 && !isLoading && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          No Data
        </Flex>
      )}
      <Button
        _hover={{ bg: '#3F6FBA' }}
        bg={'#3F6FBA'}
        color={'#fff'}
        w={'max-content'}
        onClick={handleExportToXLSX}
      >
        Export Data
      </Button>
      <Modal isOpen={isOpenDelete} onClose={onCloseDelete}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          {selectProduct.length === 1 ? (
            <ModalBody>Are you sure you want to delete this product?</ModalBody>
          ) : (
            <ModalBody>
              Are you sure you want to delete these products?
            </ModalBody>
          )}

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleSaveDelete}>
              Save
            </Button>
            <Button
              colorScheme={'black'}
              mr={3}
              variant="ghost"
              onClick={onCloseDelete}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ModalExportData
        downloadedItem={downloadedItem}
        handleClickDownload={handleClickDownload}
        isButtonChosen={isButtonChosen}
        isOpenModal={isOpenModalExportData}
        totalItem={totalItem}
        onCloseModal={() => setIsOpenModalExportData(false)}
      />
    </Flex>
  )
}

/***
 *
 * Returns: ProductList Screen
 */
export const ProductListScreen = () => {
  return <Component {...useProductListScreen()} />
}
