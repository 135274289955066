import { useCallback, useEffect, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isAxiosError } from 'axios'
import { TMetaType } from '../../../ViewProductData/components/modules/types'
import { deleteDermTips, getDermTips } from '../../../../../service/dermTip'
import { TDermTips } from './type'

/**
 * @returns Component useDermTips hook
 */
export const useDermTips = () => {
  const [dataDermTips, setDataDermTips] = useState<TDermTips[]>()
  const toast = useToast()
  const navigate = useNavigate()
  const [meta, setMeta] = useState<TMetaType>()
  const [dermTipId, setDermTipId] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const keywordType = searchParams.get('keywordType')
  const word = searchParams.get('word')
  const {
    isOpen: isOpenDelete,
    onOpen,
    onClose: onCloseDelete,
  } = useDisclosure()
  const [valueInput, setValueInput] = useState(word || '')

  const onOpenDelete = useCallback(
    (id: number) => {
      setDermTipId(id)
      onOpen()
    },
    [onOpen],
  )
  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        word: word ? word : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getDermTips(params)
      setDataDermTips(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [keywordType, page, toast, word])

  useEffect(() => {
    fetchData().then()
  }, [fetchData])

  const handleCreateAdd = useCallback(() => {
    navigate('/derm-tips/add')
  }, [navigate])

  const handleEdit = useCallback(
    (id: number) => {
      navigate(`/derm-tips/edit/${id}`)
    },
    [navigate],
  )

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )

  const handleChangeInput = useCallback(e => {
    setValueInput(e.target.value)
  }, [])

  const handleSearchAdministrator = useCallback(() => {
    setSearchParams({
      word: String(valueInput),
      page: String(1),
    })
  }, [setSearchParams, valueInput])

  const handleDeleteDermTip = useCallback(async () => {
    setIsLoading(true)
    try {
      if (!dermTipId) {
        return
      }
      await deleteDermTips(dermTipId)
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Delete Success',
        duration: 3000,
      })
      onCloseDelete()
      fetchData()
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [fetchData, onCloseDelete, toast, dermTipId])

  return {
    dataDermTips,
    isLoading,
    handleCreateAdd,
    handleEdit,
    navigate,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNextPage,
    setSearchParams,
    valueInput,
    handleChangeInput,
    handleSearchAdministrator,
    handleDeleteDermTip,
    isOpenDelete,
    onCloseDelete,
    onOpenDelete,
  }
}
