import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useParams } from 'react-router'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment'
import { Errors } from '../../../../../../types'
import {
  getUserDetail,
  getUserSurvey,
  updateStatusUser,
} from '../../../../../../service/administratorService'
import { TMetaType } from '../../../../ViewRulesPage/components/modules/type'
import { TUserSurvey } from './type'

const schema = yup.object().shape({
  id: yup.number(),
  email: yup.string().required(),
  phoneNumber: yup.string().required(),
})
type FormValue = yup.InferType<typeof schema>

/**
 * useAddAdministrators hook.
 */
export const useEditUser = () => {
  const [userDetail, setUserDetail] = useState<Partial<FormValue>>({})
  const [dataUserSurvey, setDataUserSurvey] = useState<TUserSurvey[]>()
  const [errors, setErrors] = useState<Errors>()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast()
  const navigate = useNavigate()
  const [meta, setMeta] = useState<TMetaType>()
  let [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const startAt = searchParams.get('startAt')
  const endAt = searchParams.get('endAt')
  const [valueStartAt, setValueStartAt] = useState<Date>()
  const [valueEndAt, setValueEndAt] = useState<Date>()
  const [textValidate, setTextValidate] = useState('')

  const validateDate = useCallback(() => {
    const startAt = moment(valueStartAt).format('YYYY-MM-DD')
    const endAt = moment(valueEndAt).format('YYYY-MM-DD')
    setTextValidate(
      startAt <= endAt
        ? ''
        : 'Please select a start date that occurs before the end date',
    )
    return startAt <= endAt
  }, [valueEndAt, valueStartAt])

  const fetchDetailUser = useCallback(async () => {
    if (!id) return
    setIsLoading(true)
    try {
      const detail = await getUserDetail(id)
      setUserDetail(detail)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [id, toast])

  useEffect(() => {
    fetchDetailUser().then()
  }, [fetchDetailUser])

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setUserDetail(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )

  /**
   * @returns function that handle validates form
   */
  const validation = useCallback((): boolean => {
    setErrors(undefined)
    try {
      schema.validateSync(userDetail, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          const newErrors: Errors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }

    return true
  }, [userDetail])

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelect = useCallback(
    (key: keyof FormValue, value: string) => {
      setUserDetail({
        ...userDetail,
        [key]: value,
      })
    },
    [userDetail],
  )

  const handleSubmit = useCallback(
    async (id: number) => {
      if (validation()) {
        try {
          const payload = {
            email: userDetail.email,
            phoneNumber: userDetail.phoneNumber,
          }
          await updateStatusUser(id, payload)
          toast({
            position: 'top-right',
            status: 'success',
            title: 'Edit Success',
            duration: 1000,
          })
        } catch (e) {
          if (isAxiosError(e)) {
            const message = e?.response?.data.message
            toast({
              position: 'top-right',
              status: 'error',
              title: message,
              duration: 3000,
            })
          }
        }
      }
    },
    [userDetail.email, userDetail.phoneNumber, toast, validation],
  )

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )

  const fetchDataUserSurvey = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        user_id: id,
        startAt: startAt ? moment(startAt).format('YYYY-MM-DD') : undefined,
        endAt: endAt ? moment(endAt).format('YYYY-MM-DD') : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getUserSurvey(params)
      setDataUserSurvey(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [endAt, id, page, startAt, toast])

  useEffect(() => {
    fetchDataUserSurvey().then()
  }, [fetchDataUserSurvey])

  const handleChangeStartDate = useCallback((date: Date) => {
    setValueStartAt(date)
    setTextValidate('')
  }, [])

  const handleChangeEndDate = useCallback((date: Date) => {
    setValueEndAt(date)
    setTextValidate('')
  }, [])

  /**
   * @returns handleReset
   */
  const handleReset = useCallback(() => {
    setValueStartAt(undefined)
    setValueEndAt(undefined)
    setTextValidate('')
    setSearchParams({
      startAt: '',
      endAt: '',
      page: String(1),
    })
  }, [setSearchParams])

  const handleSearch = useCallback(async () => {
    if (validateDate()) {
      try {
        setSearchParams({
          startAt: valueStartAt ? moment(valueStartAt).format('YYYY-MM-DD') : '',
          endAt: valueEndAt ? moment(valueEndAt).format('YYYY-MM-DD') : '',
          page: String(1),
        })
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    }
  }, [setSearchParams, toast, validateDate, valueEndAt, valueStartAt])

  return {
    handleSubmit,
    errors,
    handleChangeInput,
    userDetail,
    handleOnChangeSelect,
    isLoading,
    navigate,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNextPage,
    dataUserSurvey,
    handleChangeStartDate,
    handleChangeEndDate,
    valueStartAt,
    valueEndAt,
    textValidate,
    handleReset,
    handleSearch,
  }
}
