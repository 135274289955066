import qs from 'qs'
import { axiosWithToken } from '../configureAxios'
import { TMetaType } from '../app/pages/ViewProductData/components/modules/types'
import { TDermTips } from '../app/pages/DermTipsPage/components/modules/type'

type DermTipListType = {
  page: number
  limit: number
  word?: string
}

export type TDermTipsList = {
  data: TDermTips[]
  meta: TMetaType
}

/**
 * @returns get DermTips
 * */
export const getDermTips = async (
  params: DermTipListType,
): Promise<TDermTipsList> => {
  const { data } = await axiosWithToken.get('/derm-tips', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/***
 *
 * add creat DermTips
 */
export const createDermTips = async payload => {
  const { data } = await axiosWithToken.post(`/derm-tips`, payload)
  return data
}

/**
 * @returns get detail DermTips
 * */
export const getDermTipsDetail = async (dermTipId: string) => {
  const { data } = await axiosWithToken.get(`/derm-tips/${dermTipId}`)
  return data
}

/***
 *
 * update DermTips
 */
export const updateDermTips = async (dermTipId: number, payload) => {
  const { data } = await axiosWithToken.put(`/derm-tips/${dermTipId}`, payload)
  return data
}

/***
 *
 * delete DermTips
 */
export const deleteDermTips = async (dermTipId: number) => {
  const { data } = await axiosWithToken.delete(`/derm-tips/${dermTipId}`)
  return data
}
