import { Icon } from '@chakra-ui/react'
import React from 'react'

/**
 * @returns IconToFp.tsx DatePicker
 */
export const IconDatePicker = props => (
  <Icon fill={'none'} viewBox="0 0 24 25" {...props}>
    <svg
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M19 4.20251H18V2.20251H16V4.20251H8V2.20251H6V4.20251H5C3.89 4.20251 3 5.10251 3 6.20251V20.2025C3 21.3025 3.89 22.2025 5 22.2025H19C20.1 22.2025 21 21.3025 21 20.2025V6.20251C21 5.10251 20.1 4.20251 19 4.20251ZM19 20.2025H5V9.20251H19V20.2025ZM6.5 13.2025C6.5 11.8225 7.62 10.7025 9 10.7025C10.38 10.7025 11.5 11.8225 11.5 13.2025C11.5 14.5825 10.38 15.7025 9 15.7025C7.62 15.7025 6.5 14.5825 6.5 13.2025Z"
        fill="#6B70F0"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
