import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  // Switch,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react'
import { DeleteIcon, SearchIcon } from '@chakra-ui/icons'
import { ButtonBase } from '../../../../../components/elements/ButtonBase'
import { Input } from '../../../../../components/elements/Input/Input'
import { TableCustom } from '../../../../../components/elements/TableCustom'
import { Pagination } from '../../../../../components/modules/Pagination'
import { useAdministrators } from './hook'
// import {
//   OptionSelectKeyword,
//   OptionSelectOrderBy,
//   OptionSelectOrderType,
// } from './constants'

/**
 * @returns Component ProductList Page
 */
export const AdministratorsScreen = () => {
  const {
    isLoading,
    dataAdministrator,
    handleCreateAdd,
    handleEdit,
    // handleUpdateStatus,
    currentPage,
    totalPage,
    handleNextPage,
    valueInput,
    handleChangeInput,
    handleSearchAdministrator,
    handleDelete,
    isOpen,
    onClose,
    onOpenDelete,
    handleShowListInActive,
    // valueSelectKeyword,
    // handleChangeSelectKeyword,
    // handleChangeSelectOrderBy,
    // valueSelectOrderBy,
    // handleChangeSelectOrderType,
    // valueSelectOrderType,
    // handleReset,
  } = useAdministrators()
  const columns = [
    {
      title: 'edit',
      render: ({ id }) => (
        <Box onClick={() => handleEdit(id)}>
          <Text color={'#3F6FBA'} textDecoration={'underline'}>
            Edit
          </Text>
        </Box>
      ),
    },
    {
      title: 'firstName',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.firstName}</Box>
      ),
    },
    {
      title: 'lastName',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.lastName}</Box>
      ),
    },
    {
      title: 'email',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.email}</Box>
      ),
    },
    {
      title: 'phoneNumber',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {record?.phoneNumber}
        </Box>
      ),
    },
    {
      title: 'role',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.role}</Box>
      ),
    },
    // {
    //   title: 'status',
    //   render: record => <Box>{record?.status}</Box>,
    // },
    // {
    //   title: 'action',
    //   render: ({ id }) => {
    //     const statusSwitch =
    //       dataAdministrator?.find(it => it.id === id)?.status === 'ACTIVE' ||
    //       false
    //     return (
    //       <Box>
    //         <Switch
    //           isChecked={statusSwitch}
    //           size="md"
    //           onChange={() => handleUpdateStatus(id)}
    //         />
    //       </Box>
    //     )
    //   },
    // },
    {
      title: 'action',
      render: record => (
        <Tag
          colorScheme="red"
          variant="outline"
          onClick={() => onOpenDelete(record.id)}
        >
          <TagLabel mr={1}>Deactivate</TagLabel>
          <DeleteIcon cursor={'point'} />
        </Tag>
      ),
    },
  ]

  return (
    <Flex flexDirection={'column'} gap={10}>
      <Flex gap={'20px'} justifyContent={'space-between'} mb={'20px'}>
        <ButtonBase
          hasAdd
          _hover={{ bg: '#385187' }}
          title={'CREATE'}
          onClick={handleCreateAdd}
        />
        <Flex gap={'20px'}>
          <Input
            placeholder={'keyword search'}
            value={valueInput}
            w={400}
            onChange={handleChangeInput}
          />
          <Button
            _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            w={160}
            onClick={handleSearchAdministrator}
          >
            Search
          </Button>
        </Flex>
      </Flex>
      <Button
        _hover={{ bg: '#3F6FBA' }}
        alignSelf={'end'}
        bg={'#3F6FBA'}
        color={'#ffffff'}
        width={'250px'}
        onClick={handleShowListInActive}
      >
        Manage Deactivated Admins
      </Button>
      {/*<Button*/}
      {/*  _hover={{ bg: '#222', cursor: 'pointer' }}*/}
      {/*  bg={'#222'}*/}
      {/*  color={'#fff'}*/}
      {/*  w={'max-content'}*/}
      {/*  onClick={handleReset}*/}
      {/*>*/}
      {/*  Reset*/}
      {/*</Button>*/}
      <Flex alignItems={'center'} gap={2}>
        {/*<Select*/}
        {/*  options={OptionSelectOrderBy}*/}
        {/*  value={valueSelectOrderBy}*/}
        {/*  width={200}*/}
        {/*  onChange={newValue => handleChangeSelectOrderBy(newValue)}*/}
        {/*/>*/}
        {/*<Select*/}
        {/*  options={OptionSelectOrderType}*/}
        {/*  value={valueSelectOrderType}*/}
        {/*  width={200}*/}
        {/*  onChange={newValue => handleChangeSelectOrderType(newValue)}*/}
        {/*/>*/}
        {/*<Select*/}
        {/*  options={OptionSelectKeyword}*/}
        {/*  value={valueSelectKeyword}*/}
        {/*  width={200}*/}
        {/*  onChange={newValue => handleChangeSelectKeyword(newValue)}*/}
        {/*/>*/}
      </Flex>
      {dataAdministrator && !!dataAdministrator.length && (
        <>
          <Box overflowX={'auto'} w={'100%'}>
            <TableCustom columns={columns} data={dataAdministrator || []} />
          </Box>
          <Pagination
            page={currentPage}
            totalPage={totalPage}
            onChange={handleNextPage}
          />
        </>
      )}
      {dataAdministrator?.length === 0 && !isLoading && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          No Data
        </Flex>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deactivate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to deactivate this administrator account?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDelete}>
              Save
            </Button>
            <Button
              colorScheme={'black'}
              mr={3}
              variant="ghost"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
