import React from 'react'

import { PromotedRetailersDetailScreen } from './components/modules'

/**
 * PromotedRetailersDetail component page.
 * @return {JSX.Element} PromotedRetailersDetail component.
 */
export function PromotedRetailersDetail(): JSX.Element {
  return <PromotedRetailersDetailScreen />
}
