import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { getQuizQuestion } from '../../../../../service/quizQuestion'
import { TQuizQuestion } from './type'

/**
 * @returns Component useViewRules hook
 */
export const useQuizQuestion = () => {
  const [dataQuestion, setDataQuestion] = useState<TQuizQuestion[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const toast = useToast()
  const fetchDataQuestion = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await getQuizQuestion()
      setDataQuestion(response)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchDataQuestion().then()
  }, [fetchDataQuestion])

  const getDetailQuizQuestion = useCallback(
    (questionId: number) => {
      navigate(`/quiz-questions/${questionId}`)
    },
    [navigate],
  )
  return { dataQuestion, isLoading, getDetailQuizQuestion }
}
