import { Flex, HStack, Text, IconButton } from '@chakra-ui/react'
import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { IPaginationProps } from './types'
import { usePagination } from './hooks'

/**
 * @returns Component Pagination
 */
export const Pagination: React.FC<IPaginationProps> = ({
  page,
  totalPage,
  onChange = () => {},
  totalItem,
  ...props
}): JSX.Element => {
  const { currentPage, paging, handleChangePage } = usePagination({
    page,
    totalPage,
    onChange,
  })
  return (
    <HStack
      alignItems="center"
      display={'flex'}
      gap={'10px'}
      justifyContent={'center'}
      m={'0 auto'}
      spacing={'1'}
      {...props}
    >
      <IconButton
        _active={{}}
        _hover={{}}
        aria-label="pre"
        borderRadius={'3px'}
        h={'32px'}
        icon={<ChevronLeftIcon color={'#787BAC'} fontSize={'xl'} />}
        isDisabled={currentPage === 1}
        lineHeight={'100%'}
        minW={'32px'}
        p={0}
        onClick={() => handleChangePage(currentPage - 1)}
      />
      {paging.map((item, index) => {
        if (item === 'space') {
          return (
            <Flex key={index}>
              <Text
                color={'#787BAC'}
                fontSize={'14px'}
                fontWeight={'bold'}
                lineHeight={4}
              >
                .
              </Text>
              <Text
                color={'#787BAC'}
                fontSize={'14px'}
                fontWeight={'bold'}
                lineHeight={4}
              >
                .
              </Text>
              <Text
                color={'#787BAC'}
                fontSize={'14px'}
                fontWeight={'bold'}
                lineHeight={4}
              >
                .
              </Text>
            </Flex>
          )
        }
        return (
          <IconButton
            key={index}
            _active={{}}
            _hover={{}}
            aria-label="item"
            bgColor={
              item === currentPage ? 'rgba(191, 185, 255, 0.30)' : 'none'
            }
            borderColor={'none'}
            borderRadius={'99px'}
            color={'#787BAC'}
            h={'32px'}
            icon={
              <Text
                color={'#787BAC'}
                fontFamily={'Barlow'}
                fontSize={'15px'}
                fontWeight={400}
                lineHeight={'21.45px'}
              >
                {item}
              </Text>
            }
            lineHeight={'100%'}
            minW={'32px'}
            p={0}
            onClick={() => handleChangePage(item)}
          />
        )
      })}
      <IconButton
        _active={{}}
        _hover={{}}
        aria-label="next"
        borderRadius={'3px'}
        h={'32px'}
        icon={<ChevronRightIcon color={'#787BAC'} fontSize={'xl'} />}
        isDisabled={currentPage === totalPage}
        lineHeight={'100%'}
        minW={'32px'}
        p={0}
        onClick={() => handleChangePage(currentPage + 1)}
      />
      {totalItem && (
        <Text
          color={'blue.600'}
          fontSize={'lg'}
          fontWeight={'semibold'}
          lineHeight={'100%'}
          pl={2}
          textAlign={'center'}
        >
          {totalItem}/ページ
        </Text>
      )}
    </HStack>
  )
}
