import React from 'react'

import { UserScreen } from './components/modules'

/**
 * @returns Component Login Page
 */
export function UserPage() {
  return <UserScreen />
}
