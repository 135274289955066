import React from 'react'

import { IngredientINCIScreen } from './components/modules'

/**
 * @returns Component Ingredient INCI Page
 */
export function IngredientINCIPage() {
  return <IngredientINCIScreen />
}
