import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment/moment'
import { TMetaType } from '../../../ViewRulesPage/components/modules/type'
import { getDetailProductRoutine } from '../../../../../service/authService'
import { TDetailProductRoutine } from './type'

/**
 * @returns Component DetailProductRoutine hook
 */
export const useDetailProductRoutine = () => {
  const [dataDetailProductRoutine, setDataDetailProductRoutine] =
    useState<TDetailProductRoutine[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<TMetaType>()
  let [searchParams, setSearchParams] = useSearchParams()
  const toast = useToast()
  const page = searchParams.get('page')
  const startAt = searchParams.get('startAt')
  const endAt = searchParams.get('endAt')
  const { recordId } = useParams()
  const [valueStartAt, setValueStartAt] = useState<Date>()
  const [valueEndAt, setValueEndAt] = useState<Date>()
  const [textValidate, setTextValidate] = useState('')
  const navigate = useNavigate()

  const validateDate = useCallback(() => {
    const startAt = moment(valueStartAt).format('YYYY-MM-DD')
    const endAt = moment(valueEndAt).format('YYYY-MM-DD')
    setTextValidate(
      startAt <= endAt
        ? ''
        : 'Please select a start date that occurs before the end date',
    )
    return startAt <= endAt
  }, [valueEndAt, valueStartAt])

  const fetchDataDetailProductRoutine = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        user_id: recordId,
        startAt: startAt ? moment(startAt).format('YYYY-MM-DD') : undefined,
        endAt: endAt ? moment(endAt).format('YYYY-MM-DD') : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getDetailProductRoutine(params)
      setDataDetailProductRoutine(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [endAt, page, recordId, startAt, toast])

  useEffect(() => {
    fetchDataDetailProductRoutine().then()
  }, [fetchDataDetailProductRoutine])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )

  const handleChangeStartDate = useCallback((date: Date) => {
    setValueStartAt(date)
    setTextValidate('')
  }, [])

  const handleChangeEndDate = useCallback((date: Date) => {
    setValueEndAt(date)
    setTextValidate('')
  }, [])

  const handleSearch = useCallback(async () => {
    if (validateDate()) {
      try {
        setSearchParams({
          startAt: valueStartAt ? moment(valueStartAt).format('YYYY-MM-DD') : '',
          endAt: valueEndAt ? moment(valueEndAt).format('YYYY-MM-DD') : '',
          page: String(1),
        })
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    }
  }, [setSearchParams, toast, validateDate, valueEndAt, valueStartAt])

  /**
   * @returns handleReset
   */
  const handleReset = useCallback(() => {
    setValueStartAt(undefined)
    setValueEndAt(undefined)
    setTextValidate('')
    setSearchParams({
      startAt: '',
      endAt: '',
      page: String(1),
    })
  }, [setSearchParams])

  return {
    dataDetailProductRoutine,
    isLoading,
    handleNextPage,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleChangeStartDate,
    handleChangeEndDate,
    valueStartAt,
    valueEndAt,
    handleSearch,
    handleReset,
    textValidate,
    navigate,
  }
}
