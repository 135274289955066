export type Option = {
  value: number
  label: string
}
export const OptionSelect: Option[] = [
  {
    label: 'Brand',
    value: 1,
  },
  {
    label: 'ProductName',
    value: 2,
  },
  {
    label: 'Ingredient',
    value: 3,
  },
  {
    label: 'Retailer',
    value: 4,
  },
  {
    label: 'Upc',
    value: 5,
  },
  {
    label: 'Description',
    value: 6,
  },
  {
    label: 'Cat1',
    value: 7,
  },
  {
    label: 'Cat2',
    value: 8,
  },
  {
    label: 'Cat3',
    value: 9,
  },
]

export type OptionSelect = {
  value: string
  label: string
}
export const OptionSelectView: OptionSelect[] = [
  {
    label: 'Routine Instances',
    value: 'product-routine',
  },
  {
    label: 'Page Views',
    value: 'product-page-log-all-user',
  },
  {
    label: 'Saved Products',
    value: 'product-save-popular',
  },
]
