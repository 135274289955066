import React, { JSX } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

type ModalExportDataProps = {
  isOpenModal: boolean
  onCloseModal: () => void
  totalItem?: number
  handleClickDownload: (page: number) => void
  isButtonChosen?: number
  downloadedItem?: number[]
}

/**
 * @returns Component ModalExportData
 */
export const ModalExportData: React.FC<ModalExportDataProps> = ({
  isOpenModal,
  onCloseModal,
  totalItem,
  handleClickDownload,
  isButtonChosen,
  downloadedItem,
}): JSX.Element => {
  const generateButtons = () => {
    const numberOfButtons = (totalItem && Math.ceil(totalItem / 5000)) || 0
    const buttons: JSX.Element[] = []

    for (let i = 1; i <= numberOfButtons; i++) {
      buttons.push(
        <Button
          key={i}
          _hover={{ bg: '#5d8de5' }}
          bg={downloadedItem?.includes(i) ? '#b0b0b0' : '#3F6FBA'}
          color={'#fff'}
          isLoading={i === isButtonChosen}
          w={'max-content'}
          onClick={() => handleClickDownload(i)}
        >
          {`Download ${i} of ${numberOfButtons}`}
        </Button>,
      )
    }
    return buttons
  }

  return (
    <Modal isOpen={isOpenModal} onClose={onCloseModal}>
      <ModalOverlay />
      <ModalContent alignItems={'center'} minHeight={400}>
        <ModalHeader>Data Export</ModalHeader>
        <ModalCloseButton />
        <ModalBody display={'flex'} flexDirection={'column'} gap={4}>
          {generateButtons()}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
