import { useState, useCallback, useEffect, ChangeEvent } from 'react'
import * as yup from 'yup'
import { useParams } from 'hooks/useRouter'
import { useNavigate } from 'react-router-dom'
import { isAxiosError } from 'axios'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { Option } from '../../../../../types'
import {
  getBrandOption,
  getCategory1,
  getCategory2,
  getCategory3,
  getProductDetail,
  getRetailerOption,
  updateProducts,
} from '../../../../../service/productService'
import {
  TBrandOption,
  TCategory1,
  TCategory2,
  TCategory3,
  TRRetailerOption,
} from '../../../ViewProductData/components/modules/types'
import { createRetailer } from '../../../../../service/promotionService'

const schema = yup.object().shape({
  id: yup.number(),
  createdAt: yup.date().required(),
  upc: yup
    .string()
    .nullable()
    .test('upc', 'upc must be a number', value => {
      if (!value) return true
      return !isNaN(Number(value))
    }),
  brand: yup.string().required(),
  product: yup.string().required(),
  cat1: yup.object().shape({
    id: yup.number().required(),
    value: yup.string().required(),
    label: yup.string().required(),
  }),
  cat1Id: yup.number().required(),
  cat2: yup.object().shape({
    id: yup.number().required(),
    value: yup.string().required(),
    label: yup.string().required(),
  }),
  cat2Id: yup.number().required(),
  cat3: yup.object().shape({
    id: yup.number().required(),
    value: yup.string(),
    label: yup.string(),
  }),
  cat3Id: yup.number().required(),
  retailers: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
        .test('retailer', 'Retailer is a required field', value => {
          const { value: catValue, label } = value || {}
          return !(!catValue || !label)
        }),
    )
    .required(),
  stars: yup
    .string()
    .nullable()
    .test('stars', 'stars must be a number', value => {
      if (!value) return true
      return !isNaN(Number(value))
    }),
  reviewCount: yup
    .string()
    .nullable()
    .test('reviewCount', 'reviewCount must be a number', value => {
      if (!value) return true
      return !isNaN(Number(value))
    }),
  description: yup.string().nullable(),
  ingredients: yup.string().required(),
  joblink: yup.string().nullable(),
  imageurl: yup.string().url().required(),
  retailerPromoted: yup.number(),
  brandPromoted: yup.number(),
  product_img_url: yup.string(),
  spf: yup.object().shape({
    label: yup.string().nullable(),
    value: yup.string().nullable(),
  }),
  originurls: yup
    .array()
    .of(
      yup.object().shape({
        originurl: yup
          .string()
          .required('Originurl is a required field')
          .url('Originurl must be a valid URL')
          .test('originurl', 'Originurl is a required field', (value, ctx) => {
            return (
              (ctx.from &&
                ctx.from[1].value.cat1?.value === 'Prescription Topicals') ||
              !!value
            )
          }),
        price: yup
          .number()
          .required('Price is a required field')
          .test('price', 'Price is a required field', (value, ctx) => {
            return (
              (ctx.from &&
                ctx.from[1].value.cat1?.value === 'Prescription Topicals') ||
              !!value
            )
          }),
      }),
    )
    .required(),
})

const schemaRetailer = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  promoted: yup.boolean(),
  imgUrl: yup.string().url().required(),
})

export type TFormValue = yup.InferType<typeof schema>

export type FormValue = yup.InferType<typeof schemaRetailer>

const initialState: FormValue = {
  name: '',
  description: '',
  promoted: false,
  imgUrl: '',
}

type IErrors = {
  [key: string]: {
    message: string
  }
}

/***
 *
 * Returns: useProductDetail
 */
export const useProductDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false)
  const [productDetail, setProductDetail] = useState<Partial<TFormValue>>({})
  const [category1, setCategory1] = useState<TCategory1[]>()
  const [category2, setCategory2] = useState<TCategory2[]>()
  const [category3, setCategory3] = useState<TCategory3[]>()
  const [retailerOption, setRetailerOption] = useState<TRRetailerOption[]>()
  const [allOptionsRetailer, setAllOptionsRetailer] =
    useState<TRRetailerOption[]>()
  const [brandOption, setBrandOption] = useState<TBrandOption[]>()
  const [errors, setErrors] = useState<IErrors>({})
  const [errorsRetailer, setErrorsRetailer] = useState<IErrors>({})
  const [isShowInput, setIsShowInput] = useState(true)
  const [formValue, setFormValue] = useState<FormValue>(initialState)
  const toast = useToast()
  const {
    isOpen: isOpenRetailer,
    onOpen: onOpenRetailer,
    onClose: onCloseRetailer,
  } = useDisclosure()
  const fetchDetailProduct = useCallback(async () => {
    if (!id) return
    setIsLoading(true)
    try {
      const detail = await getProductDetail(id)
      setProductDetail({
        ...detail,
        spf: {
          value: detail.spf,
          label: detail.spf,
        },
        cat1: {
          id: detail.cat1Id,
          label: detail.cat1,
          value: detail.cat1,
        },
        cat2: {
          id: detail.cat2Id,
          label: detail?.cat2,
          value: detail?.cat2,
        },
        cat3: {
          id: detail.cat3Id,
          label: detail?.cat3,
          value: detail?.cat3,
        },
        retailers: [
          {
            value: detail.retailer,
            label: detail.retailer,
          },
        ],
        originurls: [
          {
            originurl: detail.originurl,
            price: detail.price,
          },
        ],
      })
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [id, toast])

  useEffect(() => {
    fetchDetailProduct().then()
  }, [fetchDetailProduct])

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target
      setProductDetail(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setFormValue(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )

  const handleChangeDate = useCallback((name: string, date: Date) => {
    setProductDetail(prevState => ({
      ...prevState,
      [name]: date,
    }))
    setErrors(prevState => ({
      ...prevState,
      [name]: { message: '' },
    }))
  }, [])

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelect = useCallback(
    (name: string, option: Option | unknown, indexItem?: number) => {
      if (typeof indexItem === 'number') {
        let currentValue = productDetail[name].map((item, index) => {
          if (index === indexItem) {
            return option
          }
          return item
        })
        setProductDetail(prevState => ({
          ...prevState,
          [name]: currentValue,
        }))
        return
      }
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        setProductDetail(prevState => ({
          ...prevState,
          [name]: selectedOption,
        }))
        setErrors(prevState => ({
          ...prevState,
          [name]: { message: '' },
        }))
      }
    },
    [productDetail],
  )

  const validation = useCallback(() => {
    let newErrors: IErrors = {}
    try {
      schema.validateSync(productDetail, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
        }
        setErrors(newErrors)
        return false
      }
    }
    return true
  }, [productDetail])

  const validationRetailer = useCallback(() => {
    let newErrors: IErrors = {}
    try {
      schemaRetailer.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
        }
        setErrorsRetailer(newErrors)
        return false
      }
    }
    return true
  }, [formValue])

  const handleCancel = useCallback(() => navigate('/product-list'), [navigate])

  const handleSubmit = useCallback(
    async (id: number) => {
      if (validation()) {
        setIsLoadingButton(true)
        try {
          const payload = Object.assign(
            {
              product: productDetail.product,
              brand: productDetail.brand,
              ingredients: productDetail.ingredients,
              description: productDetail.description,
              upc: productDetail.upc || '',
              stars: Number(productDetail.stars),
              reviewCount: Number(productDetail.reviewCount),
              cat1: productDetail.cat1?.value,
              cat2: productDetail.cat2?.value,
              cat3: productDetail.cat3?.value,
              joblink: productDetail.joblink,
              imageurl: productDetail.imageurl,
              spf: productDetail.spf?.value,
            },
            productDetail?.originurls && {
              retailers: productDetail.originurls.map((item, idx) => ({
                ...item,
                retailer: productDetail.retailers?.[idx]?.value,
              })),
            },
          )
          await updateProducts(id, payload)
          toast({
            position: 'top-right',
            status: 'success',
            title: 'Change Success',
            duration: 1000,
          })
        } catch (e) {
          if (isAxiosError(e)) {
            const message = e?.response?.data.message
            toast({
              position: 'top-right',
              status: 'error',
              title: message,
              duration: 3000,
            })
          }
        } finally {
          setIsLoadingButton(false)
        }
      }
    },
    [
      validation,
      productDetail.product,
      productDetail.brand,
      productDetail.ingredients,
      productDetail.description,
      productDetail.upc,
      productDetail.stars,
      productDetail.reviewCount,
      productDetail.cat1?.value,
      productDetail.cat2?.value,
      productDetail.cat3?.value,
      productDetail.joblink,
      productDetail.imageurl,
      productDetail.spf?.value,
      productDetail.originurls,
      productDetail.retailers,
      toast,
    ],
  )
  const fetchCategory1 = useCallback(async () => {
    try {
      const options = await getCategory1()
      setCategory1(options)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    }
  }, [toast])

  useEffect(() => {
    fetchCategory1()
  }, [fetchCategory1])

  const catSelection1:
    | { id: number; label: string; value: string }[]
    | undefined = category1?.map(it => {
    return {
      id: it.id,
      value: it.content,
      label: it.content,
    }
  })
  const fetchCategory2 = useCallback(
    async (catId1: number) => {
      try {
        const options = await getCategory2(catId1)
        setCategory2(options)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    },
    [toast],
  )

  useEffect(() => {
    if (!productDetail.cat1Id) return
    fetchCategory2(Number(productDetail?.cat1?.id))
  }, [fetchCategory2, productDetail?.cat1?.id])

  const fetchCategory3 = useCallback(
    async (catId2: number) => {
      try {
        const options = await getCategory3(catId2)
        setCategory3(options)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    },
    [toast],
  )
  useEffect(() => {
    if (!productDetail.cat2Id) return
    fetchCategory3(Number(productDetail.cat2Id))
  }, [fetchCategory3, productDetail.cat2Id])

  const catSelection2:
    | { id: number; label: string; value: string }[]
    | undefined = category2?.map(it => {
    return {
      id: it.id,
      value: it.content,
      label: it.content,
    }
  })
  const catSelection3:
    | { id: number; label: string; value: string }[]
    | undefined = category3?.map(it => {
    return {
      id: it.id,
      value: it.content,
      label: it.content,
    }
  })

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelectCategory1 = useCallback(
    (name: string, option: Option | unknown) => {
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        const selectId = selectedOption.id
        setProductDetail(prevState => ({
          ...prevState,
          [name]: selectedOption,
          cat2: { id: 0, value: '', label: '' },
          cat3: { id: 1, value: '', label: '' },
        }))
        if (selectId !== undefined) {
          fetchCategory2(selectId)
        }
      }
    },
    [],
  )
  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelectCategory2 = useCallback(
    (name: string, option: Option | unknown) => {
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        const selectId = selectedOption.id
        setProductDetail(prevState => ({
          ...prevState,
          [name]: selectedOption,
          cat3: { id: 1, value: '', label: '' },
        }))
        if (selectId !== undefined) {
          fetchCategory3(selectId)
        }
      }
    },
    [],
  )
  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelectCategory3 = useCallback(
    (name: string, option: Option | unknown) => {
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        setProductDetail(prevState => ({
          ...prevState,
          [name]: selectedOption,
        }))
      }
    },
    [],
  )

  const fetchBrand = useCallback(async () => {
    try {
      const options = await getBrandOption()
      setBrandOption(options)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
    }
  }, [toast])

  const retailerHasPromote = retailerOption?.some(
    it => it?.value === productDetail?.retailers?.[0]?.value,
  )
  const brandHasPromote = brandOption?.some(
    it => it.name === productDetail.brand,
  )

  const fetchRetailer = useCallback(async () => {
    setIsLoading(true)
    try {
      const options = await getRetailerOption()
      const mockRetailerSelection = options.map(it => {
        return {
          value: it.name,
          label: it.name,
        }
      })
      setAllOptionsRetailer(mockRetailerSelection)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [toast])

  useEffect(() => {
    const newOptions = allOptionsRetailer?.filter(
      it =>
        !productDetail?.retailers?.map(item => item.value).includes(it?.value),
    )
    setRetailerOption(newOptions)
  }, [allOptionsRetailer, productDetail])

  useEffect(() => {
    fetchBrand()
    fetchRetailer()
  }, [fetchBrand, fetchRetailer])
  const handleAddRetailer = useCallback(() => {
    setProductDetail(pre => ({
      ...pre,
      originurls: [
        ...(pre.originurls || []),
        {
          originurl: '',
          price: undefined as unknown as number,
        },
      ],
      retailers: [...(pre.retailers || []), { value: '', label: '' }],
    }))
  }, [])

  const handleRemoveBox = useCallback(index => {
    setProductDetail(prevState => ({
      ...prevState,
      originurls: prevState?.originurls?.filter((_, idx) => idx !== index),
      retailers: prevState?.retailers?.filter((_, idx) => idx !== index),
    }))
  }, [])

  const handleChangeInputRetailes = useCallback((index: number, value) => {
    setProductDetail(pre => ({
      ...pre,
      originurls: pre?.originurls?.map((retailer, retailerIndex) => {
        if (retailerIndex !== index) return retailer
        return { ...retailer, ...value }
      }),
    }))
  }, [])
  useEffect(() => {
    productDetail?.originurls?.forEach((retailer, idx) => {
      const { originurl, price } = retailer
      if (originurl !== '') {
        setErrors(prevErrors => ({
          ...prevErrors,
          [`originurls[${idx}].originurl`]: { message: '' },
        }))
      }
      if (price !== null) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [`originurls[${idx}].price`]: { message: '' },
        }))
      }
    })
    productDetail?.retailers?.forEach((retailer, idx) => {
      if (retailer.value !== '') {
        setErrors(prevErrors => ({
          ...prevErrors,
          [`retailers[${idx}]`]: { message: '' },
        }))
      }
    })
  }, [
    JSON.stringify(productDetail.originurls),
    JSON.stringify(productDetail.retailers),
  ])

  const handleOpenModalRetailer = useCallback(() => {
    onOpenRetailer()
  }, [onOpenRetailer])

  const handleCloseModalRetailer = useCallback(() => {
    onCloseRetailer()
    setFormValue({
      ...formValue,
      name: '',
      description: '',
      imgUrl: '',
      promoted: false,
    })
    setErrorsRetailer({})
  }, [formValue, onCloseRetailer])

  const handleSubmitRetailer = useCallback(async () => {
    if (validationRetailer()) {
      setIsLoading(true)
      try {
        const payload = {
          name: formValue.name,
          description: formValue.description,
          promoted: formValue.promoted ? 1 : 0,
          imgUrl: formValue?.imgUrl,
        }
        await createRetailer(payload)
        toast({
          position: 'top-right',
          status: 'success',
          title: 'Create Success',
          duration: 1000,
        })
        fetchRetailer()
        onCloseRetailer()
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
        setFormValue({
          ...formValue,
          name: '',
          description: '',
          imgUrl: '',
          promoted: false,
        })
      }
    }
  }, [validationRetailer, formValue, toast, fetchRetailer, onCloseRetailer])

  const handleTogglePromotion = useCallback(() => {
    setFormValue({
      ...formValue,
      promoted: !formValue.promoted,
    })
  }, [formValue])

  return {
    errors,
    isLoading,
    handleCancel,
    handleSubmit,
    productDetail,
    handleChangeDate,
    handleChangeInput,
    handleOnChangeSelect,
    category1,
    catSelection1,
    handleOnChangeSelectCategory1,
    handleOnChangeSelectCategory2,
    catSelection2,
    catSelection3,
    handleOnChangeSelectCategory3,
    retailerHasPromote,
    brandHasPromote,
    isLoadingButton,
    isShowInput,
    setIsShowInput,
    retailerOption,
    handleChangeInputRetailes,
    defaultBox: productDetail.originurls,
    handleAddRetailer,
    handleRemoveBox,
    isOpenRetailer,
    onCloseRetailer,
    onOpenRetailer,
    handleCloseModalRetailer,
    handleOpenModalRetailer,
    handleSubmitRetailer,
    handleTogglePromotion,
    formValue,
    errorsRetailer,
    allOptionsRetailer,
    fetchRetailer,
  }
}

export type Props = ReturnType<typeof useProductDetail>
