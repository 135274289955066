import { useCallback, useEffect, useMemo, useState } from 'react'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment/moment'
import { getDetailAttributesViews } from '../../../../../service/authService'
import { TInfoUser, TSkinProfile } from './type'

/**
 * @returns Component useDetailAttributesView hook
 */
export const useDetailAttributesView = () => {
  const [dataDetailAttributesView, setDataDetailAttributesView] = useState<
    TSkinProfile[]
  >([])
  const [infoUser, setInfoUser] = useState<TInfoUser>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  let [searchParams, setSearchParams] = useSearchParams()
  const toast = useToast()
  const page = searchParams.get('page')
  const startAt = searchParams.get('startAt')
  const endAt = searchParams.get('endAt')
  const { userId } = useParams()
  const [valueStartAt, setValueStartAt] = useState<Date>()
  const [valueEndAt, setValueEndAt] = useState<Date>()
  const [textValidate, setTextValidate] = useState('')
  const navigate = useNavigate()
  const validateDate = useCallback(() => {
    const startAt = moment(valueStartAt).format('YYYY-MM-DD')
    const endAt = moment(valueEndAt).format('YYYY-MM-DD')
    setTextValidate(
      startAt <= endAt
        ? ''
        : 'Please select a start date that occurs before the end date',
    )
    return startAt <= endAt
  }, [valueEndAt, valueStartAt])
  const fetchDataDetailAttributesView = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        userId: userId,
        startAt: startAt ? moment(startAt).format('YYYY-MM-DD') : undefined,
        endAt: endAt ? moment(endAt).format('YYYY-MM-DD') : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getDetailAttributesViews(params)
      setDataDetailAttributesView(
        response.skinProfile.filter(it => it.id !== 0),
      )
      setInfoUser(response.info)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [endAt, page, userId, startAt, toast])

  useEffect(() => {
    fetchDataDetailAttributesView().then()
  }, [fetchDataDetailAttributesView])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )

  const handleChangeStartDate = useCallback((date: Date) => {
    setValueStartAt(date)
    setTextValidate('')
  }, [])

  const handleChangeEndDate = useCallback((date: Date) => {
    setValueEndAt(date)
    setTextValidate('')
  }, [])

  const handleSearch = useCallback(async () => {
    if (validateDate()) {
      try {
        setSearchParams({
          startAt: valueStartAt
            ? moment(valueStartAt).format('YYYY-MM-DD')
            : '',
          endAt: valueEndAt ? moment(valueEndAt).format('YYYY-MM-DD') : '',
          page: String(1),
        })
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    }
  }, [setSearchParams, toast, validateDate, valueEndAt, valueStartAt])

  /**
   * @returns handleReset
   */
  const handleReset = useCallback(() => {
    setValueStartAt(undefined)
    setValueEndAt(undefined)
    setTextValidate('')
    setSearchParams({
      startAt: '',
      endAt: '',
      page: String(1),
    })
  }, [setSearchParams])

  const lifeStyle: TSkinProfile = useMemo(() => {
    return dataDetailAttributesView?.find(item => item?.title === 'Lifestyle')
  }, [dataDetailAttributesView])

  const index = useMemo(() => {
    return dataDetailAttributesView.findIndex(
      item => item?.title === 'Lifestyle',
    )
  }, [dataDetailAttributesView])

  const newLifeStyle: TSkinProfile[] | undefined = lifeStyle?.questionData?.map(
    question => {
      const answer = lifeStyle?.answers?.filter(answerData => {
        return answerData.question.content === question.content
      })
      return {
        id: lifeStyle.id,
        answerText: [],
        title: lifeStyle?.title,
        questionData: [
          {
            id: question.id,
            content: question.content,
            updatedAt: question.updatedAt,
            updateCount: question.updateCount,
          },
        ],
        answers: [
          {
            content: [answer.map(item => item.content).join(', ')],
            question: {
              id: answer[0].question.id,
              content: answer[0].question.content,
              updatedAt: answer[0].question.updatedAt,
              updateCount: answer[0].question.updateCount,
            },
          },
        ],
      }
    },
  )

  const newData: TSkinProfile[] = useMemo(() => {
    if (!newLifeStyle) return []
    const data = [...dataDetailAttributesView]
    data.splice(index, 1, ...newLifeStyle)
    return data
  }, [dataDetailAttributesView, index, newLifeStyle])

  return {
    dataDetailAttributesView,
    isLoading,
    handleNextPage,
    handleChangeStartDate,
    handleChangeEndDate,
    valueStartAt,
    valueEndAt,
    handleSearch,
    handleReset,
    textValidate,
    navigate,
    infoUser,
    newData,
  }
}
