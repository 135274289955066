import React from 'react'

import { ProductRequestCompleteScreen } from './modules'

/**
 * @returns Component Product Request Complete Page
 */
export function ProductRequestCompletePage() {
  return <ProductRequestCompleteScreen />
}
