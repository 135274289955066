import React from 'react'
import { Button, Spinner, Flex, GridItem } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Box, Text } from '@chakra-ui/layout'
import moment from 'moment'
import { InputForm } from '../../../../components/modules/InputForm'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Props, useQuizQuestionDetail } from './hooks'

const StyledWrapperButton = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 8px;
`

const StyledButtonOutline = styled(Button)`
  color: #3f6fba;
  background: transparent;
  border: 1px solid #3f6fba;
`

const StyledButtonSolid = styled(Button)`
  color: #ffffff;
  background: #3f6fba;
  &:hover {
    background: #3f6fba;
  }
`

/**
 * @returns Component
 */
const Component = (props: Props) => {
  const {
    isLoading,
    handleCancel,
    handleSubmit,
    questionDetail,
    handleChangeInput,
    dataAnswer,
    getDetailQuizAnswer,
  } = props
  if (isLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    )
  }

  const columns = [
    {
      title: 'Order',
      render: record => <Box>{record?.order}</Box>,
    },
    {
      title: 'Content',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.content}</Box>
      ),
    },
    {
      title: 'Short Content',
      render: record => <Box>{record?.shortContent}</Box>,
    },
    {
      title: 'Country Name',
      render: record => <Box>{record?.countryName}</Box>,
    },
    {
      title: 'Show Profile',
      render: record => <Box>{record?.showProfile ? 'Show' : 'Not Show'}</Box>,
    },
  ]
  return (
    <>
      <Text color={'black'} fontSize={20} fontWeight={700} mb={6}>
        Detail Question
      </Text>
      <Box mb={6}>
        <Text>Question ID : {questionDetail?.id}</Text>
        <Text>Order : {questionDetail?.order}</Text>
        <Text>Profile Title : {questionDetail?.profileTitle}</Text>
        <Text>Classify : {questionDetail?.classify}</Text>
        <Text>Group : {questionDetail?.group}</Text>
        <Text>Type : {questionDetail?.type}</Text>
        <Text>
          Created At :{' '}
          {questionDetail?.createdAt
            ? moment(questionDetail?.createdAt).format('YYYY/MM/DD, HH:mm:ss A')
            : 'N/A'}
        </Text>
      </Box>
      <GridItem>
        <InputForm
          isRequired
          label={'Content'}
          name={'content'}
          value={questionDetail?.content}
          onChange={handleChangeInput}
        />
      </GridItem>
      <GridItem>
        <InputForm
          isRequired
          label={'Sub Content1'}
          name={'subContent1'}
          value={questionDetail?.subContent1}
          onChange={handleChangeInput}
        />
      </GridItem>
      <GridItem>
        <InputForm
          isRequired
          label={'Sub Content2'}
          name={'subContent2'}
          value={questionDetail?.subContent2}
          onChange={handleChangeInput}
        />
      </GridItem>

      <StyledWrapperButton>
        <StyledButtonOutline onClick={handleCancel}>Cancel</StyledButtonOutline>
        <StyledButtonSolid onClick={handleSubmit}>Save</StyledButtonSolid>
      </StyledWrapperButton>
      {dataAnswer?.length !== 0 && (
        <>
          <Box borderBottom={'1px solid black'} my={4} />
          <Text color={'black'} fontSize={20} fontWeight={700} mb={6}>
            Answer Quiz
          </Text>

          <Box overflowX={'auto'} w={'100%'}>
            <TableCustom
              columns={columns}
              data={dataAnswer || []}
              onSelect={val => {
                getDetailQuizAnswer(val.id)
              }}
            />
          </Box>
        </>
      )}
    </>
  )
}

/***
 *
 * Returns: QuizQuestionDetailScreen Screen
 */
export const QuizQuestionDetailScreen = () => {
  return <Component {...useQuizQuestionDetail()} />
}
