import { Box } from '@chakra-ui/layout'
import { BoxProps } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'

const LayoutWrapper = styled(Box)`
  display: grid;
  grid-template:
    'sidebar header'
    'sidebar main'
    'footer footer';
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto 1fr;
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
`

export const LayoutHeader = styled.header`
  grid-area: header;
  position: sticky;
  top: 0;
  z-index: 100;
  height: 60px;
  width: 100%;
`

export const LayoutSideBar = styled(Box)`
  grid-area: sidebar;
  z-index: 100;
  top: 0;
  height: 100vh;
  position: sticky;
`

export const LayoutMain = styled.main`
  grid-area: main;
  background: #fff;
  height: 100%;
  overflow: hidden;
`

export const LayoutFooter = styled.footer`
  grid-area: footer;
  bottom: 0;
  z-index: 100;
`

interface ILayoutProps extends BoxProps {}

/**
 * @returns Component Layout
 */
export const Layout: React.FC<ILayoutProps> = ({
  children,
  ...props
}): JSX.Element => {
  return <LayoutWrapper {...props}>{children}</LayoutWrapper>
}
