import { useCallback, useEffect, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isAxiosError } from 'axios'
import * as yup from 'yup'
import {
  getUsers,
  updateStatusUser,
  UserListType,
} from '../../../../service/administratorService'
import { TMetaType } from '../../ViewProductData/components/modules/types'
import { sendContactMailsUser } from '../../../../service/productService'
import { TSendEmail, TUser } from './type'
import { TemplateEmailSignature } from './components/modules/SingerEmail'

const schemaContact = yup.object().shape({
  subject: yup.string().required('Subject required'),
  body: yup.string().required('Body required'),
  emails: yup.string().email('To Email is incorrect'),
})

export type TFormValue = yup.InferType<typeof schemaContact>

export type TSelectForm = {
  id: number
  email: string
}

type IErrors = {
  [key: string]: {
    message: string
  }
}

/**
 * @returns Component useAdministrators hook
 */
export const useUserList = () => {
  const [dataAdministrator, setDataAdministrator] = useState<TUser[]>()
  const [formValue, setFormValue] = useState<Partial<TFormValue>>({
    subject: '',
    body: '',
    emails: '',
  })
  const toast = useToast()
  const navigate = useNavigate()
  const [meta, setMeta] = useState<TMetaType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectUser, setSelectUser] = useState<TSelectForm[]>([])
  const [errors, setErrors] = useState<IErrors>({})
  const page = searchParams.get('page')
  const keywordType = searchParams.get('keywordType')
  const word = searchParams.get('word')
  const [valueSelectKeyword, setValueSelectKeyword] = useState<{
    label: string
    value?: number
  }>({
    label: 'Select Keyword ...',
    value: undefined,
  })
  const [valueSelectOrderBy, setValueSelectOrderBy] = useState<{
    label: string
    value?: number
  }>({
    label: 'Select Order By ...',
    value: undefined,
  })
  const [valueSelectOrderType, setValueSelectOrderType] = useState<{
    label: string
    value?: number
  }>({
    label: 'Select Order Type ...',
    value: undefined,
  })

  const {
    isOpen: isOpenMail,
    onOpen: onOpenMail,
    onClose: onCloseMail,
  } = useDisclosure()

  const [valueInput, setValueInput] = useState('')

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params: UserListType = {
        keywordType: keywordType ? Number(keywordType) : undefined,
        word: word ? word : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
        userType: 2,
      }
      const response = await getUsers(params)
      setDataAdministrator(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [keywordType, page, toast, word])
  useEffect(() => {
    fetchData().then()
  }, [fetchData])

  const handleCreateAdd = useCallback(() => {
    navigate('/users/add')
  }, [navigate])

  const handleEdit = useCallback(
    (id: number) => {
      navigate(`/users/edit/${id}`)
    },
    [navigate],
  )
  const handleUpdateStatus = useCallback(
    async (id: number) => {
      const statusUser = dataAdministrator?.find(it => it.id === id)
      const payload = {
        removeUser: statusUser?.status === 'ACTIVE',
      }
      try {
        await updateStatusUser(id, payload)
        fetchData()
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    },
    [dataAdministrator, fetchData, toast],
  )

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )
  const handleChangeSelectKeyword = useCallback(value => {
    setValueSelectKeyword(value)
  }, [])

  const handleChangeSelectOrderBy = useCallback(value => {
    setValueSelectOrderBy(value)
  }, [])

  const handleChangeSelectOrderType = useCallback(value => {
    setValueSelectOrderType(value)
  }, [])

  const handleChangeInput = useCallback(e => {
    setValueInput(e.target.value)
  }, [])

  const handleSearchAdministrator = useCallback(() => {
    setSearchParams({
      word: String(valueInput),
      page: String(1),
    })
  }, [setSearchParams, valueInput])

  /**
   * @returns handleReset
   */
  const handleReset = useCallback(() => {
    setValueInput('')
    setValueSelectOrderBy({ label: 'Select Order By ...', value: undefined })
    setValueSelectOrderType({
      label: 'Select Order Type ...',
      value: undefined,
    })
    setValueSelectKeyword({ label: 'Select Keyword ...', value: undefined })
    setSearchParams({
      orderBy: '',
      orderType: '',
      keywordType: '',
      word: '',
      page: String(1),
    })
  }, [setSearchParams])

  const handleCheckbox = useCallback((value: TSelectForm[]) => {
    setSelectUser(value)
  }, [])
  const handleCloseModalMail = useCallback(() => {
    onCloseMail()
  }, [onCloseMail])

  const handleOpenModalMail = useCallback(() => {
    onOpenMail()
  }, [onOpenMail])

  const handleChange = useCallback(
    (values: Partial<TFormValue>) => {
      const newErrors = { ...errors }
      Object.keys(values).forEach(key => {
        delete newErrors[key]
      })
      setFormValue({ ...formValue, ...values })
      setErrors(newErrors)
    },
    [formValue, errors],
  )

  const validation = useCallback(() => {
    let newErrors: IErrors = {}
    try {
      schemaContact.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
        }
        setErrors(newErrors)
        return false
      }
    }
    return true
  }, [formValue])
  const handleSendEmail = useCallback(async () => {
    if (validation()) {
      setIsLoading(true)
      try {
        const payload: TSendEmail = {
          subject: formValue.subject,
          body: formValue.body?.concat(TemplateEmailSignature),
        }
        if (selectUser?.length) {
          payload.emails = selectUser?.map(it => it?.email)
        }
        await sendContactMailsUser(payload)
        onCloseMail()
        toast({
          position: 'top-right',
          status: 'success',
          title: 'Send email success',
          duration: 3000,
        })
        setFormValue({ subject: '', body: '', emails: '' })
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
  }, [
    validation,
    onCloseMail,
    formValue.subject,
    formValue.body,
    selectUser,
    toast,
  ])

  const handleRemoveTag = useCallback(
    tagRemove => {
      setSelectUser(selectUser.filter(it => it.id !== tagRemove.id))
    },
    [selectUser],
  )
  return {
    dataAdministrator,
    isLoading,
    handleCreateAdd,
    handleEdit,
    handleUpdateStatus,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNextPage,
    setSearchParams,
    valueSelectKeyword,
    handleChangeSelectKeyword,
    valueInput,
    handleChangeInput,
    handleSearchAdministrator,
    handleChangeSelectOrderBy,
    valueSelectOrderBy,
    handleChangeSelectOrderType,
    valueSelectOrderType,
    handleReset,
    selectUser,
    handleCheckbox,
    isOpenMail,
    onOpenMail,
    onCloseMail,
    handleCloseModalMail,
    handleOpenModalMail,
    handleSendEmail,
    errors,
    handleChange,
    formValue,
    dataUser: selectUser,
    handleRemoveTag,
  }
}
