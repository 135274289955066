import React from 'react'
import { PromotedBrandCreateScreen } from './components/modules'

/**
 * PromotionPage component page.
 * @return {JSX.Element} PromotionPage component.
 */
export function PromotionBrandCreatePage() {
  return <PromotedBrandCreateScreen />
}
