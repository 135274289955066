import { AxiosInstance } from 'axios'

import { createAxios } from './createAxios'

export const axiosWithToken: AxiosInstance = createAxios()

export const axiosWithoutToken: AxiosInstance = createAxios({
  withAuthToken: false,
})

export const axiosWithFile: AxiosInstance = createAxios({ withFile: true })

/**
 * @returns function that handle set authorization token
 */
export function setAuthorizationToken(token?: string) {
  axiosWithToken.defaults.headers.common.Authorization = `Bearer ${token}`
}
