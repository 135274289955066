/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react'
import { useRoutes } from 'react-router-dom'

import { GlobalStyle } from 'styles/global-styles'

import { HomePage } from 'app/pages/HomePage'
import { NotFoundPage } from 'app/pages/NotFoundPage'
import { ProtectedRoute } from './components/elements/ProtectedRoute'
import { PublicRoute } from './components/elements/PublicRoute'
import { LoginPage } from './pages/LoginPage'
import { ProductListPage } from './pages/ViewProductData'
import { ProductDetailPage } from './pages/ProductDetailPage'
import { ProductRequestPage } from './pages/ProductRequestsPage'
import { AddProductPage } from './pages/AddProductPage'
import { PromotionBrandPage } from './pages/PromotionBrandPage'
import { EditAllSkinProfilePage } from './pages/EditAllSkinProfilePage'
import { ScrollToTop } from './components/elements/ScrollToTop'
import { ViewRulesPage } from './pages/ViewRulesPage'
import { QuizQuestionPage } from './pages/QuizQuesitonPage'
import { QuizQuestionDetailPage } from './pages/QuestionDetailPage'
import { QuizAnswerDetailPage } from './pages/AnswerDetailPage'
import { ProductRequestCompletePage } from './pages/ProductRequestCompletePage'
import { PromotedBrandDetail } from './pages/PromotedBrandDetail'
import { PromotionRetailersPage } from './pages/PromotionRetailersPage'
import { PromotedRetailersDetail } from './pages/PromotedRetailersDetail'
import { QuizResultsPage } from './pages/QuizResultsPage'
import { QuizResultsDetailPage } from './pages/QuizResultsDetailPage'
import { UserPage } from './pages/UserPage/List'
import { AdministratorsPage } from './pages/AdministratorsPage/List'
import { AdministratorsAddPage } from './pages/AdministratorsPage/Add'
import { AdministratorsEditPage } from './pages/AdministratorsPage/Edit'
import { UserEditPage } from './pages/UserPage/Edit'
import { IngredientTagsPage } from './pages/IngredientTagsPage'
import { IngredientDictionaryPage } from './pages/IngredientDictionaryPage'
import { IngredientINCIPage } from './pages/IngredientINCIPage'
import { IngredientDefinitionPage } from './pages/IngredientDefinitionPage'
import { HistoryFileUploadPage } from './pages/HistoryFileUploadPage'
import { TwoFactorAuthPage } from './pages/TwoFactorAuthPage'
import { DermTipsPage } from './pages/DermTipsPage'
import { DermTipsAddPage } from './pages/DermTipsAddPage'
import { DermTipsEditPage } from './pages/DermTipsEditPage'
import { UserRoutinePage } from './pages/AggregateUserRoutinePage'
import { MostPopularProductPage } from './pages/MostPopularProductPage'
import { IndividualUserMetricPage } from './pages/IndividualUserMetricPage'
import { MonthlyUserUseAppPage } from './pages/MonthlyUseAppPage'
import { ProductExternalLinkPage } from './pages/ProductExternalLinkPage'
import { DetailProductRoutinePage } from './pages/DetailProductRoutinePage'
import { DetailRetailerViewsPage } from './pages/DetailRetailerViewsPage'
import { AdministratorsInActivePage } from './pages/AdministratorsInActivePage'
import { ProductRequestAddPage } from './pages/ProductRequestAddPage'
import { DetailProductViewsPage } from './pages/DetailProductViewsPage'
import { PromotionBrandCreatePage } from './pages/PromotionBrandCreatePage'
import { PromotionRetailerCreatePage } from './pages/PromotionRetailerCreatePage'
import { DetailAttributesViewsPage } from './pages/DetailAttributesViewsPage'
import { AllViewsProductPage } from './pages/AllViewsProductPage'
import { DetailOfDetailProductViewsPage } from './pages/DetailOfDetailProductViewsPage'
/**
 *
 * Routers for app
 *
 * */
export function App() {
  let element = useRoutes([
    {
      path: '*',
      element: <NotFoundPage />,
    },
    {
      element: <ProtectedRoute />,
      children: [
        {
          path: '/',
          element: <HomePage />,
        },
        {
          path: '/product-list',
          element: <ProductListPage />,
        },
        {
          path: '/product-detail/:id',
          element: <ProductDetailPage />,
        },
        {
          path: '/users',
          element: <UserPage />,
        },
        {
          path: '/users/edit/:id',
          element: <UserEditPage />,
        },
        {
          path: '/administrators',
          element: <AdministratorsPage />,
        },
        {
          path: '/administrators-inactive',
          element: <AdministratorsInActivePage />,
        },
        {
          path: '/administrators/add',
          element: <AdministratorsAddPage />,
        },
        {
          path: '/administrators/edit/:id',
          element: <AdministratorsEditPage />,
        },
        {
          path: '/product-requests',
          element: <ProductRequestPage />,
        },
        {
          path: '/product-requests-add/:id',
          element: <ProductRequestAddPage />,
        },
        {
          path: '/product-requests-complete',
          element: <ProductRequestCompletePage />,
        },
        {
          path: '/add-products',
          element: <AddProductPage />,
        },
        {
          path: '/view-rules',
          element: <ViewRulesPage />,
        },
        {
          path: '/promoted-brand',
          element: <PromotionBrandPage />,
        },
        {
          path: '/promoted-brand/create',
          element: <PromotionBrandCreatePage />,
        },
        {
          path: '/promoted-brand/:id',
          element: <PromotedBrandDetail />,
        },
        {
          path: '/promoted-retailers',
          element: <PromotionRetailersPage />,
        },
        {
          path: '/promoted-retailers/create',
          element: <PromotionRetailerCreatePage />,
        },
        {
          path: '/promoted-retailers/:id',
          element: <PromotedRetailersDetail />,
        },
        {
          path: '/edit-profile',
          element: <EditAllSkinProfilePage />,
        },
        {
          path: '/quiz-questions',
          element: <QuizQuestionPage />,
        },
        {
          path: '/ingredient-tags',
          element: <IngredientTagsPage />,
        },
        {
          path: '/ingredient-dictionary',
          element: <IngredientDictionaryPage />,
        },
        {
          path: '/ingredient-inci',
          element: <IngredientINCIPage />,
        },
        {
          path: '/ingredient-definition',
          element: <IngredientDefinitionPage />,
        },
        {
          path: '/derm-tips',
          element: <DermTipsPage />,
        },
        {
          path: '/derm-tips/add',
          element: <DermTipsAddPage />,
        },
        {
          path: '/derm-tips/edit/:id',
          element: <DermTipsEditPage />,
        },
        {
          path: '/history_upload',
          element: <HistoryFileUploadPage />,
        },
        {
          path: '/quiz-questions/:id',
          element: <QuizQuestionDetailPage />,
        },
        {
          path: '/quiz-answer/:id',
          element: <QuizAnswerDetailPage />,
        },
        {
          path: '/quiz-results',
          element: <QuizResultsPage />,
        },
        {
          path: '/quiz-results/:id',
          element: <QuizResultsDetailPage />,
        },
        {
          path: '/aggregate-user-routine',
          element: <UserRoutinePage />,
        },
        {
          path: '/most-popular-product',
          element: <MostPopularProductPage />,
        },
        {
          path: '/individual-user-routine',
          element: <IndividualUserMetricPage />,
        },
        {
          path: '/monthly-user-use-app',
          element: <MonthlyUserUseAppPage />,
        },
        {
          path: '/product-external-link',
          element: <ProductExternalLinkPage />,
        },
        {
          path: '/detail-product-routine/:recordId',
          element: <DetailProductRoutinePage />,
        },
        {
          path: '/product-external-link/retailer/:retailerId',
          element: <DetailRetailerViewsPage />,
        },
        {
          path: '/product-external-link/product-list/:productId',
          element: <DetailProductViewsPage />,
        },
        {
          path: '/product-external-link/detail-product-list',
          element: <DetailOfDetailProductViewsPage />,
        },
        {
          path: '/product-external-link/all-view-product/:productId',
          element: <AllViewsProductPage />,
        },
        {
          path: '/individual-user-routine/attributes/:userId',
          element: <DetailAttributesViewsPage />,
        },
      ],
    },
    {
      element: <PublicRoute />,
      children: [
        { path: '/login', element: <LoginPage /> },
        {
          path: '/two_factor_auth',
          element: <TwoFactorAuthPage />,
        },
      ],
    },
  ])
  return (
    <>
      {element}
      <ScrollToTop />
      <GlobalStyle />
    </>
  )
}
