import { ChangeEvent, useCallback, useState } from 'react'
import * as yup from 'yup'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Errors } from 'types/conmon'
import { createAdministrator } from '../../../../../../service/administratorService'

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  avatar: yup.string(),
  email: yup
    .string()
    .email('email is incorrect')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'email invalid',
    )
    .required('email required'),
  phoneNumber: yup.string().required(),
  accountStatus: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
  password: yup.string().required('Password required'),
})
type FormValue = yup.InferType<typeof schema>

const initialState: FormValue = {
  firstName: '',
  lastName: '',
  avatar: '',
  email: '',
  phoneNumber: '',
  accountStatus: { value: 1, label: 'ADMIN' },
  password: '',
}
/**
 * useAddAdministrators hook.
 */
export const useAddAdministrators = () => {
  const [formValue, setFormValue] = useState<FormValue>(initialState)
  const [errors, setErrors] = useState<Errors>()
  const toast = useToast()
  const navigate = useNavigate()

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setFormValue(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )

  /**
   * @returns function that handle validates form
   */
  const validation = useCallback((): boolean => {
    setErrors(undefined)
    try {
      schema.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          const newErrors: Errors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }

    return true
  }, [formValue])
  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelect = useCallback(
    (key: keyof FormValue, value: string) => {
      setFormValue({
        ...formValue,
        [key]: value,
      })
    },
    [formValue],
  )

  const handleSubmit = useCallback(async () => {
    if (validation()) {
      try {
        const payload = {
          role: formValue.accountStatus.label,
          firstName: formValue.firstName,
          lastName: formValue.lastName,
          email: formValue.email,
          phoneNumber: formValue.phoneNumber,
          password: formValue.password,
        }
        await createAdministrator(payload)
        toast({
          position: 'top-right',
          status: 'success',
          title: 'Create Success',
          duration: 1000,
        })
        navigate('/administrators')
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    }
  }, [
    formValue.accountStatus.label,
    formValue.email,
    formValue.firstName,
    formValue.lastName,
    formValue.password,
    formValue.phoneNumber,
    navigate,
    toast,
    validation,
  ])

  return {
    handleSubmit,
    errors,
    handleChangeInput,
    formValue,
    handleOnChangeSelect,
  }
}
