import React from 'react'
import { EditProfile } from './components/templates'

/**
 * EditAllSkinProfilePage component page.
 * @return {JSX.Element} EditAllSkinProfilePage component.
 */
export function EditAllSkinProfilePage() {
  return <EditProfile />
}
