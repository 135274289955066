import { useState, useCallback, useEffect, ChangeEvent } from 'react'
import * as yup from 'yup'
import { useParams } from 'hooks/useRouter'
import { useNavigate } from 'react-router-dom'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import {
  getQuizAnswerDetail,
  updateQuizAnswer,
} from '../../../../../service/quizQuestion'

const schema = yup.object().shape({
  id: yup.number(),
  questionId: yup.number().required(),
  content: yup.string().required(),
  shortContent: yup.string().required(),
  order: yup.number().required(),
  countryName: yup.string().required(),
  isNone: yup.number().required(),
  showProfile: yup.boolean().required(),
  possibleAttributes: yup.string().required(),
})

export type TFormValue = yup.InferType<typeof schema>

type IErrors = {
  [key: string]: {
    message: string
  }
}

/***
 *
 * Returns: useQuizAnswers
 */
export const useQuizAnswerDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [answerDetail, setAnswerDetail] = useState<Partial<TFormValue>>({})
  const [errors, setErrors] = useState<IErrors>({})
  const toast = useToast()

  const fetchDetailProduct = useCallback(
    async (idArg: string) => {
      setIsLoading(true)
      try {
        const detail = await getQuizAnswerDetail(Number(idArg))
        setAnswerDetail(detail)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [toast],
  )
  useEffect(() => {
    if (!id) return
    fetchDetailProduct(id)
  }, [id])

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setAnswerDetail(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )

  const validation = useCallback(() => {
    let newErrors: IErrors = {}
    try {
      schema.validateSync(setAnswerDetail, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
        }
        setErrors(newErrors)
        return false
      }
    }
    return true
  }, [setAnswerDetail])

  const handleCancel = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const handleSubmit = useCallback(async () => {
    if (validation()) {
      setIsLoading(true)
      try {
        const payload = {
          content: answerDetail.content,
          shortContent: answerDetail.shortContent,
          showProfile: answerDetail.showProfile,
        }
        await updateQuizAnswer(Number(id), payload)
        toast({
          position: 'top-right',
          status: 'success',
          title: 'Change Success',
          duration: 1000,
        })
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
  }, [
    answerDetail.content,
    answerDetail.shortContent,
    answerDetail.showProfile,
    id,
    navigate,
    toast,
    validation,
  ])

  return {
    errors,
    isLoading,
    handleCancel,
    handleSubmit,
    answerDetail,
    handleChangeInput,
    setAnswerDetail,
  }
}

export type Props = ReturnType<typeof useQuizAnswerDetail>
