export type Option = Partial<{
  value: number
  label: string
}>
export const OptionSelect: Option[] = [
  {
    label: 'Include',
    value: 1,
  },
  {
    label: 'Exclude',
    value: 0,
  },
]
