import * as yup from 'yup'
import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { isAxiosError } from 'axios'
import { useParams } from 'react-router'
import { Errors, Option } from '../../../../types'
import {
  createProductRequest,
  getBrandOption,
  getCategory1,
  getCategory2,
  getCategory3,
  getProductRequestDetail,
  getRetailerOption,
} from '../../../../service/productService'
import {
  TBrandOption,
  TCCategory1,
  TCCategory2,
  TCCategory3,
  TRRetailerOption,
} from '../../ViewProductData/components/modules/types'
import { OptionSelect } from '../components/modules/constants'

export type TAddProductScreenProps = {}

const schema = yup.object().shape({
  id: yup.string(),
  brand: yup.string().required(),
  name: yup.string().required(),
  cat1: yup
    .object()
    .shape({
      id: yup.string().required(),
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .test('category1', 'Category 1 is a required field', value => {
      const { id, value: catValue, label } = value || {}
      return !(!id || !catValue || !label)
    }),
  cat2: yup
    .object()
    .shape({
      id: yup.string().required(),
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .test('category2', 'Category 2 is a required field', value => {
      const { id, value: catValue, label } = value || {}
      return !(!id || !catValue || !label)
    }),
  cat3: yup
    .object()
    .shape({
      id: yup.string().required(),
      value: yup.string(),
      label: yup.string(),
    })
    .test('category3', 'Category 3 is a required field', value => {
      const { id } = value || {}
      return !!id
    }),
  description: yup.string(),
  ingredients: yup.string().required(),
  originurls: yup
    .array()
    .of(
      yup.object().shape({
        originurl: yup
          .string()
          .url('originurl must be a valid URL')
          .test('originurl', 'Originurl is a required field', (value, ctx) => {
            return (
              (ctx.from &&
                ctx.from[1].value.cat1?.value === 'Prescription Topicals') ||
              !!value
            )
          }),
        price: yup
          .number()
          .test('price', 'Price is a required field', (value, ctx) => {
            return (
              (ctx.from &&
                ctx.from[1].value.cat1?.value === 'Prescription Topicals') ||
              !!value
            )
          }),
        stars: yup.number(),
        reviewCount: yup.number(),
      }),
    )
    .required(),
  retailers: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          value: yup.string().required(),
          label: yup.string().required(),
        })
        .test('retailer', 'Retailer is a required field', value => {
          const { value: catValue, label } = value || {}
          return !(!catValue || !label)
        }),
    )
    .required(),
  urlImage: yup
    .string()
    .url()
    .test('urlImage', 'UrlImage is a required field', (value, ctx) => {
      return ctx.parent.cat1?.value === 'Prescription Topicals' || !!value
    }),
  upcNumber: yup.string(),
  other_brand: yup.string(),
  other_retailer: yup.string(),
  weblink: yup.string(),
  upc: yup.string().required(),
  joblink: yup.string(),
  spf: yup.object().shape({
    label: yup.string(),
    value: yup.string(),
  }),
})

type FormValue = yup.InferType<typeof schema>

const initialState: FormValue = {
  id: '',
  brand: '',
  name: '',
  cat1: { id: '', value: '', label: '' },
  cat2: { id: '', value: '', label: '' },
  cat3: { id: '', value: '', label: '' },
  description: '',
  ingredients: '',
  originurls: [
    {
      originurl: '',
      price: undefined,
      stars: undefined,
      reviewCount: undefined,
    },
  ],
  retailers: [{ value: '', label: '' }],
  upcNumber: '',
  weblink: '',
  urlImage: '',
  upc: '',
  joblink: '',
  spf: { value: '', label: '' },
}

/**
 * useAddProductScreen hook.
 */
export const useAddProductScreen = () => {
  const [formValue, setFormValue] = useState<FormValue>(initialState)
  const [errors, setErrors] = useState<Errors>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [brandOption, setBrandOption] = useState<TBrandOption[]>()
  const [retailerOption, setRetailerOption] = useState<TRRetailerOption[]>()
  const [allOptionsRetailer, setAllOptionsRetailer] =
    useState<TRRetailerOption[]>()
  const [category1, setCategory1] = useState<TCCategory1[]>()
  const [category2, setCategory2] = useState<TCCategory2[]>()
  const [category3, setCategory3] = useState<TCCategory3[]>()
  const navigate = useNavigate()
  const toast = useToast()
  const { id } = useParams()
  const [isShowInput, setIsShowInput] = useState(true)

  const labels = {
    originurl: 'Origin Url',
    price: 'Price',
    stars: 'Star Rating',
    reviewCount: 'Number of Reviews',
  }

  const fetchDetailRequestProduct = useCallback(async () => {
    if (!id) return
    setIsLoading(true)
    try {
      const data = await getProductRequestDetail(id)
      setFormValue({
        ...data,
        originurls: [
          {
            originurl: data.weblink,
            price: data.price,
            stars: data.stars,
            reviewCount: data.reviewCount,
          },
        ],
        retailers: [
          {
            value: data.retailer,
            label: data.retailer,
          },
        ],
      })
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [id, toast])
  useEffect(() => {
    fetchDetailRequestProduct().then()
  }, [fetchDetailRequestProduct])

  const handleChangeInput = useCallback((name: string, value) => {
    setFormValue(prevState => ({
      ...prevState,
      [name]: value,
    }))
    setErrors(prevState => ({
      ...prevState,
      [name]: { message: '' },
    }))
  }, [])

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelect = useCallback(
    (name: string, option: Option | unknown, indexItem?: number) => {
      if (typeof indexItem === 'number') {
        let currentValue = formValue[name].map((item, index) => {
          if (index === indexItem) {
            return option
          }
          return item
        })
        setFormValue(prevState => ({
          ...prevState,
          [name]: currentValue,
        }))
        return
      }
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        setFormValue(prevState => ({
          ...prevState,
          [name]: selectedOption,
        }))
        setErrors(prevState => ({
          ...prevState,
          [name]: { message: '' },
        }))
      }
    },
    [formValue],
  )

  const fetchCategory2 = useCallback(
    async (catId1: number) => {
      setIsLoading(true)
      try {
        const options = await getCategory2(catId1)
        const mockCategory2 = options.map(it => {
          return {
            id: it.id,
            value: it.content,
            label: it.content,
          }
        })
        setCategory2(mockCategory2)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [toast],
  )
  useEffect(() => {
    fetchCategory2(1)
  }, [fetchCategory2])

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelectCategory1 = useCallback(
    (name: string, option: Option | unknown) => {
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        const selectId = selectedOption.id
        setFormValue(prevState => ({
          ...prevState,
          [name]: selectedOption,
          cat2: { id: '', value: '', label: '' },
          cat3: { id: '', value: '', label: '' },
        }))
        setErrors(prevState => ({
          ...prevState,
          [name]: { message: '' },
        }))
        if (selectId === 6) {
          setFormValue(pre => ({
            ...pre,
            cat3: { id: '-1', value: '', label: '' },
          }))
          setErrors(prevState => ({
            ...prevState,
            cat3: { message: '' },
          }))
        }
        if (selectId !== undefined) {
          fetchCategory2(selectId)
        }
      }
    },
    [fetchCategory2],
  )

  const fetchCategory3 = useCallback(
    async (catId2: number) => {
      setIsLoading(true)
      try {
        const options = await getCategory3(catId2)
        const mockCategory3 = options.map(it => {
          return {
            id: it.id,
            value: it.content,
            label: it.content,
          }
        })
        setCategory3(mockCategory3)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [toast],
  )

  useEffect(() => {
    fetchCategory3(1)
  }, [fetchCategory3])

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelectCategory2 = useCallback(
    (name: string, option: Option | unknown) => {
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        const selectId = selectedOption.id
        setFormValue(prevState => ({
          ...prevState,
          [name]: selectedOption,
          cat3: {
            id: Number(prevState?.cat1?.id) === 6 ? '-1' : '',
            value: '',
            label: '',
          },
        }))
        if (selectId !== undefined) {
          fetchCategory3(selectId)
        }
        setErrors(prevState => ({
          ...prevState,
          [name]: { message: '' },
        }))
      }
    },
    [fetchCategory3],
  )

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelectCategory3 = useCallback(
    (name: string, option: Option | unknown) => {
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        setFormValue(prevState => ({
          ...prevState,
          [name]: selectedOption,
        }))
        setErrors(prevState => ({
          ...prevState,
          [name]: { message: '' },
        }))
      }
    },
    [],
  )

  /**
   * @returns function that handle validates form
   */
  const validation = useCallback((): boolean => {
    setErrors(undefined)
    try {
      schema.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          const newErrors: Errors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }
    return true
  }, [formValue])

  const handleSubmit = useCallback(
    async (id: number) => {
      if (validation()) {
        try {
          const payload = {
            brand: formValue.brand,
            product: formValue.name,
            description: formValue.description,
            ingredients: formValue.ingredients,
            retailers: formValue.originurls.map((item, idx) => ({
              ...item,
              retailer: formValue.retailers[idx].value,
            })),
            upc: formValue.upc,
            imageurl: formValue.urlImage,
            cat1: formValue.cat1?.value,
            cat2: formValue.cat2?.value,
            cat3: formValue.cat3?.value,
            joblink: formValue.joblink,
            spf: formValue.spf?.value ?? OptionSelect[1].value,
          }
          await createProductRequest(id, payload)
          toast({
            position: 'top-right',
            status: 'success',
            title: 'Submit Success',
            duration: 3000,
          })
          navigate('/product-list')
        } catch (e) {
          if (isAxiosError(e)) {
            const message = e?.response?.data.message
            toast({
              position: 'top-right',
              status: 'error',
              title: message,
              duration: 3000,
            })
          }
        }
      }
    },
    [
      validation,
      formValue.brand,
      formValue.name,
      formValue.description,
      formValue.ingredients,
      formValue.originurls,
      formValue.upc,
      formValue.urlImage,
      formValue.cat1?.value,
      formValue.cat2?.value,
      formValue.cat3?.value,
      formValue.joblink,
      formValue.spf?.value,
      formValue.retailers,
      toast,
      navigate,
    ],
  )

  const fetchBrand = useCallback(async () => {
    setIsLoading(true)
    try {
      const options = await getBrandOption()
      setBrandOption(options)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [toast])

  useEffect(() => {
    fetchBrand()
  }, [fetchBrand])

  const mockBrandSelection: { label: string; value: string }[] | undefined =
    brandOption?.map(it => {
      return {
        value: it.name,
        label: it.name,
      }
    })

  const fetchRetailer = useCallback(async () => {
    setIsLoading(true)
    try {
      const options = await getRetailerOption()
      const mockRetailerSelection = options.map(it => {
        return {
          value: it.name,
          label: it.name,
        }
      })
      setAllOptionsRetailer(mockRetailerSelection)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [toast])

  useEffect(() => {
    fetchRetailer()
  }, [fetchRetailer])

  useEffect(() => {
    const newOptions = allOptionsRetailer?.filter(
      it => !formValue?.retailers?.map(item => item.value).includes(it?.value),
    )
    setRetailerOption(newOptions)
  }, [allOptionsRetailer, formValue])

  const fetchCategory1 = useCallback(async () => {
    setIsLoading(true)
    try {
      const options = await getCategory1()
      const mockCategory1 = options.map(it => {
        return {
          id: it.id,
          value: it.content,
          label: it.content,
        }
      })
      setCategory1(mockCategory1)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [toast])

  useEffect(() => {
    fetchCategory1()
  }, [fetchCategory1])

  const handleChangeInputRetailes = useCallback((index: number, value) => {
    setFormValue(pre => ({
      ...pre,
      originurls: pre?.originurls?.map((retailer, retailerIndex) => {
        if (retailerIndex !== index) return retailer
        return { ...retailer, ...value }
      }),
    }))
  }, [])

  useEffect(() => {
    formValue.originurls.forEach((retailer, idx) => {
      const { originurl, price } = retailer
      if (originurl !== '') {
        setErrors(prevErrors => ({
          ...prevErrors,
          [`originurls[${idx}].originurl`]: { message: '' },
        }))
      }
      if (price !== undefined) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [`originurls[${idx}].price`]: { message: '' },
        }))
      }
    })
    formValue.retailers.forEach((retailer, idx) => {
      if (retailer.value !== '') {
        setErrors(prevErrors => ({
          ...prevErrors,
          [`retailers[${idx}]`]: { message: '' },
        }))
      }
    })
  }, [
    JSON.stringify(formValue.originurls),
    JSON.stringify(formValue.retailers),
  ])
  const handleAddRetailer = useCallback(() => {
    setFormValue(pre => ({
      ...pre,
      originurls: [
        ...pre.originurls,
        {
          originurl: '',
          price: undefined,
          stars: undefined,
          reviewCount: undefined,
        },
      ],
      retailers: [...pre.retailers, { value: '', label: '' }],
    }))
  }, [])

  const handleRemoveBox = useCallback(index => {
    setFormValue(prevState => ({
      ...prevState,
      originurls: prevState.originurls.filter((_, idx) => idx !== index),
      retailers: prevState.retailers.filter((_, idx) => idx !== index),
    }))
  }, [])

  return {
    errors,
    formValue,
    handleSubmit,
    handleChangeInput,
    handleOnChangeSelect,
    isLoading,
    brandOption,
    mockBrandSelection,
    category1,
    category2,
    category3,
    fetchCategory2,
    fetchCategory3,
    handleOnChangeSelectCategory1,
    handleOnChangeSelectCategory2,
    handleOnChangeSelectCategory3,
    handleChangeInputRetailes,
    handleAddRetailer,
    defaultBox: formValue.originurls,
    labels,
    handleRemoveBox,
    isShowInput,
    setIsShowInput,
    retailerOption,
    fetchRetailer,
  }
}

export type Props = ReturnType<typeof useAddProductScreen>
