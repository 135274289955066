import React from 'react'
import { Layout } from 'app/components/elements/Layout'
import { TwoFactorAuthScreen } from './components/modules'

/**
 * @returns Component TwoFactorAuth Page
 */
export function TwoFactorAuthPage() {
  return (
    <Layout>
      <TwoFactorAuthScreen />
    </Layout>
  )
}
