import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { isAxiosError } from 'axios'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import {
  getDetailRetailer,
  putRetailers,
} from '../../../service/promotionService'
import { Errors } from '../../../types'

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  promoted: yup.boolean(),
  imgUrl: yup.string().url().required(),
})
type FormValue = yup.InferType<typeof schema>

const initialState: FormValue = {
  name: '',
  description: '',
  promoted: false,
  imgUrl: '',
}

/**
 * usePromotedBrandDetail hook.
 */
export const usePromotedBrandDetailScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [retailerDetail, setRetailerDetail] = useState<FormValue>(initialState)
  const [errors, setErrors] = useState<Errors>()
  const toast = useToast()
  const { id } = useParams()
  const navigate = useNavigate()

  const handleChangeForm = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target
      setRetailerDetail(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )
  const fetchRetailerData = useCallback(async () => {
    setIsLoading(true)
    try {
      if (id) {
        const res = await getDetailRetailer(id)
        setRetailerDetail(res)
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [toast])

  useEffect(() => {
    fetchRetailerData().then()
  }, [fetchRetailerData])

  const handleTogglePromotion = useCallback(() => {
    setRetailerDetail({
      ...retailerDetail,
      promoted: !retailerDetail.promoted,
    })
  }, [retailerDetail])

  /**
   * @returns function that handle validates form
   */
  const validation = useCallback((): boolean => {
    setErrors(undefined)
    try {
      schema.validateSync(retailerDetail, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          const newErrors: Errors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }

    return true
  }, [retailerDetail])

  const handleSavePromotedRetailer = useCallback(async () => {
    if (!validation()) return
    setIsLoading(true)
    try {
      if (id) {
        await putRetailers(id, {
          name: retailerDetail.name,
          description: retailerDetail.description,
          promoted: retailerDetail.promoted ? 1 : 0,
          imgUrl: retailerDetail?.imgUrl,
        })
      }
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Change Success',
        duration: 1000,
      })
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [
    id,
    toast,
    retailerDetail.name,
    retailerDetail.description,
    retailerDetail.promoted,
    retailerDetail?.imgUrl,
  ])

  const handleBack = useCallback(
    () => navigate('/promoted-retailers'),
    [navigate],
  )

  return {
    isLoading,
    retailerDetail,
    handleChangeForm,
    errors,
    handleTogglePromotion,
    handleSavePromotedRetailer,
    handleBack,
  }
}

export type Props = ReturnType<typeof usePromotedBrandDetailScreen>
