import React from 'react'
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  Props as ReactSelectProps,
  StylesConfig,
} from 'react-select'
import { useTheme } from '@chakra-ui/react'

/**
 * DropdownIndicator component.
 * @return {JSX.Element} DropdownIndicator component.
 */
const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}></components.DropdownIndicator>
  )
}

export interface ISelectProps extends ReactSelectProps {
  isInvalid?: boolean
  width?: string | number
}

/**
 * Select component.
 * @return {JSX.Element} Select component.
 */
export const Select: React.FC<ISelectProps> = ({ ...props }): JSX.Element => {
  const theme = useTheme()
  const CustomStyles: StylesConfig = {
    indicatorSeparator: styles => ({
      ...styles,
      display: 'none',
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: theme.fontSizes.sm,
      color: theme.colors.black[100],
      opacity: 0.3,
    }),
    option: (styles, props) => {
      return {
        ...styles,
        paddingLeft: theme.space[2],
        color: theme.colors.black[100],
        cursor: 'pointer',
        background: props.isSelected ? theme.colors.cyan[150] : 'transparent',
        '&:hover': {
          background: props.isSelected
            ? theme.colors.cyan[150]
            : theme.colors.cyan[250],
        },
      }
    },
    singleValue: styles => ({
      ...styles,
      color: '#000000',
      fontSize: '14px',
    }),
    control: styles => ({
      ...styles,
      '&:hover': {
        cursor: 'pointer',
      },
      borderColor: props.isInvalid ? '#E53E3E' : '#e4e7ff',
      minHeight: 40,
      boxShadow: props.isInvalid
        ? '0 0 0 1px #E53E3E'
        : '0 5px 4px 0 rgba(120, 123, 172, 0.08) inset',
      background: '#ffffff',
      height: '100%',
      width: props.width ? props.width : '100%',
      minWidth: props.width ? props.width : 300,
    }),
    menuList: styles => ({
      ...styles,
      maxHeight: 200,
    }),
    menu: styles => ({
      ...styles,
      margin: 0,
      background: '#ffffff',
    }),
    indicatorsContainer: styles => ({
      ...styles,
      paddingRight: 2,
    }),
    valueContainer: styles => ({
      ...styles,
      paddingRight: 0,
    }),
  }
  return (
    <ReactSelect
      components={{ DropdownIndicator }}
      isSearchable={true}
      styles={CustomStyles}
      {...props}
    />
  )
}
