import { ChangeEvent, useCallback, useState } from 'react'
import { isAxiosError } from 'axios'
import * as yup from 'yup'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Errors, Option } from '../../../../../types'
import { createBrands } from '../../../../../service/promotionService'

const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  promoted: yup.boolean(),
})
type FormValue = yup.InferType<typeof schema>

const initialState: FormValue = {
  name: '',
  description: '',
  promoted: false,
}
/***
 *
 * Returns: useCreatePromoteBrand
 */
export const useCreatePromoteBrand = () => {
  const [errors, setErrors] = useState<Errors>()
  const [formValue, setFormValue] = useState<FormValue>(initialState)
  const toast = useToast()
  const navigate = useNavigate()

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target
      setFormValue(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )
  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelect = useCallback(
    (name: string, option: Option | unknown) => {
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        setFormValue(prevState => ({
          ...prevState,
          [name]: selectedOption,
        }))
      }
    },
    [],
  )
  /**
   * @returns function that handle validates form
   */
  const validation = useCallback((): boolean => {
    setErrors(undefined)
    try {
      schema.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          const newErrors: Errors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }

    return true
  }, [formValue])

  const handleSubmit = useCallback(async () => {
    if (validation()) {
      try {
        const payload = {
          name: formValue.name,
          description: formValue.description,
          promoted: formValue.promoted ? 1 : 0,
        }
        await createBrands(payload)
        toast({
          position: 'top-right',
          status: 'success',
          title: 'Create Success',
          duration: 1000,
        })
        navigate('/promoted-brand')
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    }
  }, [
    formValue.description,
    formValue.name,
    formValue.promoted,
    navigate,
    toast,
    validation,
  ])

  const handleTogglePromotion = useCallback(() => {
    setFormValue({
      ...formValue,
      promoted: !formValue.promoted,
    })
  }, [formValue])

  return {
    handleSubmit,
    formValue,
    errors,
    handleChangeInput,
    handleOnChangeSelect,
    handleTogglePromotion,
  }
}
export type Props = ReturnType<typeof useCreatePromoteBrand>
