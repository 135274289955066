import React, { ComponentProps } from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import { Label } from '../Label'
import { DateTimeComponent } from './DateTimeComponent'
import { ErrorText } from './ErrorText'

type InputDateTimeProps = {
  label?: string | React.ReactNode
  direction?: 'horizontal' | 'vertical'
  labelWidth?: string | number
  isRequired?: boolean
  onChange: (value: Date) => void
  value?: Date
  errorProps?: ComponentProps<typeof ErrorText>
  errorMessage?: string
  readOnly?: boolean
  isShowTime?: boolean
  isEditProduct?: boolean
}
/**
 * @returns Component InputDateTime
 */
export const InputDateTime = (props: InputDateTimeProps) => {
  const {
    value,
    labelWidth,
    label,
    direction = 'vertical',
    isRequired,
    onChange,
    errorProps,
    errorMessage,
    readOnly,
    isShowTime,
    isEditProduct,
  } = props
  if (direction === 'horizontal') {
    return (
      <Box w={isEditProduct ? '550px' : '350px'}>
        <Flex alignItems="center" gap={2} w={'full'}>
          <Label
            isRequired={isRequired}
            label={label}
            labelWidth={labelWidth}
            mb={errorMessage ? 6 : 0}
          />
          <Flex flexDirection={'column'} gap={1} w={'full'}>
            <DateTimeComponent
              dateOfBirth={value}
              isShowTime={isShowTime}
              onChangeDate={date => {
                onChange(date)
              }}
            />
            {errorMessage && (
              <ErrorText {...errorProps}>{errorMessage}</ErrorText>
            )}
          </Flex>
        </Flex>
      </Box>
    )
  }

  return (
    <Flex flexDirection={'column'} gap={2} w={'full'}>
      <Label isRequired={isRequired} label={label} labelWidth={labelWidth} />
      <DateTimeComponent
        dateOfBirth={value}
        readOnly={readOnly}
        onChangeDate={date => {
          onChange(date)
        }}
      />
      {errorMessage && <ErrorText {...errorProps}>{errorMessage}</ErrorText>}
    </Flex>
  )
}
