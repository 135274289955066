import qs from 'qs'
import { axiosWithToken } from '../configureAxios'
import { TViewRulesList } from '../app/pages/ViewRulesPage/components/modules/type'

type ViewRulesType = {
  page: number
  limit: number
  possibleAttributes?: string
  includeOrExclude?: number
}
/**
 * @returns get ViewRules
 * */
export const getViewRules = async (
  params: ViewRulesType,
): Promise<TViewRulesList> => {
  const { data } = await axiosWithToken.get('/rules', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/***
 *
 * add update Rule
 */
export const updateRule = async payload => {
  const formData = new FormData()
  formData.append('file', payload)
  const { data } = await axiosWithToken.post(
    `/rules/import-excel?removeAll=true`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
  return data
}
