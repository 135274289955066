import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import {
  Badge,
  Button,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import styled from 'styled-components'
import moment from 'moment'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { InputDateTime } from '../../../../components/elements/Input/InputDateTime'
import { ModalExportData } from '../../../../components/modules/ModalExportData'
import { Select } from '../../../../components/elements/Select'
import { Input } from '../../../../components/elements/Input/Input'
import { useIndividualUser } from './hook'
import { OptionSelect, OptionSelectAttributes } from './constants'

const TextError = styled(Text)`
  color: #ba1a1a;
`
/**
 * @returns Component IndividualUser Screen Page
 */
export const IndividualUserScreen = () => {
  const {
    dataIndividualUser,
    currentPage,
    totalPage,
    handleNextPage,
    handleChangeStartDate,
    valueStartAt,
    handleChangeEndDate,
    valueEndAt,
    handleSearch,
    handleReset,
    isLoading,
    textValidate,
    navigate,
    handleExportToXLSX,
    downloadedItem,
    handleClickDownload,
    isButtonChosen,
    isOpenModalExportData,
    setIsOpenModalExportData,
    totalItem,
    valueSelect,
    handleChangeSelect,
    type,
    dataIndividualSingerUser,
    numberIndexTab,
    handleChangeTab,
    handleChangeInputName,
    valueInputName,
    handleChangeInputAlert,
    valueInputAlert,
    handleChangeSelectAttributes,
    valueSelectAttributes,
    handleChangeInputEmail,
    valueInputEmail,
    dataRoutine,
  } = useIndividualUser()
  const statisticsClicksField = type => {
    switch (type) {
      case OptionSelect[1].value:
        return [
          {
            title: 'User ID',
            render: record => <Box>{record?.userId}</Box>,
          },
          {
            title: 'Name',
            render: record => (
              <Box>{`${record?.user?.firstName}  ${record?.user?.lastName}`}</Box>
            ),
          },
          {
            title: 'Email',
            render: record => <Box>{`${record?.user?.email}`}</Box>,
          },
          {
            title: 'Product',
            render: record => (
              <Box
                _hover={{ textDecoration: 'underline' }}
                whiteSpace={'pre-wrap'}
                width={'100%'}
                onClick={() => {
                  navigate(`/product-detail/${record?.productId}`)
                }}
              >{`${record?.product?.product}`}</Box>
            ),
          },
          {
            title: 'Status',
            render: record => (
              <>
                {!record?.isRemove ? (
                  <Badge colorScheme="green">
                    {`ADDED ON ${moment(record?.createdAt).format(
                      'YYYY/MM/DD',
                    )}`}
                  </Badge>
                ) : (
                  <Badge
                    colorScheme="red"
                    whiteSpace={'pre-wrap'}
                    width={'100%'}
                  >{`REMOVED ON ${moment(
                    record?.createdAt,
                    'YYYY/MM/DD',
                  )} BECAUSE ${record?.removeReason}`}</Badge>
                )}
              </>
            ),
          },
        ]
      case OptionSelect[2].value:
        if (numberIndexTab === 1) {
          return [
            {
              title: 'User ID',
              render: record => <Box>{record?.userId}</Box>,
            },
            {
              title: 'Name',
              render: record => (
                <Box>{`${record?.user?.firstName} ${record?.user?.lastName}`}</Box>
              ),
            },
            {
              title: 'Email',
              render: record => <Box>{`${record?.user?.email}`}</Box>,
            },
            {
              title: 'Time Analyzed',
              render: record => {
                return (
                  <Box whiteSpace={'pre-wrap'} width={'100%'}>
                    {moment(record.createdAt).format('YYYY/MM/DD, h:mm:ss A')}
                  </Box>
                )
              },
            },
            {
              title: 'Attributes',
              render: record => {
                const uniqueAttributes = Array.from(
                  new Set(
                    record?.routineAnalysisLogDetails?.reduce((acc, cur) => {
                      if (cur.possibleAttributes === 'General') return acc
                      return [...acc, cur.possibleAttributes]
                    }, []),
                  ),
                )
                return (
                  <Box whiteSpace={'pre-wrap'} width={'100%'}>
                    {uniqueAttributes.join(', ')}
                  </Box>
                )
              },
            },
            {
              title: 'Products',
              render: record => {
                const products =
                  record?.routineAnalysisLogDetails?.reduce((acc, cur) => {
                    if (!cur.routineAnalysisLogDetailProduct.length) return acc
                    return [...acc, ...cur.routineAnalysisLogDetailProduct]
                  }, []) ?? []
                return (
                  <Flex
                    flexDirection={'column'}
                    gap={'20px'}
                    justifyContent={'space-between'}
                    width={'100%'}
                  >
                    {products.map((item, index) => (
                      <Text
                        _hover={{ textDecoration: 'underline' }}
                        whiteSpace={'pre-wrap'}
                        onClick={() => {
                          navigate(`/product-detail/${item?.id}`)
                        }}
                      >
                        {`${index + 1}. ${item.product}`}
                      </Text>
                    ))}
                  </Flex>
                )
              },
            },
            {
              title: 'Alerts',
              render: record => {
                const alertData =
                  record?.routineAnalysisLogDetails?.map((it, index) => ({
                    attribute: it.possibleAttributes,
                    text: it.alert,
                    alertType: it.alertType,
                    idx: index + 1,
                  })) ?? []
                return (
                  <Box width={'100%'}>
                    {alertData.map((item, index) => (
                      <>
                        <Text
                          _hover={{ textDecoration: 'underline' }}
                          color={
                            item.alertType === 'RED_ALERT' ? 'red' : 'green'
                          }
                          minH={'80px'}
                          whiteSpace={'pre-wrap'}
                        >
                          {`${item.idx}. ${item.attribute} - ${item.text}`}
                        </Text>
                        {alertData[index + 1] &&
                          item.attribute !== alertData[index + 1].attribute && (
                            <Box
                              backgroundColor={'black'}
                              h={'1px'}
                              w={'full'}
                            />
                          )}
                      </>
                    ))}
                  </Box>
                )
              },
            },
          ]
        } else {
          return [
            {
              title: 'User ID',
              render: record => <Box>{`${record?.userId}`}</Box>,
            },
            {
              title: 'Name',
              render: record => (
                <Box>{`${record?.user?.firstName} ${record?.user?.lastName}`}</Box>
              ),
            },
            {
              title: 'Email',
              render: record => <Box>{`${record?.user?.email}`}</Box>,
            },
            {
              title: 'Attributes',
              render: record => {
                const uniqueAttributes = Array.from(
                  new Set(
                    record?.routineAnalysisLogDetails?.reduce((acc, cur) => {
                      if (cur.possibleAttributes === 'General') return acc
                      return [...acc, cur.possibleAttributes]
                    }, []),
                  ),
                )
                return (
                  <Box whiteSpace={'pre-wrap'} width={'100%'}>
                    {uniqueAttributes.join(', ')}
                  </Box>
                )
              },
            },
            {
              title: 'Time Analyzed',
              render: record => {
                return (
                  <Box whiteSpace={'pre-wrap'} width={'100%'}>
                    {moment(record.createdAt).format('YYYY/MM/DD, h:mm:ss A')}
                  </Box>
                )
              },
            },
            {
              title: 'Products',
              render: record => {
                const products =
                  record?.routineAnalysisLogDetails?.reduce((acc, cur) => {
                    if (!cur.routineAnalysisLogDetailProduct.length) return acc
                    return [...acc, ...cur.routineAnalysisLogDetailProduct]
                  }, []) ?? []
                return (
                  <Flex
                    flexDirection={'column'}
                    gap={'20px'}
                    justifyContent={'space-between'}
                    width={'100%'}
                  >
                    {products.map((item, index) => (
                      <Text
                        _hover={{ textDecoration: 'underline' }}
                        whiteSpace={'pre-wrap'}
                        onClick={() => {
                          navigate(`/product-detail/${item?.id}`)
                        }}
                      >
                        {`${index + 1}. ${item.product}`}
                      </Text>
                    ))}
                  </Flex>
                )
              },
            },
            {
              title: 'Alerts',
              render: record => {
                const alertData =
                  record?.routineAnalysisLogDetails?.map((it, index) => ({
                    attribute: it.possibleAttributes,
                    text: it.alert,
                    alertType: it.alertType,
                    idx: index + 1,
                  })) ?? []
                return (
                  <Box width={'100%'}>
                    {alertData.map((item, index) => (
                      <>
                        <Text
                          _hover={{ textDecoration: 'underline' }}
                          color={
                            item.alertType === 'RED_ALERT' ? 'red' : 'green'
                          }
                          minH={'80px'}
                          whiteSpace={'pre-wrap'}
                        >
                          {`${item.idx}. ${item.attribute} - ${item.text}`}
                        </Text>
                        {alertData[index + 1] &&
                          item.attribute !== alertData[index + 1].attribute && (
                            <Box
                              backgroundColor={'black'}
                              h={'1px'}
                              w={'full'}
                            />
                          )}
                      </>
                    ))}
                  </Box>
                )
              },
            },
          ]
        }

      default:
        return [
          {
            title: 'User ID',
            render: record => <Box>{record?.id}</Box>,
          },
          {
            title: 'Name',
            render: record => (
              <Box>{`${record?.firstName}  ${record?.lastName}`}</Box>
            ),
          },
          {
            title: 'Email',
            render: record => <Box>{`${record?.email}`}</Box>,
          },
          {
            title: 'Attributes',
            render: record => {
              return (
                <Box
                  _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
                  whiteSpace={'pre-wrap'}
                  onClick={() => {
                    navigate(
                      `/individual-user-routine/attributes/${record?.id}`,
                    )
                  }}
                >
                  {!!record.possibleAttributes?.length ? (
                    record?.possibleAttributes.join(', ')
                  ) : (
                    <Text>No data</Text>
                  )}
                </Box>
              )
            },
          },
          {
            title: 'Memberships',
            render: record => (
              <Box>
                {!!record.memberships?.length ? (
                  `${record?.memberships.join(', ')}`
                ) : (
                  <Text>No data</Text>
                )}
              </Box>
            ),
          },
          {
            title: 'Heard about All skin from ...',
            render: record => (
              <Box>
                {!!record.whereUserHeardApp?.length ? (
                  `${record?.whereUserHeardApp.join(', ')}`
                ) : (
                  <Text>No data</Text>
                )}
              </Box>
            ),
          },
        ]
    }
  }

  const columns = statisticsClicksField(type)
  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} flexDirection={'column'} gap={10}>
        <Flex
          alignItems={'center'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          w={'full'}
        >
          {type === OptionSelect[2].value ? (
            numberIndexTab === 0 || numberIndexTab === undefined ? (
              <Flex
                flexDirection={'column'}
                w={type === OptionSelect[2].value ? '435px' : '0px'}
              >
                <TextRoutine>
                  Total Users: {dataRoutine?.dataRoutine.totalUser}
                </TextRoutine>
                <TextRoutine>
                  Users with routine(s):{' '}
                  {dataRoutine?.dataRoutine.userWithRoutine}
                </TextRoutine>
                <TextRoutine>
                  Users with no routine:{' '}
                  {dataRoutine?.dataRoutine.userWithNoRoutine}
                </TextRoutine>
              </Flex>
            ) : (
              <Flex flexDirection={'column'} w={'435px'}>
                <TextRoutine>
                  Users with routine(s):{' '}
                  {dataRoutine?.dataRoutine.userWithRoutine}
                </TextRoutine>
                <TextRoutine>Total Routines: {totalItem}</TextRoutine>
              </Flex>
            )
          ) : (
            <></>
          )}
          <Flex
            alignItems={'center'}
            flexDirection={'row'}
            justifyContent={type === OptionSelect[2].value ? '' : 'center'}
            w={'full'}
          >
            <Text
              color={'#000'}
              fontFamily={'Hiragino Kaku Gothic Pro'}
              fontSize={'sm'}
              fontWeight={'semibold'}
              lineHeight={'16px'}
              margin={8}
            >
              {'Choose Product Views'}
            </Text>
            <Select
              options={OptionSelect}
              value={valueSelect}
              width={200}
              onChange={newValue => handleChangeSelect(newValue)}
            />
          </Flex>
        </Flex>
        {type === OptionSelect[2].value ? (
          <Tabs isFitted variant="enclosed" onChange={handleChangeTab}>
            <TabList mb="1em">
              <Tab>Single</Tab>
              <Tab>Multiple</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <BoxContainer>
                  <Flex
                    alignItems={'center'}
                    gap={10}
                    justifyContent={'space-between'}
                    w={'full'}
                  >
                    <Button
                      _hover={{ bg: '#222', cursor: 'pointer' }}
                      bg={'#222'}
                      color={'#fff'}
                      h={'32px'}
                      w={'91px'}
                      onClick={handleReset}
                    >
                      Reset
                    </Button>
                    <InputDateTime
                      direction="horizontal"
                      isShowTime={false}
                      label={'Start Date'}
                      value={valueStartAt ? new Date(valueStartAt) : undefined}
                      onChange={date => {
                        handleChangeStartDate(date)
                      }}
                    />
                    <InputDateTime
                      direction="horizontal"
                      isShowTime={false}
                      label={'End Date'}
                      value={valueEndAt ? new Date(valueEndAt) : undefined}
                      onChange={date => {
                        handleChangeEndDate(date)
                      }}
                    />
                  </Flex>
                  <Flex
                    alignItems={'center'}
                    gap={2}
                    justifyContent={'space-between'}
                    w={'full'}
                  >
                    <Input
                      placeholder={'Search by name'}
                      value={valueInputName}
                      w={'300px'}
                      onChange={handleChangeInputName}
                    />
                    <Input
                      placeholder={'Search by email'}
                      value={valueInputEmail}
                      w={'300px'}
                      onChange={handleChangeInputEmail}
                    />
                    <Input
                      placeholder={'Search by alerts'}
                      value={valueInputAlert}
                      w={'300px'}
                      onChange={handleChangeInputAlert}
                    />
                  </Flex>
                  <Flex
                    alignItems={'center'}
                    gap={2}
                    justifyContent={'space-between'}
                    w={'full'}
                  >
                    <Select
                      isMulti
                      options={OptionSelectAttributes}
                      value={valueSelectAttributes}
                      width={'600px'}
                      onChange={newValue =>
                        handleChangeSelectAttributes(newValue)
                      }
                    />
                    <Button
                      _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
                      bg={'#3F6FBA'}
                      color={'#fff'}
                      leftIcon={<SearchIcon />}
                      p={'0px 34px'}
                      w={160}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Flex>

                  <Box>
                    {textValidate && <TextError>{textValidate}</TextError>}
                  </Box>
                  {isLoading && <Spinner />}
                  {dataIndividualUser?.length && !isLoading ? (
                    <>
                      <Box overflowX={'auto'} w={'100%'}>
                        <TableCustom
                          columns={columns}
                          data={dataIndividualSingerUser || []}
                        />
                      </Box>
                      <Box py={5}>
                        <Pagination
                          page={currentPage}
                          totalPage={totalPage}
                          onChange={handleNextPage}
                        />
                      </Box>
                    </>
                  ) : undefined}
                  <Flex alignSelf={'flex-start'}>
                    <Button
                      _hover={{ bg: '#3F6FBA' }}
                      bg={'#3F6FBA'}
                      color={'#fff'}
                      w={'max-content'}
                      onClick={handleExportToXLSX}
                    >
                      Export Data
                    </Button>
                  </Flex>

                  {dataIndividualUser?.length === 0 && !isLoading && (
                    <Flex alignItems={'center'} justifyContent={'center'}>
                      No Data
                    </Flex>
                  )}
                  <ModalExportData
                    downloadedItem={downloadedItem}
                    handleClickDownload={handleClickDownload}
                    isButtonChosen={isButtonChosen}
                    isOpenModal={isOpenModalExportData}
                    totalItem={totalItem}
                    onCloseModal={() => setIsOpenModalExportData(false)}
                  />
                </BoxContainer>
              </TabPanel>
              <TabPanel>
                <BoxContainer>
                  <Flex
                    alignItems={'center'}
                    gap={10}
                    justifyContent={'space-between'}
                    w={'full'}
                  >
                    <Button
                      _hover={{ bg: '#222', cursor: 'pointer' }}
                      bg={'#222'}
                      color={'#fff'}
                      h={'32px'}
                      w={'91px'}
                      onClick={handleReset}
                    >
                      Reset
                    </Button>

                    <InputDateTime
                      direction="horizontal"
                      isShowTime={false}
                      label={'Start Date'}
                      value={valueStartAt ? new Date(valueStartAt) : undefined}
                      onChange={date => {
                        handleChangeStartDate(date)
                      }}
                    />
                    <InputDateTime
                      direction="horizontal"
                      isShowTime={false}
                      label={'End Date'}
                      value={valueEndAt ? new Date(valueEndAt) : undefined}
                      onChange={date => {
                        handleChangeEndDate(date)
                      }}
                    />
                  </Flex>
                  <Flex
                    alignItems={'center'}
                    gap={2}
                    justifyContent={'space-between'}
                    w={'full'}
                  >
                    <Input
                      placeholder={'Search by name'}
                      value={valueInputName}
                      w={'300px'}
                      onChange={handleChangeInputName}
                    />
                    <Input
                      placeholder={'Search by email'}
                      value={valueInputEmail}
                      w={'300px'}
                      onChange={handleChangeInputEmail}
                    />
                    <Input
                      placeholder={'Search by alerts'}
                      value={valueInputAlert}
                      w={'300px'}
                      onChange={handleChangeInputAlert}
                    />
                  </Flex>
                  <Flex
                    alignItems={'center'}
                    gap={2}
                    justifyContent={'space-between'}
                    w={'full'}
                  >
                    <Select
                      isMulti
                      options={OptionSelectAttributes}
                      value={valueSelectAttributes}
                      width={'600px'}
                      onChange={newValue =>
                        handleChangeSelectAttributes(newValue)
                      }
                    />
                    <Button
                      _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
                      bg={'#3F6FBA'}
                      color={'#fff'}
                      leftIcon={<SearchIcon />}
                      p={'0px 34px'}
                      w={160}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Flex>
                  <Box>
                    {textValidate && <TextError>{textValidate}</TextError>}
                  </Box>
                  {isLoading && <Spinner />}
                  {dataIndividualUser?.length && !isLoading ? (
                    <>
                      <Box overflowX={'auto'} w={'100%'}>
                        <TableCustom
                          columns={columns}
                          data={dataIndividualUser || []}
                        />
                      </Box>
                      <Box py={5}>
                        <Pagination
                          page={currentPage}
                          totalPage={totalPage}
                          onChange={handleNextPage}
                        />
                      </Box>
                    </>
                  ) : undefined}
                  <Flex alignSelf={'flex-start'}>
                    <Button
                      _hover={{ bg: '#3F6FBA' }}
                      bg={'#3F6FBA'}
                      color={'#fff'}
                      w={'max-content'}
                      onClick={handleExportToXLSX}
                    >
                      Export Data
                    </Button>
                  </Flex>

                  {dataIndividualUser?.length === 0 && !isLoading && (
                    <Flex alignItems={'center'} justifyContent={'center'}>
                      No Data
                    </Flex>
                  )}
                  <ModalExportData
                    downloadedItem={downloadedItem}
                    handleClickDownload={handleClickDownload}
                    isButtonChosen={isButtonChosen}
                    isOpenModal={isOpenModalExportData}
                    totalItem={totalItem}
                    onCloseModal={() => setIsOpenModalExportData(false)}
                  />
                </BoxContainer>
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <>
            <Flex
              alignItems={'center'}
              gap={10}
              justifyContent={'space-between'}
              w={'full'}
            >
              <Button
                _hover={{ bg: '#222', cursor: 'pointer' }}
                bg={'#222'}
                color={'#fff'}
                h={'32px'}
                w={'91px'}
                onClick={handleReset}
              >
                Reset
              </Button>
              <InputDateTime
                direction="horizontal"
                isShowTime={false}
                label={'Start Date'}
                value={valueStartAt ? new Date(valueStartAt) : undefined}
                onChange={date => {
                  handleChangeStartDate(date)
                }}
              />
              <InputDateTime
                direction="horizontal"
                isShowTime={false}
                label={'End Date'}
                value={valueEndAt ? new Date(valueEndAt) : undefined}
                onChange={date => {
                  handleChangeEndDate(date)
                }}
              />
              <Button
                _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
                bg={'#3F6FBA'}
                color={'#fff'}
                leftIcon={<SearchIcon />}
                p={'0px 34px'}
                w={160}
                onClick={handleSearch}
              >
                Search
              </Button>
            </Flex>

            <Box>{textValidate && <TextError>{textValidate}</TextError>}</Box>
            {isLoading && <Spinner />}
            {dataIndividualUser?.length && !isLoading ? (
              <>
                <Box overflowX={'auto'} w={'100%'}>
                  <TableCustom
                    columns={columns}
                    data={dataIndividualUser || []}
                  />
                </Box>
                <Box py={5}>
                  <Pagination
                    page={currentPage}
                    totalPage={totalPage}
                    onChange={handleNextPage}
                  />
                </Box>
              </>
            ) : undefined}
            <Flex alignSelf={'flex-start'}>
              <Button
                _hover={{ bg: '#3F6FBA' }}
                bg={'#3F6FBA'}
                color={'#fff'}
                w={'max-content'}
                onClick={handleExportToXLSX}
              >
                Export Data
              </Button>
            </Flex>

            {dataIndividualUser?.length === 0 && !isLoading && (
              <Flex alignItems={'center'} justifyContent={'center'}>
                No Data
              </Flex>
            )}
            <ModalExportData
              downloadedItem={downloadedItem}
              handleClickDownload={handleClickDownload}
              isButtonChosen={isButtonChosen}
              isOpenModal={isOpenModalExportData}
              totalItem={totalItem}
              onCloseModal={() => setIsOpenModalExportData(false)}
            />
          </>
        )}
      </Flex>
    </Flex>
  )
}

const BoxContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 40px;
`
const TextRoutine = styled(Text)``
