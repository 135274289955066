import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import { isAxiosError } from 'axios'
import { getHistoryFileUpload } from '../../../../../service/historyFileUpload'
import { THistoryFileUpload, TMetaType } from './type'

/**
 * @returns Component useHistoryFileUpload hook
 */
export const useHistoryFileUpload = () => {
  const [dataHistoryFileUpload, setDataHistoryFileUpload] =
    useState<THistoryFileUpload[]>()
  const [meta, setMeta] = useState<TMetaType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [valueSelect, setValueSelect] = useState<{
    label: string
    value?: number
  }>({
    label: 'INGREDIENT_TAG',
    value: 1,
  })
  const toast = useToast()
  let [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const type = searchParams.get('type')

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        type: type ? Number(type) : 1,
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getHistoryFileUpload(params)
      setDataHistoryFileUpload(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, toast, type])

  useEffect(() => {
    fetchData().then()
  }, [fetchData])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )

  const handleChangeSelect = useCallback(value => {
    setValueSelect(value)
  }, [])
  const handleFindUpload = useCallback(() => {
    setSearchParams({
      type: String(valueSelect.value ?? ''),
      page: String(1),
    })
  }, [setSearchParams, valueSelect.value])

  return {
    isLoading,
    dataHistoryFileUpload,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNextPage,
    handleChangeSelect,
    valueSelect,
    handleFindUpload,
  }
}
