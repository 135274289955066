import React, { useCallback, useMemo } from 'react'
import {
  Thead,
  Tr,
  Th,
  HTMLChakraProps,
  Text,
  Tbody,
  Td,
  TableContainer,
  Table as TableChakra,
  Checkbox,
} from '@chakra-ui/react'
import { useForm } from './usFormTable'

export type TColumn<T> = {
  title: string | React.ReactNode
  render: (item: T, index: number) => React.ReactNode
  tdProps?: HTMLChakraProps<'td'>
  thProps?: HTMLChakraProps<'th'>
}

export type TTableProps<T> = {
  columns: TColumn<T>[]
  data: T[]
  selectedRowKeys?: number[]
  onChange?: (selectedRowKeys: number[]) => void
  isSelect?: boolean
  isSelectAll?: boolean
  ids?: (string | number | undefined)[]
  onSelect?: (column) => void
  isDisabled?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Table: React.FC<TTableProps<any>> = props => {
  const {
    isSelect,
    isSelectAll,
    selectedRowKeys,
    onChange,
    data,
    columns,
    ids,
    onSelect,
    ...rest
  } = props
  const rowKeys = useMemo(
    () => ids ?? data.filter(e => e.status !== 'INACTIVE').map(item => item.id),
    [ids, data],
  )
  const {
    handleSelect,
    formValues,
    handleSelectAll,
    allChecked,
    isIndeterminate,
  } = useForm({
    rowKeys,
    selectedRowKeys,
    onChange,
  })

  const isShowCheckbox = useCallback(
    (id: number | string) => data.map(e => e.id).includes(id),
    [data],
  )

  return (
    <TableContainer {...rest}>
      <TableChakra>
        <Thead>
          <Tr>
            {isSelect && (
              <Th minW={'80px'} textAlign="center">
                {isSelectAll ? (
                  <Checkbox
                    isChecked={allChecked}
                    isIndeterminate={isIndeterminate}
                    onChange={() => handleSelectAll()}
                  />
                ) : (
                  <Text></Text>
                )}
              </Th>
            )}
            {columns.map((column, i) => (
              <Th key={i} {...column.thProps} py={'9.5px'}>
                <Text
                  color={'black.1000'}
                  cursor={'pointer'}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  lineHeight={6}
                >
                  {column.title}
                </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((item, index) => {
            return (
              <Tr key={index}>
                {isSelect && (
                  <Td py={'9.5px'} textAlign={'center'}>
                    {isShowCheckbox(item.id) && (
                      <Checkbox
                        isChecked={formValues.includes(item.id)}
                        isDisabled={item.status === 'INACTIVE'}
                        onChange={() => handleSelect(item.id)}
                      />
                    )}
                  </Td>
                )}
                {columns.map((column, i) => (
                  <Td
                    key={i}
                    {...column.tdProps}
                    cursor={'pointer'}
                    py={'9.5px'}
                    onClick={() => onSelect && onSelect(item)}
                  >
                    {column.render(item, index)}
                  </Td>
                ))}
              </Tr>
            )
          })}
        </Tbody>
      </TableChakra>
    </TableContainer>
  )
}
