import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from '@chakra-ui/react'
import { ArrowRightIcon, SearchIcon } from '@chakra-ui/icons'
import moment from 'moment/moment'
import styled from '@emotion/styled'
import ReactQuill from 'react-quill'
import { Pagination } from '../../../../../components/modules/Pagination'
import { Input } from '../../../../../components/elements/Input/Input'
import { Table } from '../../../../../components/modules/TableCustom/TableCustom'
import { ErrorText } from '../../../../../components/elements/ErrorText'
import { TSelectForm, useUserList } from '../../hook'
/**
 * @returns Component UserScreen
 */
export const UserScreen = () => {
  const {
    isLoading,
    dataAdministrator,
    handleEdit,
    handleUpdateStatus,
    currentPage,
    totalPage,
    handleNextPage,
    valueInput,
    handleChangeInput,
    handleSearchAdministrator,
    handleCheckbox,
    selectUser,
    isOpenMail,
    handleCloseModalMail,
    handleOpenModalMail,
    handleSendEmail,
    handleChange,
    formValue,
    errors,
    dataUser,
    handleRemoveTag,
  } = useUserList()
  const columns = [
    {
      title: 'edit',
      render: ({ id }) => (
        <Box onClick={() => handleEdit(id)}>
          <Text color={'#3F6FBA'} textDecoration={'underline'}>
            Edit
          </Text>
        </Box>
      ),
    },
    {
      title: 'ID',
      render: record => <Box>{record?.id}</Box>,
    },
    {
      title: 'firstName',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.firstName}</Box>
      ),
    },
    {
      title: 'lastName',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.lastName}</Box>
      ),
    },
    {
      title: 'created At',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {moment(record?.openAppDate).format('YYYY-MM-DD')}
        </Box>
      ),
    },
    {
      title: 'avatar',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.avatar}</Box>
      ),
    },
    {
      title: 'email',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.email}</Box>
      ),
    },
    {
      title: 'phoneNumber',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {record?.phoneNumber}
        </Box>
      ),
    },
    {
      title: 'region',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.region}</Box>
      ),
    },
    {
      title: 'role',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.role}</Box>
      ),
    },
    {
      title: 'status',
      render: record => <Box>{record?.status}</Box>,
    },

    {
      title: 'action',
      render: ({ id }) => {
        const statusSwitch =
          dataAdministrator?.find(it => it.id === id)?.status === 'ACTIVE' ||
          false
        return (
          <Box>
            <Switch
              isChecked={statusSwitch}
              size="md"
              onChange={() => handleUpdateStatus(id)}
            />
          </Box>
        )
      },
    },
  ]

  if (isLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <Flex flexDirection={'column'} gap={10}>
      <Flex gap={'20px'} justifyContent={'flex-end'} mb={'20px'}>
        <Flex gap={'20px'}>
          <Input
            placeholder={'keyword search'}
            value={valueInput}
            w={400}
            onChange={handleChangeInput}
          />
          <Button
            _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            w={160}
            onClick={handleSearchAdministrator}
          >
            Search
          </Button>
        </Flex>
      </Flex>
      <Flex flexDirection={'column'} gap={2}>
        <Button
          colorScheme="blue"
          isDisabled={!!selectUser.length}
          rightIcon={<ArrowRightIcon />}
          size={'sm'}
          variant="outline"
          w={'200px'}
          onClick={handleOpenModalMail}
        >
          Email All Users
        </Button>
        <Button
          colorScheme="blue"
          isDisabled={!selectUser.length}
          rightIcon={<ArrowRightIcon />}
          size={'sm'}
          variant="outline"
          w={'200px'}
          onClick={handleOpenModalMail}
        >
          Email Selected Users
        </Button>
      </Flex>
      {dataAdministrator && !!dataAdministrator.length && !isLoading && (
        <>
          <Box overflowX={'auto'} w={'100%'}>
            <Table
              isSelect
              isSelectAll
              columns={columns}
              data={dataAdministrator || []}
              isDisabled={
                !!dataAdministrator.find(it => it.status === 'INACTIVE')
              }
              selectedRowKeys={selectUser.map(item => item.id)}
              onChange={it => {
                const newUser: TSelectForm[] = []
                it.forEach(item => {
                  const existedItem = selectUser.find(it => it.id === item)
                  if (existedItem) {
                    newUser.push(existedItem)
                  } else {
                    const newData = dataAdministrator?.find(
                      it => it.id === item,
                    )
                    if (newData) {
                      newUser.push({
                        id: newData.id,
                        email: newData.email,
                      })
                    }
                  }
                })
                handleCheckbox(newUser)
              }}
            />
          </Box>
          <Pagination
            page={currentPage}
            totalPage={totalPage}
            onChange={handleNextPage}
          />
        </>
      )}
      {dataAdministrator?.length === 0 && !isLoading && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          No Data
        </Flex>
      )}
      <Modal isOpen={isOpenMail} onClose={handleCloseModalMail}>
        <ModalOverlay />
        <ModalContent maxW={'800px'} minH={'400px'}>
          <ModalHeader>Send Email</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Flex alignItems={'center'} gap={6} mb={2}>
                <TitleModalInput>Subject</TitleModalInput>
                <Input
                  isRequired
                  h={'38px'}
                  value={formValue.subject}
                  w={'full'}
                  onChange={e => {
                    handleChange({ subject: e.target.value })
                  }}
                />
              </Flex>
              {!!errors?.subject && (
                <ErrorText ml={'125px'}>{errors?.subject?.message}</ErrorText>
              )}
            </Box>
            <Box>
              <Flex alignItems={'center'} gap={6} mb={2}>
                <TitleModalInput>To</TitleModalInput>
                <HStack spacing={4} w={'full'} wrap="wrap">
                  {!selectUser.length && (
                    <Box
                      _hover={{ border: '1px solid #ccc' }}
                      alignItems="center"
                      border="1px solid"
                      borderColor="#ccc"
                      borderRadius="md"
                      display="flex"
                      flexWrap="wrap"
                      gap={'10px'}
                      minH={'40px'}
                      minW={'full'}
                      p="2"
                    >
                      <Tag
                        bg={'#3F6FBA'}
                        borderRadius="10px"
                        color="#ffffff"
                        cursor={'pointer'}
                        size="md"
                        variant="solid"
                      >
                        <TagLabel>All Users</TagLabel>
                      </Tag>
                    </Box>
                  )}
                  {!!selectUser.length && (
                    <Box
                      _hover={{ border: '1px solid #ccc' }}
                      alignItems="center"
                      border="1px solid #ccc"
                      borderColor="#ccc"
                      borderRadius="md"
                      display="flex"
                      flexWrap="wrap"
                      gap={'10px'}
                      minH={'40px'}
                      minW={'full'}
                      p="2"
                    >
                      {dataUser?.map((tag, index) => {
                        return (
                          <Tag
                            key={index}
                            bg={'#3F6FBA'}
                            borderRadius="10px"
                            color="#ffffff"
                            cursor={'pointer'}
                            size="md"
                            variant="solid"
                          >
                            <TagLabel>{tag?.email}</TagLabel>
                            <TagCloseButton
                              onClick={() => handleRemoveTag(tag)}
                            />
                          </Tag>
                        )
                      })}
                    </Box>
                  )}
                </HStack>
              </Flex>
              {!!errors?.to && (
                <ErrorText ml={'125px'}>{errors?.to?.message}</ErrorText>
              )}
            </Box>

            <Box>
              <Flex alignItems={'center'} gap={6} mb={2}>
                <TitleModalInput>Body</TitleModalInput>
                <ReactQuill
                  style={{ width: '100%', height: '250px' }}
                  theme="snow"
                  value={formValue.body}
                  onChange={value => {
                    handleChange({ body: value })
                  }}
                />
              </Flex>
              {!!errors?.body && (
                <ErrorText mb={'-48px'} ml={'125px'} pt={'40px'}>
                  {errors?.body?.message}
                </ErrorText>
              )}
            </Box>
          </ModalBody>
          <ModalFooter pt={'48px'}>
            <Button colorScheme="blue" mr={3} onClick={handleSendEmail}>
              Send Email
            </Button>
            <Button
              colorScheme={'black'}
              mr={3}
              variant="ghost"
              onClick={handleCloseModalMail}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

const TitleModalInput = styled(Text)`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  width: 100px;
`
