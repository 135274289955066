import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'

type TextBaseProps = {
  text: string | undefined
} & TextProps
/**
 * @returns Text component
 */
export const TextBase = (props: TextBaseProps) => {
  const { text } = props
  return (
    <Text
      fontFamily={'Hiragino Sans'}
      fontStyle={'normal'}
      wordBreak={'break-all'}
      {...props}
    >
      {text}
    </Text>
  )
}
