export type Option = Partial<{
  value: number
  label: string
}>
export const OptionSelect: Option[] = [
  {
    label: 'INGREDIENT_TAG',
    value: 1,
  },
  {
    label: 'INCI',
    value: 2,
  },
  {
    label: 'ROUTINE_RULE_AND_ALERT',
    value: 3,
  },
  {
    label: 'FIELD_NOTE',
    value: 4,
  },
  {
    label: 'PRODUCT',
    value: 5,
  },
]
