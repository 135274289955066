import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import moment from 'moment/moment'
import styled from '@emotion/styled'

/**
 * @returns Component InfoUser Screen Page
 */
export const InfoUser = ({ user }) => {
  return (
    <Flex
      border={'1px solid #C2C4F2'}
      flexDirection={'column'}
      justifyContent={'flex-start'}
      mt={6}
      p={10}
      w={'50%'}
    >
      <Flex alignItems={'center'} justifyContent={'center'}>
        <TextHeader>User Info</TextHeader>
      </Flex>
      <Box>
        <TextInfo>User id: {user?.id}</TextInfo>
        <TextInfo>User Name: {user?.firstName + ' ' + user?.lastName}</TextInfo>
        <TextInfo>Phone: {user?.phoneNumber}</TextInfo>
        <TextInfo>Email: {user?.email}</TextInfo>
        <TextInfo>
          Registered Date:{' '}
          {moment(user?.createdAt).format('YYYY/MM/DD, HH:mm:ss A')}
        </TextInfo>
      </Box>
    </Flex>
  )
}

const TextInfo = styled(Text)`
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
`

const TextHeader = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
`
