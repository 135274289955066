export const calculateMinDateTime = () => {
  const now = new Date()
  now.setMinutes(now.getMinutes() + 10)
  return now
}

export const calculateMaxDate = () => {
  const now = new Date()
  now.setFullYear(now.getFullYear() + 100)
  return now
}
