import { ChangeEvent, useCallback, useState } from 'react'
import * as yup from 'yup'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Errors } from '../../../../../types'
import { createDermTips } from '../../../../../service/dermTip'

const schema = yup.object().shape({
  content: yup.string().required(),
})
type FormValue = yup.InferType<typeof schema>

const initialState: FormValue = {
  content: '',
}
/**
 * useDermTipsAdd hook.
 */
export const useDermTipsAdd = () => {
  const [formValue, setFormValue] = useState<FormValue>(initialState)
  const [errors, setErrors] = useState<Errors>()
  const toast = useToast()
  const navigate = useNavigate()

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setFormValue(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )

  /**
   * @returns function that handle validates form
   */
  const validation = useCallback((): boolean => {
    setErrors(undefined)
    try {
      schema.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          const newErrors: Errors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }

    return true
  }, [formValue])

  const handleSubmit = useCallback(async () => {
    if (validation()) {
      try {
        const payload = {
          content: formValue.content,
        }
        await createDermTips(payload)
        toast({
          position: 'top-right',
          status: 'success',
          title: 'Create Success',
          duration: 1000,
        })
        navigate('/derm-tips')
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    }
  }, [formValue.content, navigate, toast, validation])

  return {
    handleSubmit,
    errors,
    handleChangeInput,
    formValue,
  }
}
