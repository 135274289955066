import { ChangeEvent, useCallback, useState } from 'react'
import * as yup from 'yup'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { isAxiosError } from 'axios'
import { createRetailer } from '../../../../service/promotionService'

const schemaRetailer = yup.object().shape({
  name: yup.string().required('Name is a required field'),
  description: yup.string().required('Description is a required field'),
  promoted: yup.boolean(),
  imgUrl: yup.string().url().required('ImgUrl is a required field'),
})

type FormValue = yup.InferType<typeof schemaRetailer>

const initialState: FormValue = {
  name: '',
  description: '',
  promoted: false,
  imgUrl: '',
}

type IErrors = {
  [key: string]: {
    message: string
  }
}
export type ModalCreateRetailerProps = {
  fetchRetailer: () => void
}

/***
 *
 * Returns: useProductDetail
 */
export const useModalCreateRetailer = (props: ModalCreateRetailerProps) => {
  const { fetchRetailer } = props
  const [formValue, setFormValue] = useState<FormValue>(initialState)
  const [errorsRetailer, setErrorsRetailer] = useState<IErrors>({})
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    isOpen: isOpenRetailer,
    onOpen: onOpenRetailer,
    onClose: onCloseRetailer,
  } = useDisclosure()
  const toast = useToast()

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target
      setFormValue(prevState => ({
        ...prevState,
        [name]: value,
      }))
    },
    [],
  )

  const handleOpenModalRetailer = useCallback(() => {
    onOpenRetailer()
  }, [onOpenRetailer])

  const handleCloseModalRetailer = useCallback(() => {
    onCloseRetailer()
    setFormValue({
      ...formValue,
      name: '',
      description: '',
      imgUrl: '',
      promoted: false,
    })
    setErrorsRetailer({})
  }, [formValue, onCloseRetailer])

  const handleTogglePromotion = useCallback(() => {
    setFormValue({
      ...formValue,
      promoted: !formValue.promoted,
    })
  }, [formValue])

  const validationRetailer = useCallback(() => {
    let newErrors: IErrors = {}
    try {
      schemaRetailer.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
        }
        setErrorsRetailer(newErrors)
        return false
      }
    }
    return true
  }, [formValue])

  const handleSubmitRetailer = useCallback(async () => {
    if (validationRetailer()) {
      setIsLoading(true)
      try {
        const payload = {
          name: formValue.name,
          description: formValue.description,
          promoted: formValue.promoted ? 1 : 0,
          imgUrl: formValue?.imgUrl,
        }
        await createRetailer(payload)
        toast({
          position: 'top-right',
          status: 'success',
          title: 'Create Success',
          duration: 1000,
        })
        fetchRetailer()
        onCloseRetailer()
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
        setFormValue({
          ...formValue,
          name: '',
          description: '',
          imgUrl: '',
          promoted: false,
        })
      }
    }
  }, [validationRetailer, formValue, toast, onCloseRetailer])

  return {
    ...props,
    formValue,
    handleOpenModalRetailer,
    isOpenRetailer,
    handleChangeInput,
    handleCloseModalRetailer,
    errorsRetailer,
    handleTogglePromotion,
    handleSubmitRetailer,
    isLoading,
  }
}
