import qs from 'qs'
import { axiosWithToken } from '../configureAxios'
import { THistoryFileUploadList } from '../app/pages/HistoryFileUploadPage/components/modules/type'

type HistoryFileUploadType = {
  type?: number
  page: number
  limit: number
}

/**
 * @returns get Ingredient Definition
 * */
export const getHistoryFileUpload = async (
  params: HistoryFileUploadType,
): Promise<THistoryFileUploadList> => {
  const { data } = await axiosWithToken.get('/file-logs', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}
