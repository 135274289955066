import axios, { AxiosInstance } from 'axios'
import { getAccessToken } from '../service/storageServices'

interface IConfig {
  withAuthToken: boolean
  withFile?: boolean
}

const defaultConfig: IConfig = {
  withAuthToken: true,
  withFile: false,
}

/**
 * @returns function that handle initialization axios
 */
export function createAxios(config?: Partial<IConfig>): AxiosInstance {
  const configValues: IConfig = config
    ? { ...defaultConfig, ...config }
    : defaultConfig

  const headers: { [key: string]: string } = {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': configValues.withFile
      ? 'application/zip'
      : 'application/json;charset=utf-8',
  }

  const baseURL = `${process.env.REACT_APP_API_URL}/api`
  const client = axios.create({
    baseURL,
    headers,
  })

  if (configValues.withAuthToken) {
    client.interceptors.request.use(requestConfig => {
      const accessToken = getAccessToken()
      if (!accessToken) {
        window.location.replace('/login')
        return requestConfig
      }
      // @ts-ignore
      requestConfig.headers.Authorization = `Bearer ${accessToken}`

      return requestConfig
    })
  }
  return client
}
