import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment/moment'
import {
  exportFileUserAttributes,
  exportFileUserRoutine,
  getUserRoutine,
} from '../../../../../service/authService'
import { TMetaType } from '../../../ViewRulesPage/components/modules/type'
import { TUserRoutine } from './type'
import { Option, OptionSelect } from './constants'

type SearchParams = {
  [k: string]: string
}
/**
 * @returns Component useUserRoutine hook
 */
export const useUserRoutine = () => {
  const [dataUserRoutine, setDataUserRoutine] = useState<TUserRoutine[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<TMetaType>()
  let [searchParams, setSearchParams] = useSearchParams()
  const toast = useToast()
  const page = searchParams.get('page')
  const startAt = searchParams.get('startAt')
  const endAt = searchParams.get('endAt')
  const type = searchParams.get('type')
  const [valueStartAt, setValueStartAt] = useState<Date>()
  const [valueEndAt, setValueEndAt] = useState<Date>()
  const [textValidate, setTextValidate] = useState('')
  const [isOpenModalExportData, setIsOpenModalExportData] =
    useState<boolean>(false)
  const [downloadedItem, setDownloadedItem] = useState<number[]>([])
  const [isButtonChosen, setIsButtonChosen] = useState<number>()
  const [valueSelect, setValueSelect] = useState<Option>(
    OptionSelect.find(item => item.value === type) ?? OptionSelect[0],
  )
  const navigate = useNavigate()

  const onChangeSearchParams = useCallback(
    (type: string, page: string) => {
      const searchParams: SearchParams = {
        type,
        page,
      }
      if (valueStartAt) {
        searchParams.startAt = moment(valueStartAt).format('YYYY-MM-DD')
      }
      if (valueEndAt) {
        searchParams.endAt = moment(valueEndAt).format('YYYY-MM-DD')
      }
      setSearchParams(searchParams)
    },
    [setSearchParams, valueEndAt, valueStartAt],
  )

  const handleChangeSelect = useCallback(
    value => {
      if (value === valueSelect) return
      onChangeSearchParams(value.value ?? OptionSelect[0].value, '1')
      setValueSelect(value)
      setDownloadedItem([])
    },
    [onChangeSearchParams, valueSelect],
  )

  const validateDate = useCallback(() => {
    const startAt = moment(valueStartAt).format('YYYY-MM-DD')
    const endAt = moment(valueEndAt).format('YYYY-MM-DD')
    setTextValidate(
      startAt <= endAt
        ? ''
        : 'Please select a start date that occurs before the end date',
    )
    return startAt <= endAt
  }, [valueEndAt, valueStartAt])

  const fetchDatUserRoutine = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        startAt: startAt ? moment(startAt).format('YYYY-MM-DD') : undefined,
        endAt: endAt ? moment(endAt).format('YYYY-MM-DD') : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getUserRoutine(
        params,
        type ?? OptionSelect[0].value,
      )
      setDataUserRoutine(
        type === OptionSelect[0].value || !type ? response : response.data,
      )
      setMeta(type === OptionSelect[0].value ? undefined : response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [endAt, page, startAt, toast, type])

  useEffect(() => {
    fetchDatUserRoutine().then()
  }, [fetchDatUserRoutine])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      onChangeSearchParams(type ?? OptionSelect[0].value, String(currentPage))
    },
    [onChangeSearchParams, type],
  )

  const handleChangeStartDate = useCallback((date: Date) => {
    setValueStartAt(date)
    setTextValidate('')
  }, [])

  const handleChangeEndDate = useCallback((date: Date) => {
    setValueEndAt(date)
    setTextValidate('')
  }, [])

  const handleSearch = useCallback(async () => {
    if (validateDate()) {
      try {
        onChangeSearchParams(type ?? OptionSelect[0].value, '1')
        setDownloadedItem([])
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    }
  }, [onChangeSearchParams, toast, type, validateDate])

  /**
   * @returns handleReset
   */
  const handleReset = useCallback(() => {
    setValueStartAt(undefined)
    setValueEndAt(undefined)
    setTextValidate('')
    setSearchParams({
      page: '1',
      type: type ?? OptionSelect[0].value,
    })
  }, [setSearchParams, type])

  const handleExportToXLSX = useCallback(() => {
    setIsOpenModalExportData(true)
  }, [])

  const handleClickDownload = useCallback(
    async (page: number) => {
      setIsButtonChosen(page)
      try {
        const params = {
          page: page,
          limit: 5000,
          isExport: true,
          isApplyFilterExport: true,
          startAt: startAt ? moment(startAt).format('YYYY-MM-DD') : undefined,
          endAt: endAt ? moment(endAt).format('YYYY-MM-DD') : undefined,
        }
        const example = await exportFileUserRoutine(params)
        const link = document.createElement('a')
        link.href = example
        document.body.appendChild(link)
        link.click()
        setIsButtonChosen(0)
        setDownloadedItem(prevState => [...prevState, page])
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
        setIsButtonChosen(0)
      }
    },
    [endAt, startAt, toast],
  )

  const handleExportAttributesToXLSX = useCallback(async () => {
    try {
      const params = {
        isExport: true,
        isExportAll: true,
      }
      const example = await exportFileUserAttributes(params)
      const link = document.createElement('a')
      link.href = example
      document.body.appendChild(link)
      link.click()
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
      setIsButtonChosen(0)
    }
  }, [toast])

  return {
    dataUserRoutine,
    isLoading,
    handleNextPage,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleChangeStartDate,
    handleChangeEndDate,
    valueStartAt,
    valueEndAt,
    handleSearch,
    handleReset,
    textValidate,
    navigate,
    handleExportToXLSX,
    isOpenModalExportData,
    downloadedItem,
    handleClickDownload,
    isButtonChosen,
    totalItem: meta?.total || 0,
    setIsOpenModalExportData,
    handleChangeSelect,
    valueSelect,
    type,
    handleExportAttributesToXLSX,
  }
}
