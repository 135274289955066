import React from 'react'

import { ProductRequestScreen } from './components/modules'

/**
 * @returns Component ProductRequest Page
 */
export function ProductRequestPage() {
  return <ProductRequestScreen />
}
