import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/layout'
import ReactQuill from 'react-quill'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { MdContactMail } from 'react-icons/md'
import { BsXCircleFill } from 'react-icons/bs'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { Input } from '../../../../components/elements/Input/Input'
import { ErrorText } from '../../../../components/elements/ErrorText'
import { Props, useProductRequestScreen } from './hooks'

const StyledContainer = styled(Flex)`
  flex-direction: column;
  gap: 16px;
`

const StyledWrapperTable = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`

const StyledWrapperButton = styled(Flex)`
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

const TitleModalInput = styled(Text)`
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  width: 100px;
`

/**
 * ProductRequestLayout component page.
 * @return {JSX.Element} ProductRequestLayout component.
 */
export const ProductRequestLayout = (props: Props) => {
  const {
    isLoading,
    data,
    currentPage,
    totalPage,
    handleNextPage,
    onCloseComplete,
    productDetail,
    isOpenComplete,
    handleSaveIgnore,
    isOpenContactUs,
    handleShowHistory,
    handleChange,
    formValue,
    handleSendEmail,
    errors,
    handleCloseModalContact,
    handleOpenModalIgnore,
    handleOpenModalContactUs,

    navigate,
  } = props
  const columnsActive = [
    {
      title: 'Brand',
      render: record => <Box>{record?.brand}</Box>,
    },
    {
      title: 'Upc',
      render: record => <Box>{record?.upc}</Box>,
    },
    {
      title: 'Origin URL',
      render: record => <Box>{record?.weblink}</Box>,
    },
    {
      title: 'Name',
      render: record => <Box>{record?.name}</Box>,
    },
    {
      title: 'Retailer',
      render: record => <Box>{record?.retailer}</Box>,
    },
    {
      title: 'Action',
      render: record => (
        <StyledWrapperButton>
          <Tag
            colorScheme="blue"
            variant="outline"
            onClick={() => navigate(`/product-requests-add/${record.id}`)}
          >
            <TagLabel mr={1}>Add</TagLabel>
            <IoMdAddCircleOutline cursor={'point'} size={'20px'} />
          </Tag>
          <Tag
            colorScheme="red"
            variant="outline"
            onClick={() => handleOpenModalIgnore(record)}
          >
            <TagLabel mr={1}>Ignore</TagLabel>
            <BsXCircleFill cursor={'point'} size={'20px'} />
          </Tag>
          <Tag
            colorScheme="cyan"
            variant="outline"
            onClick={() => handleOpenModalContactUs(record)}
          >
            <TagLabel mr={1}>Contact Us</TagLabel>
            <MdContactMail color={'#9CC2E5'} cursor={'point'} size={'20px'} />
          </Tag>
        </StyledWrapperButton>
      ),
    },
  ]

  if (isLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    )
  }
  return (
    <StyledContainer>
      <Flex alignItems={'end'} justifyContent={'end'}>
        <Button
          _hover={{ bg: '#3F6FBA' }}
          bg={'#3F6FBA'}
          color={'#ffffff'}
          width={'150px'}
          onClick={handleShowHistory}
        >
          History Action
        </Button>
      </Flex>
      {data && data.length && (
        <StyledWrapperTable>
          <Box overflowX={'auto'} w={'100%'}>
            <TableCustom columns={columnsActive} data={data || []} />
          </Box>
          <Pagination
            page={currentPage}
            totalPage={totalPage}
            onChange={handleNextPage}
          />
        </StyledWrapperTable>
      )}
      {data?.length === 0 && !isLoading && (
        <Flex alignItems={'center'} h={'100%'} justifyContent={'center'}>
          No Data
        </Flex>
      )}
      <Modal isOpen={isOpenComplete} onClose={onCloseComplete}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Ignore</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Ignores entry</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => handleSaveIgnore(Number(productDetail?.id))}
            >
              Save
            </Button>
            <Button
              colorScheme={'black'}
              mr={3}
              variant="ghost"
              onClick={onCloseComplete}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenContactUs} onClose={handleCloseModalContact}>
        <ModalOverlay />
        <ModalContent maxW={'800px'} minH={'400px'}>
          <ModalHeader>Contact Us</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Send user an email from{' '}
            <Text as={'span'} fontFamily={'Hiragino Sans'} fontWeight={600}>
              {productDetail?.user?.email
                ? productDetail?.user.email
                : 'productrequests@allskinhealth.com'}
            </Text>
          </ModalBody>
          <ModalBody>
            <Box>
              <Flex alignItems={'center'} gap={6} mb={2}>
                <TitleModalInput>Subject</TitleModalInput>
                <Input
                  isRequired
                  h={'38px'}
                  value={formValue.subject}
                  w={'full'}
                  onChange={e => {
                    handleChange({ subject: e.target.value })
                  }}
                />
              </Flex>
              {!!errors?.subject && (
                <ErrorText ml={'125px'}>{errors?.subject?.message}</ErrorText>
              )}
            </Box>
            <Box>
              <Flex alignItems={'center'} gap={6} mb={2}>
                <TitleModalInput>To</TitleModalInput>
                <Input
                  isRequired
                  h={'38px'}
                  isDisabled={!!productDetail?.user?.email}
                  value={productDetail?.user?.email}
                  w={'full'}
                  onChange={e => {
                    handleChange({ to: e.target.value })
                  }}
                />
              </Flex>
              {!!errors?.to && (
                <ErrorText ml={'125px'}>{errors?.to?.message}</ErrorText>
              )}
            </Box>

            <Box>
              <Flex alignItems={'center'} gap={6} mb={2}>
                <TitleModalInput>Body</TitleModalInput>
                <ReactQuill
                  style={{ width: '100%', height: '250px' }}
                  theme="snow"
                  value={formValue.body}
                  onChange={value => {
                    handleChange({ body: value })
                  }}
                />
              </Flex>
              {!!errors?.body && (
                <ErrorText mb={'-48px'} ml={'125px'} pt={'40px'}>
                  {errors?.body?.message}
                </ErrorText>
              )}
            </Box>
          </ModalBody>
          <ModalFooter pt={'48px'}>
            <Button colorScheme="blue" mr={3} onClick={handleSendEmail}>
              Send Email
            </Button>
            <Button
              colorScheme={'black'}
              mr={3}
              variant="ghost"
              onClick={handleCloseModalContact}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </StyledContainer>
  )
}

/**
 * ProductRequestScreen component page.
 * @return {JSX.Element} ProductRequestScreen component.
 */
export const ProductRequestScreen = () => {
  return <ProductRequestLayout {...useProductRequestScreen()} />
}
