import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { getAccessToken } from '../../../service/storageServices'
/**
 * @returns PublicRoute component check use is login
 */
export const PublicRoute = () => {
  const accessToken = getAccessToken()

  if (accessToken) {
    return <Navigate replace to="/" />
  }
  return (
    <>
      <Outlet />
    </>
  )
}
