import React, { useCallback, useContext, useEffect, useState } from 'react'
import { TProfile } from '../types'
import { getProfile } from '../service/authService'

type AuthContextValue = {
  accessToken?: string
  onLogout?: () => void
  saveAccessToken?: (token: string) => void
  profile?: TProfile
  fetchProfile?: () => void
}

const initialStyleContextValue: AuthContextValue = {}

export const AuthContext = React.createContext<AuthContextValue>(
  initialStyleContextValue,
)

const routerRequiredAuth = ['/']

// eslint-disable-next-line require-jsdoc
export const AuthContextProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string>(
    window.localStorage.getItem('accessToken') || '',
  )
  const [profile, setProfile] = useState<TProfile>()
  useEffect(() => {
    if (routerRequiredAuth.includes(window.location.pathname) && !accessToken) {
      // window.location.href = '/login'
    }
  }, [accessToken])

  const fetchProfile = useCallback(async () => {
    if (accessToken) {
      try {
        const response = await getProfile()
        setProfile({
          email: response.email,
          firstName: response?.firstName,
        })
      } catch (e) {}
    } else {
      setProfile(undefined)
    }
  }, [accessToken])

  useEffect(() => {
    /***
     * get profile
     */

    fetchProfile()
  }, [fetchProfile])

  const onLogout = useCallback(() => {
    window.localStorage.removeItem('token')
    setAccessToken('')
  }, [])

  const saveAccessToken = useCallback((token: string) => {
    setAccessToken(token)
  }, [])

  return (
    <AuthContext.Provider
      value={{
        onLogout,
        accessToken,
        profile,
        saveAccessToken,
        fetchProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
/**
 * @returns hook use auth
 */
export const useAuth = () => {
  return useContext(AuthContext)
}
