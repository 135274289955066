import React from 'react'
import { QuizAnswerDetailScreen } from './components/modules'

/**
 * QuizAnswerDetailPage component page.
 * @return {JSX.Element} QuizAnswerDetailPage component.
 */
export function QuizAnswerDetailPage() {
  return <QuizAnswerDetailScreen />
}
