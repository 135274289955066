export const colors = {
  baseBlue: '#787BAC',
  baseBlue2: '#55587E',
  baseBlue3: '#BFB9FF',
  bg: '#F5F1FB',
  primary_color_main: '#565AC0',
  gray: {
    300: '#bdbdbd',
  },
  button_color_blue: '#9497F5',
  title_color_blue: '#7864FA',
  support_text_color: '#55587E',
  background_box: '#E9EAFF',
  text_link_color: '#6B70F0',
  text_support_gray: '#9495B0',
  input_error: '#BA1A1A',
  content_disabled: '#E0E0E7',
  content_secondary: '#898F8F',
}
