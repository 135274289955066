import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import styled from 'styled-components'
import moment from 'moment/moment'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { InputDateTime } from '../../../../components/elements/Input/InputDateTime'
import { InfoUser } from '../../../../components/modules/InfoUser'
import { useDetailAttributesView } from './hook'

const TextError = styled(Text)`
  color: #ba1a1a;
`

/**
 * @returns Component DetailAttributes View Screen
 */
export const DetailAttributesViewScreen = () => {
  const {
    dataDetailAttributesView,
    handleChangeStartDate,
    valueStartAt,
    handleChangeEndDate,
    valueEndAt,
    handleSearch,
    handleReset,
    isLoading,
    textValidate,
    infoUser,
    newData,
  } = useDetailAttributesView()

  const columns = [
    {
      title: 'title',
      render: record => <Box>{record?.title}</Box>,
    },
    {
      title: 'Question',
      render: record => (
        <Box whiteSpace={'pre-wrap'}>
          {record?.questionData?.map(it => it.content)}
        </Box>
      ),
    },
    {
      title: 'Answer',
      render: record => (
        <Box whiteSpace={'pre-wrap'}>
          {record.answerText?.length !== 0
            ? record.answerText
            : record?.answers?.map(it => it.content)}
        </Box>
      ),
    },
    {
      title: 'Update Count',
      render: record => (
        <Box whiteSpace={'pre-wrap'}>
          {record?.questionData?.map(it => it.updateCount)}
        </Box>
      ),
    },
    {
      title: 'updated At',
      render: record => {
        const updatedAtArray = record?.questionData
          ?.map(it => it.updatedAt)
          .filter(Boolean)
        const formattedDate = updatedAtArray.length
          ? moment(updatedAtArray[0]).format('YYYY/MM/DD, HH:mm:ss A')
          : 'Not Answered Yet'
        return <Box whiteSpace={'pre-wrap'}>{formattedDate}</Box>
      },
    },
  ]
  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} flexDirection={'column'} gap={4}>
        <Flex
          alignItems={'center'}
          gap={10}
          justifyContent={'space-between'}
          w={'full'}
        >
          <Button
            _hover={{ bg: '#222', cursor: 'pointer' }}
            bg={'#222'}
            color={'#fff'}
            h={'32px'}
            w={'91px'}
            onClick={handleReset}
          >
            Reset
          </Button>
          <InputDateTime
            direction="horizontal"
            isShowTime={false}
            label={'Start Date'}
            value={valueStartAt ? new Date(valueStartAt) : undefined}
            onChange={date => {
              handleChangeStartDate(date)
            }}
          />
          <InputDateTime
            direction="horizontal"
            isShowTime={false}
            label={'End Date'}
            value={valueEndAt ? new Date(valueEndAt) : undefined}
            onChange={date => {
              handleChangeEndDate(date)
            }}
          />
          <Button
            _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            p={'0px 34px'}
            w={160}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Flex>
        {!isLoading && <InfoUser user={infoUser} />}
        <Box>{textValidate && <TextError>{textValidate}</TextError>}</Box>
        {dataDetailAttributesView && !!dataDetailAttributesView.length && (
          <>
            <Box overflowX={'auto'} w={'100%'}>
              <TableCustom columns={columns} data={newData || []} />
            </Box>
          </>
        )}
        {dataDetailAttributesView?.length === 0 && !isLoading && (
          <Flex alignItems={'center'} justifyContent={'center'}>
            No Data
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
