import React, { forwardRef, useCallback } from 'react'
import DatePicker from 'react-datepicker'
import {
  InputGroup,
  Input,
  InputRightElement,
  InputProps,
  Box,
} from '@chakra-ui/react'
import moment, { isDate } from 'moment'
import styled from '@emotion/styled'
import { IconDatePicker } from '../Icon/IconDatePicker'

type TInputProps = InputProps & {
  hideIcon?: boolean
}

/**
 * @returns Component Input Custom
 */
export const CustomInput = forwardRef<HTMLInputElement, TInputProps>(
  (props, ref) => {
    const { hideIcon, ...inputProps } = props

    return (
      <InputGroup cursor={'pointer'} w={'full'}>
        <Input
          ref={ref}
          background={'#FFF'}
          border={'1px solid #E4E7FF'}
          borderRadius={'4px'}
          boxShadow={'0px 5px 4px 0px rgba(120, 123, 172, 0.08) inset'}
          color={'primary_color_main'}
          fontFamily={'Hiragino Sans'}
          fontSize={'14px'}
          height={'40px'}
          padding={'10px 6px 10px 16px'}
          width={'full'}
          {...inputProps}
        />
        {hideIcon ? undefined : (
          <InputRightElement mt={'2px'} onClick={props.onClick}>
            <IconDatePicker h={'24px'} w={'24px'} />
          </InputRightElement>
        )}
      </InputGroup>
    )
  },
)

const StyledDatePicker = styled(Box)`
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker-popper .react-datepicker__triangle {
    margin-left: -14px;
  }
`

type DateTimeInputProps = {
  isShowTime?: boolean
  readOnly?: boolean
  dateOfBirth?: Date
  showTimeSelect?: boolean
  maxDate?: Date
  minDate?: Date
  onChangeDate: (date: Date) => void
}

/**
 * @returns Component Date picker
 */
export const DateTimeComponent = (props: DateTimeInputProps) => {
  const {
    dateOfBirth,
    onChangeDate,
    readOnly,
    isShowTime = true,
    showTimeSelect = false,
    maxDate,
    minDate,
  } = props
  const handleChangeRaw = useCallback(
    date => {
      const newRaw = new Date(date.currentTarget.value)
      if (!isDate(newRaw)) {
        onChangeDate(newRaw)
      }
    },
    [onChangeDate],
  )
  return (
    <StyledDatePicker>
      <DatePicker
        customInput={<CustomInput />}
        dateFormat={`yyyy/MM/dd${isShowTime ? ' hh:mm aa' : ''}`}
        maxDate={maxDate ? maxDate : new Date()}
        minDate={minDate ? minDate : undefined}
        readOnly={readOnly}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <div style={{ margin: '0 auto' }}>
                <button
                  aria-label="Previous Month"
                  className={
                    'react-datepicker__navigation react-datepicker__navigation--previous'
                  }
                  disabled={prevMonthButtonDisabled}
                  style={
                    prevMonthButtonDisabled ? { visibility: 'hidden' } : {}
                  }
                  onClick={decreaseMonth}
                >
                  <span
                    className={
                      'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
                    }
                  >
                    {'<'}
                  </span>
                </button>

                <Box
                  color={'#000'}
                  fontSize={'.944rem'}
                  fontWeight={700}
                  mt={0}
                >
                  {moment(date).format('YYYY/MM/DD')}
                </Box>

                <button
                  aria-label="Next Month"
                  className={
                    'react-datepicker__navigation react-datepicker__navigation--next'
                  }
                  disabled={nextMonthButtonDisabled}
                  style={
                    nextMonthButtonDisabled ? { visibility: 'hidden' } : {}
                  }
                  onClick={increaseMonth}
                >
                  <span
                    className={
                      'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
                    }
                  >
                    {'>'}
                  </span>
                </button>
              </div>
            </div>
          )
        }}
        selected={dateOfBirth ?? undefined}
        showTimeSelect={showTimeSelect}
        onChange={date => {
          if (date !== null) {
            onChangeDate(date)
          }
        }}
        onChangeRaw={e => handleChangeRaw(e)}
      />
    </StyledDatePicker>
  )
}
