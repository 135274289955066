import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button, Spinner } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import styled from '@emotion/styled'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { Select } from '../../../../components/elements/Select'
import { Input } from '../../../../components/elements/Input/Input'
import { useViewRules } from './hook'
import { OptionSelect } from './constants'

/**
 * @returns Component ViewRulesScreen Page
 */
export const ViewRulesScreen = () => {
  const {
    dataViewRules,
    totalPage,
    currentPage,
    handleNextPage,
    handleChangeInput,
    valueInput,
    valueSelect,
    handleChangeSelect,
    handleSearchRules,
    isLoading,
    fileInputRef,
    handleFileSelect,
    handleUploadFile,
    handleSubmitFile,
    fileUpload,
  } = useViewRules()

  const columns = [
    {
      title: 'ID',
      render: record => <Box>{record?.id}</Box>,
    },
    {
      title: 'Category1',
      render: record => <Box>{record?.cat1}</Box>,
    },
    {
      title: 'Category2',
      render: record => <Box>{record?.cat2}</Box>,
    },
    {
      title: 'Category3',
      render: record => <Box>{record?.cat3}</Box>,
    },
    {
      title: 'Attributes',
      render: record => <Box>{record?.possibleAttributes}</Box>,
    },
    {
      title: 'Tag',
      render: record => <Box>{record?.tag}</Box>,
    },
    {
      title: 'Include Or Exclude',
      render: record => <Box>{record?.includeOrExclude}</Box>,
    },
  ]
  if (isLoading) {
    return (
      <Flex justifyContent={'center'} mt={10}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <Flex flexDirection={'column'} gap={10}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Box>
          <StyledTitle>Update new rule</StyledTitle>
          <Flex flexDirection={'row'} gap={5}>
            <StyledButtonUpload onClick={handleFileSelect}>
              Select File
            </StyledButtonUpload>
            <input
              ref={fileInputRef}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: 'none' }}
              type="file"
              value=""
              onChange={handleUploadFile}
            />
            <StyledButtonSubmit
              isDisabled={!fileUpload}
              onClick={handleSubmitFile}
            >
              Submit
            </StyledButtonSubmit>
          </Flex>
          <StyledFileName as={'span'}>{fileUpload?.name}</StyledFileName>
        </Box>
        <Flex alignItems={'center'} gap={2}>
          <Select
            options={OptionSelect}
            value={valueSelect}
            width={200}
            onChange={newValue => handleChangeSelect(newValue)}
          />
          <Input
            placeholder={'keyword search'}
            value={valueInput}
            onChange={handleChangeInput}
          />
          <Button
            _hover={{ bg: '#3F6FBA', hover: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            w={160}
            onClick={handleSearchRules}
          >
            Search
          </Button>
        </Flex>
      </Flex>
      {dataViewRules && !!dataViewRules.length && (
        <>
          <Box overflowX={'auto'} w={'100%'}>
            <TableCustom columns={columns} data={dataViewRules || []} />
          </Box>
          <Pagination
            page={currentPage}
            totalPage={totalPage}
            onChange={handleNextPage}
          />
        </>
      )}
      {dataViewRules?.length === 0 && !isLoading && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          No Data
        </Flex>
      )}
    </Flex>
  )
}

const StyledButtonUpload = styled(Button)`
  border: 1px solid #d7d7d7;
  background: #f2f2f2;
  border-radius: 0;
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  justify-content: flex-start;
`

const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  height: 32px;
  border-radius: 25px;
  background: #000000;
  color: #ffffff;
  &:hover {
    background: #000000;
    cursor: pointer;
  }
`
const StyledTitle = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
`

const StyledFileName = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  color: #0070c0;
`
