import React from 'react'

import { PromotedBrandDetailScreen } from './components/modules'

/**
 * PromotedBrandDetail component page.
 * @return {JSX.Element} PromotedBrandDetail component.
 */
export function PromotedBrandDetail(): JSX.Element {
  return <PromotedBrandDetailScreen />
}
