import React from 'react'
import { Flex } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/react'
import { Props, usePromotionScreen } from '../../hooks'
import { PromotionRetailersTable } from '../modules/PromotionBrandTable'

/**
 * PromotedScreenLayout component page.
 * @return {JSX.Element} PromotedScreenLayout component.
 */
export const PromotedScreenLayout = (props: Props) => {
  const {
    isLoading,
    promotionRetailersData,
    totalPage,
    currentPage,
    handleNextPage,
    handleDeleteRetailer,
    onOpenDelete,
    isOpenDelete,
    onCloseDelete,
  } = props

  if (isLoading) {
    return (
      <Flex justifyContent={'center'} mt={10}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <PromotionRetailersTable
      currentPage={currentPage}
      data={promotionRetailersData}
      handleDeleteRetailer={handleDeleteRetailer}
      handleNextPage={handleNextPage}
      isOpenDelete={isOpenDelete}
      totalPage={totalPage}
      onCloseDelete={onCloseDelete}
      onOpenDelete={onOpenDelete}
    />
  )
}

/**
 * PromotedScreen component page.
 * @return {JSX.Element} PromotedScreen component.
 */
export const PromotedRetailerScreen = () => (
  <PromotedScreenLayout {...usePromotionScreen()} />
)
