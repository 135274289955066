import { useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { isAxiosError } from 'axios'
import { getDashboard } from '../../../../../service/authService'

type TInformationResponse = {
  numScanUpc: number
  numRoutineAnalysis: number
  numWordSearch: number
  userRoutineUse: {
    user_id: number
  }[]
  products: {
    total: number
    uniqUpc: number
    uniqIngredient: number
    uniqRetailer: number
  }
  users: {
    total: number
    totalAdminUser: number
    totalCommonUser: number
    totalFreeUser: number
    totalPremiumUser: number
  }
}

/***
 *
 * Returns: useHomeScreen
 */
export const useHomeScreen = () => {
  const [data, setData] = useState<TInformationResponse>()
  const toast = useToast()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await getDashboard()
      setData(response)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [toast])

  useEffect(() => {
    fetchData()
  }, [])

  return {
    data,
    isLoading,
  }
}

export type Props = ReturnType<typeof useHomeScreen>
