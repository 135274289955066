import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import moment from 'moment/moment'
import { TMetaType } from '../../../ViewRulesPage/components/modules/type'
import { getDetailProductViews } from '../../../../../service/authService'
import { TDetailRetailerView } from './type'

export type TLocationState = {
  id: number
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  createdAt: string
}

/**
 * @returns Component useDetailProductView hook
 */
export const useDetailProductView = () => {
  const [dataDetailRetailerView, setDataDetailRetailerView] =
    useState<TDetailRetailerView[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<TMetaType>()
  let [searchParams, setSearchParams] = useSearchParams()
  const toast = useToast()
  const page = searchParams.get('page')
  const startAt = searchParams.get('startAt')
  const endAt = searchParams.get('endAt')
  const { productId } = useParams()
  const [valueStartAt, setValueStartAt] = useState<Date>()
  const [valueEndAt, setValueEndAt] = useState<Date>()
  const [textValidate, setTextValidate] = useState('')
  const navigate = useNavigate()
  const validateDate = useCallback(() => {
    const startAt = moment(valueStartAt).format('YYYY-MM-DD')
    const endAt = moment(valueEndAt).format('YYYY-MM-DD')
    setTextValidate(
      startAt <= endAt
        ? ''
        : 'Please select a start date that occurs before the end date',
    )
    return startAt <= endAt
  }, [valueEndAt, valueStartAt])

  const location = useLocation()
  const record = location.state as TLocationState

  const fetchDataDetailProductView = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        user_id: productId,
        startAt: startAt ? moment(startAt).format('YYYY-MM-DD') : undefined,
        endAt: endAt ? moment(endAt).format('YYYY-MM-DD') : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getDetailProductViews(params)
      setDataDetailRetailerView(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [endAt, page, productId, startAt, toast])

  useEffect(() => {
    fetchDataDetailProductView().then()
  }, [fetchDataDetailProductView])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )

  const handleChangeStartDate = useCallback((date: Date) => {
    setValueStartAt(date)
    setTextValidate('')
  }, [])

  const handleChangeEndDate = useCallback((date: Date) => {
    setValueEndAt(date)
    setTextValidate('')
  }, [])

  const handleSearch = useCallback(async () => {
    if (validateDate()) {
      try {
        setSearchParams({
          startAt: moment(valueStartAt).format('YYYY-MM-DD') ?? '',
          endAt: moment(valueEndAt).format('YYYY-MM-DD') ?? '',
          page: String(1),
        })
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    }
  }, [setSearchParams, toast, validateDate, valueEndAt, valueStartAt])

  /**
   * @returns handleReset
   */
  const handleReset = useCallback(() => {
    setValueStartAt(undefined)
    setValueEndAt(undefined)
    setTextValidate('')
    setSearchParams({
      startAt: '',
      endAt: '',
      page: String(1),
    })
  }, [setSearchParams])

  return {
    dataDetailRetailerView,
    isLoading,
    handleNextPage,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleChangeStartDate,
    handleChangeEndDate,
    valueStartAt,
    valueEndAt,
    handleSearch,
    handleReset,
    textValidate,
    navigate,
    record,
  }
}
