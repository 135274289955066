import { useCallback, useEffect, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { isAxiosError } from 'axios'
import { useSearchParams } from 'react-router-dom'
import {
  deleteRetailers,
  getPromotionRetailerData,
  Meta,
  TPromotionDetail,
} from '../../../service/promotionService'

/**
 * usePromotionScreen hook.
 */
export const usePromotionScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [promotionRetailersData, setPromotionRetailersData] =
    useState<TPromotionDetail[]>()
  const [retailerId, setRetailerId] = useState<number>()
  const toast = useToast()
  let [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const [meta, setMeta] = useState<Meta>()
  const {
    isOpen: isOpenDelete,
    onOpen,
    onClose: onCloseDelete,
  } = useDisclosure()

  const onOpenDelete = useCallback(
    (id: number) => {
      setRetailerId(id)
      onOpen()
    },
    [onOpen],
  )

  const fetchPromotionData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const { data, meta } = await getPromotionRetailerData(params)
      setMeta(meta)
      setPromotionRetailersData(data)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, toast])

  useEffect(() => {
    fetchPromotionData().then()
  }, [fetchPromotionData])

  const handleNextPage = useCallback((currentPage: number) => {
    window.scrollTo(0, 0)
    setSearchParams({
      page: String(currentPage),
    })
  }, [])

  const handleDeleteRetailer = useCallback(async () => {
    setIsLoading(true)
    try {
      if (!retailerId) {
        return
      }
      await deleteRetailers(retailerId)
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Delete Success',
        duration: 3000,
      })
      onCloseDelete()
      fetchPromotionData()
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [fetchPromotionData, onCloseDelete, retailerId, toast])

  return {
    isLoading,
    promotionRetailersData,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNextPage,
    handleDeleteRetailer,
    onOpenDelete,
    isOpenDelete,
    onCloseDelete,
  }
}

export type Props = ReturnType<typeof usePromotionScreen>
