import React from 'react'
import { ProductDetailScreen } from './components/modules'

/**
 * ProductDetailPage component page.
 * @return {JSX.Element} ProductDetailPage component.
 */
export function ProductDetailPage() {
  return <ProductDetailScreen />
}
