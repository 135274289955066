import React from 'react'
import { PromotedRetailerCreateScreen } from './components/modules'

/**
 * PromotionRetailerCreate component page.
 * @return {JSX.Element} PromotionRetailerCreatePage component.
 */
export function PromotionRetailerCreatePage() {
  return <PromotedRetailerCreateScreen />
}
