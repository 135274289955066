import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button, Tooltip } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import styled from 'styled-components'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { InputDateTime } from '../../../../components/elements/Input/InputDateTime'
import { Select } from '../../../../components/elements/Select'
import { Input } from '../../../../components/elements/Input/Input'
import { ModalExportData } from '../../../../components/modules/ModalExportData'
import { useMostPopularProduct } from './hook'
import { OptionSelect, OptionSelectView } from './constants'

const TextError = styled(Text)`
  color: #ba1a1a;
`
/**
 * @returns Component MostPopularProduct Screen
 */
export const MostPopularProductScreen = () => {
  const {
    dataTopProduct,
    currentPage,
    totalPage,
    handleNextPage,
    handleChangeStartDate,
    valueStartAt,
    handleChangeEndDate,
    valueEndAt,
    handleSearch,
    handleReset,
    isLoading,
    textValidate,
    valueInput,
    handleChangeInput,
    valueSelect,
    handleChangeSelect,
    navigate,
    handleExportToXLSX,
    isOpenModalExportData,
    downloadedItem,
    handleClickDownload,
    isButtonChosen,
    totalItem,
    setIsOpenModalExportData,
    valueSelectView,
    handleChangeSelectView,
    type,
  } = useMostPopularProduct()

  const statisticsClicksField = type => {
    switch (type) {
      case OptionSelectView[1].value:
        return {
          title: 'Page Views',
          render: record => (
            <Box _hover={{ textDecoration: 'underline' }}>
              {record?.numberOfView}
            </Box>
          ),
        }
      case OptionSelectView[2].value:
        return {
          title: 'SAVED INSTANCES',
          render: record => (
            <Box _hover={{ textDecoration: 'underline' }}>
              {record?.numberOfProduct}
            </Box>
          ),
        }
      default: {
        return {
          title: 'ROUTINE INSTANCES',
          render: record => (
            <Box _hover={{ textDecoration: 'underline' }}>
              {record?.numberOfProduct}
            </Box>
          ),
        }
      }
    }
  }

  const columns = [
    {
      title: 'product',
      render: record => (
        <Box whiteSpace={'pre-wrap'} width={'500px'}>
          {record?.product}
        </Box>
      ),
    },
    statisticsClicksField(type),
    {
      title: 'retailer',
      render: record => <Box>{record?.retailer}</Box>,
    },
    {
      title: 'brand',
      render: record => <Box>{record?.brand}</Box>,
    },
    {
      title: 'upc',
      render: record => <Box>{record?.upc}</Box>,
    },
    {
      title: 'description',
      render: record => (
        <Tooltip
          hasArrow
          label={record?.description}
          placement={'bottom-start'}
        >
          <Box
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            w={'400px'}
            whiteSpace={'nowrap'}
          >
            {record?.description}
          </Box>
        </Tooltip>
      ),
    },
    {
      title: 'cat1',
      render: record => <Box>{record?.cat1}</Box>,
    },
    {
      title: 'cat2',
      render: record => <Box>{record?.cat2}</Box>,
    },
    {
      title: 'cat3',
      render: record => <Box>{record?.cat3}</Box>,
    },
    {
      title: 'retailer',
      render: record => <Box>{record?.retailer}</Box>,
    },
    {
      title: 'price',
      render: record => <Box>{record?.price}</Box>,
    },
  ]

  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} flexDirection={'column'} gap={10}>
        <Flex alignItems={'center'} flexDirection={'row'}>
          <Text
            color={'#000'}
            fontFamily={'Hiragino Kaku Gothic Pro'}
            fontSize={'sm'}
            fontWeight={'semibold'}
            lineHeight={'16px'}
            margin={8}
          >
            {'Choose Product Views'}
          </Text>
          <Select
            options={OptionSelectView}
            value={valueSelectView}
            width={200}
            onChange={newValue => handleChangeSelectView(newValue)}
          />
        </Flex>
        <Flex
          alignItems={'center'}
          gap={10}
          justifyContent={'space-between'}
          w={'full'}
        >
          <Button
            _hover={{ bg: '#222', cursor: 'pointer' }}
            bg={'#222'}
            color={'#fff'}
            h={'32px'}
            w={'91px'}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Flex flexDirection={'column'} gap={4}>
            {(type === 'product-routine' || type === null) && (
              <Flex alignItems={'center'} gap={2}>
                <Select
                  options={OptionSelect}
                  value={valueSelect}
                  width={200}
                  onChange={newValue => handleChangeSelect(newValue)}
                />
                <Input
                  placeholder={'keyword search'}
                  value={valueInput}
                  onChange={handleChangeInput}
                />
              </Flex>
            )}
            <Flex gap={4}>
              <InputDateTime
                direction="horizontal"
                isShowTime={false}
                label={'Start Date'}
                value={valueStartAt ? new Date(valueStartAt) : undefined}
                onChange={date => {
                  handleChangeStartDate(date)
                }}
              />
              <InputDateTime
                direction="horizontal"
                isShowTime={false}
                label={'End Date'}
                value={valueEndAt ? new Date(valueEndAt) : undefined}
                onChange={date => {
                  handleChangeEndDate(date)
                }}
              />
            </Flex>
          </Flex>

          <Button
            _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            p={'0px 34px'}
            w={160}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Flex>
        <Box>{textValidate && <TextError>{textValidate}</TextError>}</Box>
        {dataTopProduct && !!dataTopProduct.length && (
          <>
            <Box overflowX={'auto'} w={'100%'}>
              <TableCustom
                columns={columns}
                data={dataTopProduct || []}
                onSelect={val => navigate(`/product-detail/${val?.id}`)}
              />
            </Box>
            <Box py={5}>
              <Pagination
                page={currentPage}
                totalPage={totalPage}
                onChange={handleNextPage}
              />
            </Box>
          </>
        )}
        <Flex alignSelf={'flex-start'}>
          <Button
            _hover={{ bg: '#3F6FBA' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            w={'max-content'}
            onClick={handleExportToXLSX}
          >
            Export Data
          </Button>
        </Flex>
        {dataTopProduct?.length === 0 && !isLoading && (
          <Flex alignItems={'center'} justifyContent={'center'}>
            No Data
          </Flex>
        )}
        <ModalExportData
          downloadedItem={downloadedItem}
          handleClickDownload={handleClickDownload}
          isButtonChosen={isButtonChosen}
          isOpenModal={isOpenModalExportData}
          totalItem={totalItem}
          onCloseModal={() => setIsOpenModalExportData(false)}
        />
      </Flex>
    </Flex>
  )
}
