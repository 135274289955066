import { Box } from '@chakra-ui/layout'
import React from 'react'
import { BoxProps } from '@chakra-ui/react'
import { Layout, LayoutMain } from './Layout'
import { SideBar } from './SideBar'
import { Header } from './Header'

interface IMainLayoutProps extends BoxProps {
  title?: string
}

/**
 * @returns Component Main Layout
 */
export const MainLayout: React.FC<IMainLayoutProps> = ({
  children,
  title,
  ...props
}) => {
  return (
    <Layout {...props}>
      <Header title={title} />
      <SideBar />
      <Box as={LayoutMain}>{children}</Box>
    </Layout>
  )
}
