import React from 'react'
import { Flex } from '@chakra-ui/react'
import { Box } from '@chakra-ui/layout'
import { Label } from '../elements/Label'
import { ErrorText } from '../elements/ErrorText'
import { Select, ISelectProps } from '../elements/Select'

export type SelectFormProps = ISelectProps & {
  error?: string
  label?: string | React.ReactNode
  direction?: 'horizontal' | 'vertical'
  labelWidth?: string | number
  isRequired?: boolean
  isDisabled?: boolean
}

/**
 * SelectForm component page.
 * @return {JSX.Element} SelectForm component.
 */
export const SelectForm: React.FC<SelectFormProps> = ({
  error,
  label,
  direction = 'vertical',
  labelWidth,
  isRequired,
  isDisabled,
  value,
  ...selectProps
}) => {
  if (direction === 'horizontal') {
    return (
      <>
        <Flex alignItems="center" gap={2} >
          <Label
            isRequired={isRequired}
            label={label}
            labelWidth={labelWidth}
            mb={error ? 6 : 0}
          />
          <Flex flexDirection={'column'} gap={1} w={'full'} >
            <Select
              isInvalid={!!error}
              value={value || ''}
              {...selectProps}
              isDisabled={isDisabled}
            />
            {error && <ErrorText>{error}</ErrorText>}
          </Flex>
        </Flex>
      </>
    )
  }

  return (
    <Flex flexDirection={'column'} gap={2} width={'full'}>
      <Label isRequired={isRequired} label={label} labelWidth={labelWidth} />
      <Box cursor={'not-allowed'}>
        <Select
          isDisabled={isDisabled}
          isInvalid={!!error}
          value={value || ''}
          width={'100%'}
          {...selectProps}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </Box>
    </Flex>
  )
}
