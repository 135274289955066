import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { Props, useAddProductScreen } from '../../hooks/addProduct.hooks'

const StyledContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin: 40px 40px 0 40px;
`

const StyledWrapper = styled(Flex)`
  flex-direction: column;
  gap: 20px;
`

const StyledTitle = styled(Text)`
  font-size: 20px;
  font-weight: bold;
`
const StyledDescription = styled(Text)`
  font-size: 20px;
`

const StyledFileName = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  color: #0070c0;
`

const StyledButtonUpload = styled(Button)`
  border: 1px solid #d7d7d7;
  background: #f2f2f2;
  border-radius: 0;
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  justify-content: flex-start;
`
const StyledExample = styled(Button)`
  border: 1px solid #d7d7d7;
  background: #f2f2f2;
  border-radius: 0;
  font-weight: normal;
  font-size: 14px;
  width: 150px;
  justify-content: center;
  height: 40px;
  display: flex;
  align-items: center;
`

const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  height: 32px;
  border-radius: 25px;
  background: #000000;
  color: #ffffff;
  &:hover {
    background: #000000;
    cursor: pointer;
  }
`

/**
 * BulkAddLayout component page.
 * @return {JSX.Element} BulkAddLayout component.
 */
export const BulkAddLayout = (props: Props) => {
  const {
    handleUploadFile,
    fileUpload,
    handleSubmitFile,
    handleExampleExcel,
    isLoading,
  } = props
  const fileInputRef = useRef<HTMLInputElement>(null)

  /**
   * @returns function that handle click upload file
   */
  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  return (
    <StyledContainer>
      <StyledWrapper>
        <StyledTitle>Bulk Add</StyledTitle>
        <StyledDescription>
          Add Excel sheet based on this template.{' '}
          <StyledFileName as={'span'}>{fileUpload?.name}</StyledFileName>
        </StyledDescription>
        <StyledExample isLoading={isLoading} onClick={handleExampleExcel}>
          Example Template
        </StyledExample>
      </StyledWrapper>
      <StyledWrapper>
        <StyledButtonUpload onClick={handleFileSelect}>
          Select File
        </StyledButtonUpload>
        <input
          ref={fileInputRef}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          style={{ display: 'none' }}
          type="file"
          value=""
          onChange={handleUploadFile}
        />
        <StyledButtonSubmit isDisabled={!fileUpload} onClick={handleSubmitFile}>
          Submit
        </StyledButtonSubmit>
      </StyledWrapper>
    </StyledContainer>
  )
}

/**
 * BulkAdd component page.
 * @return {JSX.Element} BulkAdd component.
 */
export const BulkAdd = () => <BulkAddLayout {...useAddProductScreen()} />
