import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getAnswerAbout } from '../../../../../service/quizQuestion'
import { TMetaType } from '../../../ViewRulesPage/components/modules/type'
import { TAnswerAbout } from './type'

/**
 * @returns Component useAnswerAbout hook
 */
export const useAnswerAbout = () => {
  const [dataAnswer, setDataAnswer] = useState<TAnswerAbout[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<TMetaType>()
  let [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const toast = useToast()
  const page = searchParams.get('page')

  const fetchDataAnswer = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getAnswerAbout(params)
      setDataAnswer(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, toast])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )

  useEffect(() => {
    fetchDataAnswer().then()
  }, [fetchDataAnswer])

  const getDetailQuizResults = useCallback(
    (answerAboutId: number) => {
      navigate(`/quiz-results/${answerAboutId}`)
    },
    [navigate],
  )
  return {
    dataAnswer,
    isLoading,
    getDetailQuizResults,
    handleNextPage,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
  }
}
