import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import {
  createProductRequest,
  getCategory1,
  getCategory2,
  getCategory3,
  getProductRequest,
  putProducts,
  sendContactMails,
} from '../../../../../service/productService'
import {
  TCategory1,
  TCategory2,
  TCategory3,
  TProductRequestResponse,
} from '../../../ViewProductData/components/modules/types'
import { TMetaType } from '../../../ViewRulesPage/components/modules/type'
import { Option } from '../../../../../types'

export type TProductRequestProps = {}

const schemaAdd = yup.object().shape({
  id: yup.number(),
  upc: yup
    .string()
    .nullable()
    .test('upc', 'upc must be a number', value => {
      if (!value) return true
      return !isNaN(Number(value))
    }),
  brand: yup.string().required(),
  name: yup.string().required(),
  cat1: yup
    .object()
    .nullable()
    .shape({
      id: yup.number().required(),
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .test('category1', 'Category 1 is a required field', value => {
      const { id, value: catValue, label } = value || {}
      return !(!id || !catValue || !label)
    }),
  cat2: yup
    .object()
    .shape({
      id: yup.number().required(),
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .test('category2', 'Category 2 is a required field', value => {
      const { id, value: catValue, label } = value || {}
      return !(!id || !catValue || !label)
    }),
  cat3: yup
    .object()
    .shape({
      id: yup.number().required(),
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .test('category3', 'Category 3 is a required field', value => {
      const { id, value: catValue, label } = value || {}
      return !(!id || !catValue || !label)
    }),
  retailer: yup.string().required(),
  stars: yup
    .string()
    .nullable()
    .test('stars', 'stars must be a number', value => {
      if (!value) return true
      return !isNaN(Number(value))
    }),
  reviewCount: yup
    .string()
    .nullable()
    .test('reviewCount', 'reviewCount must be a number', value => {
      if (!value) return true
      return !isNaN(Number(value))
    }),
  description: yup.string().nullable(),
  ingredients: yup.string().required(),
  joblink: yup.string().nullable(),
  imageurl: yup.string().required(),
  retailerPromoted: yup.number(),
  brandPromoted: yup.number(),
  weblink: yup.string().required(),
  price: yup.string().required(),
  user: yup.object().shape({
    email: yup.string().required(),
  }),
})

const schemaContact = yup.object().shape({
  subject: yup.string().required('Subject required'),
  body: yup.string().required('Body required'),
  to: yup
    .string()
    .email('To Email is incorrect')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'To Email invalid',
    )
    .required('To Email required'),
})

export type TFormValue = yup.InferType<typeof schemaContact>
export type TFormValueAdd = yup.InferType<typeof schemaAdd>

type IErrors = {
  [key: string]: {
    message: string
  }
}
/**
 * useProductRequestScreen hook.
 */
export const useProductRequestScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<TProductRequestResponse[]>()
  const [productDetail, setProductDetail] = useState<Partial<TFormValueAdd>>({})
  const [meta, setMeta] = useState<TMetaType>()
  const {
    isOpen: isOpenActive,
    onOpen: onOpenActive,
    onClose: onCloseActive,
  } = useDisclosure()
  const {
    isOpen: isOpenComplete,
    onOpen: onOpenComplete,
    onClose: onCloseComplete,
  } = useDisclosure()
  const {
    isOpen: isOpenContactUs,
    onOpen: onOpenContactUs,
    onClose: onCloseContactUs,
  } = useDisclosure()
  let [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const toast = useToast()
  const navigate = useNavigate()
  const [formValue, setFormValue] = useState<Partial<TFormValue>>({
    subject: '',
    body: '',
    to: '',
  })
  const [errors, setErrors] = useState<IErrors>({})
  const [category1, setCategory1] = useState<TCategory1[]>()
  const [category2, setCategory2] = useState<TCategory2[]>()
  const [category3, setCategory3] = useState<TCategory3[]>()
  const fetchProductRequestData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        page: page ? Number(page) : 1,
        limit: 15,
        type: 0,
      }
      const response = await getProductRequest(params)
      setData(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, toast])

  useEffect(() => {
    fetchProductRequestData().then()
  }, [fetchProductRequestData])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )
  const handleOpenModalAdd = useCallback(
    async it => {
      onOpenActive()
      setProductDetail(it)
    },
    [onOpenActive],
  )
  const handleOpenModalIgnore = useCallback(
    async it => {
      onOpenComplete()
      setProductDetail(it)
    },
    [onOpenComplete],
  )

  const handleOpenModalContactUs = useCallback(
    async it => {
      onOpenContactUs()
      setProductDetail(it)
      setFormValue({ ...formValue, to: it?.user.email })
    },
    [formValue, onOpenContactUs],
  )

  const validationInputAdd = useCallback(() => {
    let newErrors: IErrors = {}
    try {
      schemaAdd.validateSync(productDetail, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
        }
        setErrors(newErrors)
        return false
      }
    }
    return true
  }, [productDetail])

  const handleSave = useCallback(
    async (id: number) => {
      if (validationInputAdd()) {
        setIsLoading(true)
        onCloseActive()
        try {
          const payload = {
            brand: productDetail?.brand,
            retailer: productDetail?.retailer,
            upc: productDetail?.upc,
            product: productDetail?.name,
            weblink: productDetail?.weblink,
            reviewCount: Number(productDetail.reviewCount),
            stars: Number(productDetail.stars),
            imageurl: productDetail?.imageurl,
            description: productDetail?.description,
            ingredients: productDetail?.ingredients,
            cat1: productDetail.cat1?.value,
            cat2: productDetail.cat2?.value,
            cat3: productDetail.cat3?.value,
            price: Number(productDetail.price),
            joblink: productDetail.joblink,
          }
          await createProductRequest(id, payload)
          toast({
            position: 'top-right',
            status: 'success',
            title: 'Change Success',
            duration: 1000,
          })
          fetchProductRequestData()
        } catch (e) {
          if (isAxiosError(e)) {
            const message = e?.response?.data.message
            toast({
              position: 'top-right',
              status: 'error',
              title: message,
              duration: 3000,
            })
          }
        } finally {
          setIsLoading(false)
        }
      }
    },
    [
      validationInputAdd,
      onCloseActive,
      productDetail?.brand,
      productDetail?.retailer,
      productDetail?.upc,
      productDetail?.name,
      productDetail?.weblink,
      productDetail.reviewCount,
      productDetail.stars,
      productDetail?.imageurl,
      productDetail?.description,
      productDetail?.ingredients,
      productDetail.cat1?.value,
      productDetail.cat2?.value,
      productDetail.cat3?.value,
      productDetail.price,
      productDetail.joblink,
      toast,
      fetchProductRequestData,
    ],
  )
  const handleSaveIgnore = useCallback(
    async (id: number) => {
      onCloseComplete()
      try {
        const payload = { type: 2 }
        await putProducts(id, payload)
        fetchProductRequestData()
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    },
    [fetchProductRequestData, onCloseComplete, toast],
  )

  const handleShowHistory = useCallback(() => {
    navigate('/product-requests-complete')
  }, [navigate])

  const handleChange = useCallback(
    (values: Partial<TFormValue>) => {
      const newErrors = { ...errors }
      Object.keys(values).forEach(key => {
        delete newErrors[key]
      })
      setFormValue({ ...formValue, ...values })
      setErrors(newErrors)
    },
    [formValue, errors],
  )

  const validation = useCallback(() => {
    let newErrors: IErrors = {}
    try {
      schemaContact.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
        }
        setErrors(newErrors)
        return false
      }
    }
    return true
  }, [formValue])

  const handleSendEmail = useCallback(async () => {
    if (validation()) {
      setIsLoading(true)
      onCloseContactUs()
      try {
        if (formValue.subject && formValue.body && formValue.to) {
          await sendContactMails({
            subject: formValue.subject,
            body: formValue.body,
            to: formValue.to,
          })
        }
        setFormValue({ ...formValue, subject: '', body: '', to: '' })
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
  }, [formValue, onCloseContactUs, toast, validation])

  const handleCloseModalContact = useCallback(() => {
    onCloseContactUs()
    setFormValue({ ...formValue, subject: '', body: '', to: '' })
    setErrors({})
  }, [formValue, onCloseContactUs])

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target
      setProductDetail(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )
  const fetchCategory1 = useCallback(async () => {
    try {
      const options = await getCategory1()
      setCategory1(options)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    }
  }, [toast])

  useEffect(() => {
    fetchCategory1()
  }, [fetchCategory1])

  const catSelection1:
    | { id: number; label: string; value: string }[]
    | undefined = category1?.map(it => {
    return {
      id: it.id,
      value: it.content,
      label: it.content,
    }
  })
  const fetchCategory2 = useCallback(
    async (catId1: number) => {
      try {
        const options = await getCategory2(catId1)
        setCategory2(options)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    },
    [toast],
  )
  useEffect(() => {
    if (!productDetail.id) return
    fetchCategory2(Number(productDetail.id))
  }, [fetchCategory2, productDetail.id])

  const fetchCategory3 = useCallback(
    async (catId2: number) => {
      try {
        const options = await getCategory3(catId2)
        setCategory3(options)
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    },
    [toast],
  )
  useEffect(() => {
    if (!productDetail.id) return
    fetchCategory3(Number(productDetail.id))
  }, [fetchCategory3, productDetail.id])

  const catSelection2:
    | { id: number; label: string; value: string }[]
    | undefined = category2?.map(it => {
    return {
      id: it.id,
      value: it.content,
      label: it.content,
    }
  })

  const catSelection3:
    | { id: number; label: string; value: string }[]
    | undefined = category3?.map(it => {
    return {
      id: it.id,
      value: it.content,
      label: it.content,
    }
  })
  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelectCategory1 = useCallback(
    (name: string, option: Option | unknown) => {
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        const selectId = selectedOption.id
        setProductDetail(prevState => ({
          ...prevState,
          [name]: selectedOption,
          cat2: { id: 1, value: '', label: '' },
          cat3: { id: 1, value: '', label: '' },
        }))
        setErrors(prevState => ({
          ...prevState,
          [name]: { message: '' },
        }))
        if (selectId !== undefined) {
          fetchCategory2(selectId)
        }
      }
    },
    [fetchCategory2],
  )

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelectCategory2 = useCallback(
    (name: string, option: Option | unknown) => {
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        const selectId = selectedOption.id
        setProductDetail(prevState => ({
          ...prevState,
          [name]: selectedOption,
          cat3: { id: 1, value: '', label: '' },
        }))
        setErrors(prevState => ({
          ...prevState,
          [name]: { message: '' },
        }))
        if (selectId !== undefined) {
          fetchCategory3(selectId)
        }
      }
    },
    [fetchCategory3],
  )

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelectCategory3 = useCallback(
    (name: string, option: Option | unknown) => {
      if (typeof option !== 'string') {
        const selectedOption = option as Option
        setProductDetail(prevState => ({
          ...prevState,
          [name]: selectedOption,
        }))
        setErrors(prevState => ({
          ...prevState,
          [name]: { message: '' },
        }))
      }
    },
    [],
  )
  return {
    data,
    isLoading,
    handleNextPage,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    isOpenActive,
    onOpenActive,
    onCloseActive,
    handleOpenModalAdd,
    productDetail,
    handleSave,
    isOpenComplete,
    onOpenComplete,
    onCloseComplete,
    handleSaveIgnore,
    isOpenContactUs,
    onOpenContactUs,
    onCloseContactUs,
    handleShowHistory,
    handleChange,
    formValue,
    handleSendEmail,
    errors,
    handleCloseModalContact,
    handleOpenModalIgnore,
    handleOpenModalContactUs,
    handleChangeInput,
    catSelection1,
    handleOnChangeSelectCategory1,
    catSelection2,
    handleOnChangeSelectCategory2,
    catSelection3,
    handleOnChangeSelectCategory3,
    navigate,
  }
}

export type Props = ReturnType<typeof useProductRequestScreen>
