import React from 'react'

import { QuizQuestionDetailScreen } from './components/modules'

/**
 * QuizQuestionDetailPage component page.
 * @return {JSX.Element} QuizQuestionDetailPage component.
 */
export function QuizQuestionDetailPage() {
  return <QuizQuestionDetailScreen />
}
