import { useCallback, useEffect, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { isAxiosError } from 'axios'
import { useSearchParams } from 'react-router-dom'
import {
  deleteBrand,
  getPromotionBrandData,
  Meta,
  TPromotionDetail,
} from '../../../service/promotionService'

/**
 * usePromotionScreen hook.
 */
export const usePromotionScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [promotionData, setPromotionData] = useState<TPromotionDetail[]>()
  const toast = useToast()
  let [searchParams, setSearchParams] = useSearchParams()
  const [brandId, setBrandId] = useState<number>()
  const page = searchParams.get('page')
  const [meta, setMeta] = useState<Meta>()
  const {
    isOpen: isOpenDelete,
    onOpen,
    onClose: onCloseDelete,
  } = useDisclosure()

  const onOpenDelete = useCallback(
    (id: number) => {
      setBrandId(id)
      onOpen()
    },
    [onOpen],
  )

  const fetchPromotionData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const { data, meta } = await getPromotionBrandData(params)
      setMeta(meta)
      setPromotionData(data)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, toast])

  useEffect(() => {
    fetchPromotionData().then()
  }, [fetchPromotionData])

  const handleNextPage = useCallback((currentPage: number) => {
    window.scrollTo(0, 0)
    setSearchParams({
      page: String(currentPage),
    })
  }, [])

  const handleDeleteBrand = useCallback(async () => {
    setIsLoading(true)
    try {
      if (!brandId) {
        return
      }
      await deleteBrand(brandId)
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Delete Success',
        duration: 3000,
      })
      onCloseDelete()
      fetchPromotionData()
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [fetchPromotionData, onCloseDelete, brandId, toast])

  return {
    isLoading,
    promotionData,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNextPage,
    handleDeleteBrand,
    onOpenDelete,
    isOpenDelete,
    onCloseDelete,
  }
}

export type Props = ReturnType<typeof usePromotionScreen>
