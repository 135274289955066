import React from 'react'

import { UserEditScreen } from './components/modules'

/**
 * @returns Component Administrators Edit Page
 */
export function UserEditPage() {
  return <UserEditScreen />
}
