import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import {
  getAllSkinProfile,
  TAllSkinProfile,
} from '../../../../../service/editProfileService'

/**
 * useEditProfile hook.
 */
export const useEditProfile = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [value, setValue] = useState<TAllSkinProfile>()
  const toast = useToast()

  const handleFetchData = useCallback(async () => {
    setIsLoading(true)

    try {
      const response = await getAllSkinProfile()
      setValue(response)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [toast])

  useEffect(() => {
    handleFetchData().then()
  }, [handleFetchData])

  const handleOnChange = useCallback((newValue: string) => {
    console.log(newValue)
  }, [])

  return {
    value,
    isLoading,
    handleOnChange,
  }
}

export type Props = ReturnType<typeof useEditProfile>
