import qs from 'qs'
import { axiosWithToken } from '../configureAxios'
import {
  TBrandDetail,
  TRetailerDetail,
} from '../app/pages/PromotedBrandDetail/components/modules/type'

export enum StatusPromotion {
  Promoted,
  NonPromoted,
}

export type TPromotionDetail = Partial<{
  id: number
  name: string
  promoted: StatusPromotion
}>

export type TPromotionRetailer = Partial<{
  promotionRetailers: TPromotionDetail[]
}>
export type Meta = {
  limit: number
  page: number
  total: number
  totalPage: number
}
export type TPromotionBrand = Partial<{
  data: TPromotionDetail[]
  meta: Meta
}>
type PromotionBrandParams = {
  page: number
  limit: number
}
/**
 * @returns get promotion data
 * */
export const getPromotionBrandData = async (
  params: PromotionBrandParams,
): Promise<TPromotionBrand> => {
  const { data } = await axiosWithToken.get('/brands', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns get brand detail
 * */
export const getDetailBrand = async (
  brandId: string,
): Promise<TBrandDetail> => {
  const { data } = await axiosWithToken.get(`/brands/detail/${brandId}`)
  return {
    ...data,
    promoted: Boolean(data.promoted),
  }
}

/**
 * @returns putBrands
 * */
export const putBrands = async (id: string, payload) => {
  const { data } = await axiosWithToken.put(`/brands/${id}`, payload)
  return data
}
/**
 * @returns get getPromotionRetailer data
 * */
export const getPromotionRetailerData = async (
  params: PromotionBrandParams,
): Promise<TPromotionBrand> => {
  const { data } = await axiosWithToken.get('/retailers', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns get brand detail
 * */
export const getDetailRetailer = async (
  retailerId: string,
): Promise<TRetailerDetail> => {
  const { data } = await axiosWithToken.get(`/retailers/detail/${retailerId}`)
  return {
    ...data,
    promoted: Boolean(data.promoted),
  }
}

/**
 * @returns createBrands
 * */
export const createBrands = async payload => {
  const { data } = await axiosWithToken.post(`/brands`, payload)
  return data
}

/**
 * @returns createRetailer
 * */
export const createRetailer = async payload => {
  const { data } = await axiosWithToken.post(`/retailers`, payload)
  return data
}

/**
 * @returns putRetailers
 * */
export const putRetailers = async (id: string, payload) => {
  const { data } = await axiosWithToken.put(`/retailers/${id}`, payload)
  return data
}

/**
 * @returns update logo
 * */
export const updateLogoRetailer = async payload => {
  const formData = new FormData()
  formData.append('file', payload)
  const { data } = await axiosWithToken.post(`/retailers/logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return data
}

/***
 *
 * delete Retailers
 */
export const deleteRetailers = async (id: number) => {
  const { data } = await axiosWithToken.delete(`/retailers/${id}`)
  return data
}

/***
 *
 * delete Brand
 */
export const deleteBrand = async (id: number) => {
  const { data } = await axiosWithToken.delete(`/brands/${id}`)
  return data
}
