import React from 'react'
import styled from '@emotion/styled'
import { Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { InputForm } from '../../../../../components/modules/InputForm'
import { Select } from '../../../../../components/elements/Select'
import { mockSelectionAdministrators } from '../../../Add/constants'
import { useEditAdministrators } from './hook'

/**
 * @returns Component AdministratorsEdit Page
 */
export const AdministratorsEditScreen = () => {
  const {
    handleSubmit,
    errors,
    handleChangeInput,
    administratorDetail,
    handleOnChangeSelect,
    defaultValue,
  } = useEditAdministrators()

  return (
    <>
      <StyledContainer>
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.email?.message}
          isInvalid={!!errors?.email?.message}
          label={'Email'}
          labelWidth={60}
          name="email"
          placeholder={''}
          value={administratorDetail?.email}
          onChange={handleChangeInput}
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.phoneNumber?.message}
          isInvalid={!!errors?.phoneNumber?.message}
          label={'Phone Number'}
          labelWidth={60}
          name="phoneNumber"
          placeholder={''}
          value={administratorDetail?.phoneNumber}
          onChange={handleChangeInput}
        />
        <Flex alignItems={'center'} gap={'8px'} justifyContent={'flex-start'}>
          <TextTitle>
            <Text as={'span'} style={{ color: '#9B2C2C' }}>
              *
            </Text>{' '}
            Role
          </TextTitle>
          <Select
            name={'role'}
            options={mockSelectionAdministrators}
            value={defaultValue}
            width={'300px'}
            onChange={value => handleOnChangeSelect('role', value as string)}
          />
        </Flex>
      </StyledContainer>
      <StyledWrapperButton>
        <StyledButtonSubmit
          onClick={() => handleSubmit(Number(administratorDetail.id))}
        >
          Submit
        </StyledButtonSubmit>
      </StyledWrapperButton>
    </>
  )
}

const StyledContainer = styled(Flex)`
  gap: 20px;
  flex-direction: column;
  margin: 40px;
`
const StyledInputForm = styled(InputForm)`
  max-width: 300px;
`
const StyledWrapperButton = styled(Flex)`
  justify-content: center;
`
const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  height: 32px;
  border-radius: 25px;
  background: #385187;
  color: #ffffff;
  &:hover {
    background: #385187;
    cursor: pointer;
  }
`

const TextTitle = styled(Text)`
  width: 240px;
  font-size: 14px;
  color: black;
  font-weight: 700;
`
