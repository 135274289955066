import { useCallback, useEffect, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  deleteProduct,
  exportFile,
  getBrandOption,
  getProductList,
  getRetailerOption,
} from 'service/productService'
import { isAxiosError } from 'axios'
import {
  TBrandOption,
  TMetaType,
  TProductResponse,
  TRetailerOption,
} from './types'
import { OptionSelect } from './constants'

/***
 *
 * Returns: useProductListScreen
 */
export const useProductListScreen = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<TProductResponse[]>()
  const [meta, setMeta] = useState<TMetaType>()
  const [retailerOption, setRetailerOption] = useState<TRetailerOption[]>()
  const [brandOption, setBrandOption] = useState<TBrandOption[]>()
  let [searchParams, setSearchParams] = useSearchParams()
  const [selectProduct, setSelectProduct] = useState<number[]>([])
  const [isOpenModalExportData, setIsOpenModalExportData] =
    useState<boolean>(false)
  const [isButtonChosen, setIsButtonChosen] = useState<number>()
  const [downloadedItem, setDownloadedItem] = useState<number[]>([])
  const {
    isOpen: isOpenDelete,
    onOpen: onOpenDelete,
    onClose: onCloseDelete,
  } = useDisclosure()

  const page = searchParams.get('page')
  const keywordType = searchParams.get('keywordType')
  const word = searchParams.get('word')
  const [valueInput, setValueInput] = useState(word || '')

  const [valueSelect, setValueSelect] = useState<{
    label: string
    value?: number
  }>(
    OptionSelect.find(option => option.value === Number(keywordType)) || {
      label: 'Select ...',
      value: undefined,
    },
  )
  const toast = useToast()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        keywordType: keywordType ? Number(keywordType) : undefined,
        word: word ? word : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getProductList(params)
      setData(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [keywordType, word, page, toast])

  const fetchRetailer = useCallback(async () => {
    try {
      const options = await getRetailerOption()
      setRetailerOption(options)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
    }
  }, [toast])

  const fetchBrand = useCallback(async () => {
    setIsLoading(true)
    try {
      const options = await getBrandOption()
      setBrandOption(options)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [toast])

  useEffect(() => {
    fetchData()
  }, [fetchData, word, keywordType])

  useEffect(() => {
    fetchBrand()
    fetchRetailer()
  }, [fetchBrand, fetchRetailer])

  const handleExportToXLSX = useCallback(() => {
    setIsOpenModalExportData(true)
  }, [])

  const handleClickDownload = useCallback(
    async (page: number) => {
      setIsButtonChosen(page)
      try {
        const params = {
          page: page,
          limit: 5000,
        }
        const example = await exportFile(params)
        const link = document.createElement('a')
        link.href = example
        document.body.appendChild(link)
        link.click()
        setIsButtonChosen(0)
        setDownloadedItem(prevState => [...prevState, page])
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
        setIsButtonChosen(0)
      }
    },
    [toast],
  )

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        keywordType: String(valueSelect.value ?? ''),
        word: String(valueInput),
        page: String(currentPage),
      })
      setSelectProduct([])
    },
    [setSearchParams, valueInput, valueSelect.value],
  )
  /**
   * @returns handleReset
   */
  const handleReset = useCallback(() => {
    setValueInput('')
    setValueSelect({ label: 'Select ...', value: undefined })
    setSearchParams({
      keywordType: '',
      word: '',
      page: String(1),
    })
  }, [setSearchParams])

  const getDetailProduct = useCallback(
    (productId: number) => {
      navigate(`/product-detail/${productId}`)
    },
    [navigate],
  )

  const handleChangeSelect = useCallback(value => {
    setValueSelect(value)
  }, [])

  const handleChangeInput = useCallback(e => {
    setValueInput(e.target.value)
  }, [])

  const handleSearchProduct = useCallback(() => {
    setSearchParams({
      keywordType: String(valueSelect.value ?? ''),
      word: String(valueInput),
      page: String(1),
    })
  }, [setSearchParams, valueInput, valueSelect.value])

  const handleCheckbox = value => {
    setSelectProduct(value)
  }

  const handleOpenModalDelete = useCallback(() => {
    onOpenDelete()
  }, [onOpenDelete])

  const handleSaveDelete = useCallback(async () => {
    setIsLoading(true)
    try {
      if (!selectProduct) {
        return
      }
      await deleteProduct({ productIds: selectProduct })
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Delete Success',
        duration: 3000,
      })
      onCloseDelete()
      fetchData()
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [fetchData, onCloseDelete, selectProduct, toast])

  return {
    data,
    isLoading,
    handleExportToXLSX,
    handleReset,
    setData,
    navigate,
    retailerOption,
    brandOption,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    totalItem: meta?.total || 0,
    meta,
    handleNextPage,
    getDetailProduct,
    valueSelect,
    handleChangeSelect,
    valueInput,
    handleChangeInput,
    handleSearchProduct,
    handleCheckbox,
    selectProduct,
    isOpenDelete,
    onCloseDelete,
    handleOpenModalDelete,
    handleSaveDelete,
    isOpenModalExportData,
    setIsOpenModalExportData,
    handleClickDownload,
    isButtonChosen,
    downloadedItem,
  }
}

export type Props = ReturnType<typeof useProductListScreen>
