import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button, Textarea } from '@chakra-ui/react'
import { useDermTips } from './hook'

/**
 * @returns Component DermTips Edit Page
 */
export const DermTipsEditScreen = () => {
  const { handleSubmit, errors, handleChangeInput, dermTipsDetail, navigate } =
    useDermTips()
  return (
    <>
      <StyledContainer>
        <TitleDetail>
          <Text as={'span'} color={'#9B2C2C'} mr={'2px'}>
            *
          </Text>
          Derm Tip
        </TitleDetail>
        <Box w={'full'}>
          <Textarea
            border={
              errors?.content?.message
                ? '2px solid #E53E3E'
                : '1px solid #e4e7ff'
            }
            color={'#565AC0'}
            name={'content'}
            value={dermTipsDetail?.content ?? ''}
            onChange={e => handleChangeInput('content', e.target.value)}
          />
          <TextError>{errors?.content?.message}</TextError>
        </Box>
      </StyledContainer>

      <StyledWrapperButton>
        <StyledButtonBack onClick={() => navigate('/derm-tips')}>
          Cancel
        </StyledButtonBack>
        <StyledButtonSolid
          onClick={() => handleSubmit(Number(dermTipsDetail.id))}
        >
          Save
        </StyledButtonSolid>
      </StyledWrapperButton>
    </>
  )
}

const StyledContainer = styled(Flex)`
  gap: 20px;
  margin: 40px;
`

const StyledWrapperButton = styled(Flex)`
  justify-content: center;
  gap: 20px;
`
const StyledButtonSolid = styled(Button)`
  color: #ffffff;
  background: #3f6fba;
  &:hover {
    background: #3f6fba;
  }
`

const StyledButtonBack = styled(Button)`
  color: #3f6fba;
  background: transparent;
  border: 1px solid #3f6fba;
  &:hover {
    background: #fff;
  }
`
const TitleDetail = styled(Flex)`
  width: 240px;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  font-family: Georgia, Times New Roman, serif;
  height: 40px;
  align-items: center;
`
const TextError = styled(Text)`
  color: #ba1a1a;
  font-size: 12px;
  margin-top: 8px;
`
