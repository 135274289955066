import React from 'react'

import { AnswerAboutDetailDetailScreen } from './components/modules'

/**
 * @returns Component Quiz Results Detail Page
 */
export function QuizResultsDetailPage() {
  return <AnswerAboutDetailDetailScreen />
}
