import styled from '@emotion/styled'
import React from 'react'
import {
  Checkbox as ChakraCheckbox,
  Box,
  CheckboxProps,
} from '@chakra-ui/react'

type TCheckboxProps = {} & CheckboxProps

const StyledCheckbox = styled(ChakraCheckbox)`
  .chakra-checkbox__control {
    width: 18px;
    height: 18px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #6b70f0;

    &[data-checked]:hover {
      background: #6b70f0;
    }

    &[data-checked][data-hover] {
      background: #6b70f0;
    }

    &[data-checked]::before {
      width: 12px;
      height: 12px;
      background: #565ac0;
    }
    &[aria-checked='true'],
    &[data-checked],
    &[data-checked] {
      background: #6b70f0;
      color: #fff;
    }
  }
`

const BoxSelectWrapper = styled(Box)`
  .chakra-checkbox__label {
    color: #565ac0;
    font-size: 14px;
    font-family: Hiragino Sans;
    font-weight: 300;
    line-height: 100%;
    letter-spacing: 0.6px;
    margin-left: 4px;
    width: 100%;
  }
`

/**
 * @returns Component Checkbox
 */
export const Checkbox = (props: TCheckboxProps) => {
  const { ...checkboxProps } = props

  return (
    <BoxSelectWrapper>
      <StyledCheckbox {...checkboxProps} />
    </BoxSelectWrapper>
  )
}
