import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { TPromotionDetail } from '../../../../../../service/promotionService'

export type TPromotionTableProps = {
  data?: TPromotionDetail[]
  currentPage: number
  totalPage: number
  handleNextPage: (currentPage: number) => void
  onOpenDelete: (id: number) => void
  onCloseDelete: () => void
  handleDeleteBrand: () => void
  isOpenDelete: boolean
}

/**
 * usePromotionTable hook.
 */
export const usePromotionTable = (props: TPromotionTableProps) => {
  const navigate = useNavigate()

  const handleSelectItem = useCallback(
    (brandId: number) => {
      navigate(`/promoted-brand/${brandId}`)
    },
    [navigate],
  )

  const handleCreate = useCallback(() => {
    navigate('/promoted-brand/create')
  }, [navigate])

  return {
    ...props,
    handleSelectItem,
    handleCreate,
  }
}

export type Props = ReturnType<typeof usePromotionTable>
