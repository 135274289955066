import qs from 'qs'
import { axiosWithToken } from '../configureAxios'
import { TMetaType } from '../app/pages/ViewProductData/components/modules/types'
import { TUser } from '../app/pages/AdministratorsPage/List/components/modules/type'

export type TUserList = {
  data: TUser[]
  meta: TMetaType
}

export type UserListType = {
  status?: number
  page: number
  limit: number
  keywordType?: number
  word?: string
  /**
   * @param 1 is Admin
   * @param 2 is Common
   * */
  userType: 1 | 2
}
/**
 * @returns get Administrator
 * */
export const getUsers = async (params: UserListType): Promise<TUserList> => {
  const { data } = await axiosWithToken.get('/user-managements', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/***
 *
 * add creat Administrator
 */
export const createAdministrator = async payload => {
  const { data } = await axiosWithToken.post(`/user-managements`, payload)
  return data
}

/***
 *
 * updateStatus Administrator
 */
export const updateStatusUser = async (userId: number, payload) => {
  const { data } = await axiosWithToken.put(
    `/user-managements/${userId}`,
    payload,
  )
  return data
}

/**
 * @returns get detail getUserDetail
 * */
export const getUserDetail = async (id: string) => {
  const { data } = await axiosWithToken.get(`/user-managements/${id}`)
  return data
}

/***
 *
 * delete Administrator
 */
export const deleteAdministrator = async (id: number, payload) => {
  const { data } = await axiosWithToken.put(`/user-managements/${id}`, payload)
  return data
}

type UserSurveyType = {
  page: number
  limit: number
  startAt?: string
  endAt?: string
}

/**
 * @returns getUserSurvey
 * */
export const getUserSurvey = async (params: UserSurveyType) => {
  const { data } = await axiosWithToken.get('/user-survey/statistical', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}
