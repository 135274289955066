import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { useIngredientDictionary } from './hook'

/**
 * @returns Component IngredientDictionary Page
 */
export const IngredientDictionaryScreen = () => {
  const {
    dataIngredientDictionary,
    totalPage,
    currentPage,
    handleNextPage,
    isLoading,
    fileInputRef,
    handleFileSelect,
    handleUploadFile,
    fileUpload,
    isOpenSubmit,
    onCloseSubmit,
    handleOpenModalSubmit,
    handleSubmitFile,
    isLoadingSubmit,
  } = useIngredientDictionary()

  const columns = [
    {
      title: 'ID',
      render: record => <Box>{record?.id}</Box>,
    },

    {
      title: 'ingredient',
      render: record => <Box>{record?.ingredient}</Box>,
    },
  ]
  if (isLoading) {
    return (
      <Flex justifyContent={'center'} mt={10}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <Flex flexDirection={'column'} gap={10}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Box>
          <StyledTitle>Update new ingredient dictionary</StyledTitle>
          <Flex flexDirection={'row'} gap={5}>
            <StyledButtonUpload onClick={handleFileSelect}>
              Select File
            </StyledButtonUpload>
            <input
              ref={fileInputRef}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: 'none' }}
              type="file"
              value=""
              onChange={handleUploadFile}
            />
            <StyledButtonSubmit
              isDisabled={!fileUpload}
              onClick={handleOpenModalSubmit}
            >
              Submit
            </StyledButtonSubmit>
          </Flex>
          <StyledFileName as={'span'}>{fileUpload?.name}</StyledFileName>
        </Box>
      </Flex>
      {dataIngredientDictionary && !!dataIngredientDictionary.length && (
        <>
          <Box overflowX={'auto'} w={'100%'}>
            <TableCustom
              columns={columns}
              data={dataIngredientDictionary || []}
            />
          </Box>
          <Pagination
            page={currentPage}
            totalPage={totalPage}
            onChange={handleNextPage}
          />
        </>
      )}
      {dataIngredientDictionary?.length === 0 && !isLoading && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          No Data
        </Flex>
      )}
      <Modal isOpen={isOpenSubmit} onClose={onCloseSubmit}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Confirm Submit File New Ingredient Dictionary
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <WarningText>Warning</WarningText>: by uploading new data, the
            current data will be immediately lost. Are you sure you want to
            overwrite the existing data for this section?
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              isLoading={isLoadingSubmit}
              mr={3}
              onClick={handleSubmitFile}
            >
              Save
            </Button>
            <Button
              colorScheme={'black'}
              mr={3}
              variant="ghost"
              onClick={onCloseSubmit}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

const StyledButtonUpload = styled(Button)`
  border: 1px solid #d7d7d7;
  background: #f2f2f2;
  border-radius: 0;
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  justify-content: flex-start;
`

const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  height: 32px;
  border-radius: 25px;
  background: #000000;
  color: #ffffff;
  &:hover {
    background: #000000;
    cursor: pointer;
  }
`
const StyledTitle = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
`

const StyledFileName = styled(Text)`
  font-size: 20px;
  font-weight: bold;
  color: #0070c0;
`
const WarningText = styled('span')`
  color: orange;
  font-size: 20px;
  font-weight: 700;
`
