import React, { FC } from 'react'
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Box } from '@chakra-ui/layout'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { TableCustom } from '../../../../../components/elements/TableCustom'
import { Pagination } from '../../../../../components/modules/Pagination'
import { ButtonBase } from '../../../../../components/elements/ButtonBase'
import { Props, TPromotionTableProps, usePromotionTable } from './hooks'

const StyledWrapper = styled(Flex)`
  flex-direction: column;
  gap: 8px;
`

const StyledPromotionContent = styled(Flex)`
  flex: 1;
  gap: 4px;
  flex-direction: column;
`

const StyledWrapperItem = styled(Flex)`
  flex-direction: column;
  gap: 15px;
  margin: 40px;
`
const TextDes = styled(Text)`
  max-width: 980px;
  overflow: hidden;
  text-overflow: ellipsis;
`
/**
 * PromotionTable component page.
 * @return {JSX.Element} PromotionTable component.
 */
export const PromotionTableLayout: FC<Props> = ({
  data,
  handleSelectItem,
  currentPage,
  totalPage,
  handleNextPage,
  onOpenDelete,
  isOpenDelete,
  onCloseDelete,
  handleDeleteRetailer,
  handleCreate,
}) => {
  const columns = [
    {
      title: 'action',
      render: record => {
        return (
          <Box>
            <Text
              color={'#3F6FBA'}
              textDecoration={'underline'}
              onClick={() => handleSelectItem(record.id)}
            >
              Edit
            </Text>
          </Box>
        )
      },
    },
    {
      title: 'Name',
      render: record => {
        return (
          <Box _hover={{ textDecoration: 'underline' }}>{record?.name}</Box>
        )
      },
    },
    {
      title: 'Status',
      render: record => {
        return (
          <Tag
            colorScheme={record.promoted === 1 ? 'blue' : 'teal'}
            size={'lg'}
            variant="solid"
          >
            {record.promoted === 1 ? 'Promoted' : 'Not Promoted'}
          </Tag>
        )
      },
    },
    {
      title: 'description',
      render: record => {
        return (
          <TextDes _hover={{ textDecoration: 'underline' }}>
            {record.description}
          </TextDes>
        )
      },
    },
    {
      title: '',
      render: record => (
        <Button
          colorScheme="red"
          rightIcon={<RiDeleteBin6Line />}
          size={'sm'}
          variant="outline"
          onClick={() => onOpenDelete(record.id)}
        >
          Delete
        </Button>
      ),
    },
  ]
  return (
    <StyledWrapper>
      <ButtonBase
        hasAdd
        _hover={{ bg: '#385187' }}
        title={'CREATE RETAILER'}
        w={200}
        onClick={handleCreate}
      />
      <StyledPromotionContent>
        {data && !!data.length && (
          <StyledWrapperItem>
            <Box overflowX={'auto'} w={'100%'}>
              <TableCustom columns={columns} data={data || []} />
            </Box>
            <Box py={5}>
              <Pagination
                page={currentPage}
                totalPage={totalPage}
                onChange={handleNextPage}
              />
            </Box>
          </StyledWrapperItem>
        )}
        {data?.length === 0 && (
          <Flex alignItems={'center'} justifyContent={'center'}>
            No Data
          </Flex>
        )}
      </StyledPromotionContent>
      <Modal isOpen={isOpenDelete} onClose={onCloseDelete}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this Promoted Retailer?
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeleteRetailer}>
              Save
            </Button>
            <Button
              colorScheme={'black'}
              mr={3}
              variant="ghost"
              onClick={onCloseDelete}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </StyledWrapper>
  )
}

/**
 * PromotionTable component page.
 * @return {JSX.Element} PromotionTable component.
 */
export const PromotionRetailersTable = (props: TPromotionTableProps) => (
  <PromotionTableLayout {...usePromotionTable(props)} />
)
