import React, { useMemo } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { Box } from '@chakra-ui/layout'
import { getAccessToken } from '../../../service/storageServices'
import { listItemSideBar, TTreeItem } from '../../../routes'
import { MainLayout } from './MainLayout'

const getRouteFromPath = (routes: TTreeItem[], path: string) => {
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i]
    if (route.children) {
      const r = getRouteFromPath(route.children, path)
      if (Boolean(r)) {
        return r
      }
    }
    if (route.href === path) return route
  }
}

/**
 * @returns ProtectedRoute component check use is login
 */
export const ProtectedRoute = () => {
  const accessToken = getAccessToken()
  const location = useLocation()

  const title = useMemo(() => {
    const matchRoute = getRouteFromPath(listItemSideBar, location.pathname)
    return matchRoute?.title || matchRoute?.label || ''
  }, [location.pathname])

  if (!accessToken) {
    return <Navigate replace to="/login" />
  }
  return (
    <MainLayout title={title}>
      <Box p={10} w={'100%'}>
        <Outlet />
      </Box>
    </MainLayout>
  )
}
