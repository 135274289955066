import { useState, useCallback, useEffect, ChangeEvent } from 'react'
import * as yup from 'yup'
import { useParams } from 'hooks/useRouter'
import { useNavigate } from 'react-router-dom'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import {
  getQuizAnswer,
  getQuizQuestionDetail,
  updateQuizQuestion,
} from '../../../../../service/quizQuestion'
import { TQuizAnswer } from '../../../AnswerDetailPage/components/modules/type'

const schema = yup.object().shape({
  id: yup.number(),
  order: yup.number(),
  content: yup.string().required(),
  subContent1: yup.string().required(),
  subContent2: yup.string().required(),
  profileTitle: yup.string().required(),
  classify: yup.string().required(),
  group: yup.string().required(),
  type: yup.string().required(),
  createdAt: yup.string().required(),
})

export type TFormValue = yup.InferType<typeof schema>

type IErrors = {
  [key: string]: {
    message: string
  }
}

/***
 *
 * Returns: useQuizAnswers
 */
export const useQuizQuestionDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [questionDetail, setQuestionDetail] = useState<Partial<TFormValue>>({})
  const [dataAnswer, setDataAnswer] = useState<TQuizAnswer[]>()
  const [errors, setErrors] = useState<IErrors>({})
  const toast = useToast()
  const fetchDetailProduct = useCallback(async () => {
    if (!id) return
    setIsLoading(true)
    try {
      const detail = await getQuizQuestionDetail(Number(id))
      setQuestionDetail(detail)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [id])

  useEffect(() => {
    fetchDetailProduct().then()
  }, [fetchDetailProduct])

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setQuestionDetail(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )

  const validation = useCallback(() => {
    let newErrors: IErrors = {}
    try {
      schema.validateSync(setQuestionDetail, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
        }
        setErrors(newErrors)
        return false
      }
    }
    return true
  }, [setQuestionDetail])

  const handleCancel = useCallback(() => navigate(-1), [navigate])

  const handleSubmit = useCallback(async () => {
    if (validation()) {
      setIsLoading(true)
      try {
        const payload = {
          content: questionDetail.content,
          subContent1: questionDetail.subContent1,
          subContent2: questionDetail.subContent2,
        }
        await updateQuizQuestion(Number(id), payload)
        toast({
          position: 'top-right',
          status: 'success',
          title: 'Change Success',
          duration: 1000,
        })
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
  }, [
    id,
    questionDetail.content,
    questionDetail.subContent1,
    questionDetail.subContent2,
    toast,
    validation,
  ])

  const fetchDataAnswer = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await getQuizAnswer(Number(id))
      setDataAnswer(response)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchDataAnswer().then()
  }, [fetchDataAnswer])

  const getDetailQuizAnswer = useCallback(
    (questionId: number) => {
      navigate(`/quiz-answer/${questionId}`)
    },
    [navigate],
  )
  return {
    errors,
    isLoading,
    handleCancel,
    handleSubmit,
    questionDetail,
    handleChangeInput,
    dataAnswer,
    getDetailQuizAnswer,
  }
}

export type Props = ReturnType<typeof useQuizQuestionDetail>
