import React, { memo, useCallback, JSX, useState, useMemo } from 'react'
import {
  Accordion,
  AccordionItem,
  Button,
  AccordionButton,
  AccordionPanel,
  Link,
  Text,
  Flex,
  AccordionIcon,
} from '@chakra-ui/react'
import { Box } from '@chakra-ui/layout'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  removeAccessToken,
  removeRefreshToken,
} from '../../../service/storageServices'
import { listItemSideBar, TTreeItem } from '../../../routes'
import { LayoutSideBar } from './Layout'

/**
 *
 * @returns Component Side Bar
 */
export const SideBar = memo(() => {
  return (
    <Box as={LayoutSideBar} w={'300px'}>
      <Tree />
    </Box>
  )
})

export interface ITreeItemProps {
  data: TTreeItem
  idActive?: number
  setIdActive: (id: number) => void
}

const isActiveRoute = (item, idActive) => {
  if (item.children) {
    return item.children.some(child => isActiveRoute(child, idActive))
  }
  return idActive === item.id && idActive !== 1
}
const findIndex = (children, path) =>
  children.findIndex(child => isActiveRoute(child, path))

/**
 *
 * @returns Component Side Bar
 */
export const Tree = memo((): JSX.Element => {
  const location = useLocation()
  const [idActive, setIdActive] = useState(-1)
  return (
    <Box
      bg={'rgba(0,0,0,0.1)'}
      borderRightColor={'#ccc'}
      borderRightWidth={'thin'}
      h={'100%'}
      overflowY={'scroll'}
      w={'100%'}
    >
      <Flex justifyContent={'center'} py={'44px'}>
        <Text
          color={'#55587E'}
          fontFamily={'YuGothic'}
          fontSize={'18px'}
          fontWeight={'bold'}
          lineHeight={'100%'}
        >
          ALL SKIN
        </Text>
      </Flex>
      <Accordion
        allowMultiple
        allowToggle
        defaultIndex={findIndex(listItemSideBar, location.pathname)}
        padding={'0 10px'}
      >
        {listItemSideBar.map(item => (
          <TreeItem
            key={item.id}
            data={item}
            idActive={idActive}
            setIdActive={setIdActive}
          />
        ))}
      </Accordion>
    </Box>
  )
})

/**
 *
 * @returns Component Side Bar
 */
export const TreeItem: React.FC<ITreeItemProps> = memo(
  ({ data, idActive, setIdActive }): JSX.Element => {
    const { children, label, href, onClick, icon, isTargetBlank, isLogout } =
      data
    const navigate = useNavigate()
    const location = useLocation()

    const handleLogout = useCallback(() => {
      removeAccessToken()
      removeRefreshToken()
      navigate('/login')
    }, [navigate])
    const isActive = useMemo(() => {
      return isActiveRoute(data, idActive)
    }, [data, idActive])

    if (children?.length) {
      return (
        <AccordionItem border={'none'} w={'100%'}>
          <AccordionButton
            _hover={{ bgColor: '#fff' }}
            as={Button}
            borderRadius={'12px'}
            justifyContent="flex-start"
            leftIcon={<Box as={icon} fill={'#787bac'} />}
            minH={'48px'}
            variant={'ghost'}
            w={'100%'}
          >
            <Box flex="1" textAlign="left">
              <Text
                color={'#787bac'}
                fontFamily={'YuGothic'}
                fontSize={'14px'}
                fontWeight={'bold'}
                lineHeight={'150%'}
              >
                {label}
              </Text>
            </Box>
            <AccordionIcon color={'#787bac'} />
          </AccordionButton>
          <AccordionPanel p={0}>
            <Accordion
              allowMultiple
              allowToggle
              defaultIndex={findIndex(children, location.pathname)}
              padding={'0 18px'}
            >
              {children.map((item, index) => {
                return (
                  <TreeItem
                    key={index}
                    data={item}
                    idActive={idActive}
                    setIdActive={setIdActive}
                  />
                )
              })}
            </Accordion>
          </AccordionPanel>
        </AccordionItem>
      )
    }

    if (href) {
      if (isTargetBlank) {
        return (
          <Link
            _hover={{ textDecoration: 'none' }}
            color={'inherit'}
            href={href}
            target={'_blank'}
            w={'100%'}
          >
            <AccordionItem
              _hover={{ bgColor: '#fff' }}
              border={'none'}
              borderRadius={'12px'}
              justifyContent="flex-start"
              minH={'48px'}
              w={'full'}
            >
              <AccordionButton>
                <Text
                  color={isActive ? '#207aba' : '#787bac'}
                  fontFamily={'YuGothic'}
                  fontSize={'14px'}
                  fontWeight={'bold'}
                  lineHeight={'150%'}
                >
                  {label}
                </Text>
              </AccordionButton>
            </AccordionItem>
          </Link>
        )
      }

      return (
        <Box onClick={isLogout ? handleLogout : () => navigate(href)}>
          <AccordionItem
            _hover={{ bgColor: '#fff' }}
            bg={isActive ? '#fff' : ''}
            border={'none'}
            borderRadius={'12px'}
            justifyContent="flex-start"
            minH={'48px'}
            pl={'0px'}
            w={'full'}
            onClick={() => setIdActive(data.id)}
          >
            <AccordionButton borderRadius={'12px'} h={'48px'}>
              <Text
                color={'#787bac'}
                fontFamily={'YuGothic'}
                fontSize={'14px'}
                fontWeight={'bold'}
                lineHeight={'150%'}
              >
                {label}
              </Text>
            </AccordionButton>
          </AccordionItem>
        </Box>
      )
    }

    return (
      <AccordionItem
        _hover={{ bgColor: 'blue.100' }}
        border={'none'}
        borderRadius={'12px'}
        justifyContent="flex-start"
        minH={'48px'}
        w={'full'}
        onClick={onClick}
      >
        <AccordionButton borderRadius={'12px'} h={'48px'}>
          <Text
            color={isActive ? '#207aba' : '#383838'}
            fontFamily={'YuGothic'}
            fontSize={'14px'}
            fontWeight={'bold'}
            lineHeight={'150%'}
          >
            {label}
          </Text>
        </AccordionButton>
      </AccordionItem>
    )
  },
  (prev, current) =>
    prev.data.id === current.data.id && prev.idActive === current.idActive,
)
