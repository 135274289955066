import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useSearchParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import { getProductRequest } from '../../../../service/productService'
import { TProductRequestResponse } from '../../ViewProductData/components/modules/types'
import { TMetaType } from '../../ViewRulesPage/components/modules/type'
import { useNavigate } from '../../../../hooks/useRouter'

/**
 * useProductRequestCompleteScreen hook.
 */
export const useProductRequestCompleteScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [dataComplete, setDataComplete] = useState<TProductRequestResponse[]>()
  const [meta, setMeta] = useState<TMetaType>()
  let [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const toast = useToast()
  const navigate = useNavigate()

  const fetchDataComplete = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        page: page ? Number(page) : 1,
        limit: 15,
        type: 1,
      }
      const response = await getProductRequest(params)
      setDataComplete(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, toast])

  useEffect(() => {
    fetchDataComplete().then()
  }, [fetchDataComplete])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )

  const handleNavigateDetailProduct = useCallback((id: number) => {
    navigate(`/product-detail/${id}`)
  }, [])

  return {
    isLoading,
    handleNextPage,
    dataComplete,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNavigateDetailProduct,
  }
}
export type Props = ReturnType<typeof useProductRequestCompleteScreen>
