import React from 'react'
import { Flex, Text } from '@chakra-ui/layout'
import { Spinner, Box, Switch, Button } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Props, usePromotedBrandDetailScreen } from '../../hooks'
import { InputForm } from '../../../../components/modules/InputForm'
import { TextAreaBase } from '../../../../components/elements/TextAreaBase'

const TextPromotion = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 700;
`
const StyledWrapperButton = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 8px;
`

const StyledButtonOutline = styled(Button)`
  color: #3f6fba;
  background: transparent;
  border: 1px solid #3f6fba;
  &:hover {
    background: #fff;
  }
`

const StyledButtonSolid = styled(Button)`
  color: #ffffff;
  background: #3f6fba;
  &:hover {
    background: #3f6fba;
  }
`

/**
 * PromotedScreenLayout component page.
 * @return {JSX.Element} PromotedScreenLayout component.
 */
export const PromotedScreenLayout = (props: Props) => {
  const {
    isLoading,
    brandDetail,
    handleChangeForm,
    errors,
    handleTogglePromotion,
    handleSavePromotedBrand,
    handleBack,
  } = props
  if (isLoading) {
    return (
      <Flex justifyContent={'center'} mt={10}>
        <Spinner />
      </Flex>
    )
  }
  return (
    <Flex flexDirection={'column'} gap={6}>
      <Box maxW={400}>
        <InputForm
          isRequired
          error={errors?.name?.message}
          isInvalid={!!errors?.name?.message}
          label={'Name'}
          name={'name'}
          value={brandDetail?.name}
          onChange={handleChangeForm}
        />
      </Box>
      <TextAreaBase
        isRequired
        error={errors?.description?.message}
        isInvalid={!!errors?.description?.message}
        label={'Description'}
        name={'description'}
        placeholder="Enter description ..."
        value={brandDetail?.description ?? ''}
        onChange={handleChangeForm}
      />
      <Flex alignItems={'center'} gap={'6px'}>
        <TextPromotion>Promotion</TextPromotion>
        <Switch
          isChecked={brandDetail.promoted}
          size="md"
          onChange={handleTogglePromotion}
        />
      </Flex>
      <Flex alignItems={'center'} justifyContent={'end'}>
        <StyledWrapperButton>
          <StyledButtonOutline onClick={handleBack}>Back</StyledButtonOutline>
          <StyledButtonSolid onClick={handleSavePromotedBrand}>
            Save
          </StyledButtonSolid>
        </StyledWrapperButton>
      </Flex>
    </Flex>
  )
}

/**
 * PromotedScreen component page.
 * @return {JSX.Element} PromotedScreen component.
 */
export const PromotedBrandDetailScreen = () => (
  <PromotedScreenLayout {...usePromotedBrandDetailScreen()} />
)
