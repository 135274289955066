import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import styled from '@emotion/styled'
import { Spinner, Text } from '@chakra-ui/react'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { useAnswerAbout } from './hook'

/**
 * @returns Component Quiz Answer About Screen Page
 */
export const QuizAnswerAbout = () => {
  const {
    isLoading,
    dataAnswer,
    getDetailQuizResults,
    currentPage,
    totalPage,
    handleNextPage,
  } = useAnswerAbout()

  if (isLoading) {
    return (
      <Flex justifyContent={'center'} mt={10}>
        <Spinner />
      </Flex>
    )
  }

  const columns = [
    {
      title: 'action',
      render: record => {
        return (
          <Box>
            <Text
              color={'#3F6FBA'}
              textDecoration={'underline'}
              onClick={() => getDetailQuizResults(record.id)}
            >
              Edit
            </Text>
          </Box>
        )
      },
    },
    {
      title: 'Title',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.title}</Box>
      ),
    },
    {
      title: 'Video Title',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.videoTitle}</Box>
      ),
    },
    {
      title: 'Content',
      render: record => (
        <StyledWrapperContent _hover={{ textDecoration: 'underline' }}>
          {record?.content}
        </StyledWrapperContent>
      ),
    },
    {
      title: 'Profile Type',
      render: record => (
        <StyledWrapperContent _hover={{ textDecoration: 'underline' }}>
          {record?.profileType}
        </StyledWrapperContent>
      ),
    },
  ]

  return (
    <Flex flexDirection={'column'} gap={10}>
      <Box overflowX={'auto'} w={'100%'}>
        <TableCustom
          columns={columns}
          data={dataAnswer || []}
          onSelect={item => getDetailQuizResults(item.id)}
        />
      </Box>
      <Box py={5}>
        <Pagination
          page={currentPage}
          totalPage={totalPage}
          onChange={handleNextPage}
        />
      </Box>
    </Flex>
  )
}

const StyledWrapperContent = styled(Box)`
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
