import React from 'react'
import { TextareaProps, Textarea, Flex } from '@chakra-ui/react'
import { Label, LabelProps } from './Label'
import { ErrorText } from './ErrorText'

type Props = TextareaProps & {
  error?: string
  label?: string | React.ReactNode
  id?: string
  direction?: 'horizontal' | 'vertical'
  labelProps?: Omit<LabelProps, 'labelWidth' | 'label' | 'id' | 'isRequired'>
}
export const TextAreaBase = (props: Props) => {
  const { id, error, label, labelProps, isRequired, ...textAreaProps } = props
  return (
    <Flex flexDirection={'column'} gap={2} w={'full'}>
      <Label id={id} isRequired={isRequired} label={label} {...labelProps} />
      <Textarea
        color={'primary_color_main'}
        fontFamily={'Hiragino Sans'}
        fontSize={'14px'}
        fontWeight={400}
        {...textAreaProps}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </Flex>
  )
}
