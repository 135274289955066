import { useState, useCallback, useEffect, useRef, ChangeEvent } from 'react'
import * as yup from 'yup'
import { useParams } from 'hooks/useRouter'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import {
  getAnswerAboutDetail,
  updateAnswerAboutDetail,
  uploadImage,
  uploadVideo,
} from '../../../../../service/quizQuestion'
import { Errors } from '../../../../../types'

const schema = yup.object().shape({
  id: yup.number(),
  content: yup.string(),
  title: yup.string(),
  videoPreviewUrl: yup.string(),
  profileType: yup.string().notRequired(),
  videoSubTitle: yup.string().nullable(),
  videoTitle: yup.string().nullable(),
  detailImgUrl: yup.string().nullable(),
  detailImgPreviewUrl: yup.string().nullable(),
})

export type TFormValue = yup.InferType<typeof schema>

/***
 *
 * Returns: useAnswerAboutDetail
 */
export const useAnswerAboutDetail = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [answerAboutDetail, setAnswerAboutDetail] = useState<
    Partial<TFormValue>
  >({})
  const [editorHtml, setEditorHtml] = useState('')
  const [fileUpload, setFileUpload] = useState<File>()
  const [errors, setErrors] = useState<Errors>()
  const [imageUpload, setImageUpload] = useState<{
    detailImgUrl: string | null
    detailImgPreviewUrl: string | null
  }>()
  const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false)

  const toast = useToast()
  const navigate = useNavigate()

  const fileInputRefVideo = useRef<HTMLInputElement>(null)
  const fileInputRefImage = useRef<HTMLInputElement>(null)

  /**
   * @returns function that handle click upload file
   */
  const handleFileSelectVideo = () => {
    if (fileInputRefVideo.current) {
      fileInputRefVideo.current.click()
    }
  }

  /**
   * @returns function that handle click upload file
   */
  const handleFileSelectImage = () => {
    if (fileInputRefImage.current) {
      fileInputRefImage.current.click()
    }
  }

  /**
   * @returns function handle upload video
   */
  const handleUploadFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) setFileUpload(file)
    },
    [],
  )
  const handleSubmitFile = useCallback(async () => {
    try {
      await uploadVideo(fileUpload)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    }
  }, [fileUpload, toast])

  const fetchAnswerAboutDetail = useCallback(async () => {
    if (!id) return
    setIsLoading(true)
    try {
      const detail = await getAnswerAboutDetail(Number(id))
      setAnswerAboutDetail(detail)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [id, toast])

  useEffect(() => {
    if (answerAboutDetail.content) {
      setEditorHtml(answerAboutDetail.content)
    }
  }, [answerAboutDetail.content])

  useEffect(() => {
    fetchAnswerAboutDetail().then()
  }, [fetchAnswerAboutDetail])

  const handleEditorChange = html => {
    setEditorHtml(html)
  }

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setAnswerAboutDetail(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )

  /**
   * @returns function that handle validates form
   */
  const validation = useCallback((): boolean => {
    setErrors(undefined)
    try {
      schema.validateSync(answerAboutDetail, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          const newErrors: Errors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }
    return true
  }, [answerAboutDetail])

  const onSaveContent = useCallback(async () => {
    if (validation()) {
      setIsLoading(true)
      try {
        const payload = {
          videoTitle: answerAboutDetail.videoTitle,
          videoSubTitle: answerAboutDetail.videoSubTitle,
          content: editorHtml,
          detailImgPreviewUrl: imageUpload?.detailImgPreviewUrl
            ? imageUpload?.detailImgPreviewUrl
            : answerAboutDetail.detailImgPreviewUrl,
          detailImgUrl: imageUpload?.detailImgUrl
            ? imageUpload?.detailImgUrl
            : answerAboutDetail.detailImgUrl,
        }
        await updateAnswerAboutDetail(Number(id), payload)
        toast({
          position: 'top-right',
          status: 'success',
          title: 'Change Success',
          duration: 1000,
        })
        if (fileUpload) {
          await handleSubmitFile()
        }
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      } finally {
        setIsLoading(false)
      }
    }
  }, [
    answerAboutDetail.videoSubTitle,
    answerAboutDetail.videoTitle,
    editorHtml,
    fileUpload,
    handleSubmitFile,
    id,
    imageUpload?.detailImgPreviewUrl,
    imageUpload?.detailImgUrl,
    toast,
    validation,
  ])

  const handleCancel = useCallback(() => navigate(-1), [navigate])

  const handleUploadImage = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setIsLoadingButton(true)
      const file = event.target.files?.[0]
      if (file) {
        const validFileTypes = [
          'image/jpg',
          'image/jpeg',
          'image/png',
          'image/heif',
          'image/heic',
        ]
        const typeFile = validFileTypes.includes(file.type)
        if (typeFile) {
          try {
            const imageFile = await uploadImage(file)
            setImageUpload(imageFile)
          } catch (e) {
            if (isAxiosError(e)) {
              const message = e?.response?.data.message
              toast({
                position: 'top-right',
                status: 'error',
                title: message,
                duration: 3000,
              })
            }
          } finally {
            setIsLoadingButton(false)
          }
        } else {
          toast({
            position: 'top-right',
            status: 'error',
            title: 'Invalid file type. Please upload a valid image file.',
            duration: 3000,
          })
          setIsLoadingButton(false)
        }
      }
    },
    [toast],
  )

  const handleDeleteImage = useCallback(() => {
    setAnswerAboutDetail({
      detailImgUrl: null,
      detailImgPreviewUrl: null,
    })
  }, [])

  return {
    isLoading,
    answerAboutDetail,
    handleEditorChange,
    onSaveContent,
    editorHtml,
    handleCancel,
    handleFileSelectVideo,
    fileInputRefVideo,
    handleUploadFile,
    fileUpload,
    handleChangeInput,
    errors,
    handleUploadImage,
    isLoadingButton,
    imageUpload,
    fileInputRefImage,
    handleFileSelectImage,
    handleDeleteImage,
  }
}

export type Props = ReturnType<typeof useAnswerAboutDetail>
