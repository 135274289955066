import {
  axiosWithoutToken as _axiosWithoutToken,
  axiosWithToken as _axiosWithToken,
  axiosWithFile as _axiosWithFile,
} from './axios'
import { applyAppTokenRefreshInterceptor } from './applyRefreshTokenInterceptor'

export const axiosWithoutToken = _axiosWithoutToken
export const axiosWithToken = _axiosWithToken
export const axiosWithFile = _axiosWithFile
applyAppTokenRefreshInterceptor(axiosWithToken)
applyAppTokenRefreshInterceptor(axiosWithFile)
