import React from 'react'
import {
  Button,
  Spinner,
  Flex,
  Text,
  Image,
  Textarea,
  Tooltip,
  IconButton,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalHeader,
  // ModalCloseButton,
  // ModalBody,
  // ModalFooter,
  // Switch,
} from '@chakra-ui/react'
import { InputDateTime } from 'app/components/elements/Input/InputDateTime'
import styled from '@emotion/styled'
import { Box } from '@chakra-ui/layout'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'
import { InputForm } from '../../../../components/modules/InputForm'
import { SelectForm } from '../../../../components/modules/SelectForm'
import { ModalCreateRetailer } from '../../../../components/modules/ModalCreateRetailer'
import { Props, useProductDetail } from './hooks'
import { OptionSelect } from './constants'

const StyledWrapperButton = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 8px;
`
const StyledImage = styled(Image)`
  width: 300px;
  height: 150px;
  border-radius: 4px;
`

const StyledButtonOutline = styled(Button)`
  color: #3f6fba;
  background: transparent;
  border: 1px solid #3f6fba;
  &:hover {
    background: #fff;
  }
`

const StyledButtonSolid = styled(Button)`
  color: #ffffff;
  background: #3f6fba;
  &:hover {
    background: #3f6fba;
  }
`
const TitleDetail = styled(Flex)`
  width: 240px;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  font-family: Georgia, Times New Roman, serif;
  height: 30px;
`
const TitleContent = styled(Text)`
  color: #000000;
  font-size: 16px;
`
const StyledSelectForm = styled(SelectForm)`
  max-width: 300px;
`

const StyledWrapperContent = styled(Flex)`
  flex-direction: column;
  gap: 12px;
`
const StyledInputForm = styled(InputForm)`
  max-width: 300px;
`

/**
 * @returns Component
 */
const Component = (props: Props) => {
  const {
    errors,
    isLoading,
    handleCancel,
    handleSubmit,
    productDetail,
    handleChangeDate,
    handleChangeInput,
    catSelection1,
    handleOnChangeSelectCategory1,
    handleOnChangeSelectCategory2,
    handleOnChangeSelectCategory3,
    catSelection2,
    catSelection3,
    retailerHasPromote,
    brandHasPromote,
    handleOnChangeSelect,
    isLoadingButton,
    isShowInput,
    setIsShowInput,
    handleChangeInputRetailes,
    defaultBox,
    handleAddRetailer,
    handleRemoveBox,
    retailerOption,
    fetchRetailer,
  } = props

  const labels = {
    originurl: 'Origin Url',
    price: 'Price',
  }
  if (isLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <>
      <StyledWrapperContent>
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.product?.message}
          isInvalid={!!errors?.product?.message}
          label={'Product Name'}
          labelWidth={60}
          name={'product'}
          value={productDetail?.product}
          onChange={handleChangeInput}
        />

        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.brand?.message}
          isInvalid={!!errors?.brand?.message}
          label={'Brand'}
          labelWidth={60}
          name={'brand'}
          value={productDetail?.brand}
          onChange={handleChangeInput}
        />
        <Flex>
          <StyledSelectForm
            isRequired
            direction={'horizontal'}
            error={errors?.cat1?.message}
            label={'Category 1'}
            labelWidth={60}
            name={'cat1'}
            options={catSelection1}
            placeholder={''}
            value={catSelection1?.find(it => it.id === productDetail?.cat1?.id)}
            onChange={option => handleOnChangeSelectCategory1('cat1', option)}
          />
        </Flex>
        <Flex>
          <StyledSelectForm
            isRequired
            direction={'horizontal'}
            error={errors?.cat2?.message}
            isDisabled={productDetail?.cat1?.label === ''}
            label={'Category 2'}
            labelWidth={60}
            name={'cat2'}
            options={catSelection2}
            placeholder={''}
            value={
              catSelection2?.find(it => it.id === productDetail?.cat2?.id) || {}
            }
            onChange={option => handleOnChangeSelectCategory2('cat2', option)}
          />
        </Flex>
        <Flex>
          <StyledSelectForm
            isRequired
            direction={'horizontal'}
            error={errors?.cat3?.message}
            isDisabled={productDetail?.cat2?.label === ''}
            label={'Category 3'}
            labelWidth={60}
            name={'cat3'}
            options={catSelection3}
            placeholder={''}
            value={
              catSelection3?.find(it => it.id === productDetail?.cat3?.id) || {}
            }
            onChange={option => handleOnChangeSelectCategory3('cat3', option)}
          />
        </Flex>
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.ingredients?.message}
          isInvalid={!!errors?.ingredients?.message}
          label={'Ingredients'}
          labelWidth={60}
          name={'ingredients'}
          value={productDetail?.ingredients}
          onChange={handleChangeInput}
        />
        <StyledInputForm
          direction={'horizontal'}
          label={'Description'}
          labelWidth={60}
          name={'description'}
          value={productDetail?.description ?? undefined}
          onChange={handleChangeInput}
        />
        <StyledInputForm
          direction={'horizontal'}
          error={errors?.upc?.message}
          isInvalid={!!errors?.upc?.message}
          label={'UPC'}
          labelWidth={60}
          name={'upc'}
          value={productDetail?.upc ?? undefined}
          onChange={handleChangeInput}
        />
        <StyledInputForm
          direction={'horizontal'}
          error={errors?.stars?.message}
          isInvalid={!!errors?.stars?.message}
          label={'Stars'}
          labelWidth={60}
          name={'stars'}
          value={productDetail?.stars ?? undefined}
          onChange={handleChangeInput}
        />
        <StyledInputForm
          direction={'horizontal'}
          error={errors?.reviewCount?.message}
          isInvalid={!!errors?.reviewCount?.message}
          label={'Reviews'}
          labelWidth={60}
          name={'reviewCount'}
          value={productDetail?.reviewCount ?? undefined}
          onChange={handleChangeInput}
        />
        <Flex alignItems={'center'} gap={2}>
          <TitleDetail>Upload Notes</TitleDetail>
          <Textarea
            border={'1px solid #e4e7ff'}
            color={'#565AC0'}
            minH={'40px'}
            name={'joblink'}
            value={productDetail?.joblink ?? undefined}
            w={'300px'}
            onChange={handleChangeInput}
          />
        </Flex>
        <InputDateTime
          isEditProduct
          isRequired
          readOnly
          direction={'horizontal'}
          errorMessage={errors?.createdAt?.message}
          label={'Entry Date'}
          labelWidth={60}
          value={
            productDetail?.createdAt
              ? new Date(productDetail.createdAt)
              : undefined
          }
          onChange={date => {
            handleChangeDate('createdAt', date)
          }}
        />
        <Flex gap={'8px'}>
          <TitleDetail>Retailer Promoted</TitleDetail>
          <TitleContent>
            {retailerHasPromote ? 'Promoted' : 'Not Promoted'}
          </TitleContent>
        </Flex>
        <Flex gap={'8px'}>
          <TitleDetail>Brand Promoted</TitleDetail>
          <TitleContent>
            {brandHasPromote ? 'Promoted' : 'Not Promoted'}
          </TitleContent>
        </Flex>
        <Flex>
          <StyledSelectForm
            direction={'horizontal'}
            label={'Spf'}
            labelWidth={60}
            name={'spf'}
            options={OptionSelect}
            placeholder={''}
            value={
              productDetail.spf?.value === null
                ? OptionSelect[1]
                : OptionSelect.find(it => it.value === productDetail.spf?.value)
            }
            onChange={option => handleOnChangeSelect('spf', option)}
          />
        </Flex>

        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.imageurl?.message}
          isInvalid={!!errors?.imageurl?.message}
          label={'Image Url'}
          labelWidth={60}
          name={'imageurl'}
          value={productDetail?.imageurl}
          onChange={handleChangeInput}
        />
        {productDetail?.product_img_url && (
          <StyledImage
            alt="image product"
            objectFit="contain"
            src={productDetail?.product_img_url}
          />
        )}
        <Flex
          flexDirection={'column'}
          gap={'12px'}
          justifyContent={'flex-start'}
          pt={'2px'}
          w={'100%'}
        >
          {productDetail?.originurls?.map((e, i) => (
            <Flex key={i} flexDirection={'column'} gap={'12px'} pt={'2px'}>
              <StyledSelectForm
                isRequired
                direction={'horizontal'}
                error={errors?.[`retailers[${i}]`]?.message}
                label={'Retailer'}
                labelWidth={60}
                name={'retailer'}
                options={retailerOption}
                value={productDetail?.retailers?.[i]}
                onChange={option =>
                  handleOnChangeSelect('retailers', option, i)
                }
              />
              {Object.keys(e).map(label => {
                return (
                  <StyledInputForm
                    key={label}
                    isRequired
                    direction={'horizontal'}
                    error={errors?.[`originurls[${i}].${label}`]?.message}
                    isInvalid={!!errors?.[`originurls[${i}].${label}`]?.message}
                    label={labels[label]}
                    labelWidth={60}
                    type={['price'].includes(label) ? 'number' : 'text'}
                    value={e[label] ?? ''}
                    onChange={event =>
                      handleChangeInputRetailes(i, {
                        ...e,
                        [label]: ['price'].includes(label)
                          ? event.target.value === ''
                            ? ''
                            : Number(event.target.value)
                          : event.target.value,
                      } as unknown as typeof defaultBox)
                    }
                  />
                )
              })}
              <Box>
                <Tooltip
                  bg={'#000000'}
                  label={`Add Retailes`}
                  placement="top-start"
                >
                  <IconButton
                    aria-label="button flexible"
                    icon={isShowInput ? <AddIcon /> : <MinusIcon />}
                    onClick={
                      isShowInput
                        ? handleAddRetailer
                        : () => setIsShowInput(true)
                    }
                  />
                </Tooltip>
              </Box>
              {i !== 0 && (
                <Box>
                  <Tooltip
                    bg={'#000000'}
                    label={`Remove Retailes`}
                    placement="top-start"
                  >
                    <IconButton
                      aria-label="button flexible"
                      icon={<MinusIcon />}
                      onClick={() => handleRemoveBox(i)}
                    />
                  </Tooltip>
                </Box>
              )}
            </Flex>
          ))}
        </Flex>
      </StyledWrapperContent>
      <StyledWrapperButton>
        <StyledButtonOutline onClick={handleCancel}>Cancel</StyledButtonOutline>
        <StyledButtonSolid
          isLoading={isLoadingButton}
          onClick={() => handleSubmit(Number(productDetail.id))}
        >
          Save
        </StyledButtonSolid>
      </StyledWrapperButton>
      <ModalCreateRetailer fetchRetailer={fetchRetailer} />
    </>
  )
}

/***
 *
 * Returns: ProductDetail Screen
 */
export const ProductDetailScreen = () => {
  return <Component {...useProductDetail()} />
}
