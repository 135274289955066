import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button, Spinner, Tag, TagLabel } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import styled from 'styled-components'
import moment from 'moment/moment'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { InputDateTime } from '../../../../components/elements/Input/InputDateTime'
import { Select } from '../../../../components/elements/Select'
import { ModalExportData } from '../../../../components/modules/ModalExportData'
import { useProductExternalLink } from './hook'
import { OptionSelect } from './constants'

const TextError = styled(Text)`
  color: #ba1a1a;
`

const StyledWrapperButton = styled(Flex)`
  flex-direction: column;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

/**
 * @returns Component ProductExternalLink Screen Page
 */
export const ProductExternalLinkScreen = () => {
  const {
    type,
    dataProductView,
    currentPage,
    totalPage,
    handleNextPage,
    handleChangeStartDate,
    valueStartAt,
    handleChangeEndDate,
    valueEndAt,
    handleSearch,
    handleReset,
    isLoading,
    textValidate,
    valueSelect,
    handleChangeSelect,
    navigate,
    handleExportToXLSX,
    isOpenModalExportData,
    downloadedItem,
    isButtonChosen,
    totalItem,
    handleClickDownload,
    setIsOpenModalExportData,
  } = useProductExternalLink()
  const statisticsClicksField = type => {
    switch (type) {
      case OptionSelect[1].value:
        return [
          {
            title: 'User ID',
            render: record => <Box>{record?.id}</Box>,
          },
          {
            title: 'Name',
            render: record => (
              <Box>{`${record?.firstName} ${record?.lastName}`}</Box>
            ),
          },
          {
            title: 'email',
            render: record => <Box>{record?.email}</Box>,
          },
          {
            title: 'region',
            render: record => <Box>{record?.region}</Box>,
          },
          {
            title: 'products',
            render: record => (
              <Box _hover={{ cursor: 'pointer' }}>
                {`${record.numberOfProduct} number of products viewed `}
              </Box>
            ),
          },
          {
            title: 'Actions',
            render: record => (
              <StyledWrapperButton>
                <Tag
                  colorScheme="blue"
                  variant="outline"
                  onClick={() =>
                    navigate(
                      `/product-external-link/product-list/${record?.id}`,
                      { state: record },
                    )
                  }
                >
                  <TagLabel mr={1}>Product List</TagLabel>
                </Tag>
                <Tag
                  colorScheme="blue"
                  variant="outline"
                  onClick={() => {
                    navigate(
                      `/product-external-link/all-view-product/${record?.id}`,
                      { state: record },
                    )
                  }}
                >
                  <TagLabel mr={1}>All Views List</TagLabel>
                </Tag>
              </StyledWrapperButton>
            ),
          },
        ]

      case OptionSelect[2].value:
        return [
          {
            title: 'User ID',
            render: record => <Box>{record?.id}</Box>,
          },
          {
            title: 'Name',
            render: record => (
              <Box>{`${record?.firstName} ${record?.lastName}`}</Box>
            ),
          },
          {
            title: 'email',
            render: record => <Box>{record?.email}</Box>,
          },
          {
            title: 'region',
            render: record => <Box>{record?.region}</Box>,
          },
          {
            title: 'videos',
            render: record => <Box>{`${record.numberOfWatch} watches`}</Box>,
          },
        ]
      case OptionSelect[3].value:
        return [
          {
            title: 'User ID',
            render: record => <Box>{record?.id}</Box>,
          },
          {
            title: 'Name',
            render: record => (
              <Box>{`${record?.firstName} ${record?.lastName}`}</Box>
            ),
          },
          {
            title: 'email',
            render: record => <Box>{record?.email}</Box>,
          },
          {
            title: 'region',
            render: record => <Box>{record?.region}</Box>,
          },
          {
            title: 'Skin Tracker',
            render: record => (
              <Box>{`${record.numberOfSkinTracker} click `}</Box>
            ),
          },
        ]
      case OptionSelect[4].value:
        return [
          {
            title: 'User ID',
            render: record => <Box>{record?.user?.id}</Box>,
          },
          {
            title: 'Name',
            render: record => (
              <Box>{`${record?.user?.firstName} ${record?.user?.lastName}`}</Box>
            ),
          },
          {
            title: 'Entry Date',
            render: record => (
              <Box>
                {moment(record?.createdAt).format('YYYY/MM/DD, HH:mm:ss A')}
              </Box>
            ),
          },
          {
            title: 'Deleted Date',
            render: record => (
              <Box>
                {record?.deletedAt === null
                  ? 'N/A'
                  : moment(record?.deletedAt).format('YYYY/MM/DD, HH:mm:ss A')}
              </Box>
            ),
          },
          {
            title: 'In Saved',
            render: record => (
              <Box>{record.deletedAt === null ? 'Yes' : 'No'}</Box>
            ),
          },
          {
            title: 'email',
            render: record => <Box>{record?.user?.email}</Box>,
          },
          {
            title: 'region',
            render: record => <Box>{record?.user?.region}</Box>,
          },
          {
            title: 'Saved Products',
            render: record => (
              <Box _hover={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {
                  <Box
                    onClick={() => {
                      navigate(`/product-detail/${record?.product.id}`)
                    }}
                  >
                    {record?.product?.product}
                  </Box>
                }
              </Box>
            ),
          },
        ]
      default:
        return [
          {
            title: 'User ID',
            render: record => <Box>{record?.id}</Box>,
          },
          {
            title: 'Name',
            render: record => (
              <Box>{`${record?.firstName} ${record?.lastName}`}</Box>
            ),
          },
          {
            title: 'email',
            render: record => <Box>{record?.email}</Box>,
          },
          {
            title: 'region',
            render: record => <Box>{record?.region}</Box>,
          },
          {
            title: 'Retailer Click Count',
            render: record => (
              <Box _hover={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {record?.retailers?.map(it => (
                  <Box
                    onClick={() => {
                      navigate(
                        `/product-external-link/retailer/${record?.id}`,
                        { state: record },
                      )
                    }}
                  >{`${it.name} (${it.number_of_retailer}) `}</Box>
                ))}
              </Box>
            ),
          },
        ]
    }
  }

  const columns = statisticsClicksField(type)

  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} flexDirection={'column'} gap={10}>
        <Flex alignItems={'center'} flexDirection={'row'}>
          <Text
            color={'#000'}
            fontFamily={'Hiragino Kaku Gothic Pro'}
            fontSize={'sm'}
            fontWeight={'semibold'}
            lineHeight={'16px'}
            margin={8}
          >
            {'Choose Product Views'}
          </Text>
          <Select
            options={OptionSelect}
            value={valueSelect}
            width={200}
            onChange={newValue => handleChangeSelect(newValue)}
          />
        </Flex>
        <Flex
          alignItems={'center'}
          gap={10}
          justifyContent={'space-between'}
          w={'full'}
        >
          <Button
            _hover={{ bg: '#222', cursor: 'pointer' }}
            bg={'#222'}
            color={'#fff'}
            h={'32px'}
            w={'91px'}
            onClick={handleReset}
          >
            Reset
          </Button>
          <InputDateTime
            direction="horizontal"
            isShowTime={false}
            label={'Start Date'}
            value={valueStartAt ? new Date(valueStartAt) : undefined}
            onChange={date => {
              handleChangeStartDate(date)
            }}
          />
          <InputDateTime
            direction="horizontal"
            isShowTime={false}
            label={'End Date'}
            value={valueEndAt ? new Date(valueEndAt) : undefined}
            onChange={date => {
              handleChangeEndDate(date)
            }}
          />
          <Button
            _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            p={'0px 34px'}
            w={160}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Flex>
        <Box>{textValidate && <TextError>{textValidate}</TextError>}</Box>
        {isLoading && <Spinner />}
        {dataProductView?.length && !isLoading ? (
          <>
            <Box overflowX={'auto'} w={'100%'}>
              <TableCustom columns={columns} data={dataProductView || []} />
            </Box>
            <Box py={5}>
              <Pagination
                page={currentPage}
                totalPage={totalPage}
                onChange={handleNextPage}
              />
            </Box>
          </>
        ) : undefined}
        <Flex alignSelf={'flex-start'}>
          <Button
            _hover={{ bg: '#3F6FBA' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            w={'max-content'}
            onClick={handleExportToXLSX}
          >
            Export Data
          </Button>
        </Flex>
        {!dataProductView?.length && !isLoading && (
          <Flex alignItems={'center'} justifyContent={'center'}>
            No Data
          </Flex>
        )}
        <ModalExportData
          downloadedItem={downloadedItem}
          handleClickDownload={handleClickDownload}
          isButtonChosen={isButtonChosen}
          isOpenModal={isOpenModalExportData}
          totalItem={totalItem}
          onCloseModal={() => setIsOpenModalExportData(false)}
        />
      </Flex>
    </Flex>
  )
}
