export type Option = {
  value: string
  label: string
}
export const OptionSelect: Option[] = [
  {
    label: 'Attributes',
    value: 'user-most-attributes',
  },
  {
    label: 'Routine Activity',
    value: 'user-routine',
  },
]
