import React from 'react'
import { Flex } from '@chakra-ui/layout'
import { SingleAdd } from './components/modules/SingleAdd'

/**
 * ProductRequestAddPage component page.
 * @return {JSX.Element} ProductRequestAddPage component.
 */
export function ProductRequestAddPage() {
  return (
    <Flex flexDirection={'column'} gap={4} w={'100%'}>
      <SingleAdd />
    </Flex>
  )
}
