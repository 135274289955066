import { useEffect, useMemo, useState } from 'react'
import { makeArrayPaging } from './utils'
import { IPaginationProps } from './types'

/**
 * @param props
 */
export const usePagination = (props: IPaginationProps) => {
  const { page, totalPage, onChange = () => {} } = props

  const [currentPage, setCurrentPage] = useState(page ?? 1)

  useEffect(() => {
    if (page !== undefined && page !== currentPage) {
      setCurrentPage(page)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const paging = useMemo(() => {
    return makeArrayPaging(totalPage, currentPage)
  }, [currentPage, totalPage])

  /**
   * handleChangePage
   */
  const handleChangePage = (value: number) => {
    setCurrentPage(value)
    onChange(value)
  }

  return {
    currentPage,
    paging,
    handleChangePage,
  }
}
