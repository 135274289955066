import { Box, Text } from '@chakra-ui/layout'
import React from 'react'
import { BoxProps } from '@chakra-ui/react'
import { useAuth } from '../../../contexts/AuthContexts'
import { LayoutHeader } from './Layout'

type THeaderProps = {
  title?: string
} & BoxProps

/**
 *
 * @returns Component Header
 */
export const Header = (props: THeaderProps) => {
  const { title, ...rest } = props
  const { profile } = useAuth()
  return (
    <Box
      alignItems={'center'}
      as={LayoutHeader}
      background={'#385187'}
      boxShadow={'0px 5px 10px 6px rgba(0, 0, 0, 0.05)'}
      display={'flex'}
      justifyContent={'space-between'}
      minH={'60px'}
      px={6}
      w={'100%'}
      {...rest}
    >
      <Text
        color={'#fff'}
        fontFamily={'YuGothic'}
        fontSize={'20px'}
        fontWeight={'bold'}
        lineHeight={'140%'}
      >
        {title}
      </Text>
      <Text
        color={'#fff'}
        fontFamily={'YuGothic'}
        fontSize={'14px'}
        fontWeight={'bold'}
        lineHeight={'150%'}
      >
        {profile?.firstName}
      </Text>
    </Box>
  )
}
