import React from 'react'
import { Flex, Text } from '@chakra-ui/layout'
import { Box, Switch, Button, Image } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Props, usePromotedBrandDetailScreen } from '../../hooks'
import { InputForm } from '../../../../components/modules/InputForm'
import { TextAreaBase } from '../../../../components/elements/TextAreaBase'

const TextPromotion = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 700;
`

const StyledWrapperButton = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 8px;
`

const StyledButtonOutline = styled(Button)`
  color: #3f6fba;
  background: transparent;
  border: 1px solid #3f6fba;
  &:hover {
    background: #fff;
  }
`

const StyledButtonSolid = styled(Button)`
  color: #ffffff;
  background: #3f6fba;
  &:hover {
    background: #3f6fba;
  }
`

const StyledWrapper = styled(Flex)`
  flex-direction: column;
  gap: 20px;
`

const StyledImage = styled(Image)`
  height: 150px;
  border-radius: 4px;
  align-self: flex-start;
`

/**
 * PromotedScreenLayout component page.
 * @return {JSX.Element} PromotedScreenLayout component.
 */
export const PromotedScreenLayout = (props: Props) => {
  const {
    isLoading,
    retailerDetail,
    handleChangeForm,
    errors,
    handleTogglePromotion,
    handleSavePromotedRetailer,
    handleBack,
  } = props

  return (
    <Flex flexDirection={'column'} gap={6}>
      <Box maxW={400}>
        <InputForm
          isRequired
          error={errors?.name?.message}
          isInvalid={!!errors?.name?.message}
          label={'Name'}
          name={'name'}
          value={retailerDetail?.name}
          onChange={handleChangeForm}
        />
      </Box>
      <TextAreaBase
        isRequired
        error={errors?.description?.message}
        isInvalid={!!errors?.description?.message}
        label={'Description'}
        name={'description'}
        placeholder="Enter description ..."
        value={retailerDetail?.description ?? ''}
        onChange={handleChangeForm}
      />
      <Flex alignItems={'center'} gap={'6px'}>
        <TextPromotion>Promotion</TextPromotion>
        <Switch
          isChecked={retailerDetail.promoted}
          size="md"
          onChange={handleTogglePromotion}
        />
      </Flex>
      <StyledWrapper>
        <InputForm
          isRequired
          error={errors?.imgUrl?.message}
          isInvalid={!!errors?.imgUrl?.message}
          label={'Image URL'}
          labelWidth={60}
          name={'imgUrl'}
          placeholder={''}
          value={retailerDetail?.imgUrl}
          onChange={handleChangeForm}
        />
        <Box>
          {retailerDetail?.imgUrl && (
            <StyledImage
              alt="image product"
              objectFit="contain"
              src={retailerDetail?.imgUrl}
            />
          )}
        </Box>
      </StyledWrapper>

      <Flex alignItems={'center'} justifyContent={'end'}>
        <StyledWrapperButton>
          <StyledButtonOutline onClick={handleBack}>Back</StyledButtonOutline>
          <StyledButtonSolid
            isLoading={isLoading}
            onClick={handleSavePromotedRetailer}
          >
            Save
          </StyledButtonSolid>
        </StyledWrapperButton>
      </Flex>
    </Flex>
  )
}

/**
 * PromotedScreen component page.
 * @return {JSX.Element} PromotedScreen component.
 */
export const PromotedRetailersDetailScreen = () => (
  <PromotedScreenLayout {...usePromotedBrandDetailScreen()} />
)
