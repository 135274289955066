import React from 'react'
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  Image,
} from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/layout'
import styled from '@emotion/styled'
import { InputForm } from '../InputForm'
import { TextAreaBase } from '../../elements/TextAreaBase'
import { ModalCreateRetailerProps, useModalCreateRetailer } from './hook'

/**
 * @returns Component PromotedRetailerCreate Screen
 */
export const ModalCreateRetailer = (props: ModalCreateRetailerProps) => {
  const {
    formValue,
    handleOpenModalRetailer,
    isOpenRetailer,
    handleChangeInput,
    handleCloseModalRetailer,
    errorsRetailer,
    handleTogglePromotion,
    handleSubmitRetailer,
  } = useModalCreateRetailer(props)

  return (
    <>
      <Flex position={'fixed'} right={'100px'} top={'50%'} zIndex={'999'}>
        <StyledCreateRetailer>
          <Button
            colorScheme="teal"
            rightIcon={<ArrowForwardIcon />}
            variant="solid"
            onClick={handleOpenModalRetailer}
          >
            Create New Retailer
          </Button>
        </StyledCreateRetailer>
      </Flex>
      <Modal isOpen={isOpenRetailer} onClose={handleCloseModalRetailer}>
        <ModalOverlay />
        <ModalContent maxW={'800px'} minH={'400px'}>
          <ModalHeader>Create New Retailer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <StyledContainer>
              <Box maxW={400}>
                <InputForm
                  isRequired
                  error={errorsRetailer?.name?.message}
                  isInvalid={!!errorsRetailer?.name?.message}
                  label={'Name'}
                  name={'name'}
                  value={formValue?.name}
                  onChange={handleChangeInput}
                />
              </Box>
              <TextAreaBase
                isRequired
                error={errorsRetailer?.description?.message}
                isInvalid={!!errorsRetailer?.description?.message}
                label={'Description'}
                name={'description'}
                placeholder="Enter description ..."
                value={formValue?.description ?? ''}
                onChange={handleChangeInput}
              />
              <Flex alignItems={'center'} gap={'16px'}>
                <TextPromotion>Promotion</TextPromotion>
                <Switch
                  isChecked={formValue.promoted}
                  size="md"
                  onChange={handleTogglePromotion}
                />
              </Flex>
              <StyledWrapper>
                <InputForm
                  isRequired
                  error={errorsRetailer?.imgUrl?.message}
                  isInvalid={!!errorsRetailer?.imgUrl?.message}
                  label={'Image URL'}
                  labelWidth={60}
                  name={'imgUrl'}
                  placeholder={''}
                  value={formValue?.imgUrl}
                  onChange={handleChangeInput}
                />
                <Box>
                  {formValue?.imgUrl && (
                    <StyledImage
                      alt="image product"
                      objectFit="contain"
                      src={formValue?.imgUrl}
                    />
                  )}
                </Box>
              </StyledWrapper>
            </StyledContainer>
          </ModalBody>
          <ModalFooter pt={'48px'}>
            <Button colorScheme="blue" mr={3} onClick={handleSubmitRetailer}>
              Submit
            </Button>
            <Button
              colorScheme={'black'}
              mr={3}
              variant="ghost"
              onClick={handleCloseModalRetailer}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const StyledCreateRetailer = styled(Flex)`
  width: 100%;
`
const StyledContainer = styled(Flex)`
  gap: 20px;
  flex-direction: column;
  margin: 40px;
`
const TextPromotion = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 700;
`
const StyledWrapper = styled(Flex)`
  flex-direction: column;
  gap: 20px;
`
const StyledImage = styled(Image)`
  width: 300px;
  height: 150px;
  border-radius: 4px;
`
