import React from 'react'
import styled from '@emotion/styled'
import { Flex } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { InputForm } from '../../../../components/modules/InputForm'
import { useDermTipsAdd } from './hook'

/**
 * @returns Component DermTipsAddScreen
 */
export const DermTipsAddScreen = () => {
  const { handleSubmit, formValue, errors, handleChangeInput } =
    useDermTipsAdd()

  return (
    <>
      <StyledContainer>
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.content?.message}
          isInvalid={!!errors?.content?.message}
          label={'Derm Tip'}
          labelWidth={60}
          name="content"
          placeholder={''}
          value={formValue?.content}
          onChange={handleChangeInput}
        />
      </StyledContainer>
      <StyledWrapperButton>
        <StyledButtonSubmit onClick={handleSubmit}>Submit</StyledButtonSubmit>
      </StyledWrapperButton>
    </>
  )
}

const StyledContainer = styled(Flex)`
  gap: 20px;
  flex-direction: column;
  margin: 40px;
`
const StyledInputForm = styled(InputForm)`
  max-width: 300px;
`
const StyledWrapperButton = styled(Flex)`
  justify-content: center;
`
const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  height: 32px;
  border-radius: 25px;
  background: #385187;
  color: #ffffff;
  &:hover {
    background: #385187;
    cursor: pointer;
  }
`
