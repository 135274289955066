import React from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

export type LabelProps = BoxProps & {
  label: string | React.ReactNode
  id?: string
  labelWidth?: string | number
  isRequired?: boolean
}

/**
 * Label component page.
 * @return {JSX.Element} Label component.
 */
export const Label: React.FC<LabelProps> = ({
  id,
  label,
  labelWidth,
  isRequired,
  ...props
}): React.ReactElement => {
  return (
    <Box
      _before={
        isRequired
          ? {
              marginRight: 1,
              color: 'red.700',
              content: '"*"',
            }
          : undefined
      }
      as={'label'}
      color={'#000000'}
      fontSize={'14px'}
      fontWeight={'bold'}
      htmlFor={id}
      maxW={labelWidth}
      minW={labelWidth}
      w={labelWidth}
      whiteSpace={'nowrap'}
      {...props}
    >
      {label}
    </Box>
  )
}
