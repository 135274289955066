import React from 'react'
import {
  Thead,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  HTMLChakraProps,
  TableProps as TableChakraProps,
  Text,
} from '@chakra-ui/react'
import { forwardRef } from '@chakra-ui/system'

export type TColumn<T> = {
  title: string | React.ReactNode
  render: (item?: T, index?: number) => React.ReactNode
  tdProps?: HTMLChakraProps<'td'>
  thProps?: HTMLChakraProps<'th'>
}

export type TTableProps<T> = TableChakraProps & {
  columns: TColumn<T>[]
  data: T[]
  onSelect?: (column) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TableCustom = forwardRef<TTableProps<any>, 'table'>(
  (props, ref) => {
    const { columns, data, onSelect, ...rest } = props

    return (
      <Table
        ref={ref}
        overflowX={'auto'}
        size={'sm'}
        variant="simple"
        whiteSpace={'nowrap'}
        {...rest}
      >
        <Thead whiteSpace={'nowrap'}>
          <Tr>
            {columns?.map((column, i) => (
              <Th
                key={i}
                borderBottom={'1px solid #C2C4F2'}
                h={'40px'}
                {...column.thProps}
              >
                <Text
                  color={'#000'}
                  fontFamily={'Hiragino Kaku Gothic Pro'}
                  fontSize={'sm'}
                  fontWeight={'semibold'}
                  lineHeight={'16px'}
                >
                  {column.title}
                </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data?.map((item, index) => {
            return (
              <Tr
                key={index}
                _hover={{ background: '#F3F6FE' }}
                background={'#fff'}
                cursor={'pointer'}
                whiteSpace={'normal'}
              >
                {columns?.map((column, i) => {
                  return (
                    <Td
                      key={i}
                      border={'none'}
                      borderBottom={'1px solid #F3F1FF'}
                      color={'#222'}
                      fontFamily={'Hiragino Kaku Gothic Pro'}
                      fontSize={'14px'}
                      fontWeight={'light'}
                      gap={10}
                      lineHeight={'21px'}
                      whiteSpace={'nowrap'}
                      onClick={() => onSelect && onSelect(item)}
                      {...column.tdProps}
                    >
                      {column.render(item, index)}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    )
  },
)
