import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import { Image, Spinner, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { images } from '../../../../assets/images'
import { useHomeScreen, Props } from './hooks'

const LabelWrap = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    min-width: 200px;
  }
  div {
    border: 1px solid #000;
    background: #eee;
    min-width: 120px;
    p {
      padding: 0 15px;
      min-width: unset;
      font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    }
  }
`

/**
 * @returns Component
 */
const Component = (props: Props) => {
  const { data, isLoading } = props
  if (isLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    )
  }
  return (
    <Box>
      <Flex justifyContent={'center'} mb={10}>
        <Image src={images.logoAllSkin} w={'300px'} />
      </Flex>

      <Flex alignItems={'center'} flexDirection={'column'} gap={'10px'}>
        <LabelWrap>
          <Text>Total Product Count</Text>
          <Box>
            <Text>{data?.products.total}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Unique UPC Count</Text>
          <Box>
            <Text>{data?.products.uniqUpc}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Unique Ingredients Count</Text>
          <Box>
            <Text>{data?.products.uniqIngredient}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Total Retailers Count</Text>
          <Box>
            <Text>{data?.products.uniqRetailer}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Total Users Count</Text>
          <Box>
            <Text>{data?.users.total}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Total Admin Users</Text>
          <Box>
            <Text>{data?.users.totalAdminUser}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Total App Users</Text>
          <Box>
            <Text>{data?.users.totalCommonUser}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Total Free Users</Text>
          <Box>
            <Text>{data?.users.totalFreeUser}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Total Premium Users</Text>
          <Box>
            <Text>{data?.users.totalPremiumUser}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Total Routines Analyzed</Text>
          <Box>
            <Text>{data?.numRoutineAnalysis}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Total Barcodes Scanned</Text>
          <Box>
            <Text>{data?.numScanUpc}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Total Text Searches</Text>
          <Box>
            <Text>{data?.numWordSearch}</Text>
          </Box>
        </LabelWrap>
        <LabelWrap>
          <Text>Total Active User Routines</Text>
          <Box>
            <Text>{data?.userRoutineUse[0].user_id}</Text>
          </Box>
        </LabelWrap>
      </Flex>
    </Box>
  )
}

/***
 *
 * Returns: Home Screen
 */
export const HomeScreen = () => {
  return <Component {...useHomeScreen()} />
}
