import React from 'react'
import { Spinner, Flex, Button, Image } from '@chakra-ui/react'
import { Box, Text } from '@chakra-ui/layout'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import styled from '@emotion/styled'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { InputForm } from '../../../../components/modules/InputForm'
import { Label } from '../../../../components/elements/Label'
import { Props, useAnswerAboutDetail } from './hooks'

/**
 * @returns Component
 */
const Component = (props: Props) => {
  const {
    isLoading,
    answerAboutDetail,
    editorHtml,
    handleEditorChange,
    onSaveContent,
    handleCancel,
    fileInputRefVideo,
    handleFileSelectVideo,
    handleUploadFile,
    fileUpload,
    handleChangeInput,
    handleUploadImage,
    fileInputRefImage,
    handleFileSelectImage,
    isLoadingButton,
    imageUpload,
    handleDeleteImage,
  } = props

  if (isLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <Flex flexDirection={'column'} gap={10}>
      <Box>
        <Text>ID: {answerAboutDetail?.id}</Text>
        <Text>Title: {answerAboutDetail?.title}</Text>
        <Text>Profile Type: {answerAboutDetail?.profileType}</Text>
      </Box>
      <InputForm
        label={'Title'}
        name={'videoTitle'}
        value={answerAboutDetail?.videoTitle || ''}
        onChange={handleChangeInput}
      />
      <InputForm
        label={'Sub Title'}
        name={'videoSubTitle'}
        value={answerAboutDetail?.videoSubTitle || ''}
        onChange={handleChangeInput}
      />
      <Flex flexDirection={'column'} gap={2}>
        <Label label={'Content:'} />
        <ReactQuill
          theme="snow"
          value={editorHtml}
          onChange={handleEditorChange}
        />
      </Flex>
      <StyledWrapper>
        <Label label={'Video:'} />
        {answerAboutDetail.videoPreviewUrl && (
          <Box
            controls
            as="video"
            objectFit="contain"
            src={answerAboutDetail.videoPreviewUrl}
            sx={{
              aspectRatio: '16/9',
            }}
          />
        )}
        <StyledButtonSubmit onClick={handleFileSelectVideo}>
          Upload Video
        </StyledButtonSubmit>
        <input
          ref={fileInputRefVideo}
          accept="video/m4v,video/avi,video/mpg,video/mp4"
          style={{ display: 'none' }}
          type="file"
          value=""
          onChange={handleUploadFile}
        />
        <StyledFileName as={'span'}>{fileUpload?.name}</StyledFileName>
      </StyledWrapper>
      <StyledWrapper>
        <Label label={'Image:'} />
        {isLoadingButton ? (
          <Spinner />
        ) : (
          <>
            {imageUpload?.detailImgPreviewUrl ? (
              <StyledImage
                alt="image product"
                objectFit="contain"
                src={imageUpload?.detailImgPreviewUrl}
              />
            ) : (
              <>
                {answerAboutDetail?.detailImgPreviewUrl !== null &&
                  answerAboutDetail?.detailImgPreviewUrl !== undefined &&
                  answerAboutDetail?.detailImgPreviewUrl !== '' && (
                    <StyledImage
                      alt="image product"
                      objectFit="contain"
                      src={answerAboutDetail?.detailImgPreviewUrl}
                    />
                  )}
              </>
            )}
          </>
        )}
        <StyledButtonSubmit onClick={handleFileSelectImage}>
          Upload Image
        </StyledButtonSubmit>
        <input
          ref={fileInputRefImage}
          accept=".jpg, .jpeg, .png, .heif, .heic"
          style={{ display: 'none' }}
          type="file"
          value=""
          onChange={handleUploadImage}
        />
        <StyledFileName as={'span'}>{fileUpload?.name}</StyledFileName>
        {answerAboutDetail.detailImgUrl !== null && (
          <Button
            colorScheme="red"
            rightIcon={<RiDeleteBin6Line />}
            size={'sm'}
            variant="outline"
            w={'140px'}
            onClick={handleDeleteImage}
          >
            Delete Image
          </Button>
        )}
      </StyledWrapper>

      <StyledWrapperButton>
        <StyledButtonOutline onClick={handleCancel}>Cancel</StyledButtonOutline>
        <StyledButtonSolid onClick={onSaveContent}>Save</StyledButtonSolid>
      </StyledWrapperButton>
    </Flex>
  )
}

/***
 *
 * Returns: AnswerAboutDetailDetail Screen
 */
export const AnswerAboutDetailDetailScreen = () => {
  return <Component {...useAnswerAboutDetail()} />
}

const StyledWrapperButton = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 8px;
`

const StyledButtonOutline = styled(Button)`
  color: #3f6fba;
  background: transparent;
  border: 1px solid #3f6fba;
  width: 100px;
`

const StyledButtonSolid = styled(Button)`
  color: #ffffff;
  background: #3f6fba;
  width: 100px;
  &:hover {
    background: #3f6fba;
  }
`
const StyledWrapper = styled(Flex)`
  flex-direction: column;
  gap: 10px;
`
const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 120px;
  height: 32px;
  border-radius: 25px;
  background: #000000;
  color: #ffffff;
  &:hover {
    background: #000000;
    cursor: pointer;
  }
`

const StyledFileName = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  color: #0070c0;
`
const StyledImage = styled(Image)`
  height: 150px;
  border-radius: 4px;
  align-self: flex-start;
`
