import React from 'react'
import styled from '@emotion/styled'
import { Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { mockSelectionAdministrators } from '../../constants'
import { InputForm } from '../../../../../components/modules/InputForm'
import { Select } from '../../../../../components/elements/Select'
import { useAddAdministrators } from './hook'

/**
 * @returns Component ProductList Page
 */
export const AdministratorsAddScreen = () => {
  const {
    handleSubmit,
    formValue,
    errors,
    handleChangeInput,
    handleOnChangeSelect,
  } = useAddAdministrators()
  return (
    <>
      <StyledContainer>
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.firstName?.message}
          isInvalid={!!errors?.firstName?.message}
          label={'First Name'}
          labelWidth={60}
          name="firstName"
          placeholder={''}
          value={formValue?.firstName}
          onChange={handleChangeInput}
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.lastName?.message}
          isInvalid={!!errors?.lastName?.message}
          label={'Last Name'}
          labelWidth={60}
          name="lastName"
          placeholder={''}
          value={formValue?.lastName}
          onChange={handleChangeInput}
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.password?.message}
          isInvalid={!!errors?.password?.message}
          label={'Password'}
          labelWidth={60}
          name="password"
          placeholder={''}
          type={'password'}
          value={formValue?.password}
          onChange={handleChangeInput}
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.email?.message}
          isInvalid={!!errors?.email?.message}
          label={'Email'}
          labelWidth={60}
          name="email"
          placeholder={''}
          value={formValue?.email}
          onChange={handleChangeInput}
        />
        <StyledInputForm
          isRequired
          direction={'horizontal'}
          error={errors?.phoneNumber?.message}
          isInvalid={!!errors?.phoneNumber?.message}
          label={'Phone Number'}
          labelWidth={60}
          name="phoneNumber"
          placeholder={''}
          type={'number'}
          value={formValue?.phoneNumber}
          onChange={handleChangeInput}
        />
        <Flex alignItems={'center'} gap={'8px'} justifyContent={'flex-start'}>
          <TextTitle>
            <Text as={'span'} style={{ color: '#9B2C2C' }}>
              *
            </Text>{' '}
            Role
          </TextTitle>
          <Select
            name={'accountStatus'}
            options={mockSelectionAdministrators}
            value={formValue?.accountStatus}
            width={'300px'}
            onChange={value =>
              handleOnChangeSelect('accountStatus', value as string)
            }
          />
        </Flex>
      </StyledContainer>
      <StyledWrapperButton>
        <StyledButtonSubmit onClick={handleSubmit}>Submit</StyledButtonSubmit>
      </StyledWrapperButton>
    </>
  )
}

const StyledContainer = styled(Flex)`
  gap: 20px;
  flex-direction: column;
  margin: 40px;
`
const StyledInputForm = styled(InputForm)`
  max-width: 300px;
`
const StyledWrapperButton = styled(Flex)`
  justify-content: center;
`
const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  height: 32px;
  border-radius: 25px;
  background: #385187;
  color: #ffffff;
  &:hover {
    background: #385187;
    cursor: pointer;
  }
`
const TextTitle = styled(Text)`
  width: 240px;
  font-size: 14px;
  color: black;
  font-weight: 700;
`
