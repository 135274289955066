import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import moment from 'moment/moment'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { ButtonBase } from '../../../../components/elements/ButtonBase'
import { Pagination } from '../../../../components/modules/Pagination'
import { Input } from '../../../../components/elements/Input/Input'
import { Table } from '../../../../components/modules/TableCustom/TableCustom'
import { useDermTips } from './hook'

/**
 * @returns Component DermTips Screen Page
 */
export const DermTipsScreen = () => {
  const {
    isLoading,
    dataDermTips,
    handleCreateAdd,
    handleEdit,
    currentPage,
    totalPage,
    handleNextPage,
    valueInput,
    handleChangeInput,
    handleSearchAdministrator,
    handleDeleteDermTip,
    isOpenDelete,
    onCloseDelete,
    onOpenDelete,
  } = useDermTips()

  const columns = [
    {
      title: 'ID',
      render: record => <Box>{record?.id}</Box>,
    },
    {
      title: 'edit',
      render: ({ id }) => (
        <Box onClick={() => handleEdit(id)}>
          <Text color={'#3F6FBA'} textDecoration={'underline'}>
            Edit
          </Text>
        </Box>
      ),
    },
    {
      title: 'Derm Tip',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.content}</Box>
      ),
    },
    {
      title: 'ENTRY DATE',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {moment(record?.createdAt).format('YYYY/MM/DD, HH:mm:ss A')}
        </Box>
      ),
    },
    {
      title: 'LAST UPDATED DATE',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {moment(record?.updatedAt).format('YYYY/MM/DD, HH:mm:ss A')}
        </Box>
      ),
    },
    {
      title: '',
      render: record => (
        <Button
          colorScheme="red"
          rightIcon={<RiDeleteBin6Line />}
          size={'sm'}
          variant="outline"
          onClick={() => onOpenDelete(record.id)}
        >
          Delete
        </Button>
      ),
    },
  ]
  return (
    <Flex flexDirection={'column'} gap={10}>
      <Flex gap={'20px'} justifyContent={'space-between'} mb={'20px'}>
        <ButtonBase
          hasAdd
          _hover={{ bg: '#385187' }}
          title={'CREATE'}
          onClick={handleCreateAdd}
        />
        <Flex gap={'20px'}>
          <Input
            placeholder={'keyword search'}
            value={valueInput}
            w={400}
            onChange={handleChangeInput}
          />
          <Button
            _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            w={160}
            onClick={handleSearchAdministrator}
          >
            Search
          </Button>
        </Flex>
      </Flex>

      {dataDermTips && !!dataDermTips.length && (
        <>
          <Box overflowX={'auto'} w={'100%'}>
            <Table columns={columns} data={dataDermTips || []} />
          </Box>
          <Pagination
            page={currentPage}
            totalPage={totalPage}
            onChange={handleNextPage}
          />
        </>
      )}
      {dataDermTips?.length === 0 && !isLoading && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          No Data
        </Flex>
      )}
      <Modal isOpen={isOpenDelete} onClose={onCloseDelete}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Delete</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this Derm Tip?</ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDeleteDermTip}>
              Save
            </Button>
            <Button
              colorScheme={'black'}
              mr={3}
              variant="ghost"
              onClick={onCloseDelete}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
