import React from 'react'
import styled from '@emotion/styled'
import {
  Input as InputChakra,
  InputProps as InputPropsChakra,
} from '@chakra-ui/react'
import { forwardRef } from '@chakra-ui/system'

export interface IInputProps extends InputPropsChakra {}

export const InputStyle = styled(InputChakra)`
  &:focus-visible {
    box-shadow: unset;
  }
`

export const Input = forwardRef<IInputProps, 'input'>(({ ...props }, ref) => {
  return (
    <InputStyle
      ref={ref}
      background={'#fff'}
      border={'1px solid #ccc'}
      borderRadius={'4px'}
      boxShadow={'0 5px 4px 0 rgba(120, 123, 172, 0.08) inset'}
      color={'primary_color_main'}
      fontFamily={'Hiragino Sans'}
      fontSize={'14px'}
      fontWeight={400}
      height={'40px'}
      letterSpacing={'0.6px'}
      lineHeight={'100%'}
      {...props}
    />
  )
})
