import { useCallback, useEffect, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isAxiosError } from 'axios'
import { TMetaType } from '../../../ViewProductData/components/modules/types'
import {
  getUsers,
  updateStatusUser,
  UserListType,
} from '../../../../../service/administratorService'
import { TUser } from './type'

/**
 * @returns Component useAdministrators InActive hook
 */
export const useAdministratorsInActive = () => {
  const [dataAdministratorInActive, setDataAdministratorInActive] =
    useState<TUser[]>()
  const toast = useToast()
  const navigate = useNavigate()
  const [meta, setMeta] = useState<TMetaType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const keywordType = searchParams.get('keywordType')
  const word = searchParams.get('word')
  const [dataAdministratorId, setdataAdministratorId] = useState<number>()

  const [valueInput, setValueInput] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure()
  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params: UserListType = {
        status: 2,
        keywordType: keywordType ? Number(keywordType) : undefined,
        word: word ? word : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
        userType: 1,
      }
      const response = await getUsers(params)
      setDataAdministratorInActive(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [keywordType, page, toast, word])

  useEffect(() => {
    fetchData().then()
  }, [fetchData])

  const handleEdit = useCallback(
    (id: number) => {
      navigate(`/administrators/edit/${id}`)
    },
    [navigate],
  )
  const handleUpdateStatus = useCallback(
    async (id: number) => {
      const statusUser = dataAdministratorInActive?.find(it => it.id === id)
      const payload = {
        removeUser: statusUser?.status === 'ACTIVE',
      }
      try {
        await updateStatusUser(id, payload)
        fetchData()
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    },
    [dataAdministratorInActive, fetchData, toast],
  )

  const handleRestore = useCallback(async () => {
    try {
      if (!dataAdministratorId) {
        return
      }
      const payload = {
        removeUser: false,
      }
      await updateStatusUser(dataAdministratorId, payload)
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Restore Success',
        duration: 3000,
      })
      onClose()
      fetchData()
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    }
  }, [dataAdministratorId, fetchData, onClose, toast])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )

  const handleChangeInput = useCallback(e => {
    setValueInput(e.target.value)
  }, [])

  const handleSearchAdministrator = useCallback(() => {
    setSearchParams({
      word: String(valueInput),
      page: String(1),
    })
  }, [setSearchParams, valueInput])

  const handleOpenModal = useCallback(async () => {
    onOpen()
  }, [onOpen])

  const onOpenRestore = useCallback(
    (id: number) => {
      setdataAdministratorId(id)
      onOpen()
    },
    [onOpen],
  )

  return {
    dataAdministratorInActive,
    isLoading,
    handleEdit,
    handleUpdateStatus,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNextPage,
    setSearchParams,
    valueInput,
    handleChangeInput,
    handleSearchAdministrator,
    handleRestore,
    isOpen,
    onClose,
    handleOpenModal,
    dataAdministratorId,
    onOpenRestore,
  }
}
