import { useCallback, useEffect, useState } from 'react'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import moment from 'moment/moment'
import {
  exportFileProductRoutine,
  getTopProduct,
} from '../../../../../service/authService'
import { TMetaType } from '../../../ViewRulesPage/components/modules/type'
import { TMostPopularProduct } from './type'
import { OptionSelect, OptionSelectView } from './constants'

type SearchParams = {
  [k: string]: string
}

/**
 * @returns Component useMostPopularProduct hook
 */
export const useMostPopularProduct = () => {
  const navigate = useNavigate()
  const [dataTopProduct, setDataTopProduct] = useState<TMostPopularProduct[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [meta, setMeta] = useState<TMetaType>()
  let [searchParams, setSearchParams] = useSearchParams()
  const toast = useToast()
  const page = searchParams.get('page')
  const startAt = searchParams.get('startAt')
  const endAt = searchParams.get('endAt')
  const word = searchParams.get('word')
  const type = searchParams.get('type')
  const keywordType = searchParams.get('keywordType')
  const [isOpenModalExportData, setIsOpenModalExportData] =
    useState<boolean>(false)
  const [downloadedItem, setDownloadedItem] = useState<number[]>([])
  const [isButtonChosen, setIsButtonChosen] = useState<number>()

  const [valueStartAt, setValueStartAt] = useState<Date>()
  const [valueEndAt, setValueEndAt] = useState<Date>()
  const [textValidate, setTextValidate] = useState('')
  const [valueInput, setValueInput] = useState(word || '')
  const [valueSelect, setValueSelect] = useState<{
    label: string
    value?: number
  }>(
    OptionSelect.find(option => option.value === Number(keywordType)) || {
      label: 'Select ...',
      value: undefined,
    },
  )
  const [valueSelectView, setValueSelectView] = useState<OptionSelect>(
    OptionSelectView.find(item => item.value === type) ?? OptionSelectView[0],
  )

  const onChangeSearchParams = useCallback(
    (type: string, page: string) => {
      const searchParams: SearchParams = {
        type,
        page,
      }
      if (valueStartAt) {
        searchParams.startAt = moment(valueStartAt).format('YYYY-MM-DD')
      }
      if (valueEndAt) {
        searchParams.endAt = moment(valueEndAt).format('YYYY-MM-DD')
      }
      setSearchParams(searchParams)
    },
    [setSearchParams, valueEndAt, valueStartAt],
  )

  const validateDate = useCallback(() => {
    const startAt = moment(valueStartAt).format('YYYY-MM-DD')
    const endAt = moment(valueEndAt).format('YYYY-MM-DD')
    setTextValidate(
      startAt <= endAt
        ? ''
        : 'Please select a start date that occurs before the end date',
    )
    return startAt <= endAt
  }, [valueEndAt, valueStartAt])

  const fetchDatUserRoutine = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        keywordType: keywordType ? Number(keywordType) : undefined,
        word: word ? word : undefined,
        startAt: startAt ? moment(startAt).format('YYYY-MM-DD') : undefined,
        endAt: endAt ? moment(endAt).format('YYYY-MM-DD') : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getTopProduct(
        params,
        type ?? OptionSelectView[0].value,
      )
      setDataTopProduct(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [endAt, keywordType, page, startAt, toast, type, word])

  useEffect(() => {
    fetchDatUserRoutine().then()
  }, [fetchDatUserRoutine])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      onChangeSearchParams(
        type ?? OptionSelectView[0].value,
        String(currentPage),
      )
    },
    [onChangeSearchParams, type],
  )

  const handleChangeStartDate = useCallback((date: Date) => {
    setValueStartAt(date)
    setTextValidate('')
  }, [])

  const handleChangeEndDate = useCallback((date: Date) => {
    setValueEndAt(date)
    setTextValidate('')
  }, [])

  const handleChangeInput = useCallback(e => {
    setValueInput(e.target.value)
  }, [])

  const handleChangeSelect = useCallback(value => {
    setValueSelect(value)
  }, [])

  const handleChangeSelectView = useCallback(
    value => {
      if (value === valueSelectView) return
      onChangeSearchParams(value.value ?? OptionSelectView[0].value, '1')
      setValueSelectView(value)
      setDownloadedItem([])
    },
    [onChangeSearchParams, valueSelectView],
  )

  const handleSearch = useCallback(async () => {
    if (validateDate()) {
      try {
        setSearchParams({
          startAt: valueStartAt
            ? moment(valueStartAt).format('YYYY-MM-DD')
            : '',
          endAt: valueEndAt ? moment(valueEndAt).format('YYYY-MM-DD') : '',
          word: String(valueInput),
          keywordType: String(valueSelect.value ?? ''),
          page: String(1),
          type: type ?? OptionSelectView[0].value,
        })
        setDownloadedItem([])
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
      }
    }
  }, [
    setSearchParams,
    toast,
    type,
    validateDate,
    valueEndAt,
    valueInput,
    valueSelect.value,
    valueStartAt,
  ])

  /**
   * @returns handleReset
   */
  const handleReset = useCallback(() => {
    setValueStartAt(undefined)
    setValueEndAt(undefined)
    setTextValidate('')
    setValueInput('')
    setValueSelect({ label: 'Select ...', value: undefined })
    setSearchParams({
      startAt: '',
      endAt: '',
      page: String(1),
      type: type ?? OptionSelectView[0].value,
    })
  }, [setSearchParams, type])

  const handleExportToXLSX = useCallback(() => {
    setIsOpenModalExportData(true)
  }, [])

  const handleClickDownload = useCallback(
    async (page: number) => {
      setIsButtonChosen(page)
      try {
        const params = {
          page: page,
          limit: 5000,
          isExport: true,
          isApplyFilterExport: true,
          startAt: startAt ? moment(startAt).format('YYYY-MM-DD') : undefined,
          endAt: endAt ? moment(endAt).format('YYYY-MM-DD') : undefined,
        }
        const example = await exportFileProductRoutine(
          params,
          type ?? OptionSelectView[0].value,
        )
        const link = document.createElement('a')
        link.href = example
        document.body.appendChild(link)
        link.click()
        setIsButtonChosen(0)
        setDownloadedItem(prevState => [...prevState, page])
      } catch (e) {
        if (isAxiosError(e)) {
          const message = e?.response?.data.message
          toast({
            position: 'top-right',
            status: 'error',
            title: message,
            duration: 3000,
          })
        }
        setIsButtonChosen(0)
      }
    },
    [endAt, startAt, toast, type],
  )

  return {
    dataTopProduct,
    isLoading,
    handleNextPage,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    totalItem: meta?.total || 0,
    handleChangeStartDate,
    handleChangeEndDate,
    valueStartAt,
    valueEndAt,
    handleSearch,
    handleReset,
    textValidate,
    valueInput,
    handleChangeInput,
    valueSelect,
    handleChangeSelect,
    navigate,
    handleExportToXLSX,
    isOpenModalExportData,
    downloadedItem,
    handleClickDownload,
    isButtonChosen,
    setIsOpenModalExportData,
    handleChangeSelectView,
    valueSelectView,
    type,
  }
}
