import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import { Text } from '@chakra-ui/react'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { useQuizQuestion } from './hook'

/**
 * @returns Component Quiz Question Screen Page
 */
export const QuizQuestionScreen = () => {
  const { dataQuestion, getDetailQuizQuestion } = useQuizQuestion()

  const columns = [
    {
      title: 'action',
      render: record => {
        return (
          <Box>
            <Text
              color={'#3F6FBA'}
              textDecoration={'underline'}
              onClick={() => getDetailQuizQuestion(record.id)}
            >
              Edit
            </Text>
          </Box>
        )
      },
    },
    {
      title: 'Order',
      render: record => <Box>{record?.order}</Box>,
    },
    {
      title: 'Content',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.content}</Box>
      ),
    },
    {
      title: 'SubContent1',
      render: record => <Box>{record?.subContent1}</Box>,
    },
    {
      title: 'SubContent2',
      render: record => <Box>{record?.subContent2}</Box>,
    },
    {
      title: 'profileTitle',
      render: record => <Box>{record?.profileTitle}</Box>,
    },
    {
      title: 'Classify',
      render: record => <Box>{record?.classify}</Box>,
    },
    {
      title: 'Group',
      render: record => <Box>{record?.group}</Box>,
    },
    {
      title: 'Type',
      render: record => <Box>{record?.type}</Box>,
    },
    {
      title: 'Created_At',
      render: record => (
        <Box>{new Date(record.createdAt).toLocaleString()}</Box>
      ),
    },
  ]

  return (
    <Flex flexDirection={'column'} gap={10}>
      <Box overflowX={'auto'} w={'100%'}>
        <TableCustom
          columns={columns}
          data={dataQuestion || []}
          onSelect={val => {
            getDetailQuizQuestion(val.id)
          }}
        />
      </Box>
    </Flex>
  )
}
