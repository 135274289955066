/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export type EditorProps = {
  value: string
  onChange: (newAnswer: string) => void
  name: string
}

/**
 * Editor component page.
 * @return {JSX.Element} Editor component.
 */
export const Editor: FC<EditorProps> = props => {
  const { value, onChange } = props

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ script: 'sub' }, { script: 'super' }],
      [{ size: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ font: [] }],
      ['link'],
      [{ color: ['red', '#785412'] }],
      [{ background: ['red', '#785412'] }],
    ],
  }

  const formats = [
    'bold',
    'italic',
    'underline',
    'script',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
  ]

  /**
   * @returns function handleEditorChange
   */
  const handleEditorChange = value => {
    onChange(value)
  }

  return (
    <ReactQuill
      formats={formats}
      modules={modules}
      theme="snow"
      value={value}
      onChange={handleEditorChange}
    />
  )
}
