import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useParams } from 'react-router'
import { Errors } from '../../../../../../types'
import {
  getUserDetail,
  updateStatusUser,
} from '../../../../../../service/administratorService'

const schema = yup.object().shape({
  id: yup.number(),
  email: yup.string().required(),
  phoneNumber: yup.string().required(),
  role: yup.object().shape({
    value: yup.number().required(),
    label: yup.string().required(),
  }),
})
type FormValue = yup.InferType<typeof schema>

const initialState: FormValue = {
  email: '',
  phoneNumber: '',
  role: { value: 1, label: 'ADMIN' },
}

/**
 * useAddAdministrators hook.
 */
export const useEditAdministrators = () => {
  const [administratorDetail, setAdministratorDetail] =
    useState<FormValue>(initialState)
  const [errors, setErrors] = useState<Errors>()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast()

  const fetchDetailAdminitrator = useCallback(async () => {
    if (!id) return
    setIsLoading(true)
    try {
      const detail = await getUserDetail(id)
      setAdministratorDetail(detail)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [id, toast])

  useEffect(() => {
    fetchDetailAdminitrator().then()
  }, [fetchDetailAdminitrator])

  const handleChangeInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target
      setAdministratorDetail(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )

  /**
   * @returns function that handle validates form
   */
  const validation = useCallback((): boolean => {
    setErrors(undefined)
    try {
      schema.validateSync(administratorDetail, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          const newErrors: Errors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }

    return true
  }, [administratorDetail])

  /**
   * @returns function that handle select dropdown
   */
  const handleOnChangeSelect = useCallback(
    (key: keyof FormValue, value: string) => {
      setAdministratorDetail({
        ...administratorDetail,
        [key]: value,
      })
    },
    [administratorDetail],
  )

  const handleSubmit = useCallback(
    async (id: number) => {
      if (validation()) {
        try {
          const payload = {
            email: administratorDetail.email,
            phoneNumber: administratorDetail.phoneNumber,
          }
          await updateStatusUser(id, payload)
          toast({
            position: 'top-right',
            status: 'success',
            title: 'Edit Success',
            duration: 1000,
          })
        } catch (e) {
          if (isAxiosError(e)) {
            const message = e?.response?.data.message
            toast({
              position: 'top-right',
              status: 'error',
              title: message,
              duration: 3000,
            })
          }
        }
      }
    },
    [
      administratorDetail.email,
      administratorDetail.phoneNumber,
      toast,
      validation,
    ],
  )

  const defaultValue = useMemo(() => {
    switch (administratorDetail.role.label) {
      case 'ADMIN':
        return { value: 1, label: 'ADMIN' }
      default:
        return undefined
    }
  }, [administratorDetail.role.label])

  return {
    handleSubmit,
    errors,
    handleChangeInput,
    administratorDetail,
    handleOnChangeSelect,
    isLoading,
    defaultValue,
  }
}
