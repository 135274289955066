import React from 'react'
import { Layout } from 'app/components/elements/Layout'
import { LoginScreen } from './components/modules'

/**
 * @returns Component Login Page
 */
export function LoginPage() {
  return (
    <Layout>
      <LoginScreen />
    </Layout>
  )
}
