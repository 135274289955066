import { useState, useCallback } from 'react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { login } from '../../../../../service/authService'

const schema = yup.object().shape({
  email: yup
    .string()
    .email('email is incorrect')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'email invalid',
    )
    .required('email required'),
  password: yup.string().required('password required'),
})

export type TFormValue = yup.InferType<typeof schema>

type IErrors = {
  [key: string]: {
    message: string
  }
}

/***
 *
 * Returns: useLogin
 */
export const useLogin = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [formValue, setFormValue] = useState<Partial<TFormValue>>({
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState<IErrors>({})

  const handleChange = useCallback(
    (values: Partial<TFormValue>) => {
      const newErrors = { ...errors }
      Object.keys(values).forEach(key => {
        delete newErrors[key]
      })
      setFormValue({ ...formValue, ...values })
      setErrors(newErrors)
    },
    [formValue, errors],
  )

  const validation = useCallback(() => {
    let newErrors: IErrors = {}
    try {
      schema.validateSync(formValue, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
        }
        setErrors(newErrors)
        return false
      }
    }
    return true
  }, [formValue])

  const handleSubmit = useCallback(async () => {
    if (validation()) {
      setIsLoading(true)
      try {
        if (formValue.email && formValue.password) {
          const res = await login({
            email: formValue.email,
            password: formValue.password,
          })
          navigate('/two_factor_auth', { state: res.email, replace: true })
        }
      } catch (e) {
        setErrors({
          wrongEmailPassword: {
            message: 'Your email address or password is incorrect.',
          },
        })
      } finally {
        setIsLoading(false)
      }
    }
  }, [formValue, validation, navigate])

  return {
    formValue,
    errors,
    isLoading,
    handleChange,
    handleSubmit,
  }
}

export type Props = ReturnType<typeof useLogin>
