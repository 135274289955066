import { extendTheme } from '@chakra-ui/react'

import { breakpoints } from './breakpoints'
import { colors } from './color'

const fonts = {
  body: `"Noto Sans JP", sans-serif`,
}

export const theme = extendTheme({
  fonts,
  breakpoints,
  colors,
})

export type TTheme = typeof theme
