import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import { isAxiosError } from 'axios'
import { getViewRules, updateRule } from '../../../../../service/viewRules'
import { TMetaType, TViewRules } from './type'

/**
 * @returns Component useViewRules hook
 */
export const useViewRules = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [fileUpload, setFileUpload] = useState<File>()
  const [dataViewRules, setDataViewRules] = useState<TViewRules[]>()
  const [meta, setMeta] = useState<TMetaType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [valueInput, setValueInput] = useState('')
  const [valueSelect, setValueSelect] = useState<{
    label: string
    value?: number
  }>({
    label: 'Select ...',
    value: undefined,
  })
  const toast = useToast()
  let [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')
  const brand = searchParams.get('brand')
  const keyword = searchParams.get('keyword')

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        includeOrExclude: brand ? Number(brand) : undefined,
        possibleAttributes: keyword ? keyword : undefined,
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getViewRules(params)
      setDataViewRules(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, toast, keyword, brand])

  useEffect(() => {
    fetchData().then()
  }, [fetchData])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        brand: String(valueSelect.value ?? ''),
        keyword: String(valueInput),
        page: String(currentPage),
      })
    },
    [setSearchParams, valueInput, valueSelect.value],
  )

  const handleChangeInput = useCallback(e => {
    setValueInput(e.target.value)
  }, [])

  const handleChangeSelect = useCallback(value => {
    setValueSelect(value)
  }, [])

  const handleSearchRules = useCallback(() => {
    setSearchParams({
      brand: String(valueSelect.value ?? ''),
      keyword: String(valueInput),
      page: String(1),
    })
  }, [setSearchParams, valueInput, valueSelect.value])

  /**
   * @returns function handle upload file xlsx
   */
  const handleUploadFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) {
        setFileUpload(file)
      }
    },
    [],
  )

  const handleSubmitFile = useCallback(async () => {
    try {
      // handle import file rule
      await updateRule(fileUpload)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    }
  }, [fileUpload, toast])

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef?.current.click()
    }
  }
  return {
    isLoading,
    dataViewRules,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNextPage,
    handleChangeInput,
    valueInput,
    handleChangeSelect,
    valueSelect,
    handleSearchRules,
    fileInputRef,
    handleFileSelect,
    handleUploadFile,
    handleSubmitFile,
    fileUpload,
  }
}
