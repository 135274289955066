import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { LabelProps, Label } from '../elements/Label'
import { ErrorText } from '../elements/ErrorText'
import { Input, IInputProps } from '../elements/Input/Input'

export type InputFormProps = IInputProps & {
  error?: string
  label?: string | React.ReactNode
  id?: string
  direction?: 'horizontal' | 'vertical'
  labelWidth?: string | number
  labelProps?: Omit<LabelProps, 'labelWidth' | 'label' | 'id' | 'isRequired'>
}

/**
 * InputForm component page.
 * @return {JSX.Element} InputForm component.
 */
export const InputForm: React.FC<InputFormProps> = ({
  id,
  error,
  label,
  labelProps,
  direction = 'vertical',
  labelWidth,
  isRequired,
  ...inputProps
}): React.ReactElement => {
  const errorInput = error || inputProps.isInvalid

  if (direction === 'horizontal') {
    return (
      <Box w={'full'}>
        <Flex alignItems="center" gap={2} w={'full'}>
          <Label
            id={id}
            isRequired={isRequired}
            label={label}
            labelWidth={labelWidth}
            mb={errorInput ? 6 : 0}
            {...labelProps}
          />
          <Flex flexDirection={'column'} gap={1} w={'full'}>
            <Input id={id} {...inputProps} />
            {error && <ErrorText>{error}</ErrorText>}
          </Flex>
        </Flex>
      </Box>
    )
  }

  return (
    <Flex flexDirection={'column'} gap={2} w={'full'}>
      <Label id={id} isRequired={isRequired} label={label} {...labelProps} />
      <Input id={id} {...inputProps} />
      {error && <ErrorText>{error}</ErrorText>}
    </Flex>
  )
}
