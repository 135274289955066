import React from 'react'
import { Button, Spinner, Flex, GridItem } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { Box, Text } from '@chakra-ui/layout'
import { InputForm } from '../../../../components/modules/InputForm'
import { CheckBoxForm } from '../../../../components/elements/Input/CheckBoxForm'
import { Props, useQuizAnswerDetail } from './hooks'

const StyledWrapperButton = styled(Flex)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  gap: 8px;
`

const StyledButtonOutline = styled(Button)`
  color: #3f6fba;
  background: transparent;
  border: 1px solid #3f6fba;
`

const StyledButtonSolid = styled(Button)`
  color: #ffffff;
  background: #3f6fba;
  &:hover {
    background: #3f6fba;
  }
`

/**
 * @returns Component
 */
const Component = (props: Props) => {
  const {
    isLoading,
    handleCancel,
    handleSubmit,
    answerDetail,
    handleChangeInput,
    setAnswerDetail,
  } = props
  if (isLoading) {
    return (
      <Flex justifyContent={'center'}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <>
      <Text color={'black'} fontSize={20} fontWeight={700} mb={6}>
        Answer Quiz
      </Text>
      <Box mb={6}>
        <Text>Question ID : {answerDetail?.id}</Text>
        <Text>Oder : {answerDetail?.order}</Text>
        {answerDetail.countryName && (
          <Text>Country Name : {answerDetail?.countryName}</Text>
        )}
      </Box>
      <GridItem>
        <InputForm
          isRequired
          label={'Content'}
          name={'content'}
          value={answerDetail?.content}
          onChange={handleChangeInput}
        />
      </GridItem>
      <GridItem>
        <InputForm
          isRequired
          label={'Short Content'}
          name={'shortContent'}
          value={answerDetail?.shortContent}
          onChange={handleChangeInput}
        />
      </GridItem>
      <Flex mt={4}>
        <CheckBoxForm
          children={'Show Profile'}
          isChecked={answerDetail?.showProfile}
          onChange={event => {
            setAnswerDetail({
              ...answerDetail,
              showProfile: event.target.checked,
            })
          }}
        />
      </Flex>

      <StyledWrapperButton>
        <StyledButtonOutline onClick={handleCancel}>Cancel</StyledButtonOutline>
        <StyledButtonSolid onClick={handleSubmit}>Save</StyledButtonSolid>
      </StyledWrapperButton>
    </>
  )
}

/***
 *
 * Returns: QuizAnswerDetailScreen Screen
 */
export const QuizAnswerDetailScreen = () => {
  return <Component {...useQuizAnswerDetail()} />
}
