import { useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import { isAxiosError } from 'axios'
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { Errors } from '../../../../../types'
import { useParams } from '../../../../../hooks/useRouter'
import {
  getDermTipsDetail,
  updateDermTips,
} from '../../../../../service/dermTip'

const schema = yup.object().shape({
  id: yup.number(),
  content: yup.string().required(),
})
type FormValue = yup.InferType<typeof schema>

/**
 * useDermTips hook.
 */
export const useDermTips = () => {
  const [dermTipsDetail, setDermTipsDetail] = useState<Partial<FormValue>>({})
  const [errors, setErrors] = useState<Errors>()
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast()
  const navigate = useNavigate()

  const fetchDetailDermTips = useCallback(async () => {
    if (!id) return
    setIsLoading(true)
    try {
      const detail = await getDermTipsDetail(id)
      setDermTipsDetail(detail)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [id, toast])

  useEffect(() => {
    fetchDetailDermTips().then()
  }, [fetchDetailDermTips])

  const handleChangeInput = useCallback((name: string, value) => {
    setDermTipsDetail(prevState => ({
      ...prevState,
      [name]: value,
    }))
    setErrors(prevState => ({
      ...prevState,
      [name]: { message: '' },
    }))
  }, [])

  /**
   * @returns function that handle validates form
   */
  const validation = useCallback((): boolean => {
    setErrors(undefined)
    try {
      schema.validateSync(dermTipsDetail, { abortEarly: false })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        if (error.inner) {
          const newErrors: Errors = {}
          error.inner.forEach(err => {
            if (err.path && err.message) {
              newErrors[err.path] = { message: err.message }
            }
          })
          setErrors(newErrors)
          return false
        }
      }
    }

    return true
  }, [dermTipsDetail])

  const handleSubmit = useCallback(
    async (id: number) => {
      if (validation()) {
        try {
          const payload = {
            content: dermTipsDetail.content,
          }
          await updateDermTips(id, payload)
          toast({
            position: 'top-right',
            status: 'success',
            title: 'Edit Success',
            duration: 1000,
          })
        } catch (e) {
          if (isAxiosError(e)) {
            const message = e?.response?.data.message
            toast({
              position: 'top-right',
              status: 'error',
              title: message,
              duration: 3000,
            })
          }
        }
      }
    },
    [dermTipsDetail.content, toast, validation],
  )
  return {
    handleSubmit,
    errors,
    handleChangeInput,
    dermTipsDetail,
    isLoading,
    navigate,
  }
}
