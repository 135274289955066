const ACCESS_TOKEN_KEY = 'accessToken'
const REFRESH_TOKEN_KEY = 'refreshToken'

/**
 * @returns function handle set token in local storage
 */
export const setAccessToken = (accessToken: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken)

/**
 * @returns function handle get access token in local storage
 */
export const getAccessToken = (): string | null =>
  localStorage.getItem(ACCESS_TOKEN_KEY)

/**
 * @returns function handle remove access token in local storage
 */
export const removeAccessToken = () => localStorage.removeItem(ACCESS_TOKEN_KEY)

/**
 * @returns function handle set refresh token in local storage
 */
export const setRefreshToken = (refreshToken: string) =>
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)

/**
 * @returns function handle get refresh token in local storage
 */
export const getRefreshToken = (): string | null =>
  localStorage.getItem(REFRESH_TOKEN_KEY)

/**
 * @returns function handle remove refresh token in local storage
 */
export const removeRefreshToken = () =>
  localStorage.removeItem(REFRESH_TOKEN_KEY)
