import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import { Button, Image, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { images } from 'app/assets/images'
import { ErrorText } from 'app/components/elements/ErrorText'
import { Input } from 'app/components/elements/Input/Input'
import { useLogin, Props } from './hooks'

const TextLabel = styled(Text)`
  min-width: 90px;
`

/**
 * @returns Component
 */
const Component = (props: Props) => {
  const { formValue, isLoading, handleChange, handleSubmit, errors } = props
  return (
    <Flex
      alignItems={'center'}
      bg={'#f8f8f8'}
      display={'flex'}
      h={'100vh'}
      justifyContent={'center'}
      px={10}
      w={'100vw'}
    >
      <Box bg={'#fff'} border={'1px solid #000'} maxW={'800px'} w={'100%'}>
        <Flex
          alignItems={'center'}
          flexDirection={'column'}
          gap={'24px'}
          justifyContent={'center'}
          p={'30px 30px 60px'}
          w={'100%'}
        >
          <Box>
            <Image h={'auto'} src={images.logoAllSkin} w={'150px'} />
            <Text color={'#368bc7'} fontSize={'sm'} textAlign={'center'}>
              Admin Product Portal
            </Text>
          </Box>
          {!!errors?.wrongEmailPassword && (
            <ErrorText fontSize={'15px'}>
              {errors?.wrongEmailPassword?.message}
            </ErrorText>
          )}
          <Flex flexDirection={'column'} gap={'10px'}>
            <Box>
              <Flex alignItems={'center'}>
                <TextLabel>Username</TextLabel>
                <Box>
                  <Input
                    isRequired
                    h={'38px'}
                    isInvalid={!!errors?.email?.message}
                    value={formValue.email}
                    w={'220px'}
                    onChange={e => handleChange({ email: e.target.value })}
                  />
                </Box>
              </Flex>
              {!!errors?.email && (
                <ErrorText ml={'90px'}>{errors?.email?.message}</ErrorText>
              )}
            </Box>

            <Box>
              <Flex alignItems={'center'}>
                <TextLabel>Password</TextLabel>
                <Input
                  isRequired
                  h={'38px'}
                  isInvalid={!!errors?.password?.message}
                  type={'password'}
                  value={formValue.password}
                  w={'220px'}
                  onChange={e => handleChange({ password: e.target.value })}
                />
              </Flex>
              {!!errors?.password && (
                <ErrorText ml={'90px'}>{errors?.password?.message}</ErrorText>
              )}
            </Box>
          </Flex>

          <Button
            bg={'#000'}
            color={'#fff'}
            isLoading={isLoading}
            maxW={'120px'}
            w={'100%'}
            onClick={handleSubmit}
          >
            <Text>Login</Text>
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}

/***
 *
 * Returns: Login Screen
 */
export const LoginScreen = () => {
  return <Component {...useLogin()} />
}
