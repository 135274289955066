import qs from 'qs'
import { axiosWithToken } from '../configureAxios'
import {
  TIngredientTagsList,
  TProductAnalysisFirst,
} from '../app/pages/IngredientTagsPage/components/modules/type'
import { TIngredientDictionaryList } from '../app/pages/IngredientDictionaryPage/components/modules/type'
import { TIngredientInciList } from '../app/pages/IngredientINCIPage/components/modules/type'
import { TIngredientDefinitionList } from '../app/pages/IngredientDefinitionPage/components/modules/type'

/***
 *
 * add update Ingredient
 */
export const updateIngredient = async payload => {
  const formData = new FormData()
  formData.append('file', payload)
  const { data } = await axiosWithToken.post(
    `/ingredients/tags/import?removeAll=true`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
  return data
}

type IngredientTagType = {
  page: number
  limit: number
}

/**
 * @returns get Ingredient tag
 * */
export const getIngredientTags = async (
  params: IngredientTagType,
): Promise<TIngredientTagsList> => {
  const { data } = await axiosWithToken.get('/ingredients/tags', {
    params,
    paramsSerializer: {
      serialize: args => qs.stringify(args),
    },
  })
  return data
}

/**
 * @returns get Product Analysis First
 * */
export const getProductAnalysisFirst =
  async (): Promise<TProductAnalysisFirst> => {
    const { data } = await axiosWithToken.get('/product-analysis/first')
    return data
  }

/**
 * @returns post Product Analysis
 * */
export const addProductAnalysis = async (
  params,
): Promise<TProductAnalysisFirst> => {
  const { data } = await axiosWithToken.post('/product-analysis', params)
  return data
}

type IngredientDictionaryType = {
  page: number
  limit: number
}

/**
 * @returns get Ingredient Dictionary
 * */
export const getIngredientDictionary = async (
  params: IngredientDictionaryType,
): Promise<TIngredientDictionaryList> => {
  const { data } = await axiosWithToken.get(
    '/ingredients/dictionary-manager/ingredient',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}

/***
 *
 * add update Ingredient Dictionary
 */
export const updateIngredientDictionary = async payload => {
  const formData = new FormData()
  formData.append('file', payload)
  const { data } = await axiosWithToken.post(
    `/ingredients/dictionary-manager/import?removeAll=true`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
  return data
}

type IngredientInciType = {
  page: number
  limit: number
}

/**
 * @returns get Ingredient Inci
 * */
export const getIngredientInci = async (
  params: IngredientInciType,
): Promise<TIngredientInciList> => {
  const { data } = await axiosWithToken.get(
    '/ingredients/dictionary-manager/inci',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}

type IngredientDefinitonType = {
  page: number
  limit: number
}

/**
 * @returns get Ingredient Definition
 * */
export const getIngredientDefinition = async (
  params: IngredientDefinitonType,
): Promise<TIngredientDefinitionList> => {
  const { data } = await axiosWithToken.get(
    '/ingredients/dictionary-manager/function-definition',
    {
      params,
      paramsSerializer: {
        serialize: args => qs.stringify(args),
      },
    },
  )
  return data
}
