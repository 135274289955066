import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button, Spinner } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import styled from 'styled-components'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { InputDateTime } from '../../../../components/elements/Input/InputDateTime'
import { ModalExportData } from '../../../../components/modules/ModalExportData'
import { Select } from '../../../../components/elements/Select'
import { useUserRoutine } from './hook'
import { OptionSelect } from './constants'

const TextError = styled(Text)`
  color: #ba1a1a;
`
/**
 * @returns Component UserRoutine Screen Page
 */
export const UserRoutineScreen = () => {
  const {
    dataUserRoutine,
    currentPage,
    totalPage,
    handleNextPage,
    handleChangeStartDate,
    valueStartAt,
    handleChangeEndDate,
    valueEndAt,
    handleSearch,
    handleReset,
    isLoading,
    textValidate,
    navigate,
    handleExportToXLSX,
    downloadedItem,
    handleClickDownload,
    isButtonChosen,
    isOpenModalExportData,
    setIsOpenModalExportData,
    totalItem,
    handleChangeSelect,
    valueSelect,
    type,
    handleExportAttributesToXLSX,
  } = useUserRoutine()

  const getPossibleAttributes = record => {
    if (record?.possibleAttributes === 'Acne') {
      if (record?.order === 5) return `Acne-general (question ${record?.order})`
      if (record?.order === 7) return `Acne-face (question ${record?.order})`
      if (record?.order === 8) return `Acne-body (question ${record?.order})`
      return `${record?.possibleAttributes} (question ${record?.order})`
    }
    return `${record?.possibleAttributes} (question ${record?.order})`
  }

  const statisticsClicksField = type => {
    switch (type) {
      case OptionSelect[1].value:
        return [
          {
            title: 'User ID',
            render: record => <Box>{record?.id}</Box>,
          },
          {
            title: 'email',
            render: record => <Box>{record?.email}</Box>,
          },
          {
            title: 'first Name',
            render: record => (
              <Box>{`${record?.firstName} ${record?.lastName}`}</Box>
            ),
          },
          {
            title: 'phone Number',
            render: record => <Box>{record?.phoneNumber}</Box>,
          },
          {
            title: '# Products in Routines',
            render: record => (
              <Box
                _hover={{ textDecoration: 'underline' }}
                onClick={() => {
                  navigate(`/detail-product-routine/${record.id}`)
                }}
              >
                {`${record.numberOfRoutine} product`}
              </Box>
            ),
          },
          {
            title: 'status',
            render: record => <Box>{record?.status}</Box>,
          },
        ]

      default:
        return [
          {
            title: 'Attributes',
            render: record => <Box>{getPossibleAttributes(record)}</Box>,
          },
          {
            title: 'TOTAL USERS AFFECTED',
            render: record => <Box>{record?.totalOfUse}</Box>,
          },
          {
            title: 'CLASSIFY',
            render: record => <Box>{record?.classify}</Box>,
          },
        ]
    }
  }
  const columns = statisticsClicksField(type)

  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} flexDirection={'column'} gap={10}>
        <Flex alignItems={'center'} flexDirection={'row'}>
          <Text
            color={'#000'}
            fontFamily={'Hiragino Kaku Gothic Pro'}
            fontSize={'sm'}
            fontWeight={'semibold'}
            lineHeight={'16px'}
            margin={8}
          >
            {'Choose Product Views'}
          </Text>
          <Select
            options={OptionSelect}
            value={valueSelect}
            width={200}
            onChange={newValue => handleChangeSelect(newValue)}
          />
        </Flex>
        {type === OptionSelect[0].value || !type ? (
          <></>
        ) : (
          <Flex
            alignItems={'center'}
            gap={10}
            justifyContent={'space-between'}
            w={'full'}
          >
            <Button
              _hover={{ bg: '#222', cursor: 'pointer' }}
              bg={'#222'}
              color={'#fff'}
              h={'32px'}
              w={'91px'}
              onClick={handleReset}
            >
              Reset
            </Button>
            <InputDateTime
              direction="horizontal"
              isShowTime={false}
              label={'Start Date'}
              value={valueStartAt ? new Date(valueStartAt) : undefined}
              onChange={date => {
                handleChangeStartDate(date)
              }}
            />
            <InputDateTime
              direction="horizontal"
              isShowTime={false}
              label={'End Date'}
              value={valueEndAt ? new Date(valueEndAt) : undefined}
              onChange={date => {
                handleChangeEndDate(date)
              }}
            />
            <Button
              _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
              bg={'#3F6FBA'}
              color={'#fff'}
              leftIcon={<SearchIcon />}
              p={'0px 34px'}
              w={160}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Flex>
        )}

        <Box>{textValidate && <TextError>{textValidate}</TextError>}</Box>
        {isLoading && <Spinner />}
        {dataUserRoutine && dataUserRoutine?.length && !isLoading && (
          <>
            <Box overflowX={'auto'} w={'100%'}>
              <TableCustom columns={columns} data={dataUserRoutine || []} />
            </Box>
            <Box py={5}>
              {type === OptionSelect[0].value || !type ? (
                <></>
              ) : (
                <Pagination
                  page={currentPage}
                  totalPage={totalPage}
                  onChange={handleNextPage}
                />
              )}
            </Box>
          </>
        )}
        <Flex alignSelf={'flex-start'}>
          {type === OptionSelect[0].value || !type ? (
            <Button
              _hover={{ bg: '#3F6FBA' }}
              bg={'#3F6FBA'}
              color={'#fff'}
              w={'max-content'}
              onClick={handleExportAttributesToXLSX}
            >
              Export Data
            </Button>
          ) : (
            <Button
              _hover={{ bg: '#3F6FBA' }}
              bg={'#3F6FBA'}
              color={'#fff'}
              w={'max-content'}
              onClick={handleExportToXLSX}
            >
              Export Data
            </Button>
          )}
        </Flex>
        {dataUserRoutine?.length === 0 && !isLoading && (
          <Flex alignItems={'center'} justifyContent={'center'}>
            No Data
          </Flex>
        )}
        <ModalExportData
          downloadedItem={downloadedItem}
          handleClickDownload={handleClickDownload}
          isButtonChosen={isButtonChosen}
          isOpenModal={isOpenModalExportData}
          totalItem={totalItem}
          onCloseModal={() => setIsOpenModalExportData(false)}
        />
      </Flex>
    </Flex>
  )
}
