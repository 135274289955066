import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { isAxiosError } from 'axios'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { getDetailBrand, putBrands } from '../../../service/promotionService'
import { Errors } from '../../../types'
import { TBrandDetail } from './components/modules/type'

/**
 * usePromotionScreen hook.
 */
export const usePromotedBrandDetailScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [brandDetail, setBrandDetail] = useState<TBrandDetail>({
    id: 0,
    name: 'demo',
    promoted: false,
    description: 'demo',
  })
  const [errors, setErrors] = useState<Errors>()
  const toast = useToast()
  const { id } = useParams()
  const navigate = useNavigate()

  const handleChangeForm = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target
      setBrandDetail(prevState => ({
        ...prevState,
        [name]: value,
      }))
      setErrors(prevState => ({
        ...prevState,
        [name]: { message: '' },
      }))
    },
    [],
  )
  const fetchBrandData = useCallback(async () => {
    setIsLoading(true)
    try {
      if (id) {
        const res = await getDetailBrand(id)
        setBrandDetail(res)
      }
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [toast])

  useEffect(() => {
    fetchBrandData().then()
  }, [fetchBrandData])

  const handleTogglePromotion = useCallback(() => {
    setBrandDetail({
      ...brandDetail,
      promoted: !brandDetail.promoted,
    })
  }, [brandDetail])

  const handleSavePromotedBrand = useCallback(async () => {
    setIsLoading(true)
    try {
      if (id) {
        await putBrands(id, {
          name: brandDetail.name,
          description: brandDetail.description,
          promoted: brandDetail.promoted ? 1 : 0,
        })
      }
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Change Success',
        duration: 1000,
      })
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [
    brandDetail.description,
    brandDetail.name,
    brandDetail.promoted,
    id,
    toast,
  ])

  const handleBack = useCallback(() => navigate('/promoted-brand'), [navigate])

  return {
    isLoading,
    brandDetail,
    handleChangeForm,
    errors,
    handleTogglePromotion,
    handleSavePromotedBrand,
    handleBack,
  }
}

export type Props = ReturnType<typeof usePromotedBrandDetailScreen>
