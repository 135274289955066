import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import styled from '@emotion/styled'
import { BulkAdd } from './components/modules/BulkAdd'
import { SingleAdd } from './components/modules/AddProduct'

const Line = styled(Box)`
  width: 100%;
  height: 1px;
  background: #000000;
`

/**
 * AddProductPage component page.
 * @return {JSX.Element} AddProductPage component.
 */
export function AddProductPage() {
  return (
    <Flex flexDirection={'column'} gap={4} w={'100%'}>
      <BulkAdd />
      <Line />
      <SingleAdd />
    </Flex>
  )
}
