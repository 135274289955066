import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import { Button, Image, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { images } from 'app/assets/images'
import { Input } from '../../../../components/elements/Input/Input'
import { ErrorText } from '../../../../components/elements/ErrorText'
import { Props, useTwoFactorAuth } from './hooks'

const TextLabel = styled(Text)`
  min-width: 90px;
  font-size: 20px;
  font-weight: 400;
`

/**
 * @returns Component
 */
const Component = (props: Props) => {
  const { isLoading, handleSubmit, handleChange, value, error } = props
  return (
    <Flex
      alignItems={'center'}
      bg={'#f8f8f8'}
      display={'flex'}
      h={'100vh'}
      justifyContent={'center'}
      px={10}
      w={'100vw'}
    >
      <Box bg={'#fff'} border={'1px solid #000'} maxW={'800px'} w={'100%'}>
        <Flex
          alignItems={'center'}
          flexDirection={'column'}
          gap={'24px'}
          justifyContent={'center'}
          p={'30px 30px 60px'}
          w={'100%'}
        >
          <Box>
            <Image h={'auto'} src={images.logoAllSkin} w={'150px'} />
            <Text color={'#368bc7'} fontSize={'sm'} textAlign={'center'}>
              Admin Product Portal
            </Text>
          </Box>
          <Flex flexDirection={'column'} gap={'10px'}>
            <Box>
              <Flex alignItems={'center'} flexDirection={'column'} gap={10}>
                <TextLabel>Authenticate Your Account</TextLabel>
                <Flex alignItems={'center'} flexDirection={'column'} gap={4}>
                  <Input
                    h={'38px'}
                    max={6}
                    type="number"
                    value={value}
                    w={'220px'}
                    onChange={handleChange}
                  />
                  {error && <ErrorText>{error}</ErrorText>}
                </Flex>
              </Flex>
            </Box>
          </Flex>

          <Button
            _hover={{ bg: 'blue' }}
            bg={'blue'}
            color={'#fff'}
            isLoading={isLoading}
            maxW={'120px'}
            w={'100%'}
            onClick={handleSubmit}
          >
            Verify
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}

/***
 *
 * Returns: TwoFactorAuth Screen
 */
export const TwoFactorAuthScreen = () => {
  return <Component {...useTwoFactorAuth()} />
}
