import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import styled from 'styled-components'
import moment from 'moment/moment'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { InputDateTime } from '../../../../components/elements/Input/InputDateTime'
import { InfoUser } from '../../../../components/modules/InfoUser'
import { useAllViewsProduct } from './hook'

const TextError = styled(Text)`
  color: #ba1a1a;
`

/**
 * @returns Component AllViewsProduct Screen Page
 */
export const AllViewsProductScreen = () => {
  const {
    dataProductView,
    currentPage,
    totalPage,
    handleNextPage,
    handleChangeStartDate,
    valueStartAt,
    handleChangeEndDate,
    valueEndAt,
    handleSearch,
    handleReset,
    isLoading,
    textValidate,
    navigate,
    record,
  } = useAllViewsProduct()

  const columns = [
    {
      title: 'product Id',
      render: record => (
        <Box
          _hover={{ textDecoration: 'underline' }}
          whiteSpace={'pre-wrap'}
          onClick={() => {
            navigate(`/product-detail/${record?.productId}`)
          }}
        >
          {record?.productId}
        </Box>
      ),
    },
    {
      title: 'Date Viewed',
      render: record => (
        <Box
          _hover={{ textDecoration: 'underline' }}
          whiteSpace={'pre-wrap'}
          width={'max-content'}
          onClick={() => {
            navigate(`/product-detail/${record?.productId}`)
          }}
        >
          {moment(record?.createdAt).format('YYYY/MM/DD, HH:mm:ss A')}
        </Box>
      ),
    },
    {
      title: 'product name',
      render: record => (
        <Box
          _hover={{ textDecoration: 'underline' }}
          whiteSpace={'pre-wrap'}
          onClick={() => {
            navigate(`/product-detail/${record?.productId}`)
          }}
        >
          {record?.product.product}
        </Box>
      ),
    },
    {
      title: 'UPC',
      render: record => (
        <Box
          _hover={{ textDecoration: 'underline' }}
          whiteSpace={'pre-wrap'}
          onClick={() => {
            navigate(`/product-detail/${record?.productId}`)
          }}
        >
          {record?.product.upc}
        </Box>
      ),
    },
  ]

  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} flexDirection={'column'} gap={10}>
        <Flex
          alignItems={'center'}
          gap={10}
          justifyContent={'space-between'}
          w={'full'}
        >
          <Button
            _hover={{ bg: '#222', cursor: 'pointer' }}
            bg={'#222'}
            color={'#fff'}
            h={'32px'}
            w={'91px'}
            onClick={handleReset}
          >
            Reset
          </Button>
          <InputDateTime
            direction="horizontal"
            isShowTime={false}
            label={'Start Date'}
            value={valueStartAt ? new Date(valueStartAt) : undefined}
            onChange={date => {
              handleChangeStartDate(date)
            }}
          />
          <InputDateTime
            direction="horizontal"
            isShowTime={false}
            label={'End Date'}
            value={valueEndAt ? new Date(valueEndAt) : undefined}
            onChange={date => {
              handleChangeEndDate(date)
            }}
          />
          <Button
            _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            p={'0px 34px'}
            w={160}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Flex>
        {!isLoading && <InfoUser user={record} />}
        <Box>{textValidate && <TextError>{textValidate}</TextError>}</Box>
        {dataProductView && !!dataProductView.length && (
          <>
            <Box overflowX={'auto'} w={'100%'}>
              <TableCustom columns={columns} data={dataProductView || []} />
            </Box>
            <Box py={5}>
              <Pagination
                page={currentPage}
                totalPage={totalPage}
                onChange={handleNextPage}
              />
            </Box>
          </>
        )}
        {dataProductView?.length === 0 && !isLoading && (
          <Flex alignItems={'center'} justifyContent={'center'}>
            No Data
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
