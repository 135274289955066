import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDisclosure, useToast } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import { isAxiosError } from 'axios'
import {
  getIngredientInci,
  updateIngredientDictionary,
} from '../../../../../service/ingredients'
import { TIngredientInci, TMetaType } from './type'

/**
 * @returns Component useIngredientINCI hook
 */
export const useIngredientINCI = () => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [fileUpload, setFileUpload] = useState<File>()
  const [dataIngredientINCI, setDataIngredientINCI] =
    useState<TIngredientInci[]>()
  const [meta, setMeta] = useState<TMetaType>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false)
  const {
    isOpen: isOpenSubmit,
    onOpen: onOpenSubmit,
    onClose: onCloseSubmit,
  } = useDisclosure()

  const toast = useToast()
  let [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page')

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        page: page ? Number(page) : 1,
        limit: 15,
      }
      const response = await getIngredientInci(params)
      setDataIngredientINCI(response.data)
      setMeta(response.meta)
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 3000,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, toast])

  useEffect(() => {
    fetchData().then()
  }, [page])

  const handleNextPage = useCallback(
    (currentPage: number) => {
      window.scrollTo(0, 0)
      setSearchParams({
        page: String(currentPage),
      })
    },
    [setSearchParams],
  )
  /**
   * @returns function handle upload file xlsx
   */
  const handleUploadFile = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]
      if (file) {
        setFileUpload(file)
      }
    },
    [],
  )

  const handleSubmitFile = useCallback(async () => {
    setIsLoadingSubmit(true)
    try {
      await updateIngredientDictionary(fileUpload)
      toast({
        position: 'top-right',
        status: 'success',
        title: 'Submit Success',
        duration: 1000,
      })
      onCloseSubmit()
    } catch (e) {
      if (isAxiosError(e)) {
        const message = e?.response?.data.message
        toast({
          position: 'top-right',
          status: 'error',
          title: message,
          duration: 1000,
        })
      }
    }
  }, [fileUpload, onCloseSubmit, toast])

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef?.current.click()
    }
  }

  const handleOpenModalSubmit = useCallback(async () => {
    onOpenSubmit()
  }, [onOpenSubmit])

  return {
    isLoadingSubmit,
    isLoading,
    dataIngredientINCI,
    meta,
    totalPage: meta?.totalPage || 0,
    currentPage: page ? Number(page) : 1,
    handleNextPage,
    fileInputRef,
    handleFileSelect,
    handleUploadFile,
    handleSubmitFile,
    fileUpload,
    isOpenSubmit,
    onCloseSubmit,
    handleOpenModalSubmit,
  }
}
