import React from 'react'
import styled from '@emotion/styled'
import { Flex, Text } from '@chakra-ui/layout'
import { Box, Button, Switch } from '@chakra-ui/react'
import { InputForm } from '../../../../components/modules/InputForm'
import { TextAreaBase } from '../../../../components/elements/TextAreaBase'
import { useCreatePromoteBrand } from './hooks'

/**
 * @returns Component CreateBrand Screen
 */
export const PromotedBrandCreateScreen = () => {
  const {
    handleSubmit,
    formValue,
    errors,
    handleChangeInput,
    handleTogglePromotion,
  } = useCreatePromoteBrand()
  return (
    <>
      <StyledContainer>
        <Box maxW={400}>
          <InputForm
            isRequired
            error={errors?.name?.message}
            isInvalid={!!errors?.name?.message}
            label={'Name'}
            name={'name'}
            value={formValue?.name}
            onChange={handleChangeInput}
          />
        </Box>
        <TextAreaBase
          isRequired
          error={errors?.description?.message}
          isInvalid={!!errors?.description?.message}
          label={'Description'}
          name={'description'}
          placeholder="Enter description ..."
          value={formValue?.description ?? ''}
          onChange={handleChangeInput}
        />
        <Flex alignItems={'center'} gap={'16px'}>
          <TextPromotion>Promotion</TextPromotion>
          <Switch
            isChecked={formValue.promoted}
            size="md"
            onChange={handleTogglePromotion}
          />
        </Flex>
      </StyledContainer>
      <StyledWrapperButton>
        <StyledButtonSubmit onClick={handleSubmit}>Submit</StyledButtonSubmit>
      </StyledWrapperButton>
    </>
  )
}

const StyledContainer = styled(Flex)`
  gap: 20px;
  flex-direction: column;
  margin: 40px;
`
const StyledWrapperButton = styled(Flex)`
  justify-content: center;
`
const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  height: 32px;
  border-radius: 25px;
  background: #385187;
  color: #ffffff;
  &:hover {
    background: #385187;
    cursor: pointer;
  }
`
const TextPromotion = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 700;
`
