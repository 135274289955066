export type Option = {
  value: string
  label: string
}
export const OptionSelect: Option[] = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
]
