import React from 'react'
import { Box, Flex, Text } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import styled from 'styled-components'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { InputDateTime } from '../../../../components/elements/Input/InputDateTime'
import { ModalExportData } from '../../../../components/modules/ModalExportData'
import { useMonthlyUserUseApp } from './hook'

const TextError = styled(Text)`
  color: #ba1a1a;
`
const TitleText = styled(Text)`
  font-size: 20px;
  font-weight: 500;
  color: #000;
`

/**
 * @returns Component MonthlyUserUseApp Screen Page
 */
export const MonthlyUserUseAppScreen = () => {
  const {
    dataMonthlyUserUseApp,
    currentPage,
    totalPage,
    handleNextPage,
    handleChangeStartDate,
    valueStartAt,
    handleChangeEndDate,
    valueEndAt,
    handleSearch,
    handleReset,
    isLoading,
    textValidate,
    meta,
    handleExportToXLSX,
    isOpenModalExportData,
    downloadedItem,
    isButtonChosen,
    handleClickDownload,
    totalItem,
    setIsOpenModalExportData,
  } = useMonthlyUserUseApp()

  const columns = [
    {
      title: 'Name',
      render: record => <Box>{record?.firstName}</Box>,
    },
    {
      title: 'email',
      render: record => <Box>{record?.email}</Box>,
    },
    {
      title: 'phone Number',
      render: record => <Box>{record?.phoneNumber}</Box>,
    },
    {
      title: 'region',
      render: record => <Box>{record?.region}</Box>,
    },
  ]
  return (
    <Flex flexDirection={'column'}>
      <Flex alignItems={'center'} flexDirection={'column'} gap={10}>
        <Flex
          alignItems={'center'}
          gap={10}
          justifyContent={'space-between'}
          w={'full'}
        >
          <Button
            _hover={{ bg: '#222', cursor: 'pointer' }}
            bg={'#222'}
            color={'#fff'}
            h={'32px'}
            w={'91px'}
            onClick={handleReset}
          >
            Reset
          </Button>
          <InputDateTime
            direction="horizontal"
            isShowTime={false}
            label={'Start Date'}
            value={valueStartAt ? new Date(valueStartAt) : undefined}
            onChange={date => {
              handleChangeStartDate(date)
            }}
          />
          <InputDateTime
            direction="horizontal"
            isShowTime={false}
            label={'End Date'}
            value={valueEndAt ? new Date(valueEndAt) : undefined}
            onChange={date => {
              handleChangeEndDate(date)
            }}
          />
          <Button
            _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            p={'0px 34px'}
            w={160}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Flex>
        <Box>{textValidate && <TextError>{textValidate}</TextError>}</Box>
        <TitleText>
          {`During the month, there were ${meta?.total} users using the app`}
        </TitleText>
        {dataMonthlyUserUseApp && !!dataMonthlyUserUseApp.length && (
          <>
            <Box overflowX={'auto'} w={'100%'}>
              <TableCustom
                columns={columns}
                data={dataMonthlyUserUseApp || []}
              />
            </Box>
            <Box py={5}>
              <Pagination
                page={currentPage}
                totalPage={totalPage}
                onChange={handleNextPage}
              />
            </Box>
          </>
        )}
        <Flex alignSelf={'flex-start'}>
          <Button
            _hover={{ bg: '#3F6FBA' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            w={'max-content'}
            onClick={handleExportToXLSX}
          >
            Export Data
          </Button>
        </Flex>
        {dataMonthlyUserUseApp?.length === 0 && !isLoading && (
          <Flex alignItems={'center'} justifyContent={'center'}>
            No Data
          </Flex>
        )}
        <ModalExportData
          downloadedItem={downloadedItem}
          handleClickDownload={handleClickDownload}
          isButtonChosen={isButtonChosen}
          isOpenModal={isOpenModalExportData}
          totalItem={totalItem}
          onCloseModal={() => setIsOpenModalExportData(false)}
        />
      </Flex>
    </Flex>
  )
}
