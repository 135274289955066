import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react'
import { RepeatIcon, SearchIcon } from '@chakra-ui/icons'
import { Input } from '../../../../components/elements/Input/Input'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { useAdministratorsInActive } from './hook'

/**
 * @returns Component AdministratorsInActive Page
 */
export const AdministratorsInActiveScreen = () => {
  const {
    isLoading,
    dataAdministratorInActive,
    handleEdit,
    currentPage,
    totalPage,
    handleNextPage,
    valueInput,
    handleChangeInput,
    handleSearchAdministrator,
    handleRestore,
    isOpen,
    onClose,
    onOpenRestore,
  } = useAdministratorsInActive()
  const columns = [
    {
      title: 'edit',
      render: ({ id }) => (
        <Box onClick={() => handleEdit(id)}>
          <Text color={'#3F6FBA'} textDecoration={'underline'}>
            Edit
          </Text>
        </Box>
      ),
    },
    {
      title: 'firstName',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.firstName}</Box>
      ),
    },
    {
      title: 'lastName',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.lastName}</Box>
      ),
    },
    {
      title: 'email',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.email}</Box>
      ),
    },
    {
      title: 'phoneNumber',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>
          {record?.phoneNumber}
        </Box>
      ),
    },
    {
      title: 'role',
      render: record => (
        <Box _hover={{ textDecoration: 'underline' }}>{record?.role}</Box>
      ),
    },
    {
      title: 'action',
      render: record => (
        <Tag
          colorScheme="blue"
          variant="outline"
          onClick={() => onOpenRestore(record.id)}
        >
          <TagLabel mr={1}>Restore</TagLabel>
          <RepeatIcon cursor={'point'} />
        </Tag>
      ),
    },
  ]

  return (
    <Flex flexDirection={'column'} gap={10}>
      <Flex gap={'20px'} justifyContent={'center'} mb={'20px'}>
        <Flex gap={'20px'}>
          <Input
            placeholder={'keyword search'}
            value={valueInput}
            w={400}
            onChange={handleChangeInput}
          />
          <Button
            _hover={{ bg: '#3F6FBA', cursor: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            w={160}
            onClick={handleSearchAdministrator}
          >
            Search
          </Button>
        </Flex>
      </Flex>
      {dataAdministratorInActive && !!dataAdministratorInActive.length && (
        <>
          <Box overflowX={'auto'} w={'100%'}>
            <TableCustom
              columns={columns}
              data={dataAdministratorInActive || []}
            />
          </Box>
          <Pagination
            page={currentPage}
            totalPage={totalPage}
            onChange={handleNextPage}
          />
        </>
      )}
      {dataAdministratorInActive?.length === 0 && !isLoading && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          No Data
        </Flex>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Restore</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to restore this administrator account? The
            user of this account will be able to login and edit data immediately
            after this is applied.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleRestore}>
              Save
            </Button>
            <Button
              colorScheme={'black'}
              mr={3}
              variant="ghost"
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
