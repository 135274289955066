export type Option = {
  value: string
  label: string
}
export const OptionSelect: Option[] = [
  {
    label: 'Retailer',
    value: 'retailer-link-user',
  },
  {
    label: 'Product',
    value: 'product-page-log',
  },
  {
    label: 'Video',
    value: 'video-log',
  },
  {
    label: 'Skin Tracker',
    value: 'skin-tracker-user-use',
  },
  {
    label: 'Saved Products',
    value: 'product-save-history-log',
  },
]
