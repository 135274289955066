import React from 'react'
import styled from '@emotion/styled'
import { Flex, Text } from '@chakra-ui/layout'
import { Box, Button, Switch, Image } from '@chakra-ui/react'
import { InputForm } from '../../../../components/modules/InputForm'
import { TextAreaBase } from '../../../../components/elements/TextAreaBase'
import { useCreatePromoteRetailer } from './hooks'

/**
 * @returns Component PromotedRetailerCreate Screen
 */
export const PromotedRetailerCreateScreen = () => {
  const {
    isLoading,
    handleSubmit,
    formValue,
    errors,
    handleChangeInput,
    handleTogglePromotion,
  } = useCreatePromoteRetailer()

  return (
    <>
      <StyledContainer>
        <Box maxW={400}>
          <InputForm
            isRequired
            error={errors?.name?.message}
            isInvalid={!!errors?.name?.message}
            label={'Name'}
            name={'name'}
            value={formValue?.name}
            onChange={handleChangeInput}
          />
        </Box>
        <TextAreaBase
          isRequired
          error={errors?.description?.message}
          isInvalid={!!errors?.description?.message}
          label={'Description'}
          name={'description'}
          placeholder="Enter description ..."
          value={formValue?.description ?? ''}
          onChange={handleChangeInput}
        />
        <Flex alignItems={'center'} gap={'16px'}>
          <TextPromotion>Promotion</TextPromotion>
          <Switch
            isChecked={formValue.promoted}
            size="md"
            onChange={handleTogglePromotion}
          />
        </Flex>
        <StyledWrapper>
          <InputForm
            isRequired
            error={errors?.imgUrl?.message}
            isInvalid={!!errors?.imgUrl?.message}
            label={'Image URL'}
            labelWidth={60}
            name={'imgUrl'}
            placeholder={''}
            value={formValue?.imgUrl}
            onChange={handleChangeInput}
          />
          <Box>
            {formValue?.imgUrl && (
              <StyledImage
                alt="image product"
                objectFit="contain"
                src={formValue?.imgUrl}
              />
            )}
          </Box>
        </StyledWrapper>
      </StyledContainer>
      <StyledWrapperButton>
        <StyledButtonSubmit isLoading={isLoading} onClick={handleSubmit}>
          Submit
        </StyledButtonSubmit>
      </StyledWrapperButton>
    </>
  )
}

const StyledContainer = styled(Flex)`
  gap: 20px;
  flex-direction: column;
  margin: 40px;
`
const StyledWrapperButton = styled(Flex)`
  justify-content: center;
`
const StyledButtonSubmit = styled(Button)`
  font-weight: normal;
  font-size: 14px;
  width: 180px;
  height: 32px;
  border-radius: 25px;
  background: #385187;
  color: #ffffff;
  &:hover {
    background: #385187;
    cursor: pointer;
  }
`
const TextPromotion = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 700;
`
const StyledWrapper = styled(Flex)`
  flex-direction: column;
  gap: 20px;
`
const StyledImage = styled(Image)`
  height: 150px;
  border-radius: 4px;
  align-self: flex-start;
`
