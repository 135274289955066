import React from 'react'
import { Box, Flex } from '@chakra-ui/layout'
import { Button, Spinner } from '@chakra-ui/react'
import { SearchIcon } from '@chakra-ui/icons'
import moment from 'moment'
import { TableCustom } from '../../../../components/elements/TableCustom'
import { Pagination } from '../../../../components/modules/Pagination'
import { Select } from '../../../../components/elements/Select'
import { useHistoryFileUpload } from './hook'
import { OptionSelect } from './constants'

/**
 * @returns Component  HistoryFileUpload Page
 */
export const HistoryFileUploadScreen = () => {
  const {
    dataHistoryFileUpload,
    totalPage,
    currentPage,
    handleNextPage,
    isLoading,
    handleChangeSelect,
    valueSelect,
    handleFindUpload,
  } = useHistoryFileUpload()

  const columns = [
    {
      title: 'ID',
      render: record => <Box>{record?.id}</Box>,
    },
    {
      title: 'File name',
      render: record => <Box>{record?.filename}</Box>,
    },
    {
      title: 'File Size',
      render: record => <Box>{record?.fileSize}</Box>,
    },
    {
      title: 'Uploaded At',
      render: record => (
        <Box>{moment(record?.uploadedAt).format('YYYY/MM/DD, HH:mm:ss A')}</Box>
      ),
    },
    {
      title: 'type',
      render: record => <Box>{record?.type}</Box>,
    },
  ]
  if (isLoading) {
    return (
      <Flex justifyContent={'center'} mt={10}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <Flex flexDirection={'column'} gap={10}>
      <Flex alignItems={'center'} justifyContent={'space-between'}>
        <Flex alignItems={'center'} gap={2}>
          <Select
            options={OptionSelect}
            value={valueSelect}
            width={250}
            onChange={newValue => handleChangeSelect(newValue)}
          />
          <Button
            _hover={{ bg: '#3F6FBA', hover: 'pointer' }}
            bg={'#3F6FBA'}
            color={'#fff'}
            leftIcon={<SearchIcon />}
            w={160}
            onClick={handleFindUpload}
          >
            Find
          </Button>
        </Flex>
      </Flex>
      {dataHistoryFileUpload && !!dataHistoryFileUpload.length && (
        <>
          <Box overflowX={'auto'} w={'100%'}>
            <TableCustom columns={columns} data={dataHistoryFileUpload || []} />
          </Box>
          <Pagination
            page={currentPage}
            totalPage={totalPage}
            onChange={handleNextPage}
          />
        </>
      )}
      {dataHistoryFileUpload?.length === 0 && !isLoading && (
        <Flex alignItems={'center'} justifyContent={'center'}>
          No Data
        </Flex>
      )}
    </Flex>
  )
}
